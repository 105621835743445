import { useEffect, useLayoutEffect } from 'react';

const useMobileRedirect = () => {
  useLayoutEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      // Get the current URL parts
      const { pathname, search } = window.location;
      // console.log('ran desktop')

      if (screenWidth > 768) {
        // Redirect to oauarchive.com with the original path and search
        // window.location.href = `https://oauarchive.com${pathname}${search}`;
        const prevUrl = window.location.href;
        // const newUrl = `http://localhost:3000${pathname}${search}`;
        const newUrl = `https://oauarchive.com${pathname}${search}`;

        if(prevUrl.toString() === newUrl.toString()){
          return ''
        }

        //else
        // window.location.href = `http://localhost:3000${pathname}${search}`
        window.location.href = `https://oauarchive.com${pathname}${search}`;


      } 
      else {
        // Redirect to mobile.oauarchive.com with the original path and search
        // window.location.href = `https://mobile.oauarchive.com${pathname}${search}`;
        // window.location.href = `http://localhost:3001${pathname}${search}`;

        const prevUrl = window.location.href;
        // const newUrl = `http://localhost:3001${pathname}${search}`;
        const newUrl = `https://mobile.oauarchive.com${pathname}${search}`;


        if(prevUrl.toString() === newUrl.toString()){
          return ''
        }

        //else
        // window.location.href = `http://localhost:3001${pathname}${search}`
        window.location.href = `https://mobile.oauarchive.com${pathname}${search}`;

      }
    };

    // Check on initial mount
    handleResize();

    // Attach the event listener
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
};

export default useMobileRedirect;




// import { useEffect } from 'react';

// const useMobileRedirect = () => {
//   useEffect(() => {
//     const handleResize = () => {
//       const screenWidth = window.innerWidth;
//       if (screenWidth > 768) {
//         // Redirect to oauarchive.com
//         window.location.href = 'https://oauarchive.com';
//       } else {
//         // Redirect to mobile.oauarchive.com
//         window.location.href = 'https://mobile.oauarchive.com';
//       }
//     };

//     // Check on initial mount
//     handleResize();

//     // Attach the event listener
//     window.addEventListener('resize', handleResize);

//     // Clean up the event listener on component unmount
//     return () => {
//       window.removeEventListener('resize', handleResize);
//     };
//   }, []);
// };

// export default useMobileRedirect;





















// import { useEffect } from 'react';

// const useMobileRedirect = () => {
//   useEffect(() => {
//     const handleResize = () => {
//       const screenWidth = window.innerWidth;
//       if (screenWidth < 769) {
//         // Redirect to mobile.oauarchive.com
//         window.location.href = 'http://mobile.oauarchive.com';
//       }
//     };

//     // Check on initial mount
//     handleResize();

//     // Attach the event listener
//     window.addEventListener('resize', handleResize);

//     // Clean up the event listener on component unmount
//     return () => {
//       window.removeEventListener('resize', handleResize);
//     };
//   }, []);
// };

// export default useMobileRedirect;
