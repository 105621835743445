import { BookmarkAdd, DoubleArrow, Email, KeyboardArrowLeft, KeyboardArrowRight, Menu, MoreHoriz, Person3, Sell, Settings, Share, Visibility } from '@mui/icons-material'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { UseShowTopbar } from '../../../hooks/UseTopbarVisibility'
import { UseHideSidebar, UseShowSidebar } from '../../../hooks/UseSidebarVisibility'
import { CLIENT_LINKS } from '../../../utils/LINKS'
import { useAuth } from '../../../contexts/AuthContext'
import { scrollToTopInstantUtil, scrollToTopUtil, textLengthShortened } from '../../../utils/Utils'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import './viewAllThesesPage.scss'
import { useOtherContext } from '../../../contexts/OtherContext'
// import ReusableThesisItem from '../../../components/reusables/ReusableThesisItem/ReusableThesisItem'
import ReusablePaginationComponent from '../../../components/reusables/ReusablePaginationComponent/ReusablePaginationComponent'
import ReusableThesisItem from '../../../components/reusables/ReusableThesisItem/ReusableThesisItem'
import { ReusableDraggableDialog } from '../../../components/reusables/ReusableDraggableDialog/ReusableDragableDialog'
import { ReusableLoader3, ReusableLoaderInCenter } from '../../../components/reusables/ReusableLoaders/ReusableLoaders'
import { LinearProgress } from '@mui/material'
import ReusableSnackbarComponent from '../../../components/reusables/ReusableSnackbarComponent/ReusableSnackbarComponent'
import { ReusablePageSettingsDialogClient } from '../../../components/reusables/ReusablePageSettingsDialog/ReusablePageSettingsDialogClient'






const sortOptions = {
  title_asc: 'Title (A-Z)',
  title_desc: 'Title (Z-A)',
  year_asc: 'Year (from oldest)',
  year_desc: 'Year (from latest)',
  author_asc: 'Author (A-Z)',
  author_desc: 'Author (Z-A)',
  recent: 'Most Recent',
  oldest: 'Oldest',
  latest_modified: 'Latest Modified',
  oldest_modified: 'Oldest Modified',
  email_asc: 'Email (A-Z)',
  email_desc: 'Email (Z-A)',
  // first_name_asc: 'First Name (A-Z)',
  // first_name_desc: 'First Name (Z-A)',
  // last_name_asc: 'Last Name (A-Z)',
  // last_name_desc: 'Last Name (Z-A)',
  privilege_level_asc: 'Privilege Level (Low to High)',
  username_asc: 'Username (A-Z)',
  username_desc: 'Username (Z-A)',
  matric_id_asc: 'Matric ID (A-Z)',
  matric_id_desc: 'Matric ID (Z-A)',
  department_asc: 'Department (A-Z)',
  department_desc: 'Department (Z-A)',
  faculty_asc: 'Faculty (A-Z)',
  faculty_desc: 'Faculty (Z-A)',
  institution_asc: 'Institution (A-Z)',
  institution_desc: 'Institution (Z-A)',

};

const initialSortBy = sortOptions['Modified (Latest)'];

const ViewAllThesesPage = () => {
  const {user} = useAuth()
  // UseHideTopbar()
  UseShowSidebar()
  UseShowTopbar()
  // UseHideSidebar()

  const [interfaceState, setInterfaceState] = useState({
    snackbar_visible: false,
    snackbar_message: "",
    snackbar_severity: "info",
    snackbar_autohide_duration: 1*60*60*24,
    button_disabled : false,
    isSpinnerActive: false,
    isPageSettingsOpened: false

    // confirmation_prompt: false //just use a different state so you can use useeffect of this state, instead of typing each property
  })
  
  const showSnackbar = (val = true) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_visible: true  }))
  }
  const hideSnackbar = (val = true) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_visible: false  }))
  }
  const toggleInterfaceVisibility = (name) => {
    setInterfaceState((prev) => ({ ...prev,  [name]: !prev[name]  }))
  }
  const setSnackbarMessage = (message) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_message: message  }))
  }
  const setSnackbarSeverity = (severity) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_severity: severity  }))
  }
  const setSnackbarDuration = ( duration) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_autohide_duration: duration  }))
  }
  const handleInterfaceState = (name, value_passed) => {
    setInterfaceState((prev) => ({ ...prev,  [name]: value_passed  }))
  }

  const disableButton = () => {
    setInterfaceState((prev) => ({ ...prev,  button_disabled: true  }))
  }
  const enableButton = (name, value_passed) => {
    setInterfaceState((prev) => ({ ...prev,  button_disabled: false  }))
  }
  const showSpinner = () => {
    setInterfaceState((prev) => ({ ...prev,  isSpinnerActive: true  }))
  }
  const hideSpinner = () => {
    setInterfaceState((prev) => ({ ...prev,  isSpinnerActive: false  }))
  }

  const alertSnackbar = (message="hello, user", type="info", duration=9999999) => {
    setSnackbarMessage(message)
    setSnackbarSeverity(type)
    setSnackbarDuration(duration)
    showSnackbar()
  }

    const togglePageSettingsUI = () => {
    setInterfaceState(prev => ({
      ...prev,
      isPageSettingsOpened: !prev.isPageSettingsOpened
    }))
  }


  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL
  })
  


  const {search_state, search_state_session, dispatch, sidebar_width, force_update, current_page} = useOtherContext()

  const location = useLocation();
  useEffect(() => {
    //set to page 1 when we hit this page coming from a different page
    dispatch({
      type: 'MODIFY_SEARCH_STATE_SESSION',
      newObj: 'current_page',
      payload: 1
    })
  }, [location.pathname])
  // const [currentPage, setCurrentPage] = useState(1);

  const [queryState, setQueryState] = useState({
    // baseURL: `/users/find_all_users_advanced_search?itemsPerPage`,
    // itemsPerPage: admin_results_page_items,
    // current_page: admin_current_page,
    page_size: search_state?.req_query_page_size || 10,
    page_num: search_state_session?.current_page || 1,
    sort: '' ,
    // searchBy: '',
    // input_box_value: ''

  })

  const handleQueryState = (e) => {
    const {name, value} = e.target
    setQueryState(prev => ({
      ...prev,
      [name]: value
    }))
  }

  const fetchAllThesisPaginated = async () => {
    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_URL
    })

    const response = await axiosInstance.get(`/theses/view_all_theses_paginated`, 
    { 
      params: {
        page_size: search_state?.req_query_page_size || 10,
        // page_num: current_page || 1
        // page_size: search_state?.req_query_page_size || 10,
        page_num: search_state_session?.current_page || 1,
        sort: queryState.sort
      }
    }
    );
    // console.log(response.data.message)
    return response.data.message;
  };
  const { 
    isLoading, 
    isFetching,
    error: errorForFetchedThesesPaginated, 
    data:fetched_thesis_paginated, refetch} = useQuery({
    queryKey: ['view_all_thesis_paginated'],
    queryFn: () =>
      fetchAllThesisPaginated()
  })

  useEffect(() => {

    const alertError = () => {
      if(!fetched_thesis_paginated && !isFetching && !isLoading && errorForFetchedThesesPaginated){
        const error = errorForFetchedThesesPaginated
        // setSnackbarDuration(60000)
        alertSnackbar(`${error?.response?.data?.message || 'Unable to fetch theses. An errror occurred'}`, 'error', 600000000000 )
      }
    }
    alertError()
  }, [fetched_thesis_paginated, isLoading, isFetching, errorForFetchedThesesPaginated])

  useEffect(() => {

    if(fetched_thesis_paginated){
      dispatch({
        type: 'SET_MAX_PAGE',
        payload: fetched_thesis_paginated?.total_pages  || 1
      })
    }
  }, [fetched_thesis_paginated])


  useEffect(() => {

    // refetch()

    const handleRefetch = async () => {
      const scrollPosition = window.scrollY;
  
      try {
        await refetch();
      } 
      finally {
        // After the refetch is complete, set the scroll position back to where it was
        window.scrollTo(0, scrollPosition);
      }
    };
    handleRefetch()

  }, [search_state_session?.force_update])

  console.log(search_state_session?.force_update)





  



  // const queryClient = useQueryClient()

  const handlePageChange = (newPage) => {
    // Handle the page change logic here
    // setCurrentPage(newPage);
    dispatch({
      type: 'MODIFY_SEARCH_STATE_SESSION',
      newObj: 'current_page',
      payload: newPage
    })

    // refetch()

    //use this instead
    
  };


  useEffect(() => {

    const runRefetchAndScroll = async () => {
      try {
        await refetch()
        scrollToTopInstantUtil()
      } 
      catch (error) {
        console.log(error)
      }

    }
    runRefetchAndScroll()
    
  
    return () => {
    }
  }, [
    search_state_session.current_page, 
    search_state?.req_query_page_size,
    queryState.sort
  ])
  


  

  // console.log(fetched_thesis_paginated)
  // console.log(fetched_thesis_paginated.totalPages)

  

  return (
    <div className='view-all-theses-page'>
      <ReusableSnackbarComponent
        severity={interfaceState.snackbar_severity}
        open={interfaceState.snackbar_visible}  
        inner_message={interfaceState.snackbar_message} 
        setOpen={() => toggleInterfaceVisibility('snackbar_visible')}
        autoHideDuration={ interfaceState.snackbar_autohide_duration || 2000}
      />
      <div className='view-all-theses-page-wrapper'>
        <div>
          <h3
          style={{
            fontWeight: '300'
          }}
          >Theses Database</h3>
        </div>
        {
        fetched_thesis_paginated?.total_hits &&
        <div style={{fontSize: '0.8rem', fontWeight: '200', marginBottom:'0.5rem'}}>
          <span>Total matches : </span>
          <span>{fetched_thesis_paginated?.total_hits || 0}</span>
        </div>
        }
        {
        (isFetching || isLoading) &&
        <div>
          <LinearProgress />
        </div>
        }

        {
        fetched_thesis_paginated && !isFetching && !isLoading &&
        <div className='homepage-wrapper-random-suggestions'>
          <div className='homepage-wrapper-random-suggestions-wrapper'>
            <div className='homepage-wrapper-random-suggestions-wrapper-items'>
            {
            fetched_thesis_paginated?.results?.length > 0 &&
            fetched_thesis_paginated?.results?.map((thesis, index) => (
              <ReusableThesisItem
              key={thesis?._id || index} 
              // key={index} 
              passed_thesis={thesis} 
              authUser={user} />

            ))
            }
            {
            fetched_thesis_paginated?.results?.length < 1 &&
            <div>No records exists</div>
            }
            {
            isFetching && isLoading && !fetched_thesis_paginated &&
            <ReusableLoaderInCenter passed_loader={<ReusableLoader3 />} />
            }
            </div>
          </div>
        </div>
        }
        {
        errorForFetchedThesesPaginated && !fetched_thesis_paginated && !isFetching &&
        <div>Unable to fetch theses</div>
        }

        <div className='homepage-paginationComponent-retainer'
        style={{
          width: `${100 - sidebar_width}%`
        }}
        >
          <div className='homepage-paginationComponent-retainer-inner'>
            <div>
            <ReusablePaginationComponent
            // currentPage={currentPage} 
            currentPage={search_state_session.current_page}
            totalPages={fetched_thesis_paginated?.total_pages} 
            onChange={handlePageChange} />
            {/* Hello */}
            </div>
            <div className='amappc-bottom-right'>
                {/* <span>
                  <select
                    className='sort-results-by-select'
                    id='sort'
                    name='sort'
                    onChange={handleQueryState}
                    // defaultValue={sortOptions.mo}
                    // value={filtersState.sortBy}
                    // onChange={handleSortByChange}
                  >
                    <option value='' key={""}>-- Sort results by --</option>
                    {
                    // note that we passed, key ie object key, instead of object property/value
                    Object.entries(sortOptions).map(([value, label]) => (
                    <option key={value} value={value}>
                        {label}
                    </option>
                    ))}
                  </select>
                </span> */}
                <span
                onClick={togglePageSettingsUI}
                className='amappc-bottom-right-settings-icon-container'
                >
                  <Settings fontSize='1rem' />
                </span>
                <ReusableDraggableDialog
                header={"Page Settings"}
                main_body={
                  <ReusablePageSettingsDialogClient 
                    page_context={useOtherContext()} 
                    setIsModalOpened={togglePageSettingsUI}
                    isModalOpened={interfaceState.isPageSettingsOpened}
                  />
                } 
                isOpened={interfaceState.isPageSettingsOpened}
                setIsOpened={togglePageSettingsUI}
                />
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}


export default ViewAllThesesPage