import { createContext, useContext, useEffect, useReducer, useState } from 'react'
// import authReducer from './AuthReducer'


const otherContextReducer = (state, action) => {
    // console.log('fired switch')
  switch (action.type) {
    case "SET_THESES_SEARCH_URL":
        // console.log('fired login start')
        return {
            ...state,
            thesis_search_url: action.payload
        }
    case "TOGGLE_DUMMY_STATE":
        // console.log('fired login start')
        return {
            ...state,
            dummy_state: !state.dummy_state
        }
    case "TOGGLE_DUMMY_STATE_2":
        console.log('fired login start')
        return {
            ...state,
            dummy_state_2: !state.dummy_state_2
        }
    case "TOGGLE_SIDEBAR_ENABLED":
        // console.log('fired login start')
        return {
            ...state,
            sidebar_enabled: !state.sidebar_enabled
        }
    case "HIDE_SIDEBAR":
        return {
            ...state,
            sidebar_enabled: false
        }
    case "SHOW_SIDEBAR":
        return {
            ...state,
            sidebar_enabled: true
        }

    case "HIDE_TOPBAR":
    return {
        ...state,
        topbar_enabled: false
    }
case "SHOW_TOPBAR":
    return {
        ...state,
        topbar_enabled: true
    }
    case "SET_SIDEBAR_WIDTH":
        return {
            ...state,
            sidebar_width: action.payload //should be small, medium, or large
        }
    
    case "SET_TOPBAR_HEIGHT":
    return {
        ...state,
        topbar_height: action.payload
    }

    
    case "SET_OCR_OUTPUT":
        return {
            ...state,
            ocr_output: action.payload //should be small, medium, or large
        }
    case "DELETE_OCR_OUTPUT":
    return {
        ...state,
        ocr_output: '' //should be small, medium, or large
    }
    case "ON_IS_DATA_FETCHED":
        return {
        //this place is fragile
        ...state,
        isDataFetched: true
    }
    case "OFF_IS_DATA_FETCHED":
        // console.log('fired login failure')
            return {
            //this place is fragile
            ...state,
            isDataFetched: false
    }
    case "MODIFY_SEARCH_STATE":
        console.log('fire complex')
        //complex modification
        return{
            ...state,
            search_state: {...state.search_state, 
                [action.newObj]: action.payload
            }
            
        }
    case "MODIFY_SEARCH_STATE_SESSION":
    // console.log('fire complex')
    //complex modification
    return{
        ...state,
        search_state_session: {
            ...state.search_state_session, 
            [action.newObj]: action.payload
        }
    }
    case "MODIFY_CURRENT_PAGE_future":
        // console.log(action.payload)
    // console.log(state.search_state_session.force_update)
    //complex modification
        return{
            ...state,
            current_page: action.payload
        }
    case "FORCE_UPDATE_future": 
        return {
            ...state,
            force_update: !state.force_update
        }
    case "FORCE_UPDATE":
        console.log(state.search_state_session.force_update)
        //complex modification
        return{
            ...state,
            search_state_session: {
                ...state.search_state_session, 
                force_update: !state.search_state_session.force_update
            }
        }
    case "SET_MAX_PAGE": 
    return {
        ...state,
        max_page: action.payload || 1
    }
    

    default:
        return state;
      
  }
}

const INITIAL_STATE = {
    // sidebar_width: JSON.parse(localStorage.getItem("sidebar_width")) || 20 ,
    sidebar_width: JSON.parse(localStorage.getItem("sidebar_width")) || 50 ,//in percent
    sidebar_enabled: JSON.parse(localStorage.getItem("sidebar_enabled")) || true,
    topbar_height: JSON.parse(localStorage.getItem("topbar_height")) || 4,
    //vh
    topbar_enabled: JSON.parse(localStorage.getItem("topbar_enabled")) || true,
    //vh
    ocr_output: JSON.parse(localStorage.getItem("ocr_output")) || '',
    isLoading: false,
    error: false,
    isDataFetched: JSON.parse(localStorage.getItem("isDataFetched")) || false,
    search_state: JSON.parse(localStorage.getItem("search_state")) || {
        req_query_page_size: 10
    },
    search_state_session: JSON.parse(sessionStorage.getItem("search_state_session")) || 
    {
        current_page: 1,
        force_update: false
    },
    max_page: JSON.parse(sessionStorage.getItem("max_page")) || 1, //gotten from query length
    current_page: JSON.parse(sessionStorage.getItem("current_page")) || 1,
    force_update: JSON.parse(sessionStorage.getItem("force_update")) || false,
    dummy_state: false,
    dummy_state_2: false,
    thesis_search_url: ''
}

export const OtherContext = createContext(INITIAL_STATE)


export const OtherContextProvider = ({children}) => {
  const [state, dispatch] = useReducer(otherContextReducer, INITIAL_STATE)

  useEffect(() => {
    localStorage.setItem("sidebar_width", JSON.stringify(state.sidebar_width))
    localStorage.setItem("sidebar_enabled", JSON.stringify(state.sidebar_enabled))
    localStorage.setItem("topbar_height", JSON.stringify(state.topbar_height))
    localStorage.setItem("topbar_enabled", JSON.stringify(state.topbar_enabled))
    localStorage.setItem("ocr_output", JSON.stringify(state.ocr_output))
    localStorage.setItem("isDataFetched", JSON.stringify(state.isDataFetched)) //local
    localStorage.setItem("search_state", JSON.stringify(state.search_state)) //local
    sessionStorage.setItem("search_state_session", JSON.stringify(state.search_state_session)) //session
    sessionStorage.setItem("current_page", JSON.stringify(state.current_page)) //session
    sessionStorage.setItem("force_update", JSON.stringify(state.force_update)) //session
    sessionStorage.setItem("max_page", JSON.stringify(state.max_page)) //session
}, [state])

  return (
    <OtherContext.Provider
    value={{
        sidebar_width: state.sidebar_width,
        sidebar_enabled: state.sidebar_enabled,
        ocr_output: state.ocr_output,
        error:state.error,
        isDataFetched: state.isDataFetched,
        topbar_height: state.topbar_height,
        topbar_enabled: state.topbar_enabled,
        search_state: state.search_state,
        dummy_state: state.dummy_state,
        dummy_state_2: state.dummy_state_2,
        thesis_search_url: state.thesis_search_url,
        search_state_session: state.search_state_session,
        current_page: state.current_page, //not in user by client, only admin uses it
        force_update: state.force_update,
        max_page: state.max_page,
        dispatch,
    }}>
        {!state.isLoading && children}
    </OtherContext.Provider>
  )
}

export const useOtherContext = () => {
  return useContext(OtherContext)
}

// export default ThesisContext