import { useLocation, useNavigate } from "react-router-dom"



export const useNavConsiderPrevRoute = (to_url) => {
    //very useful for protected routes, 
    //for experiment, we can use this and always set the back button to navigate to the previous route
    const navigate = useNavigate()
    const location = useLocation()

        // console.log(location)
    //use this from unauthenticated page to login page
    //to url
    const navAndAddPrevLocation = (toUrl) => {
        navigate(
            toUrl, 
            {state: 
                {...location.state, comingFrom: location.pathname }
                // {...location.state, comingFrom: comingFromUrl}
            }
        ) 
    }
    //for example if we are trying to navigate to login page
    //from settings page and we are then redirected to login page because we are unauthenticated
    //the nav will store the settings route and finally when we authenticate and try to navigate, we will be redirected to the settings page

    //use this for every location, especially login page
    const navAndUsePrevLocation = (toUrl) => {
        //must have used navAndAddLocation, lest this will work as a regular navigate function
        if(location?.state?.comingFrom){
            //if we specified somewhere before we were rerouted for authentication?
            navigate(location?.state?.comingFrom || '')
        }
        else{
            navigate(toUrl)
        }
    }

    return {
        navAndAddPrevLocation,
        navAndUsePrevLocation
    }

}


