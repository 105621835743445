import { useEffect, useState } from "react"
import { useOtherContext } from "../../../contexts/OtherContext"
import './resusablePageSettingsDialogAdmin.scss'

export const ReusablePageSettingsDialogClient = ({page_context, isModalOpened, setIsModalOpened}) => {
    // console.log(page_context)
    const {admin_force_update, admin_current_page, dispatch, admin_results_page_item, search_state, search_state_session, max_page} = page_context

    // const {max_page, se} = useOtherContext()
    // console.log(admin_force_update, admin_current_page )
    const items_per_page = search_state?.req_query_page_size || 10
  
  
    const [componentState, setComponentState] = useState({
      old_results_items_per_page: search_state?.req_query_page_size || 10,
      results_items_per_page: search_state?.req_query_page_size || 10,
      current_page: search_state_session?.current_page || 1,
      old_current_page: search_state_session?.current_page || 1,
  
    })

    const handleChangeResultItemsPerPage = (e) => {

      if( (parseInt(e.target.value) < 1) || !e.target.value){
        return  setComponentState(prev => ({
          ...prev,
          results_items_per_page : parseInt(1)
        }))
      }

      setComponentState(prev => ({
        ...prev,
        results_items_per_page : parseInt(e.target.value)
      }))
    }

    const setResultItemsPerPage = () => {
      // console.log(e.target.value)
      const per_items = componentState.results_items_per_page

      if(!per_items || parseInt(per_items) <=0){
        dispatch({
          type: 'MODIFY_SEARCH_STATE',
          newObj: 'req_query_page_size',
          payload: parseInt(10) 
        })
  
        //to hide set button
        setComponentState(prev => ({
          ...prev,
          old_results_items_per_page: 10
        })) 
        return ''
      }


      dispatch({
        type: 'MODIFY_SEARCH_STATE',
        newObj: 'req_query_page_size',
        payload: parseInt(per_items) 
      })

      //to hide set button
      setComponentState(prev => ({
        ...prev,
        old_results_items_per_page: prev.results_items_per_page
      }))
    }
  
    
    const handleChangePageNumber = (e) => {

      if(parseInt(e.target.value) > max_page){
        return  setComponentState(prev => ({
          ...prev,
          current_page : parseInt(max_page || 1)
        }))
      }

      // if( (parseInt(e.target.value) < 1) || !e.target.value){
      //   return  setComponentState(prev => ({
      //     ...prev,
      //     current_page : parseInt(0)
      //   }))
      // }

      setComponentState(prev => ({
        ...prev,
        current_page : parseInt(e.target.value)
      }))
    }

    useEffect(() => {
      //wehen the result page items changes, it affects the max page
    //if the max page is currently higher than it should be, then set it 
    const keepPageNumberWithinLimit = () => {
      if(componentState.current_page > max_page ){
        setComponentState(prev => ({
          ...prev,
          current_page : max_page
        }))
      }
    }

    keepPageNumberWithinLimit()

  }, [search_state?.req_query_page_size, max_page])

    const setGoToPageNumber = (e) => {
      // console.log(

      if( parseInt(componentState.current_page) > max_page){
        dispatch({
          type: 'MODIFY_SEARCH_STATE_SESSION',
          newObj: 'current_page',
          payload: parseInt(componentState.current_page)
        })

        setComponentState(prev => ({
          ...prev,
          old_current_page: prev.current_page
        }))
    
        //close the modal
        return setIsModalOpened(!isModalOpened)
      }

      if( !componentState.current_page || componentState.current_page <= 0 ){
        dispatch({
          type: 'MODIFY_SEARCH_STATE_SESSION',
          newObj: 'current_page',
          payload: parseInt(1)
        })

        setComponentState(prev => ({
          ...prev,
          old_current_page: 1
        }))
    
        //close the modal
        return setIsModalOpened(!isModalOpened)
      }

      dispatch({
        type: 'MODIFY_SEARCH_STATE_SESSION',
        newObj: 'current_page',
        payload: parseInt(componentState.current_page)
      })
  
  
      //to hide set button
      setComponentState(prev => ({
        ...prev,
        old_current_page: prev.current_page
      }))
      //close the modal
      setIsModalOpened(!isModalOpened)
    }
  
    // console.log(
    
    return(
      <div className='admin-page-page-settings-component'>
        <div className='admin-page-page-settings-component-inner'>
          <div className='admin-page-page-settings-item'> 
            <label  className='admin-page-page-settings-item-label'>result items per page</label>
            <input 
            className='admin-page-page-settings-item-input'
            defaultValue={search_state?.req_query_page_size || 5}
            onChange={(e) => handleChangeResultItemsPerPage(e)}
            // value={admin_results_page_items}
            type='number' min={1} max={100}  placeholder='enter page'/>
            {
            componentState?.results_items_per_page !== componentState?.old_results_items_per_page &&
            <button 
            className='admin-page-page-settings-item-button'
            onClick={setResultItemsPerPage}
            >set</button> 
            }
          </div>
          <div className='admin-page-page-settings-item'> 
            <label className='admin-page-page-settings-item-label'>go to page</label>
            <input 
            className='admin-page-page-settings-item-input'
            onChange={(e) => handleChangePageNumber(e)}
            defaultValue={admin_current_page}
            value={componentState.current_page}
            type='number' min={1} max={max_page || 1}  placeholder='go to page'/> 
            {
            //render it if it not equal
            componentState?.current_page !== componentState?.old_current_page &&
            <button 
            className='admin-page-page-settings-item-button'
            onClick={setGoToPageNumber}
            >Go to Page</button>
            }
          </div>
        </div>
  
      </div>
    )
  }