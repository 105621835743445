import { createContext, useContext, useEffect, useReducer, useState } from 'react'
// import authReducer from './AuthReducer'


const adminContextReducer = (state, action) => {
    // console.log('fired switch')
  switch (action.type) {
    case "MODIFY_SEARCH_STATE":
        // console.log('fire complex')
        //complex modification
        return{
            ...state,
            search_state: {...state.search_state, 
                [action.newObj]: action.payload
            }
            
        }
    case "MODIFY_SEARCH_STATE_SESSION":
    // console.log('fire complex')
    //complex modification
    return{
        ...state,
        search_state_session: {
            ...state.search_state_session, 
            [action.newObj]: action.payload
        }
    }


    case "MODIFY_ADMIN_RESULTS_PAGE_ITEMS":
        // console.log('fire complex')
        //complex modification
        return{
            ...state,
            admin_results_page_items: action.payload
    }
    case "MODIFY_ADMIN_URL_SEARCH_STRING":
        // console.log('fire complex')
        //complex modification
        return{
            ...state,
            admin_url_search_string: action.payload
    }
    case "ADMIN_FORCE_UPDATE":
        // console.log('ADMIN FORCE UPDATE')
        // console.log(state.search_state_session.force_update)
        //complex modification
        return{
            ...state,
            admin_force_update: !state.admin_force_update
        }
    case "MODIFY_ADMIN_CURRENT_PAGE":
        // console.log(action.payload)
    // console.log(state.search_state_session.force_update)
    //complex modification
        return{
            ...state,
            admin_current_page: action.payload
        }
    case "SET_ADMIN_MAX_PAGE":
    // console.log(action.payload)
// console.log(state.search_state_session.force_update)
//complex modification
    return{
        ...state,
        admin_max_page: action.payload
    }
    

    default:
        return state;
      
  }
}

const INITIAL_STATE = {
    admin_force_update: JSON.parse(sessionStorage.getItem("admin_force_update")) || false,
    admin_results_page_items: JSON.parse(localStorage.getItem("admin_results_page_items")) || 5, //local storage
    admin_current_page: JSON.parse(sessionStorage.getItem("admin_current_page")) || 1,
    admin_url_search_string: JSON.parse(sessionStorage.getItem("admin_url_search_string")) || '',
    admin_max_page: JSON.parse(sessionStorage.getItem("admin_max_page")) || 1,
}
// console.log(INITIAL_STATE)

export const AdminContext = createContext(INITIAL_STATE)


export const AdminContextProvider = ({children}) => {
  const [state, dispatch] = useReducer(adminContextReducer, INITIAL_STATE)

  useEffect(() => {
    localStorage.setItem("admin_results_page_items", JSON.stringify(state.admin_results_page_items)) //local
    sessionStorage.setItem("admin_force_update", JSON.stringify(state.admin_force_update)) //session
    sessionStorage.setItem("admin_current_page", JSON.stringify(state.admin_current_page))
    sessionStorage.setItem("admin_url_search_string", JSON.stringify(state.admin_url_search_string))
    sessionStorage.setItem("admin_max_page", JSON.stringify(state.admin_max_page))
}, [state])
// console.log(state)

  return (
    <AdminContext.Provider
    value={{
        admin_results_page_items: state.admin_results_page_items,
        admin_force_update: state.admin_force_update,
        admin_current_page: state.admin_current_page,
        admin_url_search_string: state.admin_url_search_string,
        admin_max_page: state.admin_max_page,
        dispatch
    }}>
        {children}
    </AdminContext.Provider>
  )
}

export const useAdminContext = () => {
  return useContext(AdminContext)
}

// export default ThesisContext