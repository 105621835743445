import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export const ReusableDraggableDialog = ({header, main_body, bottom, isOpened, setIsOpened, fullScreen, maxWidth, inner_style, outer_style}) => {
  // const [open, setOpen] = React.useState(false);
  const [open, setOpen] = React.useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    // setOpen(false);
    setIsOpened(false)
  };

  return (
    <div style={{
      width:'100%',
      // height: '100%',
      backgroundColor: 'yellow'
    }}>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open draggable dialog
      </Button> */}
      <Dialog
      style={{
        // backgroundColor: 'red'
        backgroundColor: outer_style?.backgroundColor || 'inherit' ,
        height: '100%'
      }}
        fullWidth
        fullScreen={fullScreen}
        open={isOpened}
        onClose={handleClose}
        // onClose={setIsOpened}
        PaperComponent={PaperComponent}
        maxWidth={maxWidth || 'xl'}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle 
        style={{ 
          cursor: 'move', 
          backgroundColor: inner_style?.backgroundColor || 'inherit' 
          }} 
        id="draggable-dialog-title">
          {/* Subscribe */}
          {header || '' }
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        <DialogContent
        style={{
          // backgroundColor: inner_style?.backgroundColor || 'inherit' ,
          // backgroundColor: 'pink',
          // width: '100%',
          // height: '100vh'

        }}
        >
          <DialogContentText>
            {main_body || ''}
          </DialogContentText>
        </DialogContent>
        <DialogActions 
          style={{
            backgroundColor: inner_style?.backgroundColor || 'inherit' 
          }}    >
          {bottom || ''}
          {/* <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={handleClose}>Subscribe</Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export const ReusableDraggableDialogForAppJS = ({header, main_body, bottom}) => {
  // const [open, setOpen] = React.useState(false);
  const [open, setOpen] = React.useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open draggable dialog
      </Button> */}
      <Dialog
        open={open}
        // onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {/* Subscribe */}
          {header || '' }
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {main_body || ''}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {bottom || ''}
          {/* <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={handleClose}>Subscribe</Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}
// export default ReusableDraggableDialog 