import axios from 'axios';
// import { UseHideSidebar } from '../../hooks/UseSidebarVisibility';
// import './test.scss'
import './previewDocument.scss'
import React, { useState } from 'react';
import { UseHideSidebar } from '../../hooks/UseSidebarVisibility';
import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { ReusablePdfComponent } from '../../components/reusables/ReusablePdfComponent/ReusablePdfComponent';
import { Refresh } from '@mui/icons-material';

const PreviewDocument = () => {
  UseHideSidebar()
  const [pdfUrl, setPdfUrl] = useState('');
  const location = useLocation()
  // const { search } = useLocation();
  // const { createThesisPageLocalPdfFromBlob } = new URLSearchParams(location.search);

  const params = new URLSearchParams(location.search);
  const createThesisPageLocalPdfFromBlob = params.get('createThesisPageLocalPdfFromBlob');
  const updateThesisPageLocalPdfFromBlob = params.get('updateThesisPageLocalPdfFromBlob');

  const updateThesisPageServerPdfLink = params.get('updateThesisPageServerPdfLink');

  const thesisId = params.get('thesisId');

  // const {createThesisPageLocalPdfFromBlob} = params

  // console.log(location)
  // console.log(location.search)
  // console.log(createThesisPageLocalPdfFromBlob)

  // const { document_id: document_path} = useParams() //from the app.js route defininition
  // console.log(document_path)
  const fetchPdfModified = async () => {

    
    try {
      const urlToFetch = '/retrieveUploadedPdfHundredPercentWithWatermark'
      const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL
      })
      // console.log('hi')
        // const response = await axios.get(`http://localhost:5000/api/theses/retrieve_uploaded_document_pdf_lib/${document_path}`, {
        //     responseType: 'blob', // Set the response type to blob
        //   });
        // const response = await axiosInstance.get(`/theses/retrieveUploadedPdf/${updateThesisPageServerPdfLink}`, {
        //   responseType: 'blob', // Set the response type to blob
        // });
        const response = await axiosInstance.get(`/theses${urlToFetch}/${updateThesisPageServerPdfLink}?thesisId=${thesisId}`, {
          responseType: 'blob', // Set the response type to blob
        });
        const pdfBlob = response.data;
        const pdfObjectUrl = URL.createObjectURL(pdfBlob);
        setPdfUrl(pdfObjectUrl);
        // console.log(pdfBlob)
    } 
    catch (error) {
      console.error('Error fetching PDF:', error);
    }
  };

  useEffect(() => {
    // first

    const fetchPdfFromServerOrFromLocalBlob = () => {

      // console.log(1)
      if(createThesisPageLocalPdfFromBlob){
        return setPdfUrl(createThesisPageLocalPdfFromBlob)
      }
      if(updateThesisPageLocalPdfFromBlob){
        return setPdfUrl(updateThesisPageLocalPdfFromBlob)
      }
      //else 
      // console.log(3)

      return fetchPdfModified()

    }
    fetchPdfFromServerOrFromLocalBlob()


  }, [])

  // console.log(pdfUrl)

  //we dont need to start checking if you have full text access, if you are here, you must have full text access. Also if you dont have authentication, our monitor will log you out,
  //if you dont have authorization, you are wasting your time here, we dont need to do any expensive checks to notify you that you have access.
  //dont come here if you are not authorized to. Sefini
  
  return (
    <div className='previewServerDocumentPage'>
      <div className='previewServerDocumentPage-inner'>
        {/* {pdfUrl && <iframe src={pdfUrl} width="100%" height="600" title="PDF Viewer" />}
        {
        !pdfUrl &&
        <div>Sorry. File not found</div>
        } */}
      <div style={{width:'100%', margin:"1rem 0rem" }}>
        {/* dummy placeholder dont remove*/}
        <span 
        onClick={()=>fetchPdfModified()}
        style={{lineHeight:'1rem', display:'flex', alignItems: 'center', justifyContent:'flex-end', width:'100%', fontSize:'1.3rem', cursor: 'pointer'}}>
          <Refresh fontSize='1rem' />
        </span>
      </div>
        

        <ReusablePdfComponent pdfFile={pdfUrl} />
      </div>
    </div>
  );
};

export default PreviewDocument