import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import Slide from '@mui/material/Slide';
import Grow from '@mui/material/Grow';

function GrowTransition(props) {
    return <Grow {...props} />;
  }

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ReusableSnackbarComponent = ({severity, autoHideDuration, inner_message, outer_message, open, setOpen, positioning, slide_direction, otherFunctionsPassed}) => {
  //severity "info", warning, error,success
      //slide_direction, left, up, right, down
//   const [open, setOpen] = React.useState(false);
// positioning={{vertical: 'top', horizontal: 'center'}}


const [transition, setTransition] = React.useState(undefined);
  const handleClick = (Transition) => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);

    if(otherFunctionsPassed){
        otherFunctionsPassed()
    }
  };

  //vertical, horizontal
  //top, bottom, center, right, left
  const vertical = positioning?.vertical || 'top'
  const horizontal = positioning?.horizontal || 'center'


  return (
    // <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar 
      anchorOrigin={{ vertical , horizontal}}
      key={vertical + horizontal}
    //   TransitionComponent={<TransitionDirection slide_direction={slide_direction} />} 
      TransitionComponent={GrowTransition} 
      open={open} 
      autoHideDuration={autoHideDuration || 6000000} 
      onClose={handleClose}>
        <Alert onClose={handleClose}  sx={{ width: '100%' }} severity={severity || "info"} >
          {/* This is a success message! */}
          {inner_message}
        </Alert>
      </Snackbar>
    // </Stack> 
  );
}

export default ReusableSnackbarComponent
