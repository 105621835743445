import { Add, ChevronRightOutlined, Close, CopyAll, Copyright, DoubleArrow, EmojiObjects, EmojiObjectsOutlined, EmojiObjectsRounded, InfoOutlined, MoreHoriz, Refresh, Search, Settings, Visibility, VisibilityOutlined } from '@mui/icons-material'
import { UseHideSidebar } from '../../../hooks/UseSidebarVisibility'
import './ViewThesisPage.scss'
import { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { authorizedPrivilegeLevelsThatUpdateFullTextRequest_Util, authorized_privilege_levels_that_delete_thesis_Util, authorized_privilege_levels_that_update_thesis_Util, checkIfUserHasPrivilegeLevelFor_Util, document_append_methods_util, handleCopyTextToClipboard_Util, scrollToTopInstantUtil, scrollToTopUtil, textLengthShortened } from '../../../utils/Utils'
import { CLIENT_LINKS } from '../../../utils/LINKS'
import ReactQuill from 'react-quill'
import { useAuth } from '../../../contexts/AuthContext'
import ReusableSnackbarComponent from '../../../components/reusables/ReusableSnackbarComponent/ReusableSnackbarComponent.jsx'
import { ReusableDraggableDialog } from '../../../components/reusables/ReusableDraggableDialog/ReusableDragableDialog.jsx'
import ReusableFullscreenDialog from '../../../components/reusables/ReusableFullscreenDialog/ReusableFullscreenDialog.jsx'
import ReusableHorizontalScrollMenu from '../../../components/reusables/ReusableHorizontalScrollMenu/ReusableHorizontalScrollMenu.jsx'
import ReusablePaginationComponent from '../../../components/reusables/ReusablePaginationComponent/ReusablePaginationComponent.jsx'
import { useAdminContext } from '../../../contexts/AdminContext.jsx'
import { ReusablePageSettingsDialogAdmin } from '../../../components/reusables/ReusablePageSettingsDialog/ReusablePageSettingsDialogAdmin.jsx'
import { ReusableLoader3, ReusableLoaderInCenter } from '../../../components/reusables/ReusableLoaders/ReusableLoaders.jsx'
import { Fab, LinearProgress } from '@mui/material'

import { pdfjs } from 'react-pdf';
import { ReusablePdfComponent } from '../../../components/reusables/ReusablePdfComponent/ReusablePdfComponent.jsx'

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'pdfjs-dist/build/pdf.worker.min.js',
//   import.meta.url,
// ).toString();
// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
// pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.2.16.105.js'
pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.3.11.174.js'
// pdfjs.GlobalWorkerOptions.workerSrc = '../../../externalScriptsNotForRoot/pdf.worker.min.2.16.105.js'
// pdfjs.GlobalWorkerOptions.workerSrc = '../../../externalScriptsNotForRoot/pdf.worker.min.2.16.105.js'





const  modules = {  };

const sortOptions = {
  recent: 'Recent created',
  oldest: 'Oldest created',
  latest_modified: 'Latest updated',
  oldest_modified: 'Oldest updated',
};


const searchByOptions = {
  email: 'email',
  first_name: 'first name',
  last_name: 'last name',
  username: 'username',
  matric_id: 'matric id',
  department: 'department',
  faculty: 'faculty',
  institution: 'institution',
  institution_abbreviation: 'institution abbreviation',
  user_id: 'user ID' 
}


const FullTextItemDetail = ({passedFullTextItem, open, setOpen}) => {

  // const [componentState, setComponentState] = useState({})
  // useEffect(() => {
  //   setComponentState(prev => ({...prev, ...passedFullTextItem}))
  // }, [])

  // console.log(passedFullTextItem)
  const item = passedFullTextItem
  


  const minimizeDialog  = () => {
    setOpen(!open)
  }
  //for the full details of the request
  return (
    <div className='amap-fullscreen-modal-body'>

      <div className='amap-fullscreen-modal-header'>
        <div className='amap-fullscreen-modal-header-inner'>

          <span
          style={{
            paddingLeft: '1rem'
          }}
          className='title'>Full Text Request Details</span>
          <span 
          onClick={minimizeDialog}
          className='update'>Minimize</span>
        </div>
      </div>
      <div className='amap-fullscreen-modal-body-inner'>
        <div className='amap-fullscreen-modal-body-form-section'>
          <div  style={{display:'flex', width:'100%', justifyContent:'flex-end'}} className='create-thesis-page-form-item'>
            <label>Request was updated at</label>
            <label>  {item?.updatedAt && ( new Date(item?.updatedAt)?.toUTCString() ) }</label>
          </div>
          <div  className='create-thesis-page-form-item'>
            <label>Requesting for the full text of</label>
            <input 
            style = {{ cursor: 'not-allowed'  }}
            disabled={true}
            // onChange={(e) => handleChange(e)}
            className='create-thesis-page-input' type='text' 
            name="first_name" 
            defaultValue={item?.thesis?.title}/>
          </div>
          <div  className='create-thesis-page-form-item'>
            <label>Requester's Email</label>
            <input 
            style = {{ cursor: 'not-allowed'  }}
            disabled={true}
            // onChange={(e) => handleChange(e)}
            className='create-thesis-page-input' type='text' 
            name="first_name" 
            defaultValue={item?.user?.email}/>
          </div>
          {
          item?.message &&
          <div  className='create-thesis-page-form-item'>
            <label>Message</label>
            <textarea 
            style = {{ cursor: 'not-allowed'  }}
            disabled={true}
            // onChange={(e) => handleChange(e)}
            className='create-thesis-page-input' type='text' 
            name="message" 
            defaultValue={item?.message}/>
          </div>
          }
          <div  className='create-thesis-page-form-item'>
            <label>First name</label>
            <input 
            style = {{ cursor: 'not-allowed'  }}
            disabled={true}
            // onChange={(e) => handleChange(e)}
            className='create-thesis-page-input' type='text' 
            name="first_name" 
            defaultValue={item?.user?.first_name}/>
          </div>
          <div  className='create-thesis-page-form-item'>
            <label>Last name</label>
            <input 
            style = {{ cursor: 'not-allowed'  }}
            disabled={true}
            // onChange={(e) => handleChange(e)}
            // onChange={(e) => handleChangeNewThesis(e)}
            className='create-thesis-page-input' type='text' 
            name="last_name" 
            defaultValue={item?.user?.last_name || ''}/>
          </div>
          <div  className='create-thesis-page-form-item'>
            <label>Matric</label>
            <input 
            style = {{ cursor: 'not-allowed'  }}
            disabled={true}
            // onChange={(e) => handleChange(e)}
            // onChange={(e) => handleChangeNewThesis(e)}
            className='create-thesis-page-input' type='text' name="matric_id" 
            defaultValue={item?.user?.matric_id || ''}/>
          </div>
          <div  className='create-thesis-page-form-item'>
            <label>Username</label>
            <input 
            style = {{ cursor: 'not-allowed'  }}
            disabled={true}
            // onChange={(e) => handleChange(e)}
            // onChange={(e) => handleChangeNewThesis(e)}
            className='create-thesis-page-input' type='text' 
            name="username" 
            defaultValue={item?.user?.username || ''}/>
          </div>
          <div  className='create-thesis-page-form-item'>
            <label>Department</label>
            <input
            style = {{ cursor: 'not-allowed'  }}
            disabled={true}
            // onChange={(e) => handleChange(e)}
            // onChange={(e) => handleChangeNewThesis(e)}
            className='create-thesis-page-input' type='text' 
            name="department" 
            defaultValue={item?.user?.department || ''}/>
          </div>
          <div  className='create-thesis-page-form-item'>
            <label>Faculty</label>
            <input 
            style = {{ cursor: 'not-allowed'  }}
            disabled={true}
            // onChange={(e) => handleChange(e)}
            // onChange={(e) => handleChangeNewThesis(e)}
            className='create-thesis-page-input' type='text' 
            name="faculty" 
            defaultValue={item?.user?.faculty || ''}/>
          </div>
          <div  className='create-thesis-page-form-item'>
            <label>Institution</label>
            <input 
            style = {{ cursor: 'not-allowed'  }}
            disabled={true}
            // onChange={(e) => handleChange(e)}
            // onChange={(e) => handleChangeNewThesis(e)}
            className='create-thesis-page-input' type='text' 
            name="institution" 
            defaultValue={item?.user?.institution || ''}/>
          </div>
          <div  className='create-thesis-page-form-item'>
            <label>Institution abbreviation</label>
            <input 
            style = {{ cursor: 'not-allowed'  }}
            disabled={true}
            // onChange={(e) => handleChange(e)}
            // onChange={(e) => handleChangeNewThesis(e)}
            className='create-thesis-page-input' type='text' name="institution_abbreviation" 
            defaultValue={item?.user?.institution_abbreviation || ''}/>
          </div>

        </div>

        <div></div>
      </div>
    </div>
  )
}







const ReusableFullTextItem = ({passedFullTextItem}) => {
  const [interfaceState, setInterfaceState] = useState({
    snackbar_visible: false,
    snackbar_message: "",
    snackbar_severity: "info",
    snackbar_autohide_duration: 2000,
    button_disabled : false,
    fullTextItemDetailVisible: false
    // confirmationDialogVisible: false
    // confirmation_prompt: false //just use a different state so you can use useeffect of this state, instead of typing each property
  })

  const toggleFullTextItemDetailVisible = (name) => {
    console.log('TOGGLED')
    setInterfaceState((prev) => ({ ...prev,  fullTextItemDetailVisible: !prev.fullTextItemDetailVisible  }))
  }
  const showSnackbar = (val = true) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_visible: true  }))
  }
  const hideSnackbar = (val = true) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_visible: false  }))
  }
  const toggleInterfaceVisibility = (name) => {
    setInterfaceState((prev) => ({ ...prev,  [name]: !prev[name]  }))
  }
  const setSnackbarMessage = (message) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_message: message  }))
  }
  const setSnackbarSeverity = (severity) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_severity: severity  }))
  }
  const setSnackbarDuration = ( duration) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_autohide_duration: duration  }))
  }
  const handleInterfaceState = (name, value_passed) => {
    setInterfaceState((prev) => ({ ...prev,  [name]: value_passed  }))
  }

  const disableButton = () => {
    setInterfaceState((prev) => ({ ...prev,  button_disabled: true  }))
  }
  const enableButton = (name, value_passed) => {
    setInterfaceState((prev) => ({ ...prev,  button_disabled: false  }))
  }
  
  const {admin_force_update, dispatch} = useAdminContext()
  const adminForceUpdate = () => {
    dispatch({type: "ADMIN_FORCE_UPDATE"})
  }

  const approveRequest = async () => {
    try{
      disableButton()
      const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL
      })

      const body = {
        ftr_id: passedFullTextItem?._id,
        thesisId: passedFullTextItem?.thesis?._id,
        // message: componentState.message_input || ''
      }
      const res = await axiosInstance.post("full_text_request/authorGrantFullTextApprovalBy/", body)
      console.log(res.data.message)
      
      // return res.data.message
      setSnackbarMessage( `${res.data.message || `Full text of ${passedFullTextItem?.thesis?.title} has been approved for ${passedFullTextItem?.user?.email} `}`)
      setSnackbarSeverity('success')
      showSnackbar()
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 1000); // 10000 milliseconds = 10 seconds
      });

      adminForceUpdate()
    }
    catch(error){
      console.log(error)
      setSnackbarMessage(`${error?.response?.data?.message ||  'Unable to approve request' }`)
      setSnackbarSeverity('error')
      showSnackbar()

    }
    finally{
      enableButton()
    }
    
  }

  const denyRequestShort = async () => {
    try{
      disableButton()

      const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL
      })

      const body = {
        ftr_id: passedFullTextItem?._id,
        thesisId: passedFullTextItem?.thesis?._id,
        longDenial: false
        // message: componentState.message_input || ''
      }
      const res = await axiosInstance.post("full_text_request/authorDenyFullTextApprovalBy/", body)
      // console.log(res.data.message)
      
      // return res.data.message
      setSnackbarMessage( `${res.data.message || `Full text of ${passedFullTextItem?.thesis?.title} has been approved for ${passedFullTextItem?.user?.email} `}`)
      setSnackbarSeverity('success')
      setSnackbarDuration(2000)
      showSnackbar()

      await new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 1000); // 10000 milliseconds = 10 seconds
      });

      adminForceUpdate()
    }
    catch(error){
      console.log(error)
      setSnackbarMessage(`${error?.response?.data?.message ||  'Unable to approve request' }`)
      setSnackbarSeverity('error')
      showSnackbar()

    }
    finally{
      enableButton()
    }
    
  }

  const denyRequestLong = async () => {
    try{
      disableButton()

      const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL
      })

      const body = {
        ftr_id: passedFullTextItem?._id,
        thesisId: passedFullTextItem?.thesis?._id,
        shortDenial: false,
        longDenial: true
        // message: componentState.message_input || ''
      }
      const res = await axiosInstance.post("full_text_request/authorDenyFullTextApprovalBy/", body)
      console.log(res.data.message)
      
      // return res.data.message
      setSnackbarMessage( `${res.data.message || `Full text of ${passedFullTextItem?.thesis?.title} has been approved for ${passedFullTextItem?.user?.email} `}`)
      setSnackbarSeverity('success')
      showSnackbar()
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 1000); // 10000 milliseconds = 10 seconds
      });

      adminForceUpdate()
    }
    catch(error){
      console.log(error)
      setSnackbarMessage(`${error?.response?.data?.message ||  'Unable to approve request' }`)
      setSnackbarSeverity('error')
      showSnackbar()

    }
    finally{
      enableButton()
    }
    
  }

  const pushToBottom = async() => {
    try{
      disableButton()

      setSnackbarMessage(`This feature will be implemented in future updates`)
      setSnackbarSeverity('info')
      showSnackbar()

    }
    catch(error){
      // console.log(error)
      // setSnackbarMessage(`${error?.response?.data?.message ||  'Unable to approve request' }`)
      // setSnackbarMessage('Unable to fetch pdf')
      // setSnackbarSeverity('error')
      // showSnackbar()

    }
    finally{
      enableButton()
    }

  }

  const roundButton = ( ) => {
    const bgcolor = ()=> {
      if(passedFullTextItem?.granted){
        return 'lime'
      }
      if(!passedFullTextItem?.granted && !passedFullTextItem?.denied){
        //.ie pending
        return 'yellow'
      }
      if(passedFullTextItem?.long_denial){
        return 'red'
      }
      if(passedFullTextItem?.denied){
        return '#faa3bd'
      }
      return 'gray'
    }
    return (
    <div style={{
      backgroundColor: bgcolor() || 'gray', 
      // color: color || 'white',
      lineHeight: `1`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: `${0.5}rem`,
      width: `${0.5}rem`,
      borderRadius: '50%',
      padding: '0.5rem',
      overflow: 'hidden'
    }}>
      {/* BB */}
    </div>
    )
  }


  if(!passedFullTextItem){
    return (
      <div>Please pass a full text item</div>
    )
  }
  return (
    <div className="reusable-full-text-item">
      <ReusableDraggableDialog
        isOpened={interfaceState.fullTextItemDetailVisible}
        setIsOpened={toggleFullTextItemDetailVisible}
        main_body={ 
          <FullTextItemDetail
          open={interfaceState.fullTextItemDetailVisible}
          setOpen={toggleFullTextItemDetailVisible}
          passedFullTextItem={passedFullTextItem}
          // otherFunctionPassed={handleUpdateUser}
          // handleChange={handleChange}
          />}
      />
      <ReusableSnackbarComponent
        severity={interfaceState.snackbar_severity}
        open={interfaceState.snackbar_visible}  
        inner_message={interfaceState.snackbar_message} 
        setOpen={() => toggleInterfaceVisibility('snackbar_visible')}
        autoHideDuration={ interfaceState.snackbar_autohide_duration || 2000}/>

      <div className="reusable-full-text-item-inner">
        <div>
          {roundButton()}
        </div>
        <div  className="reusable-full-text-item-intro">
          {passedFullTextItem?.user?.email || ''} is requesting for the full text of {passedFullTextItem?.thesis?.title || ''} 
        </div>
        {
        passedFullTextItem?.message &&
        <div  className="reusable-full-text-item-inner-message">{passedFullTextItem?.message || ''}</div>
        }
        <div  className="reusable-full-text-item-full-details-button-container"> 
          <button onClick={toggleFullTextItemDetailVisible}> See full details</button>
          <span onClick={toggleFullTextItemDetailVisible}style={{display:'flex', alignItems:'center', lineHeight:'1', cursor:'pointer'}}> <InfoOutlined fontSize='1rem' /> </span>
        </div>
        <div  className="reusable-full-text-item-action-buttons">
          {
          !passedFullTextItem?.granted &&
          <button  
          onClick={approveRequest}
          className="reusable-full-text-item-action-button-approve">Approve </button>
          }
          {
          !passedFullTextItem?.denied &&
          <button  
          onClick={denyRequestShort}
          className="reusable-full-text-item-action-button-deny-short"> Deny(short) </button>
          }
          <button  
          onClick={pushToBottom}
          className="reusable-full-text-item-action-button-push-down">Push to bottom of list</button>
          {
          !passedFullTextItem?.long_denial &&
          <button 
          onClick = {denyRequestLong} className="reusable-full-text-item-action-button-deny-long">Deny(long) </button>
          }
        </div>
      </div>
    </div>
  )
}







const ManageFullTextsComponent = ({open, setOpen, thesis_id}) => {
  //use admin current. page. Its better than that search_state session rubbish
  const { dispatch, admin_current_page, admin_force_update, admin_results_page_items} = useAdminContext()
  const adminContext = useAdminContext()

  const {user} = useAuth()

  const [interfaceState, setInterfaceState] = useState({
    isPageSettingsOpened: false,
    isSearchInterfaceOpened: false,
    snackbar_visible: false,
    snackbar_message: "",
    snackbar_severity: "info",
    snackbar_autohide_duration: 5000,
    button_disabled : false,
  })

  const togglePageSettingsUI = () => {
    setInterfaceState(prev => ({
      ...prev,
      isPageSettingsOpened: !prev.isPageSettingsOpened
    }))
  }

  const handleClose = async () => {
    try{
      // if(otherFunctionsPassed){
      //   await otherFunctionsPassed()
      // }
      console.log('from the compo itself of ftr mgt')
      setOpen(false);
    }
    catch(error){
      alertSnackbar('an error occurred', 'error')
      // return alert('an error occurred')
    }
  };

  const toggleSearchInterface = () => {
    setInterfaceState(prev => ({
      ...prev,
      isSearchInterfaceOpened: !prev.isSearchInterfaceOpened
    }))
  }
  const toggleInterfaceVisibility = (name) => {
    setInterfaceState((prev) => ({ ...prev,  [name]: !prev[name]  }))
  }
  const showSnackbar = () => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_visible: true  }))
  }
  const hideSnackbar = () => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_visible: false  }))
  }
  const setSnackbarMessage = (message) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_message: message  }))
  }
  const setSnackbarSeverity = (severity) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_severity: severity  }))
  }
  const setSnackbarDuration = ( duration) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_autohide_duration: duration  }))
  }
  const disableButton = () => {
    setInterfaceState((prev) => ({ ...prev,  button_disabled: true  }))
  }
  const enableButton = (name, value_passed) => {
    setInterfaceState((prev) => ({ ...prev,  button_disabled: false  }))
  }

  // console.log(open)
  // console.log(open.open)
  const alertSnackbar = (message="hello, user", type="info", duration=9999999) => {
    setSnackbarMessage(message)
    setSnackbarSeverity(type)
    setSnackbarDuration(duration)
    showSnackbar()
  }

  
  const menu_bar = [
    {
      id: 1,
      text: 'All',
      simplified: 'all',
      query: ''
    },
    {
      id: 2,
      text: 'Pending',
      simplified: 'pending',
      query: '&pending=true'
    },
    {
      id: 3,
      text: 'Approved',
      simplified: 'granted',
      query: '&granted=true'
    },
    {
      id: 4,
      text: 'Denied',
      simplified: 'denied',
      query: '&denied=true'
    },
    {
      id: 5,
      text: 'Long denied',
      simplified: 'long denied',
      query: '&long_denial=true'
    },
  ]
  const [currentItem, setCurrentItem] = useState( menu_bar[0])

  const handleSetCurrentItem = (item) => {
    setCurrentItem(item)
  }
  useEffect(() => {
    // first
    dispatch({
      type: 'MODIFY_ADMIN_CURRENT_PAGE',
      payload: 1
    })

  }, [currentItem,])

  const [queryState, setQueryState] = useState({
    itemsPerPage: admin_results_page_items,
    current_page: admin_current_page,
    sort: 'latest_modified' ,
    searchBy: '',
    input_box_value: ''
  })

  const constructApiUrl = () => {
    const url = `/full_text_request/findAllFullTextRequestForThesis?current_page=${admin_current_page}&itemsPerPage=${admin_results_page_items}&sort=${queryState.sort || ''}&${queryState.searchBy}=${queryState.input_box_value || ''}${currentItem.query}&authUserId=${user?._id || ''}&thesisId=${thesis_id || ''}`
    //we cant pass user id exceipt were looking for a user

    const url2 = `/full_text_request/find_all_full_text_request_advanced_search?current_page=${admin_current_page}&itemsPerPage=${admin_results_page_items}&sort=${queryState.sort || ''}&${queryState.searchBy}=${queryState.input_box_value || ''}${currentItem.query}`

    console.log(url)
    return url
  }

  const handlePageChange = (newPage) => {
    dispatch({
      type: "MODIFY_ADMIN_CURRENT_PAGE", 
      payload: newPage
    })  
  }

  const handleQueryState = (e) => {
    const {name, value} = e.target
    setQueryState(prev => ({
      ...prev,
      [name]: value
    }))
  }

  const fetchFullTextRequestsList = async () => {


    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_URL
    })

    const response = await axiosInstance.get(`${constructApiUrl()}`);
    // console.log(response.data.message)
    return response.data.message;
  };
  const { 
    data:fetchedFTRList , 
    isLoading: isLoadingFTRList, 
    isFetching: isFetchingFTRList, 
    isRefetching: isRefetchingFTRList,
    error: errorFetchingFTRList,
    refetch:refetchFTRList } = useQuery({
    queryKey: ['ftrListf'],
    // networkMode: 'always',
    queryFn: () =>
      fetchFullTextRequestsList()
    }
  )

  if(errorFetchingFTRList){

    console.log(errorFetchingFTRList?.response?.data?.message)
  }

  useEffect(() => {
    //return error if you are not authenticated
    if(errorFetchingFTRList && !fetchedFTRList){
      console.log('RUBBISH')
      setSnackbarMessage( `${errorFetchingFTRList?.response?.data?.message || errorFetchingFTRList ||  'An error occurred' }`)
      setSnackbarSeverity('info')
      showSnackbar()
      
      setTimeout(()=> {handleClose()}, 2000)
    }
  }, [fetchedFTRList, errorFetchingFTRList])
  



  if(fetchedFTRList){
    console.log(fetchedFTRList)
  }

  const [searchMode, setSearchMode] = useState(false)
  const handleSearchMode = () => {
    // if
    if(!queryState.input_box_value){
      alertSnackbar('Please enter a value in the search box', 'error')

      // return alert('please enter a value in the search box')
    }
    if(!queryState.searchBy){
      alertSnackbar('Please enter a field to search by', 'error')

      // return alert('please enter a field to search by')
    }
    if(searchMode){
      //means we are reclicking it
      return refetchFTRList()
    }
    //else
    setSearchMode(!searchMode)

  }
  useEffect(() => {
    //clear the input box whenever we exit search mode.
    //But retain searchBy so that the user doesnt need to reset it again
      const clearInputWhenSearchModeIsOff = () => {
      //clear the input box and value state
        if(!searchMode){
          setQueryState(prev => ({
            ...prev,
            input_box_value: ''
          }))
          // return setSearchMode(!searchMode)
        }
  
        dispatch({
         type: "ADMIN_FORCE_UPDATE"
        })
      }
      clearInputWhenSearchModeIsOff()
      // refetchUsersList()
  
    }, [searchMode])
  
  useEffect(() => {
    //if we are in search mode and we are changing the input then we can refetch
    //so we can search in real time
      if(searchMode){
        refetchFTRList()
      }
    
  
    }, [queryState.input_box_value, queryState.searchBy])




  useEffect(() => {
    // first
    if(fetchedFTRList){

      dispatch({
        type: 'SET_ADMIN_MAX_PAGE',
        payload: fetchedFTRList?.total_pages || 1
      })
      }
    }, [fetchedFTRList])

  useEffect(() => {
    refetchFTRList()
  }, [admin_results_page_items, admin_current_page, admin_force_update, 
    // searchMode, 
    queryState.sort, currentItem])

  const initialSelected = (state_value, value_passed) => {
    const check_passed = (state_value === value_passed)
    return check_passed
  }


  //fetch all full texts for the thesis id

  return (

    <div 
    style={{

      width: '100%',
      display: 'flex',
      justifyContent: 'center'
      // flexDirection: ''
    }}
    className='view-thesis-mftp'>
      <ReusableSnackbarComponent
        severity={interfaceState.snackbar_severity}
        open={interfaceState.snackbar_visible}  
        inner_message={interfaceState.snackbar_message} 
        setOpen={() => toggleInterfaceVisibility('snackbar_visible')}
        autoHideDuration={ interfaceState.snackbar_autohide_duration || 2000}/>
      {
      // fetchedFTRList && !isFetchingFTRList&&
      <>
      <div className='amap-fullscreen-modal-header'>
        <div className='amap-fullscreen-modal-header-inner'>
          <span className='close'
          onClick={handleClose}
          > <Close fontSize='2rem' /> </span>
          <span className='title'>Manage Full Texts</span>
          <span 
          onClick={handleClose}
          // onClick={handleUpdateCategory}
          className='update'>OK</span>
        </div>
      </div>
      <div 
      style={{
        width: '85%',
        display: 'flex',
        flexDirection: 'column'
        // justifyContent: 'center'
        // flexDirection: ''
      }}
      className='view-thesis-mftp-inner'>

        <div className='amap-horizontal-scroll-menu'>
          <ReusableHorizontalScrollMenu
          array={menu_bar}
          currentItem={currentItem}
          handleSetCurrentItem={handleSetCurrentItem}  />
        </div>
        {
        // fetchedFTRList && !isFetchingFTRList &&
        fetchedFTRList && !isFetchingFTRList &&
        <>
        <div className='amap-search-functionality'> 
          {
          !interfaceState.isSearchInterfaceOpened &&
          <div className='amap-search-functionality-inner'>
            <span style={{
              cursor: 'pointer'
            }} onClick={toggleSearchInterface}> <Search fontSize='2rem' /> </span>
          </div>
          }
          {
          interfaceState.isSearchInterfaceOpened &&
          <div className='amap-search-functionality-inner'>  
            <span style={{
              cursor: 'pointer'
            }} onClick={toggleSearchInterface}> <Search fontSize='2rem' /> </span>
            <span className='input-container'>
              <input 
              name='input_box_value'
              onChange={handleQueryState}
              value={queryState.input_box_value}
              className='input-container-input'
              type='text' 
              title='enter your search parameter' placeholder='enter your search parameter' />
            </span>
            <span  className='search-by-select-span'>
              <select
                  className='search-by-select'
                  id='sort-by'
                  name='searchBy'
                  onChange={handleQueryState}
                  value={queryState.searchBy}
                  // value={filtersState.sortBy}
                  // onChange={handleSortByChange}
                >
                  <option value='' key={""}>-- Search by --</option>
                  {
                  // note that we passed, key ie object key, instead of object property/value
                  Object.entries(searchByOptions).map(([value, label]) => (
                  <option key={value} value={value}>
                      {label}
                  </option>
                  ))}
              </select>
            </span>        
            {/* <span> */}
              <button 
              className='search-button'
              style={{
                // border: 'none'
              }}
              // onClick={()=>setSearchMode(true)}
              onClick={handleSearchMode}
              title="search">Search</button>
            {/* </span> */}
            <ReusableSnackbarComponent 
            // positioning={{vertical: 'top', horizontal: 'right'}}
            positioning={{vertical: 'top', horizontal: 'center'}}
            inner_message={"You are in search mode"} 
            open={searchMode} 
            setOpen={setSearchMode} 
            // otherFunctionsPassed={otherFunctions}
            />
            <span 
            // onClick={handleSearchMode} 
            onClick={()=>setSearchMode(!searchMode)}
            style={{
              display: searchMode ? 'block' : 'none',
              color: searchMode ? 'red' : 'inherit',
              cursor: 'pointer'
            }}
            className='exit-search-button'>Exit search</span>
          </div>
          }
        </div>
        <div 
        style={{marginBottom: '3rem'}}
        className='fetched-full-text-reusable-items'>
          {
          fetchedFTRList?.full_text_requests?.length > 0 &&
          fetchedFTRList?.full_text_requests?.map((fullTextItem, index) => (
            <ReusableFullTextItem 
            key={fullTextItem?._id || index}
            passedFullTextItem={fullTextItem} />
          ))
          }
          {
          fetchedFTRList?.full_text_requests?.length < 1 &&
          "No records found"
          }
          
        </div>
        </>
        }
        {
        isFetchingFTRList && 
        <ReusableLoaderInCenter passed_loader={<ReusableLoader3 />} />
        }
        <div className='amap-pagination-component'>
          <div 
          style={{
            display: 'flex',
            justifyContent: 'center',
            // backgroundColor:'cyan',
            gap: '1.5rem'
          }}
          className='amap-pagination-component-inner'>
            <div>
              <ReusablePaginationComponent 
              currentPage={admin_current_page} 
              totalPages={fetchedFTRList?.total_pages } 
              onChange={handlePageChange}/>
            </div>
            <div className='amappc-bottom-right'>
              <span>
                <select
                  className='sort-results-by-select'
                  id='sort-by'
                  name='sort'
                  onChange={handleQueryState}
                >
                  {
                  // note that we passed, key ie object key, instead of object property/value
                  Object.entries(sortOptions).map(([value, label]) => (
                  <option 
                   selected={initialSelected(queryState.sort, value)}
                   key={value} value={value}>
                      {label}
                  </option>
                  ))
                  }
                </select>
              </span>
              <span
              onClick={togglePageSettingsUI}
              className='amappc-bottom-right-settings-icon-container'
              >
                <Settings fontSize='1rem' />
              </span>
              <ReusableDraggableDialog  
              header={"Page Settings"}
              main_body={
                <ReusablePageSettingsDialogAdmin
                  page_context={adminContext} 
                  setIsModalOpened={togglePageSettingsUI}
                  isModalOpened={interfaceState.isPageSettingsOpened}
                />
              } 
              isOpened={interfaceState.isPageSettingsOpened}
              setIsOpened={togglePageSettingsUI}
              />
            </div>
          </div>

        </div>

      </div>
      </>
      }
      {
      isFetchingFTRList && !fetchedFTRList &&
      <ReusableLoaderInCenter passed_loader={<ReusableLoader3 />} />
      }
    </div>
  )

}










const CreateFullTextRequestComponent = ({isOpened, setIsOpened, handleInput, createFullTextRequest}) => {

  return(
    <div style={{
      height: '100%',
      width: '100%',
      // backgroundColor: 'lime'
    }}>
      <div style={{
      height: '100%',
      width: '100%',
      // backgroundColor: 'red'
    }}>
        <textarea 
          name="message_input"
          onChange={handleInput}
          style={{
            width: '100%',
            height: '15vh',
            border: '1px solid gray',
            borderRadius: '0.3rem',
            padding: '0.5rem'
          }}  
          placeholder='Do you want to add any additional message to the author?'/>
      </div>
      <div 
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '1rem'
      }}
      onClick={createFullTextRequest}>
        <button style={{
          padding: '0.4rem 1rem',
          border: '1px solid gray',
          fontSize: '0.75rem'
        }}>Request  full text</button>
      </div>
    </div>
  )
}










const SuggestedThesisItem = ({passed_thesis}) => {
  const [interfaceState, setInterfaceState] = useState({
    snackbar_visible: false,
    snackbar_message: "",
    snackbar_severity: "info",
    snackbar_autohide_duration: 2000,
    button_disabled : false,
    confirmationDialogVisible: false
    // confirmation_prompt: false //just use a different state so you can use useeffect of this state, instead of typing each property
  })

  const showSnackbar = (val = true) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_visible: true  }))
  }
  const hideSnackbar = (val = true) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_visible: false  }))
  }
  const toggleInterfaceVisibility = (name) => {
    setInterfaceState((prev) => ({ ...prev,  [name]: !prev[name]  }))
  }
  const setSnackbarMessage = (message) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_message: message  }))
  }
  const setSnackbarSeverity = (severity) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_severity: severity  }))
  }
  const setSnackbarDuration = ( duration) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_autohide_duration: duration  }))
  }
  const handleInterfaceState = (name, value_passed) => {
    setInterfaceState((prev) => ({ ...prev,  [name]: value_passed  }))
  }

  const disableButton = () => {
    setInterfaceState((prev) => ({ ...prev,  button_disabled: true  }))
  }
  const enableButton = (name, value_passed) => {
    setInterfaceState((prev) => ({ ...prev,  button_disabled: false  }))
  }
  const alertSnackbar = (message="hello, user", type="info", duration=9999999)=> {
    setSnackbarMessage(message)
    setSnackbarSeverity(type)
    setSnackbarDuration(duration)
    showSnackbar()
  }




  const navigate = useNavigate()
  const navigateToThesis = (thesis_id) => {
    // console.log(`${CLIENT_LINKS.view_thesis.url}/${thesis_id}`)
// return ''
    if(!thesis_id){
      alertSnackbar('an error occurred', 'error')
      return ''

      // return alert('An error occured')
    }
    scrollToTopInstantUtil()
    navigate(`${CLIENT_LINKS.view_thesis.url}/${thesis_id}`)
  }
  
  return (
    <div  className='vtmr-others-suggested-body-item'>
       <ReusableSnackbarComponent
        severity={interfaceState.snackbar_severity}
        open={interfaceState.snackbar_visible}  
        inner_message={interfaceState.snackbar_message} 
        setOpen={() => toggleInterfaceVisibility('snackbar_visible')}
        autoHideDuration={ interfaceState.snackbar_autohide_duration || 2000}/>
      <div  
      onClick={()=>navigateToThesis(passed_thesis?._id)}
      className='vtmr-others-suggested-body-item-title'>
        { textLengthShortened((passed_thesis?.title || 'Thesis'), 20)}
      </div>
      <div  className='vtmr-others-suggested-body-item-details'>
        <span 
        onClick={()=>navigateToThesis(passed_thesis?._id)}
        className='vtmr-research'>research</span>
        <span>∙</span>
        <span className='vtmr-year'>{passed_thesis?.year || ''}</span>
      </div>
    </div>
  )
}








const ViewThesisPage = () => {

  UseHideSidebar()
  const [interfaceState, setInterfaceState] = useState({
    snackbar_visible: false,
    snackbar_message: "",
    snackbar_severity: "info",
    snackbar_autohide_duration: 5000,
    button_disabled : false,
    draggableDialogVisible: false,
    manageFullTextDialogVisible: false,
    isSpinnerActive: false,
    // confirmation_prompt: false
    // confirmation_prompt: false //just use a different state so you can use useeffect of this state, instead of typing each property
  })

  const toggleInterfaceVisibility = (name) => {
    setInterfaceState((prev) => ({ ...prev,  [name]: !prev[name]  }))
  }
  const showSnackbar = () => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_visible: true  }))
  }
  const hideSnackbar = () => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_visible: false  }))
  }
  const setSnackbarMessage = (message) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_message: message  }))
  }
  const setSnackbarSeverity = (severity) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_severity: severity  }))
  }
  const setSnackbarDuration = ( duration) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_autohide_duration: duration  }))
  }
  const disableButton = () => {
    setInterfaceState((prev) => ({ ...prev,  button_disabled: true  }))
  }
  const enableButton = (name, value_passed) => {
    setInterfaceState((prev) => ({ ...prev,  button_disabled: false  }))
  }
  const showSpinner = (val = true) => {
    setInterfaceState((prev) => ({ ...prev,  isSpinnerActive: true  }))
  }
  const hideSpinner = (val = true) => {
    setInterfaceState((prev) => ({ ...prev,  isSpinnerActive: false  }))
  }

  const alertSnackbar = (message="hello, user", type="info", duration=9999999) => {
    setSnackbarMessage(message)
    setSnackbarSeverity(type)
    setSnackbarDuration(duration)
    showSnackbar()
  }


  const {user} = useAuth()

  const {thesis_id} = useParams()
  // console.log('PARAMS IS ', useParams())
  // console.log(thesis_id)
   
  
  const [pdfContent, setPdfContent] = useState({
    blob: '',
    // urlToFetch: '',
    // urlToFetch: '/retrieveUploadedPdfTwentyPercentWithWatermark',
    urlToFetch: '/retrieveUploadedPdfHundredPercentWithWatermark'
    //default url should be the 20% url except otherwise
  });

  
  const setPdfBlob = (passed_blob) => {
    setPdfContent(prev => ({...prev, blob:passed_blob }))
  }
  const setPdfUrl = (passed_url) => {
    setPdfContent(prev => ({...prev, urlToFetch:passed_url }))
  }


  const fetchThesisForView = async () => {
    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_URL
    })

    const response = await axiosInstance.get(`/theses/thesis/${thesis_id}`, 
    {}
    );
    // console.log(response.data.message)
    return response.data.message;
  };
  const { 
    isLoading: isFetchingThesis,
    isFetching: isFetchingThesisReal, 
    error: errorFetchingThesisReal, 
    data:fetched_thesis_for_view } = useQuery({
    queryKey: ['fetchThesisForView'],
    queryFn: () =>{
      // fetchPdfModified()
      return fetchThesisForView()
    }
  })
  // if(!isFetchingThesis){
  //   console.log(fetched_thesis_for_view)
  // } 

  const userThesisManagementRef = useRef({
    canManageThesis: false,
    canManageFTR: false,
    canUserCreateThesis:  false

  })

  const checkAccessToFullText = async () => {
    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_URL
    })

    // if(!user?.email){
    //   return alert('Please login')
    // doesnt matter. Dont ask anyone to login
    // }

    const response = await axiosInstance.get(`/full_text_request/check_if_user_has_full_text_access_by`, 
    {
      params : {
        user_email: user?.email || '',
        thesis_id : thesis_id || '' //from the url params of router-dom
      }
    }
    );
    // console.log(response.data.message)
    return response.data.message;
  };
  const {
     isFetching: isFetchingCheckAccessToFT ,
     isLoading: isLoadingCheckAccessToFT, 
     error: errorCheckAccessToFT, 
     data: fetchedCheckAccessToFT
  } = useQuery({
    queryKey: ['checkAccessToFullText'],
    queryFn: () =>{
      // fetchPdfModified()
      return checkAccessToFullText()
    }
  })

  if(!isFetchingCheckAccessToFT){
    // console.log(fetchedCheckAccessToFT)
    // console.log(errorCheckAccessToFT)
  }

  useEffect(() => {
    const alertUserAboutFullTextAccess = () => {
      if(!isFetchingCheckAccessToFT && !isLoadingCheckAccessToFT && fetchedCheckAccessToFT && fetched_thesis_for_view ){
      // if(!isFetchingCheckAccessToFT && !isLoadingCheckAccessToFT  &&fetched_thesis_for_view ){
        // console.log('SETTING STUFFS')

        if(fetchedCheckAccessToFT.accessToFullText === true){
          userThesisManagementRef.current.canManageFTR = true
          console.log(pdfContent.urlToFetch)

          // console.log('SETTING STUFFS 222')

          setPdfUrl('/retrieveUploadedPdfHundredPercentWithWatermark')
          setSnackbarMessage('You have access to the full text')
          setSnackbarSeverity('info')
          showSnackbar()
          return ''
        }

        //else i.e if it is false
        setPdfUrl('/retrieveUploadedPdfTwentyPercentWithWatermark')
        setSnackbarMessage('You can view only the preview')
        setSnackbarSeverity('info')
        showSnackbar()
        console.log('WE GOT HERE. WHY?')
        userThesisManagementRef.current.canManageFTR = false

        return ''

      }

      if(!isFetchingCheckAccessToFT && !isLoadingCheckAccessToFT &&  fetched_thesis_for_view && errorCheckAccessToFT){
          //else i.e if it is false
          setPdfUrl('/retrieveUploadedPdfTwentyPercentWithWatermark')
          setSnackbarMessage('You can view only the preview')
          setSnackbarSeverity('info')
          showSnackbar()
          userThesisManagementRef.current.canManageFTR = false

          console.log('WE GOT HERE. WHY?')
      }

    }
    alertUserAboutFullTextAccess()

    // return () => {
    //   console.log(pdfContent.urlToFetch)
    // }
  }, [fetchedCheckAccessToFT, fetched_thesis_for_view, isFetchingCheckAccessToFT, isLoadingCheckAccessToFT,errorCheckAccessToFT ])


  // useEffect(() => {
  //   console.log('PDF URL: ', pdfContent.urlToFetch)
  // }, [pdfContent])

  // console.log(pdfContent.urlToFetch)
  

  const tellTextOrFullText = () => {

    if(fetched_thesis_for_view){
      if(fetched_thesis_for_view?.current_thesis_content?.method === document_append_methods_util.upload_document_method ){

        if(fetchedCheckAccessToFT?.accessToFullText){
          return {
            item: 'Full text',
            text: 'full_text',
            id: 2,
            enabled: true
  
          }

        }

        return {
          item: 'Preview',
          text: 'preview',
          id: 2,
          enabled: true

        }
      }
      if(fetched_thesis_for_view?.current_thesis_content?.method === document_append_methods_util.text_editor_method){
        return {
          item: 'Text',
          text: 'text',
          id: 2,
          enabled: true

        }
      }

    }

    return {
      item: 'Document',
      text: 'document',
      id: 2,
      enabled: false
    }
  }

  const menuBarItems = [
    {
      item: 'Overview',
      text: 'overview',
      id: 1
    },
    {
      item: tellTextOrFullText().item,
      text: tellTextOrFullText().text,
      id: 2,
      enabled: tellTextOrFullText()?.enabled
    },
    {
      item: 'Comments',
      text:  'comments',
      id: 3
    },
    {
      item: <MoreHoriz />,
      text: 'more',
      id: 4
    },
  ]
  const [selectedMenuBar, setSelectedMenuBar] = useState(menuBarItems[0])
  // console.log(selectedMenuBar)



  const isThesisFullyLoaded = fetched_thesis_for_view && !isFetchingThesis && !isFetchingThesisReal && !errorFetchingThesisReal
  const isThesisFullyLoadedDependencies = [isFetchingThesis, isFetchingThesisReal, errorFetchingThesisReal, fetched_thesis_for_view]
  useEffect(() => {
    // setSelectedMenuBar(menuBarItems.current[1])
    const runThings = () => {
      if(isThesisFullyLoaded){
        //if it is not open already
        //means you want to open it

        //check your auth level

        let canManageThesis = false
        const user_privilege_levels = user?.privilege_levels


        const isAdmin = checkIfUserHasPrivilegeLevelFor_Util(authorizedPrivilegeLevelsThatUpdateFullTextRequest_Util, user_privilege_levels )

        if(isAdmin){
          canManageThesis = true
          // setInterfaceState((prev) => ({ ...prev,  manageFullTextDialogVisible: !prev.manageFullTextDialogVisible }))
          // return ''
        }

        if(user.email === fetched_thesis_for_view?.email){
          canManageThesis = true
          // setInterfaceState((prev) => ({ ...prev,  manageFullTextDialogVisible: !prev.manageFullTextDialogVisible }))
          // return ''
        }
          


        console.log('LINE CAN MANAGE THESIS')
        if(canManageThesis){
          userThesisManagementRef.current.canManageThesis = true
          return ''
        }
        else{
          userThesisManagementRef.current.canManageThesis = false

        }



        // return alert('You do not have sufficient privileges to manage the records')
      }
    }
    runThings()
  
  }, [...isThesisFullyLoadedDependencies])
  

  const handleMenuBarSelection = (option, index) => { 
    setSelectedMenuBar(option)
    // console.log(option.id, index)
  }
 
  //check if access to full text is allowed

  const viewThesisPageBlobSessionKey = thesis_id || '' //from the url params

  const fetchPdfModified = async () => {
    // const axiosInstance = axios.create({
    //     baseURL: process.env.REACT_APP_API_URL
    //   })
    try {
      if( !(fetched_thesis_for_view?.current_thesis_content?.method === document_append_methods_util.upload_document_method)){
        return ''
        //dont fetch nada if it is not a pdf route
      }

      const doesCachedPdfExists = sessionStorage.getItem(viewThesisPageBlobSessionKey)

      if(doesCachedPdfExists){
        const cachedPdf = JSON.parse(sessionStorage.getItem(viewThesisPageBlobSessionKey));
          // if (cachedPdf) {
          // Set the blobUrl in state or wherever needed
          // sessionStorage.setItem('a', 'b');
          // }    

          const checkIfWeShouldRefetchPdf = () => {
            const {originalPdfLink, sessionPdf, sessionDateStored, sessionAccessToFullText} = cachedPdf
            
            const { accessToFullText: fetchedOriginalAccessToFullText } = fetchedCheckAccessToFT
            const { current_thesis_content: fetchedOriginalThesisUrl } = fetched_thesis_for_view

            const objToReturn = {shouldRefetch: true}

            if(true){
              if(fetchedOriginalThesisUrl?.content?.toString()?.trim() === originalPdfLink?.toString()?.trim() ){
                console.log('same thesis url link')
                objToReturn.shouldRefetch = false
                //dont return anything yet
                // return objToReturn
              }
              // return objToReturn
            }

            if(true){
              function checkTimeDifference(pastTimestamp) {
                // Get the current timestamp
                const currentTimestamp = Date.now();
              
                // Calculate the time difference in milliseconds
                const timeDifference = currentTimestamp - pastTimestamp;
              
                // Convert the time difference to hours
                const hoursDifference = timeDifference / (1000 * 60 * 60);
              
                // Check if the time difference exceeds 24 hours
                const hasExceeded24Hours = hoursDifference > 24;
              
                // Return an object with the results
                return {
                  // pastDate: new Date(pastTimestamp),
                  // currentDate: new Date(),
                  hoursDifference,
                  hasExceeded24Hours,
                };
              }

              const {hasExceeded24Hours} = checkTimeDifference(sessionDateStored)

              if(hasExceeded24Hours){
                console.log('has exceeded 24 hours')
                objToReturn.shouldRefetch = true
                return objToReturn

              }

            }
            if(true){
              if( fetchedOriginalAccessToFullText === sessionAccessToFullText ){
                objToReturn.shouldRefetch = false
                //dont return yet, check the below if
              }

              if( fetchedOriginalAccessToFullText !== sessionAccessToFullText ){
                objToReturn.shouldRefetch = false
                return objToReturn
              }
            }
            //should we do premature return , yes



            return objToReturn
          }

          const {shouldRefetch} = checkIfWeShouldRefetchPdf()
          if(!shouldRefetch){
            // return setPdfBlob(cachedPdf.sessionPdf); //enable if you want this
          }

          //if we should refetch, then show the spinner then do nothing, allow the route flow
      }


      showSpinner()
      
      // console.log('GETTING PDF')
      
      // console.log(pdfContent.urlToFetch)
      // const b = `/theses${pdfContent.urlToFetch}/${fetched_thesis_for_view?.current_thesis_content.content}`

      // console.log(b)
      // console.log(`/theses${pdfContent.urlToFetch}/${thesis_id}/${fetched_thesis_for_view?.current_thesis_content.content}`)

      // console.log('THESIS ID is', thesis_id)
      const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL
      })
      const response = await axiosInstance.get(`/theses${pdfContent.urlToFetch}/${fetched_thesis_for_view?.current_thesis_content.content}?thesisId=${thesis_id}`, {
        responseType: 'blob', // Set the response type to blob
      });
      // const pdfBlob = response.blob();
      const pdfBlob = response.data;
      const pdfObjectUrl = URL.createObjectURL(pdfBlob);
      setPdfBlob(pdfObjectUrl);
      // console.log(pdfBlob)
      console.log(pdfObjectUrl)
      console.log('SUCCESSFULY GOT PDF')

      // Save blobUrl to session storage
      const sessionStorageForThesisPdf = {
        originalPdfLink: fetched_thesis_for_view.current_thesis_content.content, //if this changes refetch
        sessionPdf: pdfObjectUrl, 
        sessionDateStored: new Date(), //if this exceeds 24 hours refetch
        sessionAccessToFullText: fetchedCheckAccessToFT?.accessToFullText || false //if this changes refetch
      }
      // sessionStorage.setItem(viewThesisPageBlobSessionKey, pdfObjectUrl);
      const tempDisabled = () => {
        // sessionStorage.setItem(viewThesisPageBlobSessionKey, pdfObjectUrl);
          sessionStorage.setItem(
            viewThesisPageBlobSessionKey, 
            // sessionStorageForThesisPdf.toString() 
            //why is toString() giving [Object object] ?
            JSON.stringify(sessionStorageForThesisPdf)
          );
        }
  
        // tempDisabled()


      //for better comparison, we can put the whole thesis item as a field thesis in the session Storage, then check for object equality using a custom function, if the objects dont match then refetch
      
      //
      
    } 
    catch (error) {
      // Handle error
      console.error('Error fetching PDF:', error);

      // error?.status === 401 && console.log('UNAUTHORIZED')
      // error?.status === 403 && console.log('NO PERMISSION')
      if (error.response) {
        // console.log('hiyaa')
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log('Error status:', error.response.status); // HTTP status code
    
        // Read blob data as text
        const reader = new FileReader();
        const blobData = await new Promise((resolve) => {
          reader.onload = function () {
            resolve(reader.result);
          };
          reader.readAsText(error.response.data);
        });
    
        // Parse the blob data as JSON
        try {
          const jsonData = JSON.parse(blobData);
          console.log('Parsed Error Data:', jsonData);
          if(jsonData){
            // if(!fetchedCheckAccessToFT.accessToFullText){

              setSnackbarMessage(`${jsonData?.message ||  'Unable to fetch pdf' }`)
              setSnackbarSeverity('info')
              showSnackbar()
              return ''
            // }
          }

        } catch (parseError) {
          console.error('Error parsing JSON:', parseError);
        }
    
      } 
      if (error.request) {
        // The request was made but no response was received
        console.log('No response received:', error.request);
      } 
      // else {
      //   // Something happened in setting up the request that triggered an Error
      //   console.log('Request setup error:', error.message);
      // }

      console.log('an error occurred')
      setSnackbarMessage(`${error?.response?.data?.message ||  'Unable to fetch pdf we will reattempt in a bit' }`, )
      // setSnackbarMessage('Unable to fetch pdf')
      setSnackbarSeverity('info')
      showSnackbar()
    }
    finally{
      hideSpinner()
    }
  };


  const location = useLocation()
  useEffect(() => {

    const cachePdfNow = () => {
      const doesCachedPdfExists = sessionStorage.getItem(viewThesisPageBlobSessionKey)

      if(!doesCachedPdfExists){
        return ''
      }
      //below means it exists

      const cachedPdf = JSON.parse(sessionStorage.getItem(viewThesisPageBlobSessionKey));

      if (doesCachedPdfExists) {
        // Set the blobUrl in state or wherever needed
        // sessionStorage.setItem('a', 'b');
        return setPdfBlob(cachedPdf.sessionPdf);
      }    
    }
    // cachePdfNow() //enable this later
  }, [location.pathname])


  useEffect(() => {
    const handleBeforeUnload = (event) => {
      //clear session storage on refresh

      // sessionStorage.setItem()
      // You can perform actions here before the page is reloaded
      // For example, you can save data or show a confirmation message
      // console.log('Page is about to be reloaded!');
      // Uncomment the next line if you want to show a confirmation message
      // event.returnValue = ''; // Standard for most browsers
      // Remove the item from sessionStorage

      // const 
      // Alternatively, you can check if the item exists before removing it
      const doesCachedPdfExists = sessionStorage.getItem(viewThesisPageBlobSessionKey)

      if (doesCachedPdfExists) {
        sessionStorage.removeItem(viewThesisPageBlobSessionKey);
      }

      return ''; // For some older browsers
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      // Cleanup the event listener when the component is unmounted
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []); // Empty dependency array means this effect runs once when the component mounts


  useEffect(() => {
    // first
    if(!isFetchingThesis && !isFetchingThesisReal && fetched_thesis_for_view && !isFetchingCheckAccessToFT){
      // if(!isFetchingThesis && !isFetchingThesisReal ){
        // if(fetched_thesis_for_view){
      if(fetched_thesis_for_view?.current_thesis_content?.method === document_append_methods_util.upload_document_method){
        // console.log('LEVEL 1')
        fetchPdfModified()
      }
    }

  }, [fetched_thesis_for_view, pdfContent.urlToFetch, 
    isFetchingCheckAccessToFT,
    isLoadingCheckAccessToFT,
    errorCheckAccessToFT,
    fetchedCheckAccessToFT
    //should these last 4 items be included?
  ])

  // useEffect(() => {
  //   fetchPdfModified()
  // }, [fetchedCheckAccessToFT])


  const [componentState, setComponentState] = useState({
    citation: '',
    message_input: ''
  })

  const handleInput = (e) => {
    const {name, value} = e.target
    setComponentState(prev => ({...prev, [name]: value }))
  }


  const copyCitationToClipboard = async () => {
    try{
      const text_copied_successfully = await handleCopyTextToClipboard_Util(componentState.citation)
      if(!text_copied_successfully){
        return alertSnackbar('Failed to copy citation, something went wrong', 'error')

        // return alert('Failed to copy citation, something went wrong')
      }
      // return alert(`copied: ${componentState.citation}`)
      return alertSnackbar(`copied: ${componentState.citation}`, 'success')
    }
    catch(error){
      alertSnackbar('error occurred while trying to copy citation', error)
      console.error(error)
    }

  }

  //generate and set the citation of the fetched thesis to the citation state
  useEffect(() => {

    const generateCitation = () => {
      if(fetched_thesis_for_view){
        const body = fetched_thesis_for_view
        if(!body?.author){
          return ''
        }
        const full_name = body.author.split(' ')
        // console.log(fu)
        // return ''
        // console.log(full_name)
        //capitalize 1st letter of first
        const first_name = full_name[0].charAt(0).toUpperCase() + full_name[0].slice(1).toLowerCase();
        // const last_name = full_name[1].toUpperCase();
        //capitalize and extract the first letter of the last name. add a period behind
        let last_name_abbr 
        try{
          last_name_abbr = full_name[1][0].toUpperCase()
        }
        catch(err){
          last_name_abbr = ''
        }
        // = full_name[1][0].toUpperCase()


        const new_full_name = first_name + ' ' + last_name_abbr 
        const year = fetched_thesis_for_view.year
        const title = fetched_thesis_for_view.title

        const new_citation = `${new_full_name}. (${year}). ${title} `

        setComponentState(prev => ({...prev, citation: new_citation}))
      }
    }
    generateCitation()
  
    return () => {
      // second
    }
  }, [fetched_thesis_for_view])
  
  const navigate = useNavigate()

  const fetchRandomTheses = async () => {
    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_URL
    })

    const response = await axiosInstance.get(`/theses/view_random_theses/?how_many=2`, 
    {}
    );
    // console.log(response.data.message)
    return response.data.message;
  };
  const { isLoading: isFetchingRandomTheses, error: error_of_fetching_random_theses, data: fetched_random_theses } = useQuery({
    queryKey: ['fetchRandomTheses'],
    queryFn: () =>{
      // fetchPdfModified()
      return fetchRandomTheses()
    }
  })

  // console.log(error_of_fetching_random_theses)

  // console.log(user)
  const openManageThesisPage = async (uploaded_document) => {
    try{
      if(!fetched_thesis_for_view){
        // navigate(CLIENT_LINKS.login.url)
       return alertSnackbar('The thesis has not loaded. Please refresh', 'info')
      }

                  
      let canDeleteThesis = false
      const user_privilege_levels = user?.privilege_levels

      if(!user){
        alertSnackbar('You are not logged in, or your session expired. Please log in', 'error')
        
        await new Promise((resolve) => {
          setTimeout(() => {
            resolve();
          }, 2000); // 10000 milliseconds = 10 seconds
        });
        navigate(CLIENT_LINKS.login.url)

        return ''
        // return alert('You are not logged in. Please log in')
      }
      
      const isAdmin = checkIfUserHasPrivilegeLevelFor_Util(authorized_privilege_levels_that_delete_thesis_Util, user_privilege_levels )
      if(isAdmin){  
        canDeleteThesis = true
        console.log('USED ADMIN ROUTE')
        navigate(`${CLIENT_LINKS.admin_manage_specific_thesis.url}/${fetched_thesis_for_view._id}`); 
          // window.open(`${CLIENT_LINKS.admin_manage_thesis.url}/${fetched_thesis_for_view._id}`, '_blank'); 

        return ''
      }

      if(user.email === fetched_thesis_for_view?.email){
        canDeleteThesis = true
        console.log('USED AUTHORS ROUTE')
        navigate(`${CLIENT_LINKS.author_manage_specific_thesis.url}/${fetched_thesis_for_view._id}`);

        // window.open(`${CLIENT_LINKS.author_manage_specific_thesis.url}/${fetched_thesis_for_view._id}`, '_blank');
        return ''
      }
        


      if(!canDeleteThesis ){
          return alertSnackbar('You do not have sufficient privileges to manage this record', 'error')
          

          // return alert('You do not have sufficient privileges to manage this record')
      }

      return alertSnackbar('You do not have sufficient privileges to manage this record', 'error')
      
      // return alert('You do not have sufficient privileges to manage this record')

    }
    // window.open(`${CLIENT_LINKS.author_manage_thesis.url}/${fetched_thesis_for_view._id}`, '_blank');
    catch(error){
      console.error(error)
      alertSnackbar('An unexpected error occurred', 'error')
    }
  };




  const toggleDraggableDialogVisibility = () => {
    setInterfaceState((prev) => ({ ...prev,  draggableDialogVisible: !prev.draggableDialogVisible  }))
  }
  
  const toggleFullTextDialogVisibility = async () => {
    try{

    // setInterfaceState((prev) => ({ ...prev,  manageFullTextDialogVisible: !prev.manageFullTextDialogVisible }))
    // return ''

    if(!interfaceState.manageFullTextDialogVisible){
      //if it is not open already
      //means you want to open it

      //check your auth level

      let canManageFTR = false
      const user_privilege_levels = user?.privilege_levels

      if(!user){
        alertSnackbar('You are not logged in, or your session expired. Please log in', 'error')

        await new Promise((resolve) => {
          setTimeout(() => {
            resolve();
          }, 2000); // 10000 milliseconds = 10 seconds
        });
        // navigate(CLIENT_LINKS.login.url)
        return ''
        // return alert('You are not logged in. Please log in')
      }
      
      const isAdmin = checkIfUserHasPrivilegeLevelFor_Util(authorizedPrivilegeLevelsThatUpdateFullTextRequest_Util, user_privilege_levels )
      if(isAdmin){
        canManageFTR = true
        setInterfaceState((prev) => ({ ...prev,  manageFullTextDialogVisible: !prev.manageFullTextDialogVisible }))
        return ''
      }

      if(user.email === fetched_thesis_for_view?.email){
        canManageFTR = true
        setInterfaceState((prev) => ({ ...prev,  manageFullTextDialogVisible: !prev.manageFullTextDialogVisible }))
        return ''
      }
        


      if(!canManageFTR){
        return alertSnackbar('You do not have sufficient privileges to manage this record', 'error')
        
      }

      setSnackbarMessage('You do not have sufficient privileges to manage this record')
      setSnackbarSeverity('error')
      showSnackbar()
      return ''
      // return alert('You do not have sufficient privileges to manage the records')
    }

    setInterfaceState((prev) => ({ ...prev,  manageFullTextDialogVisible: !prev.manageFullTextDialogVisible }))
    }
    catch(error){
      console.error(error)
      alertSnackbar('An error occcurred', 'error')
    }
  }


  const createFullTextRequest = async () => {
    try{ 
      const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL
      })

      const body = {
        user_id: user?._id,
        thesis_id: thesis_id,
        message: componentState.message_input || ''
      }
      const res = await axiosInstance.post("full_text_request/create_full_text_request/", body)
      console.log(res.data.message)

      setComponentState(prev => ({...prev, message_input: '' }))
      toggleDraggableDialogVisibility()
      // return res.data.message
      setSnackbarMessage(res.data.message, "The author may grant or reject your request as they deem fit")
      setSnackbarSeverity('success')
      showSnackbar()
      // console.log('WE GOT HERE. WHY?')
    }
    catch(error){
      console.error(error) //this red is disturbing me. 
      setSnackbarMessage( `Failed to send full text request. Reason: ${error.response?.data?.message || error ||  'An error occurred' }`)
      setSnackbarSeverity('error')
      showSnackbar()

    }
  }

  //have ls store an array of 
  // [{"thesis_id": "last_request"}]
  // this is what we use for elapsed time in the frontend to help us know if we can request
  //method 2 is to create a route that checks the elapsed time in the backend and consume the results appropriately





  // const canUserCreateThesisRef = useRef({canUserCreateThesis: false})
  const checkIfUserCanCreateThesis = async () => {
    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_URL
    })

    const response = await axiosInstance.get(`/theses/canUserCreateThesis/${user?._id || ''}`, 
    {}
    );
    // console.log(response.data.message)
    return response.data.message;
  };
  const { 
    isFetching: isFetchingIfUserCanCreateThesis,
    isLoading: isLoadingIfUserCanCreateThesis, 
    error: errorCheckingIfUserCanCreateThesis, 
    data: canUserCreateThesisData, 
  } = useQuery({
    queryKey: ['canUserCreateThesis'],
    // retryDelay: 15000,
    queryFn: () =>{
      // fetchPdfModified()
      return checkIfUserCanCreateThesis()
    }
  })

  useEffect(() => {
    if(canUserCreateThesisData && !isLoadingIfUserCanCreateThesis && !isFetchingIfUserCanCreateThesis && !errorCheckingIfUserCanCreateThesis){
      // canUserCreateThesisRef.current = canUserCreateThesisData
      userThesisManagementRef.current.canUserCreateThesis = canUserCreateThesisData.canUserCreateThesis
    }

  }, [isFetchingIfUserCanCreateThesis, isLoadingIfUserCanCreateThesis, errorCheckingIfUserCanCreateThesis, canUserCreateThesisData])
  


 


 

  return (
    <div className='view-thesis-page'>
      <ReusableSnackbarComponent
        severity={interfaceState.snackbar_severity}
        open={interfaceState.snackbar_visible}  
        inner_message={interfaceState.snackbar_message} 
        setOpen={() => toggleInterfaceVisibility('snackbar_visible')}
        autoHideDuration={ interfaceState.snackbar_autohide_duration || 2000}/>
      <ReusableDraggableDialog
        header={"Request Full Text"}
        main_body={
          <CreateFullTextRequestComponent 
            handleInput={handleInput}
            createFullTextRequest={createFullTextRequest}
          />
        } 
        isOpened={interfaceState.draggableDialogVisible}
        setIsOpened={toggleDraggableDialogVisibility}
      />
      <ReusableFullscreenDialog 
        open={interfaceState.manageFullTextDialogVisible}
        // setOpen={toggleFullTextDialogVisibility}
        // title={'Update Account'}
        // action_title={'update'}
        no_preloaded_content
        // otherFunctionsPassed={finallyUpdate}
        main_body={
          <ManageFullTextsComponent 
          open={interfaceState.manageFullTextDialogVisible}
          setOpen={toggleFullTextDialogVisibility}
          thesis_id={thesis_id}
          />}
      />
      <div 
        style={{
          position: 'fixed',
          bottom: '1rem',
          right: '1rem',
          display: userThesisManagementRef.current.canUserCreateThesis ? true : 'none'
        }}
        onClick={() => {navigate(CLIENT_LINKS.create_thesis.url)} }

      >
        <Fab color="primary" aria-label="add" size="small">
             <Add />
        </Fab>
      </div>
      <div className='view-thesis-top-header'>
        <div className='view-thesis-top-header-inner'>
          <div className='view-thesis-top-header-type'>
            <span className='view-thesis-top-header-type-item'>research</span>
            <span className='view-thesis-top-header-type-item'>
              {/* full text available */}
              {
                  isFetchingThesis ? 
                  '' :
                  (
                  fetched_thesis_for_view?.current_thesis_content?.method === document_append_methods_util.upload_document_method ? 'full text pdf' : 'text'
            
                  )
              }
            </span>
          </div>
          <div className='view-thesis-top-header-title'>
            {
              isFetchingThesis ? 
              '' :
              (
              fetched_thesis_for_view?.title || ''
              )
            }  
          </div> 
          <div className='view-thesis-top-header-date'> 
            {
              isFetchingThesis ? 
              '' :
              <span>
                {fetched_thesis_for_view?.author || ''}
              </span>
            }  
            •
            {
              isFetchingThesis ? 
              '' :
              <span>
               { fetched_thesis_for_view?.year || ''}
              </span>
            }  
          </div>
          {/* <div className='view-thesis-top-header-author'>Author</div> */}
          <div className='view-thesis-top-header-menu-bar'>
            {
            menuBarItems.map((option, index) => (
              <span 
              key={index}
              onClick={() => handleMenuBarSelection(option, index)}
              className={(selectedMenuBar.id-1) === index ?'view-thesis-top-header-menu-bar-item active' : 'view-thesis-top-header-menu-bar-item' }
              >{option.item}</span>
            ))
            }
          </div>
        </div>
      </div>
      <div className='view-thesis-main'>
        <div className='view-thesis-main-inner'>
          {
          selectedMenuBar.text === 'overview' &&
          <>
          <div className='view-thesis-main-body'>
            <div className='view-thesis-main-abstract'>
              <div className='view-thesis-main-abstract-head'>Abstract</div>
              <div className='view-thesis-main-abstract-body'>
                {
                  isFetchingThesis ? 
                  '' :
                  (
                  fetched_thesis_for_view?.abstract ? 
                  fetched_thesis_for_view?.abstract :
                  fetched_thesis_for_view?.title
                  )
                }
              </div>
            </div>
          </div>
          <div className='view-thesis-minor-right'>
            <div className='vtmr-keywords'>
              <div className='vtmr-keywords-header'>Keywords</div>
              <div className='vtmr-keywords-body'>
                {
                  isFetchingThesis ? 
                  '' :
                  (
                  fetched_thesis_for_view?.keywords?.length ?
                  fetched_thesis_for_view.keywords.map((keyword) => (
                    <span className='vtmr-keywords-body-item'>{keyword}</span>
                  )) :
                  "No keywords"
                  )
                }

              </div>
            </div>
            <div className='vtmr-citation'>
              <div 
              className='vtmr-citation-head'
              onClick={()=>copyCitationToClipboard()}>
                <span>Cite</span>
                <span><CopyAll /></span>
              </div>
              <div className='vtmr-citation-body'>
                {/* Smith, J. (2023). The Art of Programming. ABC Publications. */}
                {componentState.citation}
              </div>
            </div>
            <div className='vtmr-others-suggested'>
              <div  className='vtmr-others-suggested-head'>
                <span>
                Suggested research
                </span>
                <span><EmojiObjectsOutlined /></span>
              </div>
              <div  className='vtmr-others-suggested-body'>
                {
                isFetchingRandomTheses ? '' :
                fetched_random_theses?.map((thesis, index) => (
                  <SuggestedThesisItem 
                  passed_thesis={thesis} 
                  key={thesis?._id || index} />
                ))
                }

              </div>
              <div 
              onClick={()=>navigate(CLIENT_LINKS.view_all_theses.url)}
              className='vtmr-others-suggested-body-view-more'>
                View more
              </div>
            </div>
          </div>
          </>
          }
          {
            //use display none so we can retain last scroll state
          // selectedMenuBar.text === ('text') &&
          // selectedMenuBar.text === ('full_text' || 'text') &&
          // selectedMenuBar?.enabled &&
          <div 
          style={{
            display: selectedMenuBar?.enabled ? 'block' : 'none'
          }}
          className='view-thesis-full-text'>
            {
            !fetchedCheckAccessToFT?.accessToFullText &&
            <div className='full-text-request-div'>
              <div className='full-text-request-div-inner'>
                <button 
                onClick={toggleDraggableDialogVisibility} className='full-text-request-button'>Request full text</button>
              </div>
            </div>
            }


            {
              // interfaceState.isSpinnerActive &&
              <div style={{}}>
                <div style={{padding: 0, margin: '1.5rem 0rem', display: interfaceState.isSpinnerActive ? true: 'none'}}>Full text is loading, It might take a few minutes...but this will only happen once... </div>
                <div style={{padding: 0, margin: '1.5rem 0rem', display: interfaceState.isSpinnerActive ? true: 'none'}}> Please do not close this webpage if you do not want to have to refetch the pdf from scratch everytime... </div>
                <div style={{padding: 0, margin: '1.5rem 0rem', display: interfaceState.isSpinnerActive ? true: 'none'}}>Please be patient. This is a one-time fix for this webpage visit. You will be notified if this request should fail</div>
                <LinearProgress sx={{padding: 0, margin: 0, display: interfaceState.isSpinnerActive ? true: 'none'}}/>
              </div>
            }
            {
            (fetched_thesis_for_view?.current_thesis_content?.method === document_append_methods_util.upload_document_method) && !interfaceState.isSpinnerActive &&
            <>
            <div className='view-thesis-full-text-head'>
              <div className='view-thesis-full-text-head-left'>
                <span className='view-thesis-full-text-head-icon'>
                  <VisibilityOutlined />
                </span>
                <span>
                {
                  isFetchingThesis ? 
                  '' :
                  (
                  tellTextOrFullText().item
            
                  )
                }
                </span>
              </div>
              <div className='view-thesis-full-text-head-right'>
                {/* dummy placeholder dont remove*/}
                <span 
                onClick={()=>fetchPdfModified()}
                style={{lineHeight:'1rem', display:'flex', alignItems: 'center', justifyContent:'flex-end', width:'100%', fontSize:'1.3rem', cursor: 'pointer'}}>
                  <Refresh fontSize='1rem' />
                </span>
              </div>

            </div>
            <div className='view-thesis-full-text-head2-right'>
            <div className='view-thesis-full-text-head2-right-inner'>
              <span className='view-thesis-full-text-head2-right-icon'><Copyright fontSize='1rem' /></span>
              <span>Content is subject to copyright</span>
            </div>
            </div>
            <div className='view-thesis-full-text-body'>
                {/* <div className='view-thesis-full-text-body-inner'>
                </div> */}
                {/* <iframe 
                  title="View Thesis"
                  // src= "https://media.geeksforgeeks.org/wp-content/cdn-uploads/20210101201653/PDF.pdf" 
                  // src='http://localhost:5000/submittedImages/86439ec5-1905-4b30-b0c8-c893cd767642Laboratory%20Assignment%20Two.pdf'
                  src={pdfContent.blob}
                  width="800"
                  height="500">
                 </iframe> */}
                <ReusablePdfComponent pdfFile={pdfContent.blob} showFakeLoading={true}/>
             </div>
            </>
            }
            {
            fetched_thesis_for_view?.current_thesis_content?.method === document_append_methods_util.text_editor_method &&
            <>
            <div className='view-thesis-full-text-head'>
              <div className='view-thesis-full-text-head-left'>
                <span className='view-thesis-full-text-head-icon'>
                  <VisibilityOutlined />
                </span>
                <span>
                {
                  isFetchingThesis ? 
                  '' :
                  (
                  tellTextOrFullText().item
            
                  )
                }
                </span>
              </div>
              <div className='view-thesis-full-text-head-right'>
                {/* dummy placeholder dont remove*/}
              </div>

            </div>
            <div className='view-thesis-full-text-head2-right'>
            <div className='view-thesis-full-text-head2-right-inner'>
              <span className='view-thesis-full-text-head2-right-icon'><Copyright fontSize='1rem' /></span>
              <span>Content is subject to copyright</span>
            </div>
            </div>
            <div className='view-thesis-full-text-body'>
              <div className="image-ocr-extract-page-ocrText">
                <div className='quill-content-section'>
                {/* <div className='quill-content-section-label'>
                    <span>
                        Content
                    </span>
                </div> */}
                <ReactQuill 
                theme='snow' 
                modules={modules}   
                // defaultValue={theses[0].content || ''}
                // placeholder="The extracted text will be displayed here (you can edit the results)"  
                // value={quillContent}
                defaultValue={`${fetched_thesis_for_view?.current_thesis_content?.content}`}
                readOnly={true}
                />
                </div>
            </div>
            </div>
            </>

            }
          </div>

          }
          {
            selectedMenuBar.text === 'comments' &&
            <div>
              <span>.....Feature will be added in future updates</span>
            </div>
          }

          {
            selectedMenuBar.text === 'more' &&
            <div className='view-thesis-page-more-view'>
              <div  className='view-thesis-page-more-view-inner'>
                {
                userThesisManagementRef.current.canManageThesis &&
                <div 
                 onClick={()=>openManageThesisPage()}className='view-thesis-page-more-manage-thesis-section'>
                  {/* <Link className='view-thesis-page-more-manage-thesis-section router-dom-LINK'> */}
                  <button 
                  className='view-thesis-page-more-manage-thesis-section-button'
                 >Manage Thesis</button>
                  <DoubleArrow 
                  className='view-thesis-page-more-manage-thesis-section-icon'
                  fontSize='1rem'/>
                  {/* </Link> */}
                </div>
                }
                {
                userThesisManagementRef.current.canManageFTR &&
                <div 
                style={{
                  marginTop: '1rem'
                }}
                 onClick={() => toggleFullTextDialogVisibility()}
                 className='view-thesis-page-more-manage-thesis-section'>
                  {/* <Link className='view-thesis-page-more-manage-thesis-section router-dom-LINK'> */}
                  <button 
                  className='view-thesis-page-more-manage-thesis-section-button'
                 >Manage Full Text Requests</button>
                  <DoubleArrow 
                  className='view-thesis-page-more-manage-thesis-section-icon'
                  fontSize='1rem'/>
                  {/* </Link> */}
                </div>
                }
                <div
                style={{
                  marginTop: '2rem'
                }}>
                  Additional features will be implemented in future updates...check back later
                </div>



              </div>
              {/* <span>.....Feature will be added in future updates</span> */}
            </div>
          }

          
        </div>
      </div>
    </div>
  )
}

export default ViewThesisPage