import { useEffect, useLayoutEffect } from "react"
import { useOtherContext } from "../contexts/OtherContext"

export const UseHideTopbar = () => {
  const {topbar_enabled, dispatch} = useOtherContext()

  useLayoutEffect(()=> {
    dispatch({
      type: "HIDE_TOPBAR"
    })    
  }, [])
}

export const UseShowTopbar = () => {
  const {topbar_enabled, dispatch} = useOtherContext()

  useLayoutEffect(()=> {
    dispatch({
      type: "SHOW_TOPBAR"
    })   
  }, [])
}

