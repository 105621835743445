import './advancedSearchResultsPage.scss'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import {UseHideSidebar, UseShowSidebar} from '../../hooks/UseSidebarVisibility'
import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { CLIENT_LINKS } from '../../utils/LINKS'
import { Add, AddCircle, AddCircleOutline, AdminPanelSettings, ArrowLeft, CalendarMonth, Close, Delete, DeleteOutline, DriveFileRenameOutline, FilterAlt, FitScreenOutlined, HighlightOff, Keyboard, PlayArrow, Search, Settings, Title, ZoomIn, ZoomOut } from '@mui/icons-material'
import MultiRangeSlider from "multi-range-slider-react";
import { UseHideTopbar } from '../../hooks/UseTopbarVisibility'
import { useOtherContext } from '../../contexts/OtherContext'
import { LinearProgress, Zoom } from '@mui/material'
import ReusablePaginationComponent from '../../components/reusables/ReusablePaginationComponent/ReusablePaginationComponent'
import { useAuth } from '../../contexts/AuthContext'
import ReusableThesisItem from '../../components/reusables/ReusableThesisItem/ReusableThesisItem'
import { scrollToTopInstantUtil } from '../../utils/Utils'
import { ReusableDraggableDialog } from '../../components/reusables/ReusableDraggableDialog/ReusableDragableDialog'
import { ReusablePageSettingsDialogClient } from '../../components/reusables/ReusablePageSettingsDialog/ReusablePageSettingsDialogClient'
// import { ReusableLoader7, ReusableLoaderInCenter } from '../../components/reusables/ReusableLoaders/ReusableLoaders'
// import { ReusablePaginationComponent } from '../home/HomePage'

// import RangeSlider from 'react-range-slider-input';
// import 'react-range-slider-input/dist/style.css';


const RenderFilters = (props) => {
  const { data } = props;
  // console.log(data)
  
const sortOptions = {
  title_asc: 'Title (A-Z)',
  title_desc: 'Title (Z-A)',
  year_asc: 'Year (from oldest)',
  year_desc: 'Year (from latest)',
  author_asc: 'Author (A-Z)',
  author_desc: 'Author (Z-A)',
  recent: 'Most Recent',
  oldest: 'Oldest',
  latest_modified: 'Latest Modified',
  oldest_modified: 'Oldest Modified',
  email_asc: 'Email (A-Z)',
  email_desc: 'Email (Z-A)',
  // first_name_asc: 'First Name (A-Z)',
  // first_name_desc: 'First Name (Z-A)',
  // last_name_asc: 'Last Name (A-Z)',
  // last_name_desc: 'Last Name (Z-A)',
  privilege_level_asc: 'Privilege Level (Low to High)',
  username_asc: 'Username (A-Z)',
  username_desc: 'Username (Z-A)',
  matric_id_asc: 'Matric ID (A-Z)',
  matric_id_desc: 'Matric ID (Z-A)',
  department_asc: 'Department (A-Z)',
  department_desc: 'Department (Z-A)',
  faculty_asc: 'Faculty (A-Z)',
  faculty_desc: 'Faculty (Z-A)',
  institution_asc: 'Institution (A-Z)',
  institution_desc: 'Institution (Z-A)',

};

  // Function to render a button for each key/property
  const renderButtons = () => {
    return Object.entries(data).map(([key, value]) => (
      <div key={key} className='render-filter-parameters-inner'>
        {
        key === "category_list" && 
        (
          data[key]&&
          <div className='render-filter-parameter-item-div'>

            <button >
              {`categories: ${data[key].split(',').length} applied`}
            </button>
            <Close onClick={() => handleClick(key)} fontSize='1rem' className='render-filter-parameter-item-icon'/>

          </div>
        )
        }
        {
        key === "supervisor_list" && 
        ( 
          data[key] &&
          
          <div className='render-filter-parameter-item-div'>
            <button 
           >
              {`supervisors: ${data[key].split(',').length} applied`}
            </button>
            <Close onClick={() => handleClick(key)} fontSize='1rem' className='render-filter-parameter-item-icon'/>

          </div>
        )
        }
        {
        key === "title" && 
        (
          data[key]&&
          <div className='render-filter-parameter-item-div'>

            <button 
            >
              {`${key}: ${data[key]}`}
            </button>
            <Close onClick={() => handleClick(key)} fontSize='1rem' className='render-filter-parameter-item-icon'/>
          </div>
          
        )
        }
        {
        key === "title_exact_match" && 
        (
          data[key]&&
          <div className='render-filter-parameter-item-div'>

            <button 
            >
              {`${'match title exactly'}: ${data[key] === 'false' ? 'no' : 'yes' }`}
            </button>
            <Close onClick={() => handleClick(key)} 
            fontSize='1rem' className='render-filter-parameter-item-icon'/>

          </div>
        )
        }
        {
        key === "author" && 
        (
          data[key]&&
          <div className='render-filter-parameter-item-div'>

            <button 
            >
              {`${key}: ${data[key]}`}
            </button>
            <Close onClick={() => handleClick(key)} 
            fontSize='1rem' className='render-filter-parameter-item-icon'/>

          </div>
        )
        }
        {
        key === "author_exact_match" && 
        (
          data[key]&&
          <div className='render-filter-parameter-item-div'>

            <button 
            >
              {`${'match author exactly'}: ${data[key] === 'false' ? 'no' : 'yes'}`}
            </button>
            <Close onClick={() => handleClick(key)} 
            fontSize='1rem' className='render-filter-parameter-item-icon'/>

          </div>
        )
        }
        {
        key === "keyword" && 
        (
          data[key]&&
          <div className='render-filter-parameter-item-div'>

            <button 
            >
              {`${key}: ${data[key]}`}
            </button>
            <Close onClick={() => handleClick(key)} 
            fontSize='1rem' className='render-filter-parameter-item-icon'/>

          </div>
        )
        }        
        {
          key === "keyword_exact_match" && 
          (
            data[key]&&
            <div className='render-filter-parameter-item-div'>

              <button 
              >
                {`${'match keyword exactly'}: ${data[key] === 'false' ? 'no' : 'yes'}`}
              </button>
            <Close onClick={() => handleClick(key)} 
            fontSize='1rem' className='render-filter-parameter-item-icon'/>

            </div>
          )
        }        
        {
          key === "min_year" && 
          (
            data[key]&&
            <div className='render-filter-parameter-item-div'>

              <button>
                {`${'min year'}: ${data[key]}`}
              </button>
            <Close onClick={() => handleClick(key)} 
            fontSize='1rem' className='render-filter-parameter-item-icon'/>

            </div>
          )
          }        
          {
          key === "max_year" && 
          (
            data[key]&&
            <div className='render-filter-parameter-item-div'>

              <button> 
                {`${'max year'}: ${data[key]}`}
              </button>
            <Close onClick={() => handleClick(key)} 
            fontSize='1rem' className='render-filter-parameter-item-icon'/>

            </div>
          )
          }
        {
        key === "sort" && 
        (
          data[key]&&
          <div className='render-filter-parameter-item-div'>

            <button>
              {/* {`${'sort by'}: ${sortOptions[key]}`} */}
              {/* {`${'sort by'}: ${sortOptions[value]}`} */}
              {`${'sort by'}: ${(sortOptions[data[key]])?.toLowerCase()}`}
              {/* {`${'sort by'}: ${data[key]}`} */}
            </button>
            <Close onClick={() => handleClick(key)} 
            fontSize='1rem' className='render-filter-parameter-item-icon'/>

          </div>
        )
        }
        {
        key === "and_combination" && 
        (
          data[key]&&
          <div className='render-filter-parameter-item-div'>

            <button >
              {`${'must match all'}: ${data[key] === 'false' ? 'no' : 'yes'}`}
            </button>
            <Close onClick={() => handleClick(key)} 
             fontSize='1rem' className='render-filter-parameter-item-icon'/>


          </div>
          
        )
        }

      </div>
    ));
  };


  const parseUrlParamsToObject = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const paramsObject = {};
    for (const [key, value] of searchParams.entries()) {
      paramsObject[key] = value;
    }
    return paramsObject;
  };

  // Function to update the URL with the modified parameters
  const updateUrlWithParams = (paramsObject) => {
    const searchParams = new URLSearchParams(paramsObject);
    // const newUrl = `${window.location.origin}${window.location.pathname}?${searchParams}`;
    // const newUrl = `${window.location.pathname}?${searchParams}`;
    const newUrl = `${searchParams}`;
    return newUrl
    // window.history.pushState({ path: newUrl }, '', newUrl);
  };

  const navigate = useNavigate()
  const {dispatch} = useOtherContext()
  // Handle button click event
  const handleClick = (key) => {

        // Parse the current URL parameters into an object
    const currentParams = parseUrlParamsToObject();
    

    // Remove the key from the object
    delete currentParams[key];

    // Update the URL with the modified parameters
    const updated_query_string = updateUrlWithParams(currentParams);
    console.log(updated_query_string)


    dispatch({
      type: 'MODIFY_SEARCH_STATE_SESSION',
      newObj: 'current_page',
      payload: 1
    })
    //adv page is not updating because the currentPage is not changing
    //we use this double to force adv page to rerender

    dispatch({
      type: 'FORCE_UPDATE',
    })
    navigate(`${CLIENT_LINKS.advanced_search_results.url}?${updated_query_string}`)

    // You can add your logic here for handling button clicks
    console.log(`Button for Key: ${key} clicked`);
  };

  return (
    <div className='render-filter-parameters'>
      {renderButtons()}
    </div>
  );
}


const countKeysWithValues = (obj) => {
  let count = 0;
  
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      if (value !== null && value !== undefined && value !== "") {
        //we dont want page_size to be considereed, even thought we use in api req
        if(key==="page_size"){
          continue
        }
        count++;
      }
    }
  }
  
  return count;
}

const sortOptions = {
  title_asc: 'Title (A-Z)',
  title_desc: 'Title (Z-A)',
  year_asc: 'Year (from oldest)',
  year_desc: 'Year (from latest)',
  author_asc: 'Author (A-Z)',
  author_desc: 'Author (Z-A)',
  recent: 'Most Recent',
  oldest: 'Oldest',
  latest_modified: 'Latest Modified',
  oldest_modified: 'Oldest Modified',
  email_asc: 'Email (A-Z)',
  email_desc: 'Email (Z-A)',
  // first_name_asc: 'First Name (A-Z)',
  // first_name_desc: 'First Name (Z-A)',
  // last_name_asc: 'Last Name (A-Z)',
  // last_name_desc: 'Last Name (Z-A)',
  privilege_level_asc: 'Privilege Level (Low to High)',
  username_asc: 'Username (A-Z)',
  username_desc: 'Username (Z-A)',
  matric_id_asc: 'Matric ID (A-Z)',
  matric_id_desc: 'Matric ID (Z-A)',
  department_asc: 'Department (A-Z)',
  department_desc: 'Department (Z-A)',
  faculty_asc: 'Faculty (A-Z)',
  faculty_desc: 'Faculty (Z-A)',
  institution_asc: 'Institution (A-Z)',
  institution_desc: 'Institution (Z-A)',

};
const AdvancedSearchResultsPage = () => {
  // UseHideSidebar()
  UseShowSidebar()
  const [interfaceState, setInterfaceState] = useState({
    snackbar_visible: false,
    snackbar_message: "",
    snackbar_severity: "info",
    snackbar_autohide_duration: 1*60*60*24,
    button_disabled : false,
    isSpinnerActive: false,
    isPageSettingsOpened: false

    // confirmation_prompt: false //just use a different state so you can use useeffect of this state, instead of typing each property
  })
  
  const showSnackbar = (val = true) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_visible: true  }))
  }
  const hideSnackbar = (val = true) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_visible: false  }))
  }
  const toggleInterfaceVisibility = (name) => {
    setInterfaceState((prev) => ({ ...prev,  [name]: !prev[name]  }))
  }
  const setSnackbarMessage = (message) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_message: message  }))
  }
  const setSnackbarSeverity = (severity) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_severity: severity  }))
  }
  const setSnackbarDuration = ( duration) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_autohide_duration: duration  }))
  }
  const handleInterfaceState = (name, value_passed) => {
    setInterfaceState((prev) => ({ ...prev,  [name]: value_passed  }))
  }

  const disableButton = () => {
    setInterfaceState((prev) => ({ ...prev,  button_disabled: true  }))
  }
  const enableButton = (name, value_passed) => {
    setInterfaceState((prev) => ({ ...prev,  button_disabled: false  }))
  }
  const showSpinner = () => {
    setInterfaceState((prev) => ({ ...prev,  isSpinnerActive: true  }))
  }
  const hideSpinner = () => {
    setInterfaceState((prev) => ({ ...prev,  isSpinnerActive: false  }))
  }

  const alertSnackbar = (message="hello, user", type="info", duration=9999999) => {
    setSnackbarMessage(message)
    setSnackbarSeverity(type)
    setSnackbarDuration(duration)
    showSnackbar()
  }

  // Use the useLocation hook to access the current URL
  const {search_state, dummy_state, dispatch, thesis_search_url, search_state_session, sidebar_width} = useOtherContext()
  



  const location = useLocation();
  useEffect(() => {
    //set to page 1 when we hit this page coming from a different page
    //or filtering a different route result
    dispatch({
      type: 'MODIFY_SEARCH_STATE_SESSION',
      newObj: 'current_page',
      payload: 1
    })
  }, [location.pathname])

  const prev_params_from_url = () => {
    // Use the URLSearchParams API to parse the query parameters
    const queryParams = new URLSearchParams(location.search);

    const params = {};
    // Iterate over the query parameters and store them in the params object
    for (const [key, value] of queryParams.entries()) {
      params[key] = value;
    }
    // console.log(params)
    return params

  }

      const [componentState, setComponentState] = useState({
    isPageSettingsOpened: false
  })
    const togglePageSettingsUI = () => {
    setComponentState(prev => ({
      ...prev,
      isPageSettingsOpened: !prev.isPageSettingsOpened
    }))
  }


  const [queryState, setQueryState] = useState({
    // baseURL: `/users/find_all_users_advanced_search?itemsPerPage`,
    // itemsPerPage: admin_results_page_items,
    // current_page: admin_current_page,
    page_size: search_state?.req_query_page_size || 10,
    page_num: search_state_session?.current_page || 1,
    sort: '' ,
    // searchBy: '',
    // input_box_value: ''

  })

  const handleQueryState = (e) => {
    const {name, value} = e.target
    setQueryState(prev => ({
      ...prev,
      [name]: value
    }))
  }

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL
  })

  const [currentPage, setCurrentPage] = useState(1);

  const fetchTheses = async () => {
    const query_params = {
      ...prev_params_from_url(),
      page_size : search_state?.req_query_page_size || 10,
      page_num: search_state_session?.current_page || 1,

    }
    let queryParamsString = new URLSearchParams(query_params).toString();
    const response = await axiosInstance.get(`/theses/advanced_search2?${queryParamsString}`, {});

    // console.log(queryParamsString)

    // console.log(response.data.message)
    return response.data.message;

  };

  const { 
    data:fetched_thesis, 
    refetch, 
    isLoading: isLoadingResults,
    isFetching: isFetchingResults,
    error: errorFetchingResults
  } = useQuery({
    queryKey: ['thesisListResultsPage'],
    queryFn: () =>
      fetchTheses()
  }
  )

  useEffect(() => {

    const alertError = () => {
      if(errorFetchingResults && !fetched_thesis && !isFetchingResults && !isLoadingResults ){
        const error = errorFetchingResults
        // setSnackbarDuration(60000)
        alertSnackbar(`${error?.response?.data?.message || 'Unable to fetch theses. An errror occurred'}`, 'error')
      }
    }
    alertError()
  }, [errorFetchingResults, fetched_thesis ,isFetchingResults, isLoadingResults])

  if(!isLoadingResults){
    // console.log(fetched_thesis?.results?.length)
  }
  // console.log(fetched_thesis)
  const aim = {
    ...prev_params_from_url(),
    page_size: search_state?.req_query_page_size || 10,
    page_num: currentPage
  }

  // console.log(aim)

  const handlePageChange = (newPage) => {
    // Handle the page change logic here
    // console.log(newPage)
    // dispatch()
    setCurrentPage(newPage); //not useful. we are using the ls context now
    dispatch({
      type: 'MODIFY_SEARCH_STATE_SESSION',
      newObj: 'current_page',
      payload: newPage
    })
    // refetch()
  };

  useEffect(() => {
    const runRefetchAndScroll = async () => {
      try {
        // await refetch()
        await refetch()
        scrollToTopInstantUtil()
      } 
      catch (error) {
        console.log(error)
      }

    }
    runRefetchAndScroll()
    
  
    return () => {
    }
  }, [
    search_state_session?.current_page, 
    search_state?.req_query_page_size, 
    queryState.sort
  ])


  useEffect(() => {
    refetch()
  }, [
    search_state_session?.force_update,
  ])

  useEffect(() => {
    //set it in the session
    if(fetched_thesis){
      dispatch({
        type: 'SET_MAX_PAGE',
        payload: fetched_thesis?.total_pages || 1
      })
    }
  }, [fetched_thesis])
  


  //fetch the title using react query
  //if the paremValue is null then search the db
  const {user} = useAuth()
  //get the fiter from the url params

  const [prevParamsState, setPrevParamsState] = useState(prev_params_from_url() || {})
  
  return (
    <div className='advanced-search-results-page'>

      <div className='advanced-search-results-page-wrapper'>
        <div className='advanced-search-results-page-wrapper-filters'>
          <div
          style={{
            // paddingLeft: '1rem'
          }}>
          Search results with
          { ' ' + countKeysWithValues(prev_params_from_url()) + ' '} 
          filters
          </div>
          <div>
            {/* <ButtonList data={prevParamsState} />  */}
            <RenderFilters data={prev_params_from_url()} /> 
          </div>
        </div>
        {
        fetched_thesis?.total_hits &&
        <div style={{fontSize: '0.8rem', fontWeight: '200', marginBottom:'0.5rem'}}>
          <span>Total matches : </span>
          <span>{fetched_thesis?.total_hits || 0}</span>
        </div>
        }
        {
        (isLoadingResults || isFetchingResults) &&
        <div>
          <LinearProgress />
        </div>
        }
        {
        fetched_thesis && !isFetchingResults && !isLoadingResults &&
        <div className='advanced-results-page-results'>
            <div className='advanced-results-page-results-inner'>
              <div className='advanced-results-page-items'>
              {
              fetched_thesis?.results?.length > 0 &&
              fetched_thesis?.results?.map((thesis, index) => (
                <ReusableThesisItem
                // key={index} 
                key={thesis?._id || index} 
                passed_thesis={thesis} 
                authUser={user} />
              ))
              }
              {
              fetched_thesis?.results?.length <= 0 &&
              <p>
                No documents matched your query
              </p>
              }
              </div>
            </div>
        </div>
        }
        {
        errorFetchingResults && !fetched_thesis && !isFetchingResults && !isLoadingResults &&
        <div>Unable to fetch theses</div>
        }




        <div className='adv-srp-pagination-component'
                style={{
                  width: `${100 - sidebar_width}%`
                }}>
          <div>

            <ReusablePaginationComponent
            // currentPage={currentPage} 
            currentPage={search_state_session.current_page}
            totalPages={fetched_thesis?.total_pages} 
            onChange={handlePageChange} />
          </div>
          <div className='amappc-bottom-right'>
                <span
                onClick={togglePageSettingsUI}
                className='amappc-bottom-right-settings-icon-container'
                >
                  <Settings fontSize='1rem' />
                </span>
                <ReusableDraggableDialog
                header={"Page Settings"}
                main_body={
                  <ReusablePageSettingsDialogClient 
                    page_context={useOtherContext()} 
                    setIsModalOpened={togglePageSettingsUI}
                    isModalOpened={componentState.isPageSettingsOpened}
                  />
                } 
                isOpened={componentState.isPageSettingsOpened}
                setIsOpened={togglePageSettingsUI}
                />
            </div>
        </div>
      </div>
    </div>
  )
}



export default AdvancedSearchResultsPage
// export default ReusableFilterResultsBox