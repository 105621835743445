// import React from 'react'

import ReusableHorizontalScrollMenu from '../../../../components/reusables/ReusableHorizontalScrollMenu/ReusableHorizontalScrollMenu'
import './adminManageMessages.scss'




const AdminManageMessages = () => {
  return (
    <div className='admin-manage-accounts-page'>
      <div className='admin-manage-accounts-page-inner'>
        <div>Admin Messages</div>
        <div>Currently, you can only check your notifications in the topbar dropdown</div>
        <div>The full message module will be implemented in future patches</div>
        <div>
          {/* <ReusableHorizontalScrollMenu array={} /> */}
        </div>
      </div>
    </div>
  )
}

export default AdminManageMessages