
export const CLIENT_LINKS = {
    document_preview: {
        url: "/document_preview",
        method: "GET"
    },
    admin_panel: {
        url: "/admin_panel",
        method: "GET"
    },
    home:{
        url: "/home",
        method: "GET"
    } ,
    create_thesis:{
        url: "/create_thesis",
        text: "Create new thesis",
        method: "POST"
    } ,
    view_thesis: {
        url: "/view_thesis",
        method: "GET"
        //  :id
    },
    view_all_theses: {
        url: "/view_all_theses/",
        method: "GET"
    },
    update_thesis: 
    {
        url: "/update_thesis",
        method: "PUT"
    },
    // delete_thesis has been removed form appjs routes. Use manage_thesis
    delete_thesis: 
    {
        url: "/delete_thesis",
        method: "DELETE"
        //  :id
    },
    get_account_messages: {
        url: "/get_account_messages",
        method: "GET"
    },
    saved_theses_library: {
        url: "/saved_theses_library",
        method: "GET"
    },
    manage_theses: {
        //all for user //same as author manage_theses
        url: "/manage_theses",
        method: "GET"
    },
    author_manage_theses: {
        //all for user
        url: "/author_manage_theses",
        method: "GET"
    },
    author_manage_specific_thesis: {
        //specific
        url: "/author_manage_specific_thesis",
        method: "GET"
    },
    admin_manage_specific_thesis: {
        //specific
        url: "/admin_manage_specific_thesis",
        method: "GET"
    },
    search_thesis: 
    {
        url: "/search",
        method: "GET"
    },
    advanced_search: {
        url: "/advanced_search",
        method: "GET"
    },
    search: {
        url: "/search",
        method: "GET"
    },
    login: {
        url: "/login",
        method: "POST"
    },
    register: {
        url: "/register",
        method: "POST"
    },
    ocr_module: {
        url: "/ocr_module",
        method: "POST"
    },

    approve_account: {
        url: "/approve_account/:id",
        method: "PUT"
    },
    upload_document: {
        url:"/upload_document",
        method: "POST"
    },
    logout: {
        url: "/logout",
        method: "POST"
    },
    update_account: {
        url: "/update_account",
        method: "POST"
    },
    search_results: {
        url: "/search_results",
        method: "GET"
    },
    advanced_search_results: {
        url: "/advanced_search_results",
        method: "GET"
    },
    about_supervisor_page: {
        url: "/about_supervisor_page",
        method: "GET"
    },
    account_settings_page: {
        url: "/account_settings_page",
        method: "GET"
    },
    notifications_page: {
        url: "/notifications_page",
        method: "GET"
    },
    verify_account_page: {
        //not in use. its logic is in register page
        url: "/verify_account_page",
        method: 'GET'
    },
    verify_account_success_page: {
        url: "/verify_account_success_page",
        method: 'GET'
    },
    forgot_password_page: {
        url: "/forgot_password_page",
        method: "GET"
    },
    reset_password_success_page: {
        url: "/reset_password_success_page", // :resetToken
        method: "GET"
    }


}

export const ADMIN_LINKS = {
    admin_homepage: {
        url: "/admin_panel/",
        method: "GET"
    },
    home: {
        url: "admin_panel",
        method: "GET"
    },
    admin_manage_accounts: {
        url: "manage_accounts",
        method: "GET"
    },
    admin_manage_categories: {
        url: "manage_categories",
        method: "GET"
    },
    admin_manage_research_type: {
        url: "manage_research_type",
        method: "GET"
    },
    admin_manage_supervisors: {
        url: "manage_supervisors",
        method: "GET"
    },
    admin_manage_theses: {
        url: "manage_theses",
        method: "GET"
    },
    admin_manage_administrators: {
        url: "manage_administrators",
        method: "GET"
    },
    admin_manage_full_text_requests: {
    url: "manage_full_text_requests",
    method: "GET"
    },
    adminManageEnrollmentKeys: {
        url: "manageEnrollmentKeys",
        method: "GET"
    },
    admin_manage_messages: {
        url: "manage_messages",
        method: "GET"
    },

}

export const API_LINKS = {
    login: {
        url: "/auth/login",
        method: "POST"
    },
    register: {
        url: "/auth/register",
        method: "POST"
    },
    create_thesis:{
        url: "/theses/create_thesis",
        method: "POST"
    } ,
    view_thesis: {
        url: "/theses/view_thesis/:id",
        method: "GET"
    },
    view_all_theses: {
        url: "/theses/view_all_theses",
        method: "GET"
    },
    update_thesis: 
    {
        url: "/theses/update_thesis/:id",
        method: "PUT"
    },
    // delete_thesis has been removed form appjs. Use manage_thesis
    delete_thesis: 
    {
        url: "/theses/delete_thesis/:id",
        method: "DELETE"
    },
    search_thesis: 
    {
        url: "/theses/search?title=abc",
        method: "GET"
    },
    advanced_search: {
        url: "/theses/advanced_search?name=abc&title=abc",
        method: "GET"
    },
    ocr_module: {
        url: "/theses/ocr_module",
        method: "POST"
    },
    manage_accounts: {
        url: "api/users/manage_accounts",
        method: "GET"
    },
    approve_account: {
        url: "/approve_account/:id",
        method: "PUT"
    },
    upload_document: {
        url:"/upload_document",
        method: "POST"
    }

}





// export const ROUTER_LINKSs = {
//     create_thesis: "create_thesis",
//     view_thesis: "view_thesis/:id",
//     update_thesis: "update_thesis/:id",
//     delete_thesis: "delete_thesis/:id",
//     search_thesis: "search?title=abc",
//     login: "/login",
//     register: "/register",
//     manage_accounts: "/manage_accounts",
//     ocr_module : "/ocr_module"
// }


