import axios from "axios";


export const textLengthShortenedDeprecated = (original_string, end=20, start=0) => {
    //dep because it is too basic
    const shortenedString = original_string.substring(start, end).concat('...');
// console.warn(shortendString) //Hel...
    return shortenedString
}



export const textLengthShortened = (original_string, end=50, start=0) => {  
  if(typeof(original_string) !== 'string'){
    console.warn('The value you supplied is not a string')
    return ''
  }
//no space between the words
//     const shortenedString = original_string.substring(start, end).concat('...');
//     return shortenedString
// console.warn(original_string)

  if(!original_string){
    console.warn('no string passed for shortening')
    return ''
  }
  
  if(original_string?.length <= end){
    return original_string
    //do nothing because ...it means the wohole string would be rendered
  }
  
  if(original_string?.length > end){
  	//below means the condition  
  }
  
  //space between words
  const stringLength = original_string.length
  
  const checkIfSpaceInText =  (original_string) => {
    // try{
      // console.log(original_string)
      if(!original_string){
        return false
      }



      // const splitString = async () => {
      //   try{

      //   }
      // }
      if(typeof(original_string) !== 'string'){
        console.warn('The value you supplied is not a string')
        return false
      }
      
      const fragments = original_string?.split(' ') 
      if(fragments.length > 0){
        return true
      }
      else{
        return false
      }
      return false
    // }
    // catch(err){
    //   return false
    // }
  }
  
  const isSpaceInText = checkIfSpaceInText(original_string)
  
  if(!isSpaceInText){
    //no space in text, it wouldnt make sense to split and show another split of a single block of word
    const shortenedString = original_string.substring(start, end).concat('...');
  	return shortenedString
  }
  
  
  //below means it is long(i.e exceeeds end) and has at least one space
  
  const fragments = original_string.split(' ')
  const last_fragment = fragments.slice(-1)
  const lastIndexAppearance = original_string.lastIndexOf(last_fragment)
  
//   const mod_start = start
  const initial_end = end
  const new_end = end - last_fragment.length
  
  const shortenedString = original_string.substring(start, new_end).concat('...').concat(last_fragment);
  return shortenedString
  
  
  
  
    //temporary solution that must be change ASAP
    var new_string = original_string.substring(start, new_end)
  
  
    //temporary solution that must be change ASAP
  
  
  
}

export const generateLocalFileURL = (file) => {
    const link =  URL.createObjectURL(file)
    return link
}

export const fetchTheses = async () => {
    const response = await axios.get('https://api.example.com/data');
    return response.data.message;
  };

export const objectIsPopulated = (obj) => {
    return !(Object.keys(obj).length === 0);
}

export const dep_deleteItemAndGetNeighborIndex = (array, itemToDelete) => {
  const indexToDelete = array.indexOf(itemToDelete);

  if (indexToDelete === -1) {
    // Item not found in the array
    console.warn("Item not found");
    return -1; // or any other appropriate value
  }

  array.splice(indexToDelete, 1);

  let previousNeighborIndex = indexToDelete - 1;
  let nextNeighborIndex = indexToDelete + 1;

  if (previousNeighborIndex < 0) {
    // No previous neighbor if first neighbour, use next neighbor
    console.warn("No previous neighbor, using next neighbor");
    // console.warn
    return nextNeighborIndex;
  } else if (nextNeighborIndex >= array.length) {
    // No next neighbor if last item, use previous neighbor
    console.warn("No next neighbor, using previous neighbor");
    return previousNeighborIndex;
  } else {
    // Both previous and next neighbors exist
    console.warn("Both previous and next neighbors exist");
    return previousNeighborIndex;
  }
}


export const deprecated_deleteItemAndGetNeighborIndex = (array, itemToDelete) => {
  const indexToDelete = array.indexOf(itemToDelete);

  if (indexToDelete === -1) {
    // Item not found in the array
    console.warn("Item not found");
    return -1; // or any other appropriate value
  }

  // Delete the item from the array
  array.splice(indexToDelete, 1);

  let previousNeighborIndex = indexToDelete - 1;
  let nextNeighborIndex = indexToDelete + 1;

  if (previousNeighborIndex >= 0) {
    // Previous neighbor exists
    return previousNeighborIndex;
  } else if (nextNeighborIndex < array.length) {
    // No previous neighbor, return the next neighbor
    return nextNeighborIndex;
  } else {
    // No neighbors, display alert
    alert("Last item has been deleted already");
    return -1; // or any other appropriate value
  }
}

export const deleteItemAndGetNeighborIndex = (array, itemToDelete) => {
  const indexToDelete = array.indexOf(itemToDelete);

  if (indexToDelete === -1) {
    // Item not found in the array
    console.warn("Item not found");
    return -1; // or any other appropriate value
  }

  array.splice(indexToDelete, 1);

  let previousNeighborIndex = indexToDelete - 1;
  let nextNeighborIndex = indexToDelete + 1;

  // return indexToDelete

  if (previousNeighborIndex < 0) {
    // No previous neighbor if first neighbour, use next neighbor
    console.warn("No previous neighbor, using next neighbor at index: ", indexToDelete);
    // console.warn
  return indexToDelete+1

    // return nextNeighborIndex;

  } else if (nextNeighborIndex >= array.length) {
    // No next neighbor if last item, use previous neighbor
    console.warn("No next neighbor, using previous neighbor at index: ", indexToDelete);
  return indexToDelete-1

    // return previousNeighborIndex;
  } else {
    
    // Both previous and next neighbors exist
    console.warn("Both previous and next neighbors exist:");
    return indexToDelete
    return previousNeighborIndex;
  }
}

export const scrollToBottomUtil = () => {
  const scrollHeight = Math.max(
    document.documentElement.scrollHeight,
    document.body.scrollHeight
  );
  const scrollStep = scrollHeight / 100; // Adjust the step for desired smoothness
  let scrollPosition = 0;

  function step() {
    scrollPosition += scrollStep;
    window.scrollTo(0, scrollPosition);
    if (scrollPosition < scrollHeight) {
      window.requestAnimationFrame(step);
    }
  }

  window.requestAnimationFrame(step);
}
// scrollToBottom();

export const scrollToTopUtil = () => {
  const scrollStep = -window.scrollY / 100; // Adjust the step for desired smoothness
  let scrollPosition = window.scrollY;

  function step() {
    scrollPosition += scrollStep;
    window.scrollTo(0, scrollPosition);
    if (scrollPosition > 0) {
      window.requestAnimationFrame(step);
    }
  }

  window.requestAnimationFrame(step);
}

export const scrollToTopInstantUtil = () => {
    window.scrollTo(0, 0);
}


export const deepCheck2ObjectsForEquality_Util = (object1, object2) => {
  //this is a custom function for comparing equality of object, irrespective of order. It does only a level 1 value check.
  //the level 1 value check is mainly for array of items
  
  var checkTypeStrict = (variable) => {
  if (Array.isArray(variable)) {
    return 'array';
  } else if (typeof variable === 'object' && variable !== null) {
    return 'object';
  } else if (typeof variable === 'string') {
    return 'string';
  } else if (typeof variable === 'number') {
    return 'number';
  } else {
    return 'unknown';
  }
	}

  
  var list_of_keys_in_object1 = []
  var list_of_keys_in_object2 = []
  for(let key in object1){
    //push keys of obj1
    list_of_keys_in_object1.push(key)
  }
 	 for(let key in object2){
     //push keys of obj2
    list_of_keys_in_object2.push(key)
  }
  
  

  
  var object1_length = list_of_keys_in_object1.length
  var object2_length = list_of_keys_in_object2.length
  //level 1.1, check if all keys match
  //length of keys must be equal
  if(object1_length !== object2_length){
    // console.warn('total number of keys are not equal. ')
    return false
  }
  
  
	// console.warn(list_of_keys_in_object1)
	// console.warn(list_of_keys_in_object2)
  
  //level 1.2, check if all keys match
  var checkIfObjectsContainsSameKeys = () => {
    //loop through the first array of keys and check if each is found in the other
    var loop_successfully_passed_count = 0
    loop_object1_keys:
    for(let key1 of list_of_keys_in_object1){
      loop_object2_keys:
      for(let key2 of list_of_keys_in_object2){
        if(key1 === key2){
          loop_successfully_passed_count++ //increase count of test passed
          continue loop_object1_keys;
        }
      }
    }
    if(loop_successfully_passed_count === object1_length){
      //means all keys matched
      return true
    }
    
    //below means not all keys matched
    return false
    
  }
  
  
  //function run verification
  var do_objects_contain_same_keys = checkIfObjectsContainsSameKeys()
  if(!do_objects_contain_same_keys){
    //objects do not contain same keys
    // console.warn('total keys are equal, but all keys do not match/mirror')
    return false
    //below means objects contain same keys lg
  }
  
  
  //level 2.0, check properties of each key
  
  //level 2.1, check level 1 property types
	var checkLevel_1_Value_Types = () => {
    
    var count_tracker = 0
    loop_object_keys:
  	for(let key of list_of_keys_in_object1){
      //both objects have same keys, we've ensured that from preliminary checks
      //we can use list_of_keys_in_object2 likewise
      
      var value_of_object_1_for_loop_key = object1[key]
      var value_of_object_2_for_loop_key = object2[key]
      
      var type_of_val_1 = checkTypeStrict(value_of_object_1_for_loop_key)
      var type_of_val_2 = checkTypeStrict(value_of_object_2_for_loop_key)
      
      if(type_of_val_1 === type_of_val_2){
        count_tracker++
        continue loop_object_keys;
      }
      
//       console.warn('all types failed to match for level 1 check')
//       return false
  	}
    
     if(count_tracker !== list_of_keys_in_object1.length){
      // console.warn('all types failed to match for level 1 check')
      return true
    }
    
    if(count_tracker === list_of_keys_in_object1.length){
      // console.warn('all types match for level 1 check')
      return true
    }
    
    // console.warn('we dont know what to do')
    return true// must return something so we dont miss or break abruptly
  
  }
  
  
  
    //function run verification
  var do_level_1_value_types_concord = checkLevel_1_Value_Types()
  if(!do_level_1_value_types_concord){
    //objects do not contain same keys
    // console.warn('Type concordance failed between level 1 values')
    return false
    //below means objects contain same keys lg
  }
  
  
  //level 2.2, check level 1 values if they are equivalent
  var checkLevel_1_Values = () => {
    loop_object_keys:
  	for(let key of list_of_keys_in_object1){
      //both objects have same keys, we've ensured that from preliminary checks
      //we can use list_of_keys_in_object2 likewise
      
      var value_of_object_1_for_loop_key = object1[key] 
      var value_of_object_2_for_loop_key = object2[key]
      
      var type_of_val_1 = checkTypeStrict(value_of_object_1_for_loop_key)
      var type_of_val_2 = checkTypeStrict(value_of_object_2_for_loop_key)
      
      //for array level 1 values
      if(type_of_val_1 === 'array' && type_of_val_2 === 'array'){
        //we can verify any of val 1 or val 2 as array because we already checked type concordance
        
        //check length
        var array_item_1_length = value_of_object_1_for_loop_key.length
        var array_item_2_length = value_of_object_2_for_loop_key.length
        
        if(array_item_1_length !== array_item_2_length){
          //their length differs, dont waste my time
          // console.warn('length of level 1 arrays values differs from one another')
          return false
          
          //below means they are of same length lg. very important
          //because we shift as we see a valid match
        }
        
        
        var tracker_of_loop_success = 0 //should be equi to the lenght of loop
        loop_array1:
        for(let array_item_1 of value_of_object_1_for_loop_key){
          loop_array2:
          for(let array_item_2 of value_of_object_2_for_loop_key){
            var item_1 = JSON.stringify(array_item_1)
            var item_2 = JSON.stringify(array_item_2)
            if(item_1 === item_2){
              tracker_of_loop_success++
              continue loop_array1;
            }
          }
        }
        
        if(tracker_of_loop_success !== array_item_1_length){
          // console.warn('returning false')
          return false
        }
        //else
        continue loop_object_keys;
      }
      
      
      //for others //needs further specification for type
      var value_of_object_1 = JSON.stringify(object1[key])
      var value_of_object_2 = JSON.stringify(object2[key])
      
      if(value_of_object_1 !== value_of_object_2){
        // console.warn('obj1:', value_of_object_1)
        // console.warn('obj2:', value_of_object_2)
        // console.warn('here')
        return false
      }

  	}
  
    //below means there was no impediment
    return true
  }
  
  //function run verification
  var do_level_1_values_concord = checkLevel_1_Values()
  if(!do_level_1_values_concord){
    //objects do not contain same keys
//     console.warn('total keys are equal, but all keys do not match/mirror')
    // console.warn('here')
    return false
    //below means objects contain same keys lg
  }

  if(do_level_1_values_concord){
    //objects do not contain same keys
//     console.warn('total keys are equal, but all keys do not match/mirror')
    // console.warn('here')
    return true
    //below means objects contain same keys lg
  }

  // console.warn('returning false soon')
  return false
  // return false so that in case everything fails, 
  // we are still able to use the button
}


export const document_append_methods_util = Object.freeze({
  upload_document_method: "upload_document_method",
  text_editor_method: "text_editor_method"
});


export const handleCopyTextToClipboard_Util = async (textToCopy) => {
  try {
    if(!textToCopy){
      console.warn('No text was provided to copy')
      return ''
        // return alert('No text to copy: Please upload image(s)')
    }

    // await navigator.clipboard.writeText(textToCopy);
    await navigator.clipboard.writeText(textToCopy?.toString());

    return true
  } 
  catch (error) {
    console.warn('Failed to copy text:', error);
    // alert('Failed to copy text');
    return false
  }
};


const countKeysWithValues_Util = (obj) => {
  let count = 0;
  
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      if (value !== null && value !== undefined && value !== "") {
        count++;
      }
    }
  }
  
  return count;
}


export const returnMaxNumberInArray_Util = (array) => {
  if (!Array.isArray(array)) {
    return ('this parameter is not a true array')
  }
  if(array?.length < 1){
    return('the array is empty')
  }

  const max = array.reduce((a, b) => Math.max(a, b), -Infinity);
  return max
}

export const generateRandomConcatenatedString_Util = (length) => {
  
  const generateRandom32BitString = () => {
  // Generate a random 32-bit integer
  const randomInt = Math.floor(Math.random() * (2**32));

  // Convert the integer to a hexadecimal string
  const hexString = randomInt.toString(16).toUpperCase();

  return hexString;
	}
  
  let concatenatedString = '';
  for (let i = 0; i < length; i++) {
    concatenatedString += generateRandom32BitString();
  }
  return concatenatedString;
}




export const capitalizeFirstLetter_Util = (string) => {

  if(!string){
    console.warn('no string was supplied')
    return ''
  }
  var newString = (string.replace(/^./, string[0].toUpperCase()));
  return newString
}


export const authorized_privilege_levels_that_delete_users_Util = [52, 53]
export const authorized_privilege_levels_that_update_users_Util = [52, 53]

export const authorized_privilege_levels_that_delete_category_Util = [ 52, 53]
export const authorized_privilege_levels_that_update_category_Util = [ 52, 53]
export const authorized_privilege_levels_that_add_category_Util = [ 52, 53]
export const authorized_privilege_levels_that_create_category_Util = [ 52, 53] //add and create are similar


export const authorized_privilege_levels_that_delete_research_type_Util = [ 52, 53]
export const authorized_privilege_levels_that_update_research_type_Util = [ 52, 53]
export const authorized_privilege_levels_that_create_research_type_Util = [ 52, 53]


export const authorized_privilege_levels_that_create_supervisor_Util = [ 52, 53]
export const authorized_privilege_levels_that_update_supervisor_Util = [ 52, 53]
export const authorized_privilege_levels_that_delete_supervisor_Util = [ 52, 53]

export const authorized_privilege_levels_that_create_admin_Util = [ 53]
export const authorized_privilege_levels_that_update_admin_Util = [ 53]
export const authorized_privilege_levels_that_delete_admin_Util = [ 53]


export const authorized_privilege_levels_that_create_thesis_Util = [ 52, 53]
export const authorized_privilege_levels_that_update_thesis_Util = [ 52, 53]
export const authorized_privilege_levels_that_delete_thesis_Util = [ 52, 53]


export const authorizedPrivilegeLevelsThatUpdateFullTextRequest_Util = [51, 52, 53]


export const authorized_privilege_levels_that_are_admin_Util = [51, 52, 53]

export const authorizedPrivilegeLevelsThatCanAdmin_Util = [51, 52, 53]




export const checkIfUserHasPrivilegeLevelFor_Util = ( authorization_levels_array, user_authorization_levels_array, level=1) => {
  //auth levels first,
  //user levels second
  //skip level
  //level is not needed actually
  const issues = {}
  if(true){
    //the if true block just allows me to collapse these checks in my code editor. nothing much. these conditions must always be checked
    if(!level){
      issues['1'] = 'level not supplied'
      console.warn('level not supplied')
      // return false
    }
    if(typeof(level) !== "number"){
      issues['2'] = 'supplied level was not a number'
      console.warn('supplied level was not a number')
    }

    if(!authorization_levels_array){
      issues['3'] = 'no value was supplied as authorization levels array'
      console.warn('no value was supplied as authorization levels array')
    }
    if(!Array.isArray(authorization_levels_array)){
      issues['4'] = 'value passed as authorization levels is not an array'
      console.warn('value passed as authorization levels is not an array')
    }
    if( authorization_levels_array?.length <= 0 ){
      issues['5'] = 'authorization levels array is empty'
      console.warn('authorization levels array is empty')
    }

    if(!user_authorization_levels_array){
      issues['6'] = 'no value was supplied as authorization levels array'
      console.warn('no value was supplied as authorization levels array')
    }
    if(!Array.isArray(user_authorization_levels_array)){
      issues['7'] = 'value passed as user authorization levels is not an array'
      console.warn('value passed as user authorization levels is not an array')
    }
    if( user_authorization_levels_array?.length <= 0 ){
      issues['8'] = 'user authorization levels is empty'
      console.warn('user authorization levels is empty')
    }
  }

  if ( countKeysWithValues_Util(issues) > 0 ){
    console.warn(issues)
    return false
  }


  const authorized_privilege_levels = authorization_levels_array
  const user_privilege_levels = user_authorization_levels_array

    
  //check if auth user has the privilege
  for(let user_level of user_privilege_levels){
    if(authorized_privilege_levels.includes(user_level) ){
      return true
    }
  }

  return false
}
  



export const navigateAndOpenInNewTab = (link, ) => {
  if(!link){
    console.warn('Please supply a link')
  }
  window.open(`${link}`, '_blank');
}
  



const renderStringWithEllipses_Util = (inputString, firstHalfPercentage = 50, lastQuarterPercentage = 25) => {
  if(!inputString){
    console.warn('Please add an input string in renderStringWithEllipses_Util')
  }

  // Validate percentage values
  if (firstHalfPercentage < 0 || lastQuarterPercentage < 0 || firstHalfPercentage + lastQuarterPercentage > 100) {
    throw new Error('Invalid percentage values');
  }

  // Calculate lengths based on percentages
  const totalLength = inputString.length;
  const firstHalfLength = Math.floor((firstHalfPercentage / 100) * totalLength);
  const lastQuarterLength = Math.floor((lastQuarterPercentage / 100) * totalLength);

  // Extract substrings
  const firstHalf = inputString.substring(0, firstHalfLength);
  const lastQuarter = inputString.substring(totalLength - lastQuarterLength);

  // Construct the result string
  const resultString = `${firstHalf}...${lastQuarter}`;

  return resultString;
}



export const validateEmail_Util = (email) => {

  if(email.toString().trim().length > 100){
    return {
      isValid: false,
      invalidCharacters: [],
      reason: 'String length cannot exceed 100',
    };
  }

  // return {
    // isValid,
    // invalidCharacters,
    // message,
  // };
  // Remove trailing spaces
  const trimmedEmail = email.toString().trim();

  // Regular expression for validating an Email
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Test the email against the regex
  const isValid = emailRegex.test(trimmedEmail);

  // Get the list of invalid characters
  const invalidCharacters = trimmedEmail.split('').filter(char => !emailRegex.test(char));

  // Create a generic message
  const message = isValid ? "Email is valid" : "Email is invalid";

  // Return the validation result object
  return {
    isValid,
    invalidCharacters,
    message,
  };
}

export const validatePassword_Util = (password) => {

  if(password.toString().trim().length > 150){
    return {
      isValid: false,
      invalidCharacters: [],
      reason: 'String length cannot exceed 150',
    };
  }


  // Example usage:
  // const passwordToCheck = "WeakPss";
  // const validationResult = validatePassword(passwordToCheck);
  // console.log(validationResult)

  // validationResult = 
  // {​
  // isValid: false,
  // ​issueArray:  [ "Password must be at least 8 characters long.", "Password must contain at least one symbol.", "Password must contain at least one number." ],
  // issues: { length: "Password must be at least 8 characters long.", symbol: "Password must contain at least one symbol.", number: "Password must contain at least one number." }
  // ​}


  const result = {
    isValid: true,
    issues: {},
    issueArray: [],
  };

  // Check if the password is at least 8 characters long
  if (password.length < 8) {
    result.isValid = false;
    result.issues.length = 'Password must be at least 8 characters long.';
    result.issueArray.push('Password must be at least 8 characters long.');
  }

  // Check if the password contains at least one uppercase letter
  if (!/[A-Z]/.test(password)) {
    result.isValid = false;
    result.issues.uppercase = 'Password must contain at least one uppercase letter.';
    result.issueArray.push('Password must contain at least one uppercase letter.');
  }

  // Check if the password contains at least one lowercase letter
  if (!/[a-z]/.test(password)) {
    result.isValid = false;
    result.issues.lowercase = 'Password must contain at least one lowercase letter.';
    result.issueArray.push('Password must contain at least one lowercase letter.');
  }

  // Check if the password contains at least one symbol
  if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(password)) {
    result.isValid = false;
    result.issues.symbol = 'Password must contain at least one symbol.';
    result.issueArray.push('Password must contain at least one symbol.');
  }

  // Check if the password contains at least one number
  if (!/\d/.test(password)) {
    result.isValid = false;
    result.issues.number = 'Password must contain at least one number.';
    result.issueArray.push('Password must contain at least one number.');
  }

  return result;
}

export const validateAlphabeticWithTrim_Util = (input) => {
  //for name, surname, etc

  if(input.toString().trim().length > 50){
    return {
      isValid: false,
      invalidCharacters: [],
      reason: 'String length cannot exceed 50',
    };
  }


  // Example usage:
  // const inputToCheck = "  Alph@b3t$ Only  ";
  // const validationResult = validateAlphabeticWithTrim(inputToCheck);
  const result = {
    isValid: true,
    invalidCharacters: [],
    additionalIssue: []
  };

  // Trim leading and trailing spaces
  const trimmedInput = input.toString().trim();
  if(trimmedInput.includes(' ')){
    result.isValid = false
    result.invalidCharacters.push(' ')
    result.additionalIssue.push('Text should not contain space')
  }

  // Define a regular expression to match only alphabetic characters and spaces
  const alphabeticPattern = /^[A-Za-z\s]+$/;

  // Test the trimmed input against the pattern
  if (!alphabeticPattern.test(trimmedInput)) {
    result.isValid = false;

    // Find and store the invalid characters
    const invalidChars = trimmedInput.split('').filter(char => !/[A-Za-z\s]/.test(char));
    result.invalidCharacters = invalidChars;
  }

  return result;
}

export const validateAlphanumericWithSpaces_Util = (inputString) => {

  if(inputString.toString().trim().length > 2000){
    return {
      isValid: false,
      invalidCharacters: [],
      reason: 'String length cannot exceed 2000',
    };
  }

  //for thesis title

  // {
  //   isValid: isValid,
  //   invalidCharacters: invalidCharacters,
  //invalidity info
  // };
  // Regular expression to match alphanumeric characters and spaces
  const alphanumericRegexWithSpaces = /^[a-zA-Z0-9\s]+$/;

  // Test the input string against the regular expression
  const isValid = alphanumericRegexWithSpaces.test(inputString);

  // If not valid, find the list of invalid characters
  let invalidCharacters = [];
  if (!isValid) {
    const invalidCharsMatch = inputString.match(/[^a-zA-Z0-9\s]+/g);
    invalidCharacters = invalidCharsMatch ? invalidCharsMatch.join('') : [];
  }

  // Return the result as an object
  return {
    isValid: isValid,
    invalidCharacters: invalidCharacters,
    invalidityInfo: ["Text cannot contain symbols or special characters"]
  };
}




















//new functions 

//alphabets allowed, 
//numbers, spaces, special characters disallowed
//e.g first name,
// the string cannot contain spaces in between, but can contain apostrophes, hyphens but cannot contain numbers. The string must also start with an alphabet. and end with an alphabet
export const validateSingleAlphabeticWordV1_Util = (input) => {
  //no space allowed in between words
  //for name, surname, etc

  if(input.toString().trim().length > 50){
    return {
      isValid: false,
      invalidCharacters: [],
      reason: 'String length cannot exceed 100',
    };
  }

  // Example usage:
  // const inputToCheck = "  Alph@b3t$ Only  ";
  // const validationResult = validateAlphabeticWithTrim(inputToCheck);
  const result = {
    isValid: true,
    invalidCharacters: [],
    additionalIssue: []
  };

  // Trim leading and trailing spaces
  const trimmedInput = input.toString().trim();
  if(trimmedInput.includes(' ')){
    result.isValid = false
    result.invalidCharacters.push(' ')
    result.additionalIssue.push('Text should not contain space')
  }

  // Define a regular expression to match only alphabetic characters and spaces
  const alphabeticPattern = /^[A-Za-z\s]+$/;

  // Test the trimmed input against the pattern
  if (!alphabeticPattern.test(trimmedInput)) {
    result.isValid = false;

    // Find and store the invalid characters
    const invalidChars = trimmedInput.split('').filter(char => !/[A-Za-z\s]/.test(char));
    result.invalidCharacters = invalidChars;
  }

  return result;
}


//matric number
export const validateMatricIDV1_Util = (input) => {
  if(input.toString().trim().length > 20){
    return {
      isValid: false,
      invalidCharacters: [],
      reason: 'String length cannot exceed 20',
    };
  }
  // Trim whitespaces
  const trimmedInput = input.toString().trim();

  // Define the regex pattern
  const pattern = /^[a-zA-Z0-9]+(?:\/[a-zA-Z0-9]+)*$/;

  // Check if the string matches the pattern
  const isValid = pattern.test(trimmedInput) && !trimmedInput.startsWith('/') && !trimmedInput.endsWith('/');

  // Return the validation result
  return {
    isValid: isValid,
    input: trimmedInput,
    message: isValid ? 'Validation successful' : 'Invalid string format',
  };
}

//numbers, letters, spaces allowed
//special characters disallowed
//e.g The Fox3  news
//for titles also
export const validateAlphanumericTitleBlockV1_Util = (inputString) => {
  //for thesis title

  if(inputString.toString().trim().length > 2000){
    return {
      isValid: false,
      invalidCharacters: [],
      reason: 'String length cannot exceed 2000',
    };
  }

  // {
  //   isValid: isValid,
  //   invalidCharacters: invalidCharacters,
  //invalidity info
  // };
  // Regular expression to match alphanumeric characters and spaces
  const alphanumericRegexWithSpaces = /^[a-zA-Z0-9\s]+$/;

  // Test the input string against the regular expression
  const isValid = alphanumericRegexWithSpaces.test(inputString);

  // If not valid, find the list of invalid characters
  let invalidCharacters = [];
  if (!isValid) {
    const invalidCharsMatch = inputString.match(/[^a-zA-Z0-9\s]+/g);
    invalidCharacters = invalidCharsMatch ? invalidCharsMatch.join('') : [];
  }

  // Return the result as an object
  return {
    isValid: isValid,
    invalidCharacters: invalidCharacters,
    invalidityInfo: ["Text cannot contain symbols or special characters"]
  };
}

//alphabets and spaces allowed
//numbers, special characters disallowed
//e.g The Fox
// for titles
export const validateAlphabeticTitleBlockV1_Util  = (input) => {
  // Trim whitespaces
  const trimmedInput = input.toString().trim();

  if(trimmedInput.toString().length > 2000){
    return {
      isValid: false,
      invalidCharacters: [],
      reason: 'String length cannot exceed 2000',
    };
  }


  // Regular expression
  const regex = /^[A-Za-z]+(?: [A-Za-z]+)*$/;

  // Test the input against the regex
  const isValid = regex.test(trimmedInput);

  // Return the result object
  return {
    isValid,
    message: isValid ? 'String is valid' : 'Invalid characters or format',
  };
}

//8 length
//must contain 
//special character, uppercase, lowercase, number
export const validatePasswordV1_Util = (password) => {

  // Example usage:
  // const passwordToCheck = "WeakPss";
  // const validationResult = validatePassword(passwordToCheck);
  // console.log(validationResult)

  // validationResult = 
  // {​
  // isValid: false,
  // ​issueArray:  [ "Password must be at least 8 characters long.", "Password must contain at least one symbol.", "Password must contain at least one number." ],
  // issues: { length: "Password must be at least 8 characters long.", symbol: "Password must contain at least one symbol.", number: "Password must contain at least one number." }
  // ​}
  if(password.toString().trim().length > 150){
    return {
      isValid: false,
      invalidCharacters: [],
      reason: 'Password length cannot exceed 150',
    };
  }

  


  const result = {
    isValid: true,
    issues: {},
    issueArray: [],
  };

  // Check if the password is at least 8 characters long
  if (password.length < 8) {
    result.isValid = false;
    result.issues.length = 'Password must be at least 8 characters long.';
    result.issueArray.push('Password must be at least 8 characters long.');
  }

  // Check if the password contains at least one uppercase letter
  if (!/[A-Z]/.test(password)) {
    result.isValid = false;
    result.issues.uppercase = 'Password must contain at least one uppercase letter.';
    result.issueArray.push('Password must contain at least one uppercase letter.');
  }

  // Check if the password contains at least one lowercase letter
  if (!/[a-z]/.test(password)) {
    result.isValid = false;
    result.issues.lowercase = 'Password must contain at least one lowercase letter.';
    result.issueArray.push('Password must contain at least one lowercase letter.');
  }

  // Check if the password contains at least one symbol
  if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(password)) {
    result.isValid = false;
    result.issues.symbol = 'Password must contain at least one symbol.';
    result.issueArray.push('Password must contain at least one symbol.');
  }

  // Check if the password contains at least one number
  if (!/\d/.test(password)) {
    result.isValid = false;
    result.issues.number = 'Password must contain at least one number.';
    result.issueArray.push('Password must contain at least one number.');
  }

  return result;
}

// allows letters, numbers, the special character underscore _ but no other special characters
export const validateUsernameV1_Util = (input)  => {
  // Trim whitespaces
  const trimmedInput = input.toString().trim();

  if(trimmedInput.toString().length > 100){
    return {
      isValid: false,
      invalidCharacters: [],
      reason: 'String length cannot exceed 100',
    };
  }

  // Regular expression
  const regex = /^[A-Za-z0-9_]+$/;

  // Test the input against the regex
  const isValid = regex.test(trimmedInput);

  // Return the result object
  return {
    isValid,
    message: isValid ? 'String is valid' : 'Invalid characters or format',
  };
}

//hypen and apostrophe allowed
//numbers, space, other characters disallowed
export const validateSingleName_Util = (input) => {
  // Trim whitespaces
  const inputString = input.toString().trim();

  if(input.toString().trim().length > 50){
    return {
      isValid: false,
      invalidCharacters: [],
      reason: 'String length cannot exceed 50',
    };
  }

  // Define the regular expression pattern
  const pattern = /^[a-zA-Z][a-zA-Z'-]*[a-zA-Z]$/;

  // Test the input string against the pattern
  const isValid = pattern.test(inputString);

  // Find and return the list of invalid characters
  const invalidCharacters = isValid
    ? []
    : inputString.split('').filter(char => {
        return !/^[a-zA-Z'-]$/.test(char);
      });

  
  return {
    isValid,
    invalidCharacters,
    reason: isValid ? 'Valid' : 'Invalid string format',
  };
}


export const validateThesisYearV1_Util = (input) => {
  // Remove any whitespaces
  const trimmedInput = input.toString().trim();

  // Define the current year
  const currentYear = new Date().getFullYear();

  // Convert the input to a number
  const numericValue = Number(trimmedInput);

  // Check if the input is a valid number and meets the criteria
  const isValid = !isNaN(numericValue) && Number.isInteger(numericValue) &&
                  numericValue > 0 && numericValue <= currentYear;

  // Return the validation result
  return {
    isValid: isValid,
    input: trimmedInput,
    message: isValid ? 'Validation successful' : 'Invalid number format or value',
  };
}



export const validateFullNameV1_Util = (input) => {
  //no space allowed in between words
  //for name, surname, etc

  if(input.toString().trim().length > 50){
    return {
      isValid: false,
      invalidCharacters: [],
      reason: 'String length cannot exceed 100',
    };
  }

  // Example usage:
  // const inputToCheck = "  Alph@b3t$ Only  ";
  // const validationResult = validateAlphabeticWithTrim(inputToCheck);
  const result = {
    isValid: true,
    invalidCharacters: [],
    additionalIssue: []
  };

  // Trim leading and trailing spaces
  const trimmedInput = input.toString().trim();
  if(trimmedInput.includes(' ')){
    result.isValid = false
    result.invalidCharacters.push(' ')
    result.additionalIssue.push('Text should not contain space')
  }

  // Define a regular expression to match only alphabetic characters and spaces
  const alphabeticPattern = /^[A-Za-z\s]+$/;

  // Test the trimmed input against the pattern
  if (!alphabeticPattern.test(trimmedInput)) {
    result.isValid = false;

    // Find and store the invalid characters
    const invalidChars = trimmedInput.split('').filter(char => !/[A-Za-z\s]/.test(char));
    result.invalidCharacters = invalidChars;
  }

  return result;
}


export const validateFullNameV2_Util = (fullNameInput) => {
  const fullName = fullNameInput.trim();

  if (fullName?.length > 100) {
    return {
      isValid: false,
      reason: "Full name is greater than 100 characters"
    };
  }

  // Modified regex to allow spaces between words
  const fullNameRegex = /^[a-zA-Z]+(?:['-\s][a-zA-Z]+)*$/;
  const isValid = fullNameRegex.test(fullName);

  return { isValid: isValid };
};




export const saveLocalStorageItemWithExpirationDate_Util = async ({
  key,
  data,
  expirationDate = 0,
  expirationUnit = 'seconds',
} = {}) => {

  try{
    const millisecondsPerUnit = {
      seconds: 1000,
      minutes: 60 * 1000,
      hours: 60 * 60 * 1000,
      days: 24 * 60 * 60 * 1000,
      weeks: 7 * 24 * 60 * 60 * 1000,
      months: 30 * 24 * 60 * 60 * 1000, // Assuming 30 days in a month
      years: 365 * 24 * 60 * 60 * 1000, // Assuming 365 days in a year
    };

    const expirationInMilliseconds =
      expirationDate * millisecondsPerUnit[expirationUnit];

    const itemToStore = {
      data,
      expirationDate: Date.now() + expirationInMilliseconds,
    };

    localStorage.setItem(key, JSON.stringify(itemToStore));
  }
  catch(error){
    console.log(error)
  }
};


// Retrieving an item and checking expiration
export const getLocalStorageItemAndCheckExpiration_Util = async (key) => {
  try{

    const storedItem = localStorage.getItem(key);

    if (storedItem) {
      const parsedItem = JSON.parse(storedItem);

      // Check if the item has expired
      if (Date.now() > parsedItem?.expirationDate) {
        // Item has expired, perform cleanup or remove it
        localStorage.removeItem(key);
        return null; // Signal that the item has expired
      }

      return parsedItem.data; // Return the data if not expired
    }

    return null; // Return null if the item doesn't exist
  }
  catch(error){
    console.log(error)
  }
};

// Function to remove expired items from localStorage
export const removeExpiredLocalStorageItems_Util = async () => {
  try{

    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      const storedItem = localStorage.getItem(key);

      if (storedItem) {
        const parsedItem = JSON.parse(storedItem);

        // Check if the item has expired
        if (Date.now() > parsedItem?.expirationDate) {
          // Item has expired, remove it from localStorage
          localStorage.removeItem(key);
        }
      }
    }
    
  }
  catch(error){
    console.log(error)
  }
};