import './reusablePdfComponent.scss'


import { useState } from 'react';
import { Document, Page } from 'react-pdf';

export const ReusablePdfComponent = (props) => {
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const {pdfFile, showFakeLoading} = props

  return (
    <div className="pdf-div">

        <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess} noData={showFakeLoading ? "Pdf Loading" : "No PDF file specified"} >
        {
        Array.apply(null, Array(numPages))
        .map((x, i) => i + 1)
            .map((page, index) => {
                return (
                <>
                <p>
                  Page {index+1} of {numPages}
                </p>
                <Page
                    pageNumber={page}
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                />
                </>
                );
            })
        }
        </Document>
    </div>
  );
}

// export const ReusablePdfComponent















