// import React from 'react'
import { Link } from 'react-router-dom'
import { UseHideSidebar, UseShowSidebar } from '../../../../hooks/UseSidebarVisibility'
import './adminHomePage.scss'
import { ADMIN_LINKS } from '../../../../utils/LINKS'
import { useState } from 'react'
import { ReusableDraggableDialog } from '../../../../components/reusables/ReusableDraggableDialog/ReusableDragableDialog'
import axios from 'axios'
import { AdminPanelSettingsOutlined, Biotech, CategoryOutlined, EmailOutlined, FolderCopyOutlined, ManageAccounts, ManageAccountsOutlined, Subject, SupervisorAccountOutlined, KeyOutlined } from '@mui/icons-material'

const ContactAdminComponent = ({setIsOpened}) => {

  //set upd routes to send the message , error handling too
  //also set up styles
  const INITIAL_MESSAGE_OBJECT = {
    title: '',
    from: '',
    text_body: ''
  }
  const [messageObject, setMessageObject] = useState({
    title: '',
    from: '',
    text_body: ''
  })

  const [buttonDisabled, setButtonDisabled] = useState(false)

  const handleSetMessage = (e) => {
    // console.log(e.target.value)
    const {name, value} = e.target
    setMessageObject((prev) => ({...prev, [name]: value }))
  }

  const handleClose = () => {
    // setOpen(false);
    setIsOpened(false)
  };

  const handleSendMessage = async (e) => {
    // console.log('hi')
      e.preventDefault()
      // alert('hi')
      const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL
      })
    if(true){
      if(!messageObject.title){

        return alert('Please enter the title of your message')
      }
      if(messageObject.title.length < 5){
        return alert("Title cannot be less than 5 characters")
      }
      if(!messageObject.from){

        return alert('Please enter an email')
      }
      if(messageObject.from.length < 3){
        //future
        //do a regex test of the email to ensure that it is a valid email
        return alert('invalid email')
      }
      if(!messageObject.text_body){

        return alert('Please type the body')
      }
      if(messageObject.text_body.length < 5){
        return alert('Message cannot be less than 5 characters')
      }
    }

    try{
      setButtonDisabled(true)
      const body = messageObject
        const res = await axiosInstance.post('/admin/send_message_to_super_admins', body)
        handleClose()
        setButtonDisabled(false)
        setMessageObject(INITIAL_MESSAGE_OBJECT)
        alert('Your message has been successfully sent')

    }
    catch(err){
      let temp = 'an error occurred'
      console.log(err)
      console.log('failure occurred')
      if(err.response.data.custom_code === 4 || err.response.data.custom_code === 5 ){
        console.log('no user or invalid credentials')
        temp = 'no user or invalid credentials'
      }
      return alert('an error occurred')
    }
    finally{
      setButtonDisabled(false)
    }
  }

  // console.log(messageObject)

  return(
    <div className='modal-contact-admin-component'>
      <div className='contact-admin-component-inner'>
        <div className='caci-topbar'>Contact super administrator</div>
        <form className='contact-admin-component-form'>
          <div className='contact-admin-component-title-wrapper'>
            <input 
            name="title"
            onChange={handleSetMessage}
            className='contact-admin-component-title' placeholder='...enter message title' type='text'/>
          </div>
          <div className='contact-admin-component-email-wrapper'>
            <input  
            name="from"
            onChange={(e) => handleSetMessage(e)}
            className='contact-admin-component-email' placeholder='...enter your email ' type='email'/>
          </div>
          <div className='contact-admin-component-textarea-wrapper'>
            <textarea  
            name="text_body"
            onChange={handleSetMessage}
            className='contact-admin-component-textarea' placeholder='...type your message here' rows={9}/>
          </div>
          <div 
          className='contact-admin-component-button-wrapper'>
            <button
            disabled={buttonDisabled}
            onClick={handleSendMessage}
            className='contact-admin-component-button'>Send message</button>
          </div>
        </form>
      </div>
    </div>
  )
}


const AdminHomePage = () => {

  UseHideSidebar()
  // <UseShowSidebar />

  const [modalsVisibility, setModalsVisibility] = useState({
    contact_admin_form: false
  })

  const handleModalsVisibility = (name, typer) => {

    if(typer === 'toggle'){

      setModalsVisibility((prev) => ({...prev, [name]: !prev[name] }))
    }

  }

  // console.log(modalsVisibility)

  const LINKS_ARRAY = [
    {
      to:ADMIN_LINKS.admin_manage_accounts.url,
      text: 'Manage users',
      icon: <ManageAccountsOutlined fontSize='1rem'/>
    },
    {
      to: ADMIN_LINKS.admin_manage_categories.url,
      text: 'Manage categories',
      icon: <CategoryOutlined fontSize='1rem' />
    },
    {
      to: ADMIN_LINKS.admin_manage_research_type.url,
      text: 'Manage research type',
      icon: <Biotech fontSize='1rem' />
    },
    {
      to: ADMIN_LINKS.admin_manage_supervisors.url,
      text: "Manage supervisors",
      icon: <SupervisorAccountOutlined fontSize='1rem' />
    },
    {
      to: ADMIN_LINKS.admin_manage_theses.url,
      text: "Manage theses" ,
      icon: <FolderCopyOutlined  fontSize='1rem'/>
    },
    {
      to: ADMIN_LINKS.admin_manage_administrators.url,
      text: "Manage other adminstrators" ,
      icon: <AdminPanelSettingsOutlined fontSize='1rem' />
    },
    {
      to: ADMIN_LINKS.admin_manage_full_text_requests.url,
      text: "Manage full text requests" ,
      icon: <Subject fontSize='1rem' />
    },
    {
      to: ADMIN_LINKS.admin_manage_messages.url,
      text: "Manage messages" ,
      icon: <EmailOutlined fontSize='1rem' />
    },
    {
      to: ADMIN_LINKS.adminManageEnrollmentKeys.url,
      text: "Manage Enrollment Keys",
      icon: <KeyOutlined fontSize="1rem" />
    }
  ]

  return (
    <div className='admin-homepage'>
      <div className='admin-homepage-inner'>
        <div className='admin-homepage-top-info'>
          <div> Welcome to the Admin Panel</div>
          <div>You can perform any of the following actions</div>
          <div>
            If you run into issues. Please contact the super administrator through this 
            <strong
            // name="contact_admin_form"
            onClick={(e) => {handleModalsVisibility("contact_admin_form", 'toggle')}}
            style={{
              cursor: 'pointer'
            }}
            > form </strong>

          </div>
          {
          // modalsVisibility.contact_admin_form &&
          <ReusableDraggableDialog 
          inner_style={{
            // backgroundColor: 'lime'
          }}
          outer_style={{
            // backgroundColor: 'red'
          }}
          // fullScreen
          // style={{backgroundColo}}
          // header={"Contact super admin"} 
          main_body={<ContactAdminComponent setIsOpened={() =>handleModalsVisibility( "contact_admin_form", 'toggle')} />} 
          // bottom={""} 
          isOpened={modalsVisibility.contact_admin_form}
          // isOpened={true}
          setIsOpened={() =>handleModalsVisibility( "contact_admin_form", 'toggle')} />
          }
        </div>
        <div className='admin-homepage-links'>
          {
          LINKS_ARRAY.map((link, index) => (
          <div className='admin-homepage-link' key={index}>
            <Link to={link.to} className='admin-homepage-link-inner'>
              <span style={{
                display: 'flex',
                alignItems: 'center',
                lineHeight: '1rem',
                fontSize: '1.5rem'
              }}> 
                {link.icon}
              </span>
              <span>
                {link.text}
              </span>

            </Link>
          </div>
          ))
          }
        </div>
      </div>
    </div>
  )
}

export default AdminHomePage