import { UseHideSidebar } from '../../../hooks/UseSidebarVisibility'
import { UseHideTopbar } from '../../../hooks/UseTopbarVisibility'
import './accountSettingsPage.scss'

// import React from 'react'
import { AddOutlined, CheckCircle, Close, InfoOutlined, ManageAccountsOutlined, Search, Settings, VisibilityOutlined } from '@mui/icons-material'
import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
// import { UseHideSidebar } from '../../../hooks/UseSidebarVisibility'

// import { UseHideSidebar } from '../../../../hooks/UseSidebarVisibility'
import { ReusableLoader3, ReusableLoaderInCenter } from '../../../components/reusables/ReusableLoaders/ReusableLoaders'
import ReusableSnackbarComponent from '../../../components/reusables/ReusableSnackbarComponent/ReusableSnackbarComponent'
import {useAdminContext} from '../../../contexts/AdminContext'
import { useAuth } from '../../../contexts/AuthContext'
import { ReusableDraggableDialog } from '../../../components/reusables/ReusableDraggableDialog/ReusableDragableDialog'
import { useEffect } from 'react'
import {authorized_privilege_levels_that_delete_users_Util, authorized_privilege_levels_that_update_users_Util, checkIfUserHasPrivilegeLevelFor_Util, validateAlphabeticTitleBlockV1_Util, validateAlphabeticWithTrim_Util,  validateMatricIDV1_Util,  validatePasswordV1_Util,  validatePassword_Util, validateSingleAlphabeticWordV1_Util, validateSingleName_Util, validateUsernameV1_Util } from '../../../utils/Utils'
import { useNavigate } from 'react-router-dom'
import { CLIENT_LINKS } from '../../../utils/LINKS'




const ConfirmPasswordDialog = () => {

  return (
    <div>

    </div>
  )
}







const AccountSettingsConfirmationDialog = ({open, setOpen, handleChange, otherFunctionPassed}) => {
  //other function passed is the function to run
  //must be dynamically passed

    const [interfaceState, setInterfaceState] = useState({
    button_disabled : false,
    // confirmation_prompt: false //just use a different state so you can use useeffect of this state, instead of typing each property
  })

  
  const disableButton = () => {
    setInterfaceState((prev) => ({ ...prev,  button_disabled: true  }))
  }
  const enableButton = (name, value_passed) => {
    setInterfaceState((prev) => ({ ...prev,  button_disabled: false  }))
  }



  const runYes = async () => {
    try{
      disableButton()
      console.log('here')
      if(otherFunctionPassed){
        await otherFunctionPassed()
        // setOpen(!open) //the main funct will handle this
      }
      if(!otherFunctionPassed){
        console.error('You did not pass other functions')
      }
      console.log('yes')

    }
    catch(error){
      console.error(error)
    }
    finally{
      enableButton()
    }


  }

  const runNo = async () => {
    setOpen(!open)
  }

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      width:'100%'
    }}>
      <label>Save settings?</label>
      <div style={{
        width:'100%',
        display: 'flex',
        gap: '1rem'
      }}>
        <input
        style={{
          width: '100%',
          padding: '0.4rem',
          border: '1px solid gainsboro'
        }}
        className='create-thesis-page-input'
        name="legitPassword" 
        onChange={(e)=>handleChange(e)}
        type='password' 
        placeholder='...enter your account password' />
      </div>
      <div style={{
        width:'100%',
        display: 'flex',
        gap: '1rem'
      }}>
        <button  
        style={{
          padding: '0.2rem 1rem',
          border: '1px solid gray'
        }}
        onClick={runYes}
        disabled={interfaceState.button_disabled}
        >Save</button>
        <button         
        style={{
          padding: '0.2rem 1rem',
          border: '1px solid gray'
        }}
        onClick={runNo}
        disabled={interfaceState.button_disabled}
        >No (go back)</button>
      </div>
    </div>

  )
}








const AccountSettingsPage = () => {
  UseHideSidebar()
  // UseHideTopbar()
  const {dispatch} = useAdminContext()
  const {user} = useAuth()

  //handles the interface
  const [interfaceState, setInterfaceState] = useState({
    snackbar_visible: false,
    snackbar_message: "",
    snackbar_severity: "info",
    snackbar_autohide_duration: 2000,
    button_disabled : false,
    confirmationDialogVisible: false
    // confirmation_prompt: false //just use a different state so you can use useeffect of this state, instead of typing each property
  })

  const showSnackbar = (val = true) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_visible: true  }))
  }
  const hideSnackbar = (val = true) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_visible: false  }))
  }
  const toggleInterfaceVisibility = (name) => {
    setInterfaceState((prev) => ({ ...prev,  [name]: !prev[name]  }))
  }
  const setSnackbarMessage = (message) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_message: message  }))
  }
  const setSnackbarSeverity = (severity) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_severity: severity  }))
  }
  const setSnackbarDuration = ( duration) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_autohide_duration: duration  }))
  }
  const handleInterfaceState = (name, value_passed) => {
    setInterfaceState((prev) => ({ ...prev,  [name]: value_passed  }))
  }

  const disableButton = () => {
    setInterfaceState((prev) => ({ ...prev,  button_disabled: true  }))
  }
  const enableButton = (name, value_passed) => {
    setInterfaceState((prev) => ({ ...prev,  button_disabled: false  }))
  }
  const alertSnackbar = (message="hello, user", type="info", duration=9999999) => {
    setSnackbarMessage(message)
    setSnackbarSeverity(type)
    setSnackbarDuration(duration)
    showSnackbar()
  }



  const [componentState, setComponentState] = useState({
    legitPassword: '', //this will be requested compulsorily for any update
    passwordOldInput: '',
    passwordNewInput : '',
    passwordNewInputConfirm : '',
    first_name: '',
    last_name: '',
    matric_id: '',
    username: '',
    department: '',
    faculty: '',
    institution: '',
    institution_abbreviation: '',
    newPasswordType: 'password',
    oldPasswordType: 'password'

  })
  const toggleConfirmationDialog = () => {
    if(interfaceState.confirmationDialogVisible){
      //means we have it on and we want to turn it off
      //set the legit password input to false
      setInterfaceState((prev) => ({ ...prev,  legitPassword: '' }))
    }
    setInterfaceState((prev) => ({ ...prev,  confirmationDialogVisible: !prev.confirmationDialogVisible }))

  }

  const toggleOldPasswordVisibility = async() => {


    if(componentState.oldPasswordType === 'password') (
      setComponentState((prev) => ({...prev, oldPasswordType: 'text' }))
      
      )
    if(componentState.oldPasswordType === 'text') {
      setComponentState((prev) => ({...prev, oldPasswordType: 'password' }))
      
    }

    await new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 5000); // 10000 milliseconds = 10 seconds
    });

    setComponentState((prev) => ({...prev, oldPasswordType: 'password' }))


    
    //if it is text do nothing. This prevents us from having to disable button
  }

  const toggleNewPasswordVisibility = async() => {


    if(componentState.newPasswordType === 'password') (
      setComponentState((prev) => ({...prev, newPasswordType: 'text' }))
      
      )
    if(componentState.newPasswordType === 'text') {
      setComponentState((prev) => ({...prev, newPasswordType: 'password' }))
      
    }

    await new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 5000); // 10000 milliseconds = 10 seconds
    });

    setComponentState((prev) => ({...prev, newPasswordType: 'password' }))


    
    //if it is text do nothing. This prevents us from having to disable button
  }

  console.log(componentState)

  const handleChange = (e) => {
    const {name, value} = e.target
    setComponentState((prev) => ({...prev, [e.target.name]: e.target.value}))
  }

 

  const fetchUser = async () => {
    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_URL
    })

    // const response = await axiosInstance.get('/users/find_all');
    const response = await axiosInstance.get(`/users/find_user/${user._id}`);
    // console.log(response.data.message)
    return response.data.message;
  };
  const { 
    data:fetched_user , 
    isLoading: isLoadingUser, 
    error: errorFetchingUser,
    isFetching: isFetchingUser,
    refetch:refetchUser 
  } = useQuery({
    queryKey: ['specific_userdasf'],
    // networkMode: 'always',
    queryFn: () =>
      fetchUser(),
    // enabled: false
  })
  useEffect(() => {

    const alertError = () => {

      if(errorFetchingUser && !fetched_user && !isFetchingUser && !isLoadingUser ){
        const error = errorFetchingUser
        // setSnackbarDuration(60000)
        alertSnackbar(`${error?.response?.data?.message || 'Unable to fetch user. An errror occurred'}`, 'error')
      }
      if(!user?._id){
        alertSnackbar(`Please login. You are not authenticated`, 'error')
      }
    }
    alertError()
  }, [errorFetchingUser, fetched_user, isFetchingUser, isLoadingUser])



  const navigate = useNavigate()
  const [componentTimeouts, setComponentTimeouts] = useState({
    navigateLoginTimeout: ''
  })

  const handleComponentTimeouts = (key, value) => {
    setComponentTimeouts(prev => ({...prev, [key]: value }))
  }

  
  const handleUpdateUser = async () => {
    try{
      console.log('FIRED OTHER RUNC IN UPDATE')
      // setInterfaceState((prev) => ({ ...prev,  button_disabled: true  }))
      disableButton()

      if(true){
        const {legitPassword, passwordOldInput, passwordNewInput, passwordNewInputConfirm, first_name, last_name, matric_id, username, department, faculty, institution, institution_abbreviation} = componentState
        

        if(true){
          //collapsible compulsory checks
          if(!legitPassword){
            alertSnackbar('You must supply your password to authorize the change', 'error')
            return ''
          }
          if(legitPassword){
            const {isValid: isValidPassword} = validatePasswordV1_Util(legitPassword)
            if(!isValidPassword){
              alertSnackbar('Your password is not secure. Ensure it is at least 8 characters (and must include a lowercase, uppercase, number and symbol)', 'error')
              return ''

            }
          }
          if(!first_name){
            alertSnackbar('You must supply a first name', 'error')
            return ''
          }
          if(first_name){
            const {isValid: isValidFirstName} = validateSingleName_Util(first_name)
            if(!isValidFirstName){
              alertSnackbar('The first name you have supplied is not valid', 'error')
            }
            return ''
          }
          if(!last_name){
            alertSnackbar( 'You must supply a last name', 'error')
            return ''
          }
          if(last_name){
            const {isValid: isValidLastName} = validateSingleName_Util(last_name)
            if(!isValidLastName){
              alertSnackbar('You must supply a valid last name', 'error')
              return ''
            }
          }

        }

        //the below ifs are not compulsory but when they are present, check them

        if(matric_id){
          const {isValid: isValidMatric }= validateMatricIDV1_Util(matric_id)
          if(!isValidMatric){

            alertSnackbar('The matric ID you supplied is not valid', 'error')
          }
        }
        if(username){
          const {isValid: isValidUsername} = validateUsernameV1_Util(username)
          if(!isValidUsername){

            alertSnackbar('The username you supplied is not valid', 'error')
          }

        }
        if(department){
          const {isValid: isValidDepartment} = validateAlphabeticTitleBlockV1_Util(department)
          if(!isValidDepartment){

            alertSnackbar('The department you supplied is not valid', 'error')
          }
        }

        if(faculty){
          const {isValid: isValidFaculty} = validateAlphabeticTitleBlockV1_Util(faculty)
          if(!isValidFaculty){
            alertSnackbar('The faculty you supplied is not valid', 'error')
            return ''
          }

        }
        if(institution){
          const {isValid: isValidInstitution} = validateAlphabeticTitleBlockV1_Util(institution)
          if(!isValidInstitution){
            alertSnackbar('The institution you supplied is not valid', 'error')
            return ''
          }

        }
        if(institution_abbreviation){
          const {isValid: isValidInstAbbreviation} = validateSingleAlphabeticWordV1_Util(institution_abbreviation)

          if(!isValidInstAbbreviation){
            alertSnackbar('The institution abbreviation you supplied is not valid', 'error')
            return ''
          }
        }
      }

      const user_privilege_levels = user?.privilege_levels

      if(!user_privilege_levels || user_privilege_levels?.length < 1){
        //navigate to login page

        //alert error
        setSnackbarMessage("Please login")
        setSnackbarSeverity('error')
        showSnackbar()

        handleComponentTimeouts(
          'navigateLoginTimeout', 
          setTimeout(() => {
            navigate(CLIENT_LINKS.login.url)
          }, 1000) 
          //set it to the timeout value itself
        )

        return ''
      }
      if(componentTimeouts.navigateLoginTimeout){
        handleComponentTimeouts(
          'navigateLoginTimeout', 
          clearTimeout(componentTimeouts.navigateLoginTimeout) 
        )
        //set it to the cleared timeout value
      }
      const can_update_user = checkIfUserHasPrivilegeLevelFor_Util(authorized_privilege_levels_that_update_users_Util, user_privilege_levels )
      
      if(can_update_user){ 
        if(!renderedPromptToDelete){
          setSnackbarMessage("This action is permanent. Click again to confirm")
          setSnackbarDuration(5000)
          setSnackbarSeverity("warning")
          showSnackbar()
          setRenderedPromptToDelete(true) //confirmation prompt
          
          return ''
        }

        const axiosInstance = axios.create({
          baseURL: process.env.REACT_APP_API_URL
        })
  
        const body = {...componentState}
        const res = await axiosInstance.post(`/users/update_user/${body._id}`, body)

        // const res = await axiosInstance.post(`/users/admin_update_user/bac`, body)

        setSnackbarMessage("Successfully updated user")
        setSnackbarSeverity( "success")
        showSnackbar()
        console.log('SUCCESS')
        toggleConfirmationDialog()
        await refetchUser()

        dispatch({
          type: "ADMIN_FORCE_UPDATE"
        })

        return ''
      }
      throw new Error("You are not granted permission to perform this action ")

    }
    catch(error){
      console.error(error)
      setSnackbarMessage( `Failed to update user. Reason: ${error.response?.data?.message || 'An error occurred' }`)
      setSnackbarSeverity('error')
      showSnackbar()
    }
    finally{
      enableButton()
    }
  }


  useEffect(() => {
    if(fetched_user && !isLoadingUser){
      setComponentState( (prev) => ( {...prev, ...fetched_user}) )
    }
  }, [fetched_user, isLoadingUser])

  
  const [renderedPromptToDelete, setRenderedPromptToDelete] = useState(false)
  useEffect(() => {
    //if any of these change, user must reconfirm deletion
    if(renderedPromptToDelete){
      setRenderedPromptToDelete(false)
    }
  }, [fetched_user, componentState, ])

  useEffect(() => {
    let timer
    //after 3 seconds, if user fails to delete, they will need to reconfirm
    if(renderedPromptToDelete){

      timer = setTimeout(() => {
        setInterfaceState((prev) => ({ ...prev,  button_disabled: false  }))
        setRenderedPromptToDelete(false)

        // console.log('This runs once after 2000ms');
      }, 2000);

    }

    return () => clearTimeout(timer)
  }, [renderedPromptToDelete])




  const handleChangePassword = async () => {
   try{
    disableButton()
    const body = {
      passwordOldInput: componentState.passwordOldInput,
      passwordNewInput: componentState.passwordNewInput
    }
    if(true){
      if(!user?._id){
        setSnackbarMessage( "Please login")
        setSnackbarSeverity("error")
        showSnackbar()
        return ''
      }
      //update with the password alone
      if(!componentState.passwordOldInput){
        setSnackbarMessage( "Please supply your old password")
        setSnackbarSeverity("error")
        showSnackbar()
        return ''
      }
      if(!componentState.passwordNewInput){
        setSnackbarMessage( "Please supply the new password you want to use")
        setSnackbarSeverity("error")
        showSnackbar()
        return ''
      }
      if( componentState.passwordOldInput === componentState.passwordNewInput){
        setSnackbarMessage( "Duplicate. No need to change")
        setSnackbarSeverity("error")
        showSnackbar()
        return ''
      }
      if(!componentState.passwordNewInputConfirm){
        setSnackbarMessage( "Please supply the new password you want to use")
        setSnackbarSeverity("error")
        showSnackbar()
        return ''
      }
      if(componentState.passwordNewInput !== componentState.passwordNewInputConfirm){
        setSnackbarMessage("New password and Confirm new password do not match")
        setSnackbarSeverity("error")
        showSnackbar()
        return ''
      }
    }
    if(true){
      const {passwordOldInput, passwordNewInput} = body
      
        const {isValid: isValidPasswordNewInput} = validatePasswordV1_Util(passwordNewInput)
        if(!isValidPasswordNewInput){
          alertSnackbar('Your new password is not secure. Ensure it is at least 8 characters (and must include a lowercase, uppercase, number and symbol)', 'error')
          return ''
        }

    }


    if(!renderedPromptToDelete){
      setSnackbarMessage("This action is permanent. Click again to confirm")
      setSnackbarDuration(5000)
      setSnackbarSeverity("warning")
      showSnackbar()
      setRenderedPromptToDelete(true) //confirmation prompt
      
      return ''
    }

    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_URL
    })

    // const response = await axiosInstance.get('/users/find_all');
    const response = await axiosInstance.post(
      `/users/update_user_password/${user?._id}`,
      body
    );
    // console.log(user?._id)
    // const response = await axiosInstance.post(
    //   `/users/update_user_password/abc`,
    //   body
    // );
    // console.log(response.data.message)
    // return response.data.message;
    setComponentState(prev => ({
      ...prev, 
      passwordOldInput: '', 
      passwordNewInput: '',
      passwordNewInputConfirm: '',
    }))
    setSnackbarMessage(` ${response?.data?.message ||  'Password successfully changed' }`)
    setSnackbarSeverity("success")
      // toggleInterfaceVisibility('snackbar_visible')
    showSnackbar()
   }
   catch(error){
    console.error(error)
    setSnackbarMessage(`Failed to change password. Reason: ${error.response?.data?.message ||  'An error occurred' }`)
    setSnackbarSeverity("error")
      // toggleInterfaceVisibility('snackbar_visible')
    showSnackbar()
   }   
   finally{
    enableButton()
   }
  }



  const provideInputInfo = (name) => {
    // const {name, value} = e.target

    if(name === "email"){
      const requirements = 
      <>
        <div>Email must contain @  </div>
        <div> Email cannot contain spaces</div>
      </>

      alertSnackbar(requirements)
      
      return ''
    }
    if(name === "passwordOldInput"){
      const requirements = 
      <>
      <div>Password must be at least 8 characters in length </div>
      <div> Password must contain at least one digit </div>
      <div>Password must contain at least one lowercase letter</div>
      <div>Password must contain at least one uppercase letter </div>
      <div>Password and confirm password must match </div>
      </>
      
      alertSnackbar(requirements)


      return ''
    }

    if(name === "passwordNewInput"){
      const requirements = 
      <>
      <div>Password and confirm password must match </div>
      <div>Confirm password must be at least 8 characters in length </div>
      <div> Confirm password must contain at least one digit </div>
      <div>Confirm password must contain at least one lowercase letter</div>
      <div>Confirm password must contain at least one uppercase letter </div>
      </>
      
      alertSnackbar(requirements)


      return ''
    }

    if(name === "passwordNewInputConfirm"){
      const requirements = 
      <>
      <div>Password and confirm password must match </div>
      <div>Confirm password must be at least 8 characters in length </div>
      <div> Confirm password must contain at least one digit </div>
      <div>Confirm password must contain at least one lowercase letter</div>
      <div>Confirm password must contain at least one uppercase letter </div>
      </>
      
      alertSnackbar(requirements)


      return ''
    }



    
  }

  const provideInputFeedback = (name) => {
    //this function runs on every state change
    //we should rather use an effect for this in the case there are many other things that are changing

    //this should be in a use effect that changes when creedntials changes
    
    // console.log(e)
    // const {name, value} = e?.target
    // const {name,value} = e?.target
    // console.log(name, value)
    // return ''

    const goodInput =
    <span 
      style={{cursor: 'pointer', color: 'green', display:'flex', alignItems:'center', justifyContent: 'center', fontSize: '0.8rem', width: '5%' }}
      className='register-page-form-inner-wrapper-group-2-a-icon-2'><CheckCircle fontSize='1rem'/> 
    </span>

    const grayInput = 
    <span 
    style={{cursor: 'pointer', color: 'gray', display:'flex', alignItems:'center', justifyContent: 'center', fontSize: '0.8rem', width: '5%' }}
    onClick={()=> provideInputInfo(name) }
    className='register-page-form-inner-wrapper-group-2-a-icon-2'><InfoOutlined fontSize='1rem'/> </span>

    const badInput = 
    <span 
    style={{cursor: 'pointer', color: 'tomato', display:'flex', alignItems:'center', justifyContent: 'center', fontSize: '0.8rem', width: '5%' }}
    onClick={()=> provideInputInfo(name) }
    className='register-page-form-inner-wrapper-group-2-a-icon-2'><InfoOutlined fontSize='1rem'/> </span>


    const value = componentState[name]


    //i.e credentials.name that is the value of the password, name etc
    

    if(name === "first_name"){
      const {isValid }= validateSingleName_Util(value)
      if(isValid){
          return goodInput
      }
      return  badInput
    }

    if(name === "last_name"){
      // const {isValid }= validateAlphabeticWithTrim_Util(value)
      const {isValid }= validateSingleName_Util(value)

      if(isValid){
        return goodInput
      }
      return  badInput
    }


    if(name === "matric_id"){
      const {isValid }= validateMatricIDV1_Util(value)
      if(isValid){
        return goodInput
      }
      // return goodInput
      return  grayInput
    }

    if(name === "username"){
      const {isValid }= validateUsernameV1_Util(value)
      if(isValid){
        return goodInput
      }
      return  grayInput
    }

    if(name === "department"){
      const {isValid }= validateAlphabeticTitleBlockV1_Util(value)

      if(isValid){
        return goodInput
      }
      return  grayInput
    }

    if(name === "faculty"){
      const {isValid }= validateAlphabeticTitleBlockV1_Util(value)

      if(isValid){
        return goodInput
      }
      return  grayInput
    }



    if(name === "institution"){
      const {isValid }= validateAlphabeticTitleBlockV1_Util(value)

      if(isValid){
        return goodInput
      }
      return  grayInput
    }

    if(name === "institution_abbreviation"){
      const {isValid }= validateSingleAlphabeticWordV1_Util(value)

      if(isValid){
        return goodInput
      }
      return  grayInput
    }

    if(name === "passwordOldInput"){
      const {isValid }= validatePassword_Util(value)
      if(isValid){
        return goodInput
      }
      return  grayInput
    }

    if(name === "passwordNewInput"){
      const {isValid }= validatePassword_Util(value)
      if(isValid){
        return goodInput
      }
      return  grayInput
    }

    if(name === "passwordNewInputConfirm"){
      const {isValid }= validatePassword_Util(value)
      if(isValid){
        return goodInput
      }
      return  grayInput
    }






    // //default
    return grayInput

    
  }


  //refetch the id  
  return(
    <div className='amap-fullscreen-modal-body'>
      <ReusableSnackbarComponent 
      severity={interfaceState.snackbar_severity}
      open={interfaceState.snackbar_visible}  
      inner_message={interfaceState.snackbar_message} 
      setOpen={() => toggleInterfaceVisibility('snackbar_visible')}
      autoHideDuration={ interfaceState.snackbar_autohide_duration || 2000}/>
      <ReusableDraggableDialog
        isOpened={interfaceState.confirmationDialogVisible}
        setIsOpened={toggleConfirmationDialog}
        main_body={ 
          <AccountSettingsConfirmationDialog
          open={interfaceState.confirmationDialogVisible}
          setOpen={toggleConfirmationDialog}
          otherFunctionPassed={handleUpdateUser}
          handleChange={handleChange}
          />}
      />

      <div className='amap-fullscreen-modal-header'>
        <div className='amap-fullscreen-modal-header-inner'>

          <span
          style={{
            paddingLeft: '1rem'
          }}
          className='title'>Account Settings</span>
          <span 
          onClick={toggleConfirmationDialog}
          className='update'>SAVE</span>
        </div>
      </div>
      <div className='amap-fullscreen-modal-body-inner'>
        <div className='amap-fullscreen-modal-body-form-section'>
          <div  className='create-thesis-page-form-item'>
            <label>*First name</label>
            <div style={{display: 'flex', width: '100%',}}>
              <input 
              style={{width: '95%'}}
              onChange={(e) => handleChange(e)}
              className='create-thesis-page-input' type='text' 
              name="first_name" 
              defaultValue={componentState?.first_name}/>
              {provideInputFeedback("first_name")}

            </div>
          </div>
          <div  className='create-thesis-page-form-item'>
            <label>*Last name </label>
            <div style={{display: 'flex', width: '100%'}}>
              <input 
                style={{width: '95%'}}
              onChange={(e) => handleChange(e)}
              // onChange={(e) => handleChangeNewThesis(e)}
              className='create-thesis-page-input' type='text' 
              name="last_name" 
              defaultValue={componentState?.last_name || ''}/>
              {provideInputFeedback("last_name")}

            </div>
          </div>
          <div  className='create-thesis-page-form-item'>
            <label>Matric</label>
            <div style={{display: 'flex', width: '100%'}}>
              <input 
                style={{width: '95%'}}
              onChange={(e) => handleChange(e)}
              // onChange={(e) => handleChangeNewThesis(e)}
              className='create-thesis-page-input' type='text' 
              name="matric_id" 
              defaultValue={componentState?.matric_id || ''}/>
              {provideInputFeedback("matric_id")}

            </div>
          </div>
          <div  className='create-thesis-page-form-item'>
            <label>Username</label>
            <div style={{display: 'flex', width: '100%'}}>
              <input 
              style = {{
                cursor: 'not-allowed', width: '95%'
              }}
              onChange={(e) => handleChange(e)}
              // onChange={(e) => handleChangeNewThesis(e)}
              className='create-thesis-page-input' type='text' 
              name="username" 
              defaultValue={componentState?.username || ''}/>
              {provideInputFeedback("username")}

            </div>
          </div>
          <div  className='create-thesis-page-form-item'>
            <label>Department</label>
            <div style={{display: 'flex', width: '100%'}}>
              <input
                style={{width: '95%'}}

              onChange={(e) => handleChange(e)}
              // onChange={(e) => handleChangeNewThesis(e)}
              className='create-thesis-page-input' type='text' 
              name="department" 
              defaultValue={componentState?.department || ''}/>
              {provideInputFeedback("department")}

            </div>
          </div>
          <div  className='create-thesis-page-form-item'>
            <label>Faculty</label>
            <div style={{display: 'flex', width: '100%'}}>
              <input 
              style={{width: '95%'}}
              onChange={(e) => handleChange(e)}
              // onChange={(e) => handleChangeNewThesis(e)}
              className='create-thesis-page-input' type='text' 
              name="faculty" 
              defaultValue={componentState?.faculty || ''}/>
              {provideInputFeedback("faculty")}

            </div>
          </div>
          <div  className='create-thesis-page-form-item'>
            <label>Institution</label>
            <div style={{display: 'flex', width: '100%'}}>
              <input 
              style={{width: '95%'}}
              onChange={(e) => handleChange(e)}
              // onChange={(e) => handleChangeNewThesis(e)}
              className='create-thesis-page-input' type='text' 
              name="institution" 
              defaultValue={componentState?.institution || ''}/>
              {provideInputFeedback("institution")}

            </div>
          </div>
          <div  className='create-thesis-page-form-item'>
            <label>Institution abbreviation</label>
            <div style={{display: 'flex', width: '100%'}}>
              <input 
                style={{width: '95%'}}
                onChange={(e) => handleChange(e)}
                // onChange={(e) => handleChangeNewThesis(e)}
                className='create-thesis-page-input' type='text' name="institution_abbreviation" 
                defaultValue={componentState?.institution_abbreviation || ''}/>
                {provideInputFeedback("institution_abbreviation")}

            </div>
          </div>
          <div  className='create-thesis-page-form-item'>
            <details>
              <summary style={{cursor: 'pointer'}}> Change Password </summary>
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
                paddingTop: '1rem'
              }}>
                <div  className='create-thesis-page-form-item'>
                  <label>Old password</label>
                  <div style={{display: 'flex', width: '100%'}}>

                    <input 
                    style={{width: '95%'}}
                    onChange={(e) => handleChange(e)}
                    // onChange={(e) => handleChangeNewThesis(e)}
                    className='create-thesis-page-input' 
                    type={componentState?.oldPasswordType || 'password'}
                    name="passwordOldInput" 
                    value={componentState.passwordOldInput}
                    placeholder='...old password'
                    />
                    {
                    componentState?.passwordOldInput &&
                    <span 
                      onClick={toggleOldPasswordVisibility}
                      style={{cursor: 'pointer',display:'flex', alignItems:'center', fontSize: '1.1rem', paddingRight:'0.3rem', color: 'gray' }}
                      className='register-page-form-inner-wrapper-group-2-a-icon-2'><VisibilityOutlined fontSize='1rem'/>
                    </span>
                    }
                    {provideInputFeedback("passwordOldInput")}

                  </div>
                </div>
                <div  className='create-thesis-page-form-item'>
                  <label>New password</label>
                  <div style={{display: 'flex', width: '100%'}}>
                    <input 
                    style={{width: '95%'}}
                    onChange={(e) => handleChange(e)}
                    // onChange={(e) => handleChangeNewThesis(e)}
                    className='create-thesis-page-input' 
                    type={componentState?.newPasswordType || 'password'} 
                    name="passwordNewInput" 
                    value={componentState.passwordNewInput}
                    placeholder='...new password'
                    />
                    {
                    componentState?.passwordNewInput &&
                    <span 
                      onClick={toggleNewPasswordVisibility}
                      style={{cursor: 'pointer',display:'flex', alignItems:'center', fontSize: '1.1rem', paddingRight:'0.3rem', color: 'gray' }}
                      className='register-page-form-inner-wrapper-group-2-a-icon-2'><VisibilityOutlined fontSize='1rem'/>
                    </span>
                    }
                    {provideInputFeedback("passwordNewInput")}

                  </div>
                </div>
                <div  className='create-thesis-page-form-item'>
                  <label>Confirm New password</label>
                  <div style={{display: 'flex', width: '100%'}}>                  
                    <input 
                    style={{width: '95%'}}
                    onChange={(e) => handleChange(e)}
                    // onChange={(e) => handleChangeNewThesis(e)}
                    className='create-thesis-page-input' 
                    type={componentState?.newPasswordType || 'password' } 
                    name="passwordNewInputConfirm" 
                    value={componentState.passwordNewInputConfirm}
                    placeholder='...confirm new password '
                    />
                    {
                    componentState?.passwordNewInputConfirm &&
                    <span 
                      onClick={toggleNewPasswordVisibility}
                      style={{cursor: 'pointer',display:'flex', alignItems:'center', fontSize: '1.1rem', paddingRight:'0.3rem', color: 'gray' }}
                      className='register-page-form-inner-wrapper-group-2-a-icon-2'><VisibilityOutlined fontSize='1rem'/>
                    </span>
                    }
                    {provideInputFeedback("passwordNewInputConfirm")}

                  </div>

                </div>
                <div>
                  <button onClick={handleChangePassword} 
                  disabled={interfaceState.button_disabled}
                  >Change Password</button>
                </div>
              </div>
              
            </details>
            
          </div>
        </div>

        <div></div>
      </div>
    </div>
  )
}
export default AccountSettingsPage