// import React from 'react'

import './adminManageTheses.scss'


const AdminManageTheses = () => {
  return (
    <div>AdminManageTheses</div>
  )
}

export default AdminManageTheses