import './sidebar.scss'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
// import {UseHideSidebar, UseShowSidebar} from '../../hooks/UseSidebarVisibility'
import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
// import { CLIENT_LINKS } from '../../utils/LINKS'
import { Add, AddCircle, AddCircleOutline, AddCircleOutlineSharp, AdminPanelSettings, ArrowLeft, CalendarMonth, CloseFullscreenOutlined, Delete, DeleteOutline, DriveFileRenameOutline, FilterAlt, FitScreenOutlined, HighlightOff, Keyboard, PlayArrow, Remove, Search, Title, ZoomIn, ZoomOut } from '@mui/icons-material'
import MultiRangeSlider from "multi-range-slider-react";
// import { UseHideTopbar } from '../../hooks/UseTopbarVisibility'
// import { useOtherContext } from '../../contexts/OtherContext'
import { Slider, Zoom } from '@mui/material'
import { useOtherContext } from '../../contexts/OtherContext'
import { CLIENT_LINKS } from '../../utils/LINKS'
import { UseShowSidebar } from '../../hooks/UseSidebarVisibility'
import _ from 'lodash'
import {  deepCheck2ObjectsForEquality_Util } from '../../utils/Utils'



const CustomCheckbox = ({ id, checked, onChange, label }) => (
  <div>
    <input
      type="checkbox"
      checked={checked}
      onChange={onChange}
      id={id}
    />
    <label htmlFor={id}>{label}</label>
  </div>
);


const sortOptions = {
  title_asc: 'Title (A-Z)',
  title_desc: 'Title (Z-A)',
  year_asc: 'Year (from oldest)',
  year_desc: 'Year (from latest)',
  author_asc: 'Author (A-Z)',
  author_desc: 'Author (Z-A)',
  recent: 'Most Recent',
  oldest: 'Oldest',
  latest_modified: 'Latest Modified',
  oldest_modified: 'Oldest Modified',
  // email_asc: 'Email (A-Z)',
  // email_desc: 'Email (Z-A)',
  // first_name_asc: 'First Name (A-Z)',
  // first_name_desc: 'First Name (Z-A)',
  // last_name_asc: 'Last Name (A-Z)',
  // last_name_desc: 'Last Name (Z-A)',
  // privilege_level_asc: 'Privilege Level (Low to High)',
  username_asc: 'Username (A-Z)',
  username_desc: 'Username (Z-A)',
  matric_id_asc: 'Matric ID (A-Z)',
  matric_id_desc: 'Matric ID (Z-A)',
  department_asc: 'Department (A-Z)',
  department_desc: 'Department (Z-A)',
  faculty_asc: 'Faculty (A-Z)',
  faculty_desc: 'Faculty (Z-A)',
  institution_asc: 'Institution (A-Z)',
  institution_desc: 'Institution (Z-A)',

};

// const initialSort = sortOptions['latest_modified'];

const INITIAL_FILTER_STATE = {
  category_list: [
    {
    "_id": "64dc21d65bca0d941b948049",
    "category": "hardware",
    "createdAt": "2023-08-16T01:09:42.772Z",
    "updatedAt": "2023-08-16T01:09:42.772Z",
    "__v": 0
    },
    {
      "_id": "64dc21ef5bca0d941b94804d",
      "category": "software",
      "createdAt": "2023-08-16T01:10:07.139Z",
      "updatedAt": "2023-08-16T02:46:08.709Z",
      "__v": 0
    }

  ],
  supervisor_list: [
    {
      "_id": "64dc3abfef07eea52721373c",
      "supervisor_name": "Theresa O. Omodunbi",
      "supervisor_title": "Dr.",
      "department": "Computer Science and Engineering",
      "faculty": "Technology",
      "institution": "Obafemi Awolowo University",
      "institution_abbreviation": "OAU",
      "createdAt": "2023-08-16T02:55:59.783Z",
      "updatedAt": "2023-08-16T02:55:59.783Z",
      "__v": 0
  },
  {
    "_id": "64dc3b8cef07eea52721373e",
    "supervisor_name": "F.O. Asahiah",
    "supervisor_title": "Dr.",
    "department": "Computer Science and Engineering",
    "faculty": "Technology",
    "institution": "Obafemi Awolowo University",
    "institution_abbreviation": "OAU",
    "createdAt": "2023-08-16T02:59:24.563Z",
    "updatedAt": "2023-08-16T02:59:24.563Z",
    "__v": 0
  } 
  ],
  year: {
    min_year: 1960,
    max_year: new Date().getFullYear()
  },
  sort: "latest_modified",
}


export const Sidebar = () => {
  //you must have come from the advanced search page, hence when you get here we fetch the distinct categories and distinct supervisors of your search. 
  //Else use the normal db ones

  // UseHideSidebar()
  // UseHideTopbar()

  //component derives data from search state of router
  //search state is also available in the OtherContext

  const initialSelected = (state_value, value_passed) => {
    const check_passed = (state_value === value_passed)
    return check_passed
  }

  const {sidebar_width, sidebar_enabled, dispatch, search_state, dummy_state, search_state_session} = useOtherContext()

  const setSidebarWidth = (direction) => {
      //will be rendered in percent

      if(direction === "minus"){
          if(sidebar_width <= 10){
              //modify if if error occurs
              return alert('minimum height reached')
          }
          return dispatch({
              type: "SET_SIDEBAR_WIDTH",
              payload: sidebar_width - 25
          })
      }

      if(direction === "plus"){
          if(sidebar_width >= 50){
              return alert('maximum height reached')
          }
          return dispatch({
              type: "SET_SIDEBAR_WIDTH",
              payload: sidebar_width + 25
          })
      }

      else{
          return ''
      }
  }

  const minimizeSidebar = async() => {
    await dispatch({
      type: "SET_SIDEBAR_WIDTH",
      payload: 5
    })
    // return dispatch({
      // type: "HIDE_SIDEBAR",
    // })
  }

  const maximizeSidebar = async() => {
    await dispatch({
      type: "SET_SIDEBAR_WIDTH",
      payload: 25
    })
    // return dispatch({
      // type: "HIDE_SIDEBAR",
    // })
  }

  const navigate = useNavigate()
  const location = useLocation()
  // console.log(location)

  //fetch the title using react query
  //if the paremValue is null then search the db

  //below for query page items returned *start
  const [reqQueryPageSize, setReqQueryPageSize] = useState(search_state?.req_query_page_size || 10)
  const handleReqQueryPageSize = (e) => {
    // setReqQueryPageSize(e.target.value) //this is done directly in the jsx code
    dispatch({
      type: 'MODIFY_SEARCH_STATE',
      newObj: 'req_query_page_size',
      payload: reqQueryPageSize
    })
  }
  //below for query page items returned *end

  const fetchSupervisorList = async () => {
    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_URL
    })

    const response = await axiosInstance.get('/supervisor/find_all_supervisor');
    // console.log(response.data.message)
    return response.data.message;
  };
  const { data:fetched_supervisor_list } = useQuery({
    queryKey: ['supervisorList'],
    queryFn: () =>
      fetchSupervisorList()
  }
  )



  const fetchCategoryList = async () => {
    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_URL
    })

    const response = await axiosInstance.get('/category/find_all_category');
    // console.log(response.data.message)
    return response.data.message;
  };
  const { data:fetched_category_list , isLoading: isLoadingCategoryList} = useQuery({
    queryKey: ['categoryList'],
    queryFn: () =>
      fetchCategoryList()
  }
  )
  if(!isLoadingCategoryList){
    // console.log(fetched_category_list)
  }





  const [filtersState, setFiltersState] = useState(INITIAL_FILTER_STATE)
  // console.table(filtersState.category_list)
  // console.log(filtersState.category_list)

  const [trackerForAppliedFilter, setTrackerForAppliedFilter] = useState({
    // applied: false,
    previous_state_before_application: INITIAL_FILTER_STATE, //or use a single useeffect to store it
    appliable: false //if its appliable then the button is enabled
  })

  // console.log(trackerForAppliedFilter.previous_state_before_application)
  // console.log(filtersState)

  // const [yearRange, setYearRange] = useState([10, new Date().getFullYear()]);
  const [yearRange, setYearRange] = useState([ 1960, new Date().getFullYear() ]);
  const handleYearRange = (e, newValue) => {
    // const { minValue, maxValue } = e;
    setYearRange(newValue)
    setFiltersState((prev) => ({
      ...prev,
      year: {
        min_year: newValue[0],
        max_year: newValue[1],
      },
    }));    
  };
  //sets the state with the fetched data
  useEffect(() => {

    if(fetched_supervisor_list){
      setFiltersState((prev) => ({
        ...prev,
        supervisor_list : [
          // ...prev.supervisor_list, 
          ...fetched_supervisor_list
        ]
      }))
      setTrackerForAppliedFilter(prev => ({
        ...prev,
        // previous_state_before_application: filtersState
        previous_state_before_application: {
          ...prev.previous_state_before_application,
          supervisor_list: [...fetched_supervisor_list]
        }
      }))
    }

    if(fetched_category_list){
      setFiltersState((prev) => ({
        ...prev,
        category_list : [
          // ...prev.category_list  , 
          ...fetched_category_list
        ]
      }))
      setTrackerForAppliedFilter(prev => ({
        ...prev,
        // previous_state_before_application: filtersState
        previous_state_before_application: {
          ...prev.previous_state_before_application,
          category_list: [...fetched_category_list],
        }
      }))
    }
  }, [fetched_category_list, fetched_supervisor_list]);


  const handleSortByChange = (event) => {
    const selectedSortOption = event.target.value;
    setFiltersState((prevFilterState) => ({
      ...prevFilterState,
      sort: selectedSortOption,
    }));
  };


  const toggleSelected = (selectedArray, selectedItem) => {
    return selectedArray.some(selected => selected._id === selectedItem._id)
      ? selectedArray.filter(selected => selected._id !== selectedItem._id)
      : [...selectedArray, selectedItem];
  };

  const handleCategoryChange = (category) => {
    setFiltersState(prev => ({
      ...prev,
      category_list: [...toggleSelected(prev.category_list, category) ]
    }))
  };

  const handleSupervisorChange = (supervisor) => {
    setFiltersState(prev => ({
      ...prev,
      supervisor_list: [...toggleSelected(prev.supervisor_list, supervisor) ]
    }))
  };


  const resetFiltersState = () => {
    setFiltersState(INITIAL_FILTER_STATE)
  }

  const handleClearAllCategories = () => {
    setFiltersState(prev => ({
      ...prev,
      category_list: []
    }))
  }
  const handleResetCategories = () => {
    setFiltersState(prev => ({
      ...prev,
      category_list: fetched_category_list
    }))
  }

  const handleClearAllSupervisors = () => {
    setFiltersState(prev => ({
      ...prev,
      supervisor_list: []
    }))
  }
  const handleResetSupervisors = () => {
    setFiltersState(prev => ({
      ...prev,
      supervisor_list: fetched_supervisor_list
    }))
  }


  function ButtonList({ data }) {
    return (
      <div>
        {Object.keys(data).map((key) => (
          <button key={key}>
            {`${key}: ${
              Array.isArray(data[key]) ? `Array (${data[key].length})` : data[key]
            }`}
          </button>
        ))}
      </div>
    );
  }



  //run the params_from_url on every rerender, then set the year to what we have in the sidebar year, else default to regular date if none is available in the url params

  const constructApiUrl = async () => {

    const params_from_url = () => {
      // Use the URLSearchParams API to parse the query parameters
      const queryParams = new URLSearchParams(location.search);
  
      const params = {};
      // Iterate over the query parameters and store them in the params object
      for (const [key, value] of queryParams.entries()) {
        params[key] = value;
      }
      // console.log(params)
      return params
  
    }
  
    const {  sort, year, ...restFilters } = filtersState;
  
    // Create an object to store the query parameters
    let queryParams = {};
  
    // Iterate through the rest of the filters
    // i.e get the rest of the filters from the things we manipulated in the sidebar
    for (const [filterName, filterValue] of Object.entries(restFilters)) {
      // Check if the filterValue is an array and not empty
      if (Array.isArray(filterValue) && filterValue.length > 0) {
        queryParams[filterName] = filterValue.map(item => item._id).join(',');
      } else if (filterValue) {
        queryParams[filterName] = filterValue;
      }
    }
  
    // Add other fixed parameters
    queryParams.sort = sort;
    queryParams.page_size = search_state?.req_query_page_size || 10;
  
    // Add year filter parameters if they are specified
    if (year.min_year && year.max_year) {
      queryParams.min_year = year.min_year;
      queryParams.max_year = year.max_year;
    }
  
    queryParams = {
      ...params_from_url(),
      ...queryParams
      // keyword:"data",
      // superfiltersState.s
    }
      // console.log(queryParams); 



      //we dont need to set the below, if we are running this function, it means we are setting a new filter, so we start from page1. Page 1 is specified in the dispatch newObj
    // queryParams.page_num = search_state_session.current_page
  
    // Convert the queryParams object to a query string
    let queryParamsString = new URLSearchParams(queryParams).toString();
  
    try {
      setTrackerForAppliedFilter(prev => ({ ...prev, appliable: false }));
  
      const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL
      });

      // console.log(queryParamsString)
      // queryParamsString = queryParamsString.replaceAll('%', ',')
      // console.log(queryParamsString)
      
      // console.log(`/theses/advanced_search2?${queryParamsString}`)
      // console.log('hhhhh')

      const response = await axiosInstance.get(`/theses/advanced_search2?${queryParamsString}`, {});
      // console.log(response.data.message);


      //set the current page to 1st page and push it into the queryParams
          dispatch({
      type: 'MODIFY_SEARCH_STATE_SESSION',
      newObj: 'current_page',
      payload: 1
    })
    //adv page is not updating because the currentPage is not changing
    //we use this double to force adv page to rerender

    dispatch({
      type: 'FORCE_UPDATE',
    })

    setTrackerForAppliedFilter(prev => ({ ...prev, previous_state_before_application: filtersState }));

      navigate(`${CLIENT_LINKS.advanced_search_results.url}?${queryParamsString}`)
      // alert('hi')
    } 
    catch (error) {
      setTrackerForAppliedFilter(prev => ({ ...prev, appliable: true }));
      console.log(error);
    } 
    finally {
      // Any cleanup or additional code you want to execute
    }
  };

  // console.log(dummy_state)
  
  

  //prevent clickable button if filters dont change
  useEffect(() => {
    // console.log('effect ran')

    const checkForFilterChange = () => {

      const object1 = filtersState
      const object2 = trackerForAppliedFilter.previous_state_before_application
      
      // console.log(object1)
      // console.log(object2)
      // const isEqual = deepEqual(object1, object2);
      const isEqual = deepCheck2ObjectsForEquality_Util(object1, object2);
      // console.log(isEqual)
      if(isEqual){
        // console.log('is the same')
        // console.log('IS EQUAL')
        setTrackerForAppliedFilter(prev => ({...prev, appliable: false}))
        return ''
      }
      
      // console.log('IS NOT EQUAL')
    
      // console.log('is not the same')
      setTrackerForAppliedFilter(prev => ({...prev, appliable: true}))

    }

    checkForFilterChange()
  
  }, [filtersState])


  // console.log(filtersState)
  // console.log(trackerForAppliedFilter.previous_state_before_application)


  
  return (
    <div className='reusable-filter-results-box'>
      {
      // sidebar_width > 5 &&
      <div 
      style={{
        display: sidebar_width > 5 ? 'flex' : 'none'
      }}
      className='reusable-filter-results-box-wrapper'>
        <div className='rfr-heading'>
            {/* <h1>Filters</h1> */}
            {/* fitlet */}
            <div className='rfr-sidebar-toggler'>
              <div className='rfr-sidebar-toggler-items'>
              {/* {
                sidebar_width >= 50 && 
                <span 
                className='rfr-sti-first'
                onClick = {() => setSidebarWidth("minus")}>
                  <ZoomOut fontSize='1rem'/>
                </span>
              } */}
                            {
                sidebar_width >= 50 && 
                <span 
                className='rfr-sti-third'
                onClick = {() => setSidebarWidth("minus")}>
                  <Remove fontSize='1rem'/>
                </span>
              }
              {
                sidebar_width <= 25 && 
                <span 
                className='rfr-sti-second'
                onClick = {() => setSidebarWidth("plus")}>
                  <Add fontSize='1rem'/>
                </span>
              }
              {
                sidebar_width >= 5 && 
                <span 
                className='rfr-sti-third'
                onClick = {() => minimizeSidebar()}>
                  <CloseFullscreenOutlined fontSize='1rem'/>
                </span>
              }
              </div>
            </div>
        </div>

        <div className='reset-buttons'>
              {/* <span 
              onClick={()=>resetFiltersState()}
              className='reset-button-item'>reset intial</span> */}
              {/* <span  className='reset-button-item'>clear all</span> */}
              {/* <span  
              onClick={()=>navigate(CLIENT_LINKS.search.url)}
              className='reset-button-item'>new search</span> */}

              {
                // filtersUsed.map((item, index) => (
                //   <div key={index}>
                //     {item.text}
                //   </div>
                // ))
              }
        </div>

        <div className='reusable-filter-results-box-wrapper-main'>
          <details >
            <summary>Category</summary>
            <div className='clear-checkbuttons'>
                    {/* willbe displayed only if any is selected */}
                      <button className='clear-buttonxyz' onClick={()=>handleClearAllCategories()}>
                      clear
                    </button>
                    <button onClick={()=>handleResetCategories()}>
                      reset
                    </button>
            </div>
            {
              fetched_category_list?.length > 0 &&
              fetched_category_list.map((category, index) => (
                <CustomCheckbox
                  key={category._id}
                  id={category._id}
                  checked={filtersState.category_list.some(selected => selected._id === category._id)}
                  onChange={() => handleCategoryChange(category)}
                  label={category.category}
                />
              ))
            }
          </details>

          <details>
            <summary>Supervisor</summary>
            <div className='clear-checkbuttons'>
                    {/* willbe displayed only if any is selected */}
                      <button className='clear-buttonxyz' onClick={()=>handleClearAllSupervisors()}>
                      clear
                    </button>
                    <button onClick={()=>handleResetSupervisors()}>
                      reset
                    </button>
            </div>
            {
              fetched_supervisor_list?.length > 0 &&
              fetched_supervisor_list.map((supervisor, index) => (
                <CustomCheckbox
                  key={supervisor._id}
                  id={supervisor._id}
                  checked={filtersState.supervisor_list.some(selected => selected._id === supervisor._id)}
                  onChange={() => handleSupervisorChange(supervisor)}
                  label={`${supervisor.supervisor_title} ${supervisor.supervisor_name}`}
                />
              ))
            }
          </details>
          <details>
            <summary>Year</summary>
            <div  className='sort-by-detail-wrapper'>
              <div className='clear-checkbuttons'>
                    {/* willbe displayed only if any is selected */}
                    <button onClick={()=>handleResetCategories()}>
                      reset
                    </button>
              </div>
              <div className='multi-range-sliderV'>
                <Slider
                  // getAriaLabel={() => 'Temperature range'}
                  value={yearRange}
                  onChange={handleYearRange}
                  valueLabelDisplay="on"
                  min={1960}
                  max={new Date().getFullYear()}
                  // getAriaValueText={valuetext}
                  // valueLabelDisplay="on"
                />
              </div>
            </div>
          </details>
          <details  className='sort-by-detail'>
            <summary>
              <span>Sort by</span>
            </summary>
            <div className='sort-by-detail-wrapper'>
              <div  className='sort-by-detail-wrapper-inner'>
                {/* <label htmlFor='sort-by'> Sort by</label> */}
                <select
                  className='sort-by-select'
                  name='sort'
                  id='sort'
                  value={filtersState.sort}
                  onChange={handleSortByChange}
                >
                  { Object.entries(sortOptions).map(([value, label]) => (
                    <option selected={initialSelected(filtersState.sort,value )} key={value} value={value}>
                        {label}
                    </option>
                  ))}
                </select>

              </div>

            </div>
          </details>

        </div>
        <div className='apply-filters-button-container'>
          <button 
          disabled={!trackerForAppliedFilter.appliable} 
          onClick={() => constructApiUrl()}
          className='apply-filters-button-container-button'>Apply Filters</button>
        </div>


      </div>
      }
      {
      sidebar_width <= 5 &&
      <div 
      onClick={()=>maximizeSidebar()}
      className='sidebar-maximize-button-container'>
        <span className='sidebar-maximize-button-icon-container'>
          {/* <CloseFullscreenOutlined className='sidebar-maximize-button-icon' fontSize='1rem' /> */}
          {/* <Add className='sidebar-maximize-button-icon' fontSize='1rem' /> */}
          {/* <AddCircleOutlineSharp className='sidebar-maximize-button-icon' fontSize='1rem' /> */}
          <PlayArrow className='sidebar-maximize-button-icon' fontSize='1rem' />
        </span>
      </div>
      }
    </div>
  )
}



 
export default Sidebar
// export default ReusableFilterResultsBox