import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import './.reusableFullscreenDialog.scss'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ReusableFullscreenDialog ({open, setOpen, otherFunctionsPassed, title, action_title, main_body, no_preloaded_content, should_close_after_main_action}) {
  // const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = async () => {
    try{
      // if(otherFunctionsPassed){
      //   await otherFunctionsPassed()
      // }
      setOpen(false);
    }
    catch(error){
      console.log('error at reusable full screen modal')
      console.error(error)
      return alert('an error occurred')
    }
  };

  const handleMainAction =  async () => {
    try{
      if(otherFunctionsPassed){
        await otherFunctionsPassed()
      }
      if(should_close_after_main_action){
        return setOpen(false);
      }


    }
    catch(error){
      console.log('error at reusable full screen modal')
      console.error(error)
      return alert('an error occurred')
    }
  };

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open full-screen dialog
      </Button> */}
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        { 
         !no_preloaded_content &&
          <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {/* Sound */}
              { (title === '' || title ) || 'Please input a title'}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleMainAction}>
              { (action_title === '' || action_title ) || 'Please input an action title'}
            </Button>
          </Toolbar>
        </AppBar>
        }
        {main_body}

      </Dialog>
    </div>
  );
}
