import { Link, useNavigate } from 'react-router-dom'
import './reusableSearchBox.scss'
import { Search } from '@mui/icons-material'
import { useState } from 'react'
import { CLIENT_LINKS } from '../../../utils/LINKS'
import { useOtherContext } from '../../../contexts/OtherContext'

//for the TOPBAR

const ReusableSearchBox = () => {
  const [interfaceState, setInterfaceState] = useState({
    snackbar_visible: false,
    snackbar_message: "",
    snackbar_severity: "info",
    snackbar_autohide_duration: 2000,
    button_disabled : false,
    confirmationDialogVisible: false
    // confirmation_prompt: false //just use a different state so you can use useeffect of this state, instead of typing each property
  })

  const showSnackbar = (val = true) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_visible: true  }))
  }
  const hideSnackbar = (val = true) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_visible: false  }))
  }
  const toggleInterfaceVisibility = (name) => {
    setInterfaceState((prev) => ({ ...prev,  [name]: !prev[name]  }))
  }
  const setSnackbarMessage = (message) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_message: message  }))
  }
  const setSnackbarSeverity = (severity) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_severity: severity  }))
  }
  const setSnackbarDuration = ( duration) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_autohide_duration: duration  }))
  }
  const handleInterfaceState = (name, value_passed) => {
    setInterfaceState((prev) => ({ ...prev,  [name]: value_passed  }))
  }

  const disableButton = () => {
    setInterfaceState((prev) => ({ ...prev,  button_disabled: true  }))
  }
  const enableButton = (name, value_passed) => {
    setInterfaceState((prev) => ({ ...prev,  button_disabled: false  }))
  }
  const alertSnackbar = (message="hello, user", type="info", duration=9999999) => {
    setSnackbarMessage(message)
    setSnackbarSeverity(type)
    setSnackbarDuration(duration)
    showSnackbar()
  }


  const navigate = useNavigate()
  const {search_state_session, dispatch} = useOtherContext()

  const [searchParameterTitle, setSearchParameterTitle] = useState('')

  const handleSearchInput = (e) => {
    setSearchParameterTitle(e.target.value)
  }

  const handleSubmit = () => {
    if(!searchParameterTitle){
      return alertSnackbar('please fill in a search parameter', 'info')
    }
    // navigate(
    //   `${CLIENT_LINKS.search_results.url}?title=${searchParameterTitle}`, 
    //   {state: {router_SearchParameterTitle: searchParameterTitle }}
    // )
              dispatch({
      type: 'MODIFY_SEARCH_STATE_SESSION',
      newObj: 'current_page',
      payload: 1
    })
    //adv page is not updating because the currentPage is not changing
    //we use this double to force adv page to rerender

    dispatch({
      type: 'FORCE_UPDATE',
    })

    navigate(`${CLIENT_LINKS.advanced_search_results.url}?title=${searchParameterTitle}`)
  }
  
  const handleEnterPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit();
    }
  };

  return (
    <div className='reusable-search-box'>
      <div className='reusable-search-box-input-container'>
        <input 
         onKeyDown={handleEnterPress}
        onChange={(e) => handleSearchInput(e)}
        className='reusable-search-box-input'
        type='text'
        placeholder='...search for thesis' />
      </div>
      <div 
      onClick={handleSubmit}
      className='reusable-search-box-sb'>
        <button >
          <Search fontSize='1rem' />
        </button>
      </div>
    </div>
  )
}

export default ReusableSearchBox