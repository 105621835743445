import { useEffect, useState } from "react"
import './resusablePageSettingsDialogAdmin.scss'


export const ReusablePageSettingsDialogAdmin = ({page_context, isModalOpened, setIsModalOpened}) => {
    // console.log(page_context)
    const {admin_force_update, admin_current_page, dispatch, admin_results_page_items, admin_max_page} = page_context
    // console.log(admin_force_update, admin_current_page )
  
    console.log(admin_current_page)
  
    const [componentState, setComponentState] = useState({
      results_items_per_page: admin_results_page_items || 10,
      old_results_items_per_page: admin_results_page_items || 10,
      old_current_page: admin_current_page || 1 ,
      current_page: admin_current_page || 1 
  
    })

    const handleChangeResultItemsPerPage = (e) => {

      if( (parseInt(e.target.value) < 1) || !e.target.value){
        return  setComponentState(prev => ({
          ...prev,
          results_items_per_page : parseInt(1)
        }))
      }

      setComponentState(prev => ({
        ...prev,
        results_items_per_page : parseInt(e.target.value)
      }))
    }


    const setResultItemsPerPage = (e) => {
      const per_items = componentState.results_items_per_page


      if(!per_items || (parseInt(per_items) <= 0)){
              // console.log(e.target.value)
        dispatch({
          type:"MODIFY_ADMIN_RESULTS_PAGE_ITEMS", 
          payload: parseInt(10)
          // payload: parseInt(e.target.value) 
          // payload: parseInt(e.target.value) || 5
          // payload: e.target.value
        })

        setComponentState(prev => ({
          ...prev,
          old_results_items_per_page: 10
        }))
        return ''
      }
      

      // console.log(e.target.value)
      dispatch({
        type:"MODIFY_ADMIN_RESULTS_PAGE_ITEMS", 
        payload: parseInt(per_items)
        // payload: parseInt(e.target.value) 
        // payload: parseInt(e.target.value) || 5
        // payload: e.target.value
      })

      setComponentState(prev => ({
        ...prev,
        old_results_items_per_page: prev.results_items_per_page
      }))
    }
  
    
    const handleChangePageNumber = (e) => {
      // max={admin_max_page || 1}
      if(parseInt(e.target.value) > admin_max_page){
        return  setComponentState(prev => ({
          ...prev,
          current_page : parseInt(admin_max_page)
        }))
      }
      // if(parseInt(e.target.value) < 1 || !e.target.value){
      //   return  setComponentState(prev => ({
      //     ...prev,
      //     current_page : parseInt(1)
      //   }))
      // }
      
      setComponentState(prev => ({
        ...prev,
        current_page : parseInt(e.target.value)
      }))
    }



    useEffect(() => {
        //wehen the result page items changes, it affects the max page
      //if the max page is currently higher than it should be, then set it 
      const keepPageNumberWithinLimit = () => {
        if(componentState.current_page > admin_max_page ){
          setComponentState(prev => ({
            ...prev,
            current_page : admin_max_page
          }))
        }
      }

      keepPageNumberWithinLimit()
    
      return () => {
        // second
      }
    }, [admin_max_page, admin_results_page_items])
    

    const setGoToPageNumber = (e) => {
      // console.log(

      if(componentState.current_page > admin_max_page){
        dispatch({
          type: "MODIFY_ADMIN_CURRENT_PAGE",
          payload: parseInt(admin_max_page)
        })

        setComponentState(prev => ({
          ...prev,
          old_current_page: prev.current_page
        }))
    
        //close the modal
        return setIsModalOpened(!isModalOpened)
      }


      if(componentState.current_page <= 0 || !componentState.current_page ){
        dispatch({
          type: "MODIFY_ADMIN_CURRENT_PAGE",
          payload: parseInt(1)
        })

        setComponentState(prev => ({
          ...prev,
          old_current_page: 1
        }))
    
        //close the modal
        return setIsModalOpened(!isModalOpened)
      }

      dispatch({
        type: "MODIFY_ADMIN_CURRENT_PAGE",
        payload: parseInt(componentState.current_page)
      })

      setComponentState(prev => ({
        ...prev,
        old_current_page: prev.current_page
      }))
  
      //close the modal
      setIsModalOpened(!isModalOpened)
    }
  
    // console.log(
    
    return(
      <div className='admin-page-page-settings-component'>
        <div className='admin-page-page-settings-component-inner'>
          <div className='admin-page-page-settings-item'> 
            <label  className='admin-page-page-settings-item-label'>result items per page</label>
            <input 
            className='admin-page-page-settings-item-input'
            defaultValue={admin_results_page_items || 5}
            onChange={handleChangeResultItemsPerPage}
            // value={admin_results_page_items}
            value={componentState.results_items_per_page}
            type='number' min={1} max={100}  placeholder='enter page'/> 
            {
            componentState?.results_items_per_page !== componentState?.old_results_items_per_page &&
            <button 
            className='admin-page-page-settings-item-button'
            onClick={setResultItemsPerPage}
            >set</button> 
            }
          </div>
          <div className='admin-page-page-settings-item'> 
            <label className='admin-page-page-settings-item-label'>go to page</label>
            <input 
            className='admin-page-page-settings-item-input'
            onChange={(e) => handleChangePageNumber(e)}
            defaultValue={admin_current_page}
            value={componentState.current_page}
            type='number' min={1} max={admin_max_page || 1}  placeholder='go to page'/> 
            {
            componentState?.current_page !== componentState?.old_current_page &&
            <button 
            className='admin-page-page-settings-item-button'
            onClick={setGoToPageNumber}
            >Go to Page</button>
            }
          </div>
        </div>
  
      </div>
    )
  }