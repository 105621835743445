import './topbar.scss'
import { useOtherContext } from "../../contexts/OtherContext"
import { Link, useLocation, useNavigate } from 'react-router-dom'
import ReusableSearchBox from '../reusables/ReusableSearchBox/ReusableSearchBox'
import { ArrowDropDown, ArrowDropUp, DeleteForever, DeleteForeverOutlined, Edit, Email, FiberNew, FiberNewOutlined, Info, InfoOutlined, Logout, NotificationsNone, Person, Preview, PreviewOutlined, Settings, Upgrade, UpgradeOutlined } from '@mui/icons-material'
import { ADMIN_LINKS, CLIENT_LINKS } from '../../utils/LINKS'
import { useEffect, useState } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import { authorized_privilege_levels_that_are_admin_Util, returnMaxNumberInArray_Util, checkIfUserHasPrivilegeLevelFor_Util } from '../../utils/Utils'



const TopbarDesktopDropDownUI = ({dropdown_array, uiCloser}) => {
    return(
        <div className='topbar-dropdown-ui-container'>
            <div className='topbar-dropdown-ui-items'>
                {
                dropdown_array.map((route, index) => (
                    <Link
                    onClick={uiCloser}
                    className='router-dom-LINK topbar-dropdown-ui-item'
                    to={route.url}
                    key={index}>
                        {route.text}
                        {route.icon}
                    </Link>
                ))
                }
            </div>
        </div>
    )
}


const Topbar = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const {dispatch, topbar_height}  = useOtherContext()
    const {user} = useAuth()
    // console.log(user?.privilege_level)


    const setTopbarHeight = (direction) => {

        if(direction === "minus"){
            if(topbar_height <= 4){
                //modify if if error occurs
                return alert('minimum height reached')
            }
            return dispatch({
                type: "SET_TOPBAR_HEIGHT",
                payload: topbar_height - 1
            })
        }

        if(direction === "plus"){
            if(topbar_height >= 15){
                return alert('maximum height reached')
            }
            return dispatch({
                type: "SET_TOPBAR_HEIGHT",
                payload: topbar_height + 1
            })
        }

        else{
            return ''
        }
    }

    const [desktopMenu, setDesktopMenu] = useState({
        dropDownOpen: !false,
        theses: false,
        ocr: false,
        profile: false
    })

    const toggleDesktopDropDown = (menu_section)=>{
        setDesktopMenu(prev => (
            {
                theses: false,
                ocr: false,
                profile: false,
                // dropDownOpen: !prev.dropDownOpen,
                [menu_section]: !prev[menu_section]

            }
        ))
        // setDesktopDropDownOpen(prev => (!prev))
    }

    const closeAllDropDowns = ()=>{
        setDesktopMenu(prev => (
            {
                theses: false,
                ocr: false,
                profile: false,
            }
        ))
        // setDesktopDropDownOpen(prev => (!prev))
    }


    
    useEffect(() => {
        // console.log(location)
      const closeAllDropdownOnUrlChange = () => {
        // closeAllDropDowns()
        // toggleMenuForMobile()
        // setInterfaceState((prev) => ({ ...prev,  isMenuForMobileOpen: false  }))
        closeAllDropDowns()

      } 
      closeAllDropdownOnUrlChange()
    
      return () => {
        // second
      }
    }, [location.pathname])

    const dropdown_arrays = {
        profile_dropdown_array : [
            {
                url:  CLIENT_LINKS.notifications_page.url,
                text: 'Notifications',
                icon: <NotificationsNone  fontSize='1rem'/>,
                // ui_closer: closeAllDropDowns
            },
            {
                url:  CLIENT_LINKS.account_settings_page.url,
                text: 'Account settings',
                icon: <Settings  fontSize='1rem'/>,
                // ui_closer: closeAllDropDowns
            },
            // {
            //     url: CLIENT_LINKS.update_account.url,
            //     text: 'Update account',
            //     icon: <Person fontSize='1rem' />,
            //     // ui_closer: closeAllDropDowns
            // },
            // {
            //     url: CLIENT_LINKS.site_index.url,
            //     text: 'Site index',
            //     icon: <Person fontSize='1rem' />,
            //     // ui_closer: closeAllDropDowns
            // },
            {
                url:  CLIENT_LINKS.logout.url,
                text: 'Log out',
                icon: <Logout  fontSize='1rem'/>,
                // ui_closer: closeAllDropDowns
            },


        ],

        theses_dropdown_array : [
            {
                url: CLIENT_LINKS.create_thesis.url,
                text: 'Submit thesis',
                icon: '',
                // icon: <FiberNew fontSize='1rem'/>,
                ui_closer: closeAllDropDowns
            },
            {
                url: CLIENT_LINKS.view_all_theses.url,
                text: 'View all theses',
                icon: '',
                // icon: <PreviewOutlined fontSize='1rem'/>,
                // ui_closer: closeAllDropDowns
            },
            // {
            //     url: CLIENT_LINKS.update_thesis.url,
            //     text: 'Update theses',
            //     icon: '',
            //     // icon: <Edit fontSize='1rem'/>,
            //     // ui_closer: closeAllDropDowns
            // },
            // {
            //     url: CLIENT_LINKS.delete_thesis.url,
            //     text: 'Delete thesis',
            //     icon: '',

            //     // icon: <DeleteForeverOutlined fontSize='1rem'/>,
            //     // ui_closer: closeAllDropDowns
            // },
            {
                url: CLIENT_LINKS.manage_theses.url,
                text: 'My thesis',
                icon: '',

                // icon: <DeleteForeverOutlined fontSize='1rem'/>,
                // ui_closer: closeAllDropDowns
            },
            {
                url: CLIENT_LINKS.saved_theses_library.url,
                text: 'Saved Library',
                icon: '',
                // ui_closer: closeAllDropDowns
            },
        ]
    }

  return (
    <div className='topbar'>
        {/* <div className='topbar-size-modify-buttons'>
                <span  
                onClick = {() => setTopbarHeight("minus")}
                className='sidebar-size-modify-button-decrease'>-</span>
                <span
                onClick = {() => setTopbarHeight("plus")}
                className='sidebar-size-modify-button-increase'>+</span>
        </div> */}
        <div className='topbar-wrapper'>
            <div 
            className='topbar-wrapper-left'
            onClick={() => navigate(CLIENT_LINKS.home.url)}
            >OAU</div>
            <div className='topbar-wrapper-center'>
                <ReusableSearchBox />
            </div>
            <div className='topbar-wrapper-right'>
                {
                checkIfUserHasPrivilegeLevelFor_Util(authorized_privilege_levels_that_are_admin_Util ,user?.privilege_levels ) &&
                <Link 
                to={ADMIN_LINKS.admin_homepage.url}
                className='topbar-wrapper-right-item1 router-dom-LINK'
                onClick={() => {
                    navigate(ADMIN_LINKS.admin_homepage.url)
                }}
                >
                    <span>Admin</span>
                </Link>
                }
                <div 
                to={CLIENT_LINKS.advanced_search.url}
                className='topbar-wrapper-right-item1 router-dom-LINK'
                onClick={() => {
                    closeAllDropDowns()
                    navigate(CLIENT_LINKS.advanced_search.url)
                }}
                >
                    <span>Advanced Search</span>
                </div>

                <div 
                className='topbar-wrapper-right-item2'>
                    <span 
                    onClick={()=> toggleDesktopDropDown('theses')}>Theses</span>
                    {   
                        desktopMenu.theses ?
                        <ArrowDropUp fontSize='1rem' 
                        onClick={()=> toggleDesktopDropDown('theses')}/> :
                        <ArrowDropDown fontSize='1rem'
                        onClick={()=> toggleDesktopDropDown('theses')}/>
                    }
                    {
                        desktopMenu.theses &&
                       <TopbarDesktopDropDownUI 
                       uiCloser={closeAllDropDowns}
                       dropdown_array={dropdown_arrays.theses_dropdown_array} />
                    }

                </div>
                

                <div className='topbar-wrapper-right-item3'>
                    <Link
                    to={CLIENT_LINKS.ocr_module.url}
                    className='topbar-wrapper-right-item1 router-dom-LINK'
                    onClick={()=> navigate(CLIENT_LINKS.ocr_module.url)}> OCR </Link>
                    <span 
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                        lineHeight: '1'
                    }}
                    title="You can extract text from images with OCR"><InfoOutlined fontSize='1rem' /></span>
                </div>
                <div className='topbar-wrapper-right-item4'>
                    <span 
                    onClick={()=> toggleDesktopDropDown('profile')}
                    > <Person fontSize='1rem'/> </span>
                    {   
                        desktopMenu.profile ?
                        <ArrowDropUp fontSize='1rem'
                        onClick={()=> toggleDesktopDropDown('profile')}/> :
                        <ArrowDropDown fontSize='1rem'
                        onClick={()=> toggleDesktopDropDown('profile')}/>
                    }
                    {
                       desktopMenu.profile &&
                       <TopbarDesktopDropDownUI 
                       uICloser={closeAllDropDowns}
                       dropdown_array={dropdown_arrays.profile_dropdown_array} />

                    }

                </div>




            </div>
        </div>

    </div>
  )
}

export default Topbar