import React, { useEffect } from 'react'
import { removeExpiredLocalStorageItems_Util } from '../../utils/Utils'
// import r

export const useClearExpLocalStorageItems = () => {

  const removeExpiredItems = async () => {
    try{
      await removeExpiredLocalStorageItems_Util()
    }
    catch(error){
      console.log(error)
    }
  }

  useEffect(() => {
    const cleanupExpLocalStorage = setInterval(removeExpiredItems, 30000);
  
    return () => {
      clearInterval(cleanupExpLocalStorage)
    }
  }, [])
  

  // return (
  //   <div>useClearExpLocalStorageItems</div>
  // )
}

// export default useClearExpLocalStorageItems