import React, { useEffect } from 'react'
import './savedThesesLibraryPage.scss'
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { useAuth } from '../../../contexts/AuthContext';
import ReusableThesisItem from '../../../components/reusables/ReusableThesisItem/ReusableThesisItem';
import ReusableThesisSavedItem from '../../../components/reusables/ReusableThesisItem/ReusableThesisSavedItem';
import { useOtherContext } from '../../../contexts/OtherContext';
import ReusablePaginationComponent from '../../../components/reusables/ReusablePaginationComponent/ReusablePaginationComponent';
import { UseHideSidebar } from '../../../hooks/UseSidebarVisibility';
import { ReusableLoader1, ReusableLoader2, ReusableLoader3, ReusableLoader4, ReusableLoader5, ReusableLoader6, ReusableLoader7, ReusableLoaderInCenter } from '../../../components/reusables/ReusableLoaders/ReusableLoaders';
import { scrollToTopInstantUtil } from '../../../utils/Utils';
import { useState } from 'react';
import ReusableSnackbarComponent from '../../../components/reusables/ReusableSnackbarComponent/ReusableSnackbarComponent';
import { Settings } from '@mui/icons-material';
import { ReusableDraggableDialog } from '../../../components/reusables/ReusableDraggableDialog/ReusableDragableDialog';
import { ReusablePageSettingsDialogClient } from '../../../components/reusables/ReusablePageSettingsDialog/ReusablePageSettingsDialogClient';
import { useLocation } from 'react-router-dom';

const SavedThesesLibraryPage = () => {
    UseHideSidebar()
    const {user} = useAuth()
    const {dispatch} = useOtherContext()
    //create a custom component similar to the Thesis Item, except the last button will be the remove from saved thesis list

    //
    
  const [interfaceState, setInterfaceState] = useState({
    snackbar_visible: false,
    snackbar_message: "",
    snackbar_severity: "info",
    snackbar_autohide_duration: 1*60*60*24,
    button_disabled : false,
    isSpinnerActive: false,

    isPageSettingsOpened: false

    // confirmation_prompt: false //just use a different state so you can use useeffect of this state, instead of typing each property
  })

  const showSnackbar = (val = true) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_visible: true  }))
  }
  const hideSnackbar = (val = true) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_visible: false  }))
  }
  const toggleInterfaceVisibility = (name) => {
    setInterfaceState((prev) => ({ ...prev,  [name]: !prev[name]  }))
  }
  const setSnackbarMessage = (message) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_message: message  }))
  }
  const setSnackbarSeverity = (severity) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_severity: severity  }))
  }
  const setSnackbarDuration = ( duration) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_autohide_duration: duration  }))
  }
  const handleInterfaceState = (name, value_passed) => {
    setInterfaceState((prev) => ({ ...prev,  [name]: value_passed  }))
  }

  const disableButton = () => {
    setInterfaceState((prev) => ({ ...prev,  button_disabled: true  }))
  }
  const enableButton = (name, value_passed) => {
    setInterfaceState((prev) => ({ ...prev,  button_disabled: false  }))
  }
  const showSpinner = (val = true) => {
    setInterfaceState((prev) => ({ ...prev,  isSpinnerActive: true  }))
  }
  const hideSpinner = (val = true) => {
    setInterfaceState((prev) => ({ ...prev,  isSpinnerActive: false  }))
  }

  const togglePageSettingsUI = () => {
    setInterfaceState(prev => ({
      ...prev,
      isPageSettingsOpened: !prev.isPageSettingsOpened
    }))
  }

  const alertSnackbar = (message="hello, user", type="info", duration=9999999) => {
    setSnackbarMessage(message)
    setSnackbarSeverity(type)
    setSnackbarDuration(duration)
    showSnackbar()
  }

    const {user: authUser}  = useAuth() 
    const {search_state_session, search_state, dispatch: dispatchOtherContext} = useOtherContext()
    
    const fetchThesesFromSavedThesesLibrary = async () => {

      const isPopulatedObject = (passed_object) => {
        return (
          passed_object !== "" &&
          passed_object !== null &&
          passed_object !== undefined &&
          passed_object !== false &&
          (!(typeof passed_object === "object") || Object.keys(passed_object).length > 0)
        );
      }
      if(!(isPopulatedObject(authUser)) ){
        //wont work because there must be an object
        return alert('You need to login') //means there is no user in the local storage
      }
      const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL
      })

      const query_params = {
        user_id: authUser._id,
        // ...prev_params_from_url(),
        page_size : search_state?.req_query_page_size || 10,
        page_num: search_state_session?.current_page || 1
      }
      let queryParamsString = new URLSearchParams(query_params).toString();
      const response = await axiosInstance.get(`/theses/find_saved_theses_library?${queryParamsString}`, {});
  
      // console.log(queryParamsString)
  
      // console.log(response.data.message)
      return response.data.message;
  
    };
  
    const { 
      data:fetched_thesis_from_saved_theses_library, 
      isLoading,
      isFetching,
      error: errorFetching,
      refetch, 
    } = useQuery({
      queryKey: ['thesisListResultsPage'],
      queryFn: () =>
        fetchThesesFromSavedThesesLibrary()
    }
    )

    console.log(errorFetching)

    useEffect(() => {

      const alertError = () => {
        if(!fetched_thesis_from_saved_theses_library && errorFetching){
          console.log('HEY HEY HEY')
          const errorMessage = errorFetching?.response?.data?.message

          alertSnackbar( ( errorMessage || 'Unable to perform operation. An error occurred' ), 'error')
        }
        if(!user?._id){
          return alertSnackbar('You are not authenticated, please log in', 'error')
        }
      }
      alertError()
    }, [fetched_thesis_from_saved_theses_library, isLoading, isFetching, errorFetching])

    // if(!isLoading){
    //   console.log(fetched_thesis_from_saved_theses_library)
    // }

    const handlePageChange = (newPage) => {
      // Handle the page change logic here
      // console.log(newPage)
      // dispatch()
      // setCurrentPage(newPage);
      dispatchOtherContext({
        type: 'MODIFY_SEARCH_STATE_SESSION',
        newObj: 'current_page',
        payload: newPage
      })
      // refetch()
    };


    useEffect(() => {
      // console.log('RUNNING EFFECT REFETCH SADSFAFSDFASFD')
      const runRefetchAndScroll = async () => {
        try {
          // await refetch()
          refetch()
          scrollToTopInstantUtil()
        } 
        catch (error) {
          console.log(error)
        }
  
      }
      runRefetchAndScroll()
      
    
      return () => {
      }
    }, [
      search_state_session?.current_page, 
      search_state?.req_query_page_size, 
      search_state_session?.force_update
    ])

    
  useEffect(() => {
    //set it in the session
    if(fetched_thesis_from_saved_theses_library){
      dispatchOtherContext({
        type: 'SET_MAX_PAGE',
        payload: fetched_thesis_from_saved_theses_library?.total_pages || 1
      })
    }
  }, [fetched_thesis_from_saved_theses_library])
  
  
  const location = useLocation();
  useEffect(() => {
    //set to page 1 when we hit this page coming from a different page
    //or filtering a different route result
    dispatch({
      type: 'MODIFY_SEARCH_STATE_SESSION',
      newObj: 'current_page',
      payload: 1
    })
  }, [location.pathname])
    
  
  return (
    <div className='saved-theses-library-page'>
            <ReusableSnackbarComponent
        severity={interfaceState.snackbar_severity}
        open={interfaceState.snackbar_visible}  
        inner_message={interfaceState.snackbar_message} 
        setOpen={() => toggleInterfaceVisibility('snackbar_visible')}
        autoHideDuration={ interfaceState.snackbar_autohide_duration || 2000}
      />
        {
          isLoading &&
          <ReusableLoaderInCenter passed_loader={<ReusableLoader3 />} />
        }
        <div 
        style={{
          display: isLoading ? "none" : "flex"
        }}
        className='saved-theses-library-page-inner'>
            <div className='saved-theses-list'>
              {
              fetched_thesis_from_saved_theses_library?.results?.length > 0 &&
              fetched_thesis_from_saved_theses_library?.results?.map((thesis, index) => (
                <ReusableThesisSavedItem
                key={thesis?._id || index} 
                // key={index} 
                passed_thesis={thesis} 
                authUser={authUser} />
              ))
              }
            </div>

            
            <div className='adv-srp-pagination-component'
              style={{
                  width: `${100}%`
              }}>
              <div className='reusable-pagination-component'>

                <ReusablePaginationComponent
                // currentPage={currentPage} 
                currentPage={search_state_session?.current_page}
                totalPages={fetched_thesis_from_saved_theses_library?.total_pages} 
                onChange={handlePageChange} />
              </div>
              <div className='amappc-bottom-right'>
                  <span
                  onClick={togglePageSettingsUI}
                  className='amappc-bottom-right-settings-icon-container'
                  >
                    <Settings fontSize='1rem' />
                  </span>
                  <ReusableDraggableDialog
                  header={"Page Settings"}
                  main_body={
                    <ReusablePageSettingsDialogClient
                      page_context={useOtherContext()} 
                      setIsModalOpened={togglePageSettingsUI}
                      isModalOpened={interfaceState.isPageSettingsOpened}
                    />
                  } 
                  isOpened={interfaceState.isPageSettingsOpened}
                  setIsOpened={togglePageSettingsUI}
                  />
              </div>
            </div>
        </div>
    </div>
  )
}

export default SavedThesesLibraryPage