import './authorManageThesisPage.scss'
import {UseHideSidebar} from '../../../hooks/UseSidebarVisibility'
import { BuildCircle, Close, DeleteOutline, Recycling, WarningAmber } from '@mui/icons-material'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { useAuth } from '../../../contexts/AuthContext'
import ReusableThesisItem from '../../../components/reusables/ReusableThesisItem/ReusableThesisItem'
import { useNavigate, useParams } from 'react-router-dom'
import { useState } from 'react'
import { Dialog, Modal } from '@mui/material'
import { authorized_privilege_levels_that_delete_thesis_Util, checkIfUserHasPrivilegeLevelFor_Util } from '../../../utils/Utils'
import ReusableSnackbarComponent from '../../../components/reusables/ReusableSnackbarComponent/ReusableSnackbarComponent'

const DeleteModalComponent = ({modalState, modalStateToggler, handleDeleteThesis, thesis}) => {

    console.log(thesis)
    return(
        <Dialog 
        open={modalState}
        >
            <div className='delete-thesis-modal'>
                <div className='delete-thesis-modal-inner'>
                    <div className='delete-thesis-modal-top'>
                        <span  className='delete-thesis-modal-top-title'>
                            Delete {"thesis"} {thesis?.title || ''} ?
                        </span>
                        <span  
                        onClick={()=>modalStateToggler()}
                        className='delete-thesis-modal-top-close-icon'>
                            <Close fontSize='1rem'/>
                        </span>
                    </div>
                    <div className='delete-thesis-modal-middle-1'>
                        <span  className='delete-thesis-modal-middle-1-icon'> <WarningAmber fontSize='1rem' /> </span>
                        <span  className='delete-thesis-modal-middle-1-warning-text'>This action cannot be reversed</span>
                    </div>
                    <div  className='delete-thesis-modal-bottom'>
                        <div  className='delete-thesis-modal-button-container'>
                            <button  
                            onClick={()=> handleDeleteThesis(thesis)}
                            className='delete-thesis-modal-button'>Delete now!</button>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>  
    )
}

export const AuthorManageSpecificThesis = () => {

    //this route fetches the thesis by the thesis itself
    //it does not use an array, rather it points to the 
    UseHideSidebar()

    const {thesis_id} = useParams()
    // console.log(thesis_id)

    const [interfaceState, setInterfaceState] = useState({
        snackbar_visible: false,
        snackbar_message: "",
        snackbar_severity: "info",
        snackbar_autohide_duration: 2000,
        button_disabled : false,
        confirmation_prompt: false
        // confirmation_prompt: false //just use a different state so you can use useeffect of this state, instead of typing each property
    })

    const showSnackbar = () => {
        setInterfaceState((prev) => ({ ...prev,  snackbar_visible: true  }))
    }
    const hideSnackbar = () => {
        setInterfaceState((prev) => ({ ...prev,  snackbar_visible: false  }))
    }
    const setSnackbarDuration = ( duration) => {
        setInterfaceState((prev) => ({ ...prev,  snackbar_autohide_duration: duration  }))
    }
    const disableButton = () => {
        setInterfaceState((prev) => ({ ...prev,  button_disabled: true  }))
    }
    const enableButton = () => {
        setInterfaceState((prev) => ({ ...prev,  button_disabled: false  }))
    }
    const enableConfirmationPrompt = () => {
        setInterfaceState((prev) => ({ ...prev,  confirmation_prompt: true  }))
    }
    const disableConfirmationPrompt = () => {
        setInterfaceState((prev) => ({ ...prev,  confirmation_prompt: false  }))
    }

    const {user} = useAuth()
    // const navigate 

    const fetchAuthorThesis  = async () => {

        const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL
        })

        const response = await axiosInstance.get(`/theses/thesis/${thesis_id}`);
        console.log(response.data.message)
        return response.data.message;
    };
    const { isLoading, error, data:fetched_author_theses, refetch:refetchFetchedAuthorThesis } = useQuery({
        queryKey: ['fetchAuthorThesis'],
        queryFn: () =>
        fetchAuthorThesis()
    })

//   console.log(fetched_author_theses)
//   console.log(user.email)


    const [modalOpen, setModalOpen] = useState(false)
    const handleModalToggler = () => {
        setModalOpen(!modalOpen)
    }


    const handleDeleteThesis = async(thesis) => {
        // setModalOpen(!modalOpen)
        try {
            const checkCanDelete = () =>{
                let canDeleteThesis = false
                const user_privilege_levels = user?.privilege_levels

                const isAdmin = checkIfUserHasPrivilegeLevelFor_Util(authorized_privilege_levels_that_delete_thesis_Util, user_privilege_levels )
                if(isAdmin){
                    canDeleteThesis = true
                }

                if(user.email === thesis.email){
                    canDeleteThesis = true
                }
                
                //find the thesis id and the email associated with it
                //if the 
                return canDeleteThesis
            }

            if(!checkCanDelete() ){
                return alert('You do not have sufficient privileges to delete this thesis')
            }

            const axiosInstance = axios.create({
                baseURL: process.env.REACT_APP_API_URL
            })
            await axiosInstance.delete(`/theses/delete_thesis/${thesis._id}`)
            await refetchFetchedAuthorThesis()
            await refetchFetchedAuthorThesis()
            alert('Thesis successfully deleted')

        } 
        catch (error) {
            console.log(error)
            return alert('Failed to delete thesis')    
        }
    }


    return (
    <div className='delete-thesis-page'>
        <ReusableSnackbarComponent
            // positioning={{vertical: 'top', horizontal: 'right'}}
            // positioning={{vertical: 'top', horizontal: 'center'}}
            // inner_message={"You are in search mode"} 
            // open={searchMode} 
            // setOpen={setSearchMode} 
            // otherFunctionsPassed={otherFunctions}
        />
        <div className='delete-research-header'>
            <div className='delete-research-header-inner'>
                <span style={{
                    display: 'flex',
                    alignItems: 'center',
                    lineHeight: 1
                }}><BuildCircle fontSize='1rem' className='delete-research-header-icon' /></span>
                <span>Manage Thesis</span>
            </div>
        </div>  
        <div className='delete-thesis-page-inner'>

            <div className='delete-thesis-page-theses-list'>
                {
                fetched_author_theses?._id &&
                <div>              
                    <ReusableThesisItem 
                    authUser={user} 
                    passed_thesis={fetched_author_theses}/>
                    <div className='delete-thesis-page-delete-button-container'>
                        <button 
                        onClick={()=> handleModalToggler()}
                        className='delete-thesis-page-delete-button'>Delete Thesis</button>
                    </div>
                    {
                    modalOpen &&
                    <DeleteModalComponent 
                    modalState={modalOpen} 
                    modalStateToggler={handleModalToggler} 
                    thesis={fetched_author_theses}
                    handleDeleteThesis={handleDeleteThesis}
                    />
                    }
                </div>
                }
                {
                !fetched_author_theses?._id &&
                <div>You have not created any thesis</div> 
                }
            </div>

        </div>
    </div>
    )
}

// export default AdminManageThesisSpecialPage