import './resetPasswordSuccessPage.scss'


import { useNavConsiderPrevRoute } from '../../../hooks/useNavConsiderPrevRoute'
import { useNavToPrev } from '../../../hooks/useNavToPrev'

import { CheckCircle, CheckCircleOutline, InfoOutlined, Lock, Mail, MailOutline, SpaRounded, VisibilityOutlined} from '@mui/icons-material';
import { Link, useNavigate, useNavigation, useParams } from 'react-router-dom';
import { useContext, useEffect, useRef, useState } from 'react';
import {useAuth} from '../../../contexts/AuthContext';
import axios from 'axios'
import { UseHideSidebar } from '../../../hooks/UseSidebarVisibility';
import { UseHideTopbar } from '../../../hooks/UseTopbarVisibility';
import { API_LINKS, CLIENT_LINKS } from '../../../utils/LINKS';
import ReusableSnackbarComponent from '../../../components/reusables/ReusableSnackbarComponent/ReusableSnackbarComponent';
import { useQuery } from '@tanstack/react-query';
import { LinearProgress } from '@mui/material';
import { validateEmail_Util, validatePassword_Util } from '../../../utils/Utils';
import { useWindowSize } from '../../../hooks/useWindowSize/useWindowSize';



const ResetPasswordSuccessPage = () => {
  //if your cookies tally
  //display 2 boxes for new and new password confirm
  //redirect the user on setting new passwords
  //if any error with the cookies, set error and redirect to login page
  //so they can click the forgot pwd button

  UseHideSidebar()
  UseHideTopbar()
  const navigate = useNavigate()

  const {resetToken} = useParams()
  // console.log(resetToken)
  
  // const {user, loading, error, dispatch,} = useAuth()
  // const {width: userWindowWidth} = useWindowSize()
// console.log(user, 'printing out')
  const [credentials, setCredentials] = useState({
    newPassword: "",
    newPasswordConfirm: "",
    passwordType: 'password',
  })

  const handleChange = (e) => {
    setCredentials((prev) => ({...prev, [e.target.name]: e.target.value}))
  }

  const [interfaceState, setInterfaceState] = useState({
    snackbar_visible: false,
    snackbar_message: "",
    snackbar_severity: "info",
    snackbar_autohide_duration: 1*60*60*24,
    button_disabled : true,
    isSpinnerActive: false,
    // confirmation_prompt: false //just use a different state so you can use useeffect of this state, instead of typing each property
  })

  const showSnackbar = (val = true) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_visible: true  }))
  }
  const hideSnackbar = (val = true) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_visible: false  }))
  }
  const toggleInterfaceVisibility = (name) => {
    setInterfaceState((prev) => ({ ...prev,  [name]: !prev[name]  }))
  }
  const setSnackbarMessage = (message) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_message: message  }))
  }
  const setSnackbarSeverity = (severity) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_severity: severity  }))
  }
  const setSnackbarDuration = ( duration) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_autohide_duration: duration  }))
  }
  const handleInterfaceState = (name, value_passed) => {
    setInterfaceState((prev) => ({ ...prev,  [name]: value_passed  }))
  }

  const disableButton = () => {
    setInterfaceState((prev) => ({ ...prev,  button_disabled: true  }))
  }
  const enableButton = (name, value_passed) => {
    setInterfaceState((prev) => ({ ...prev,  button_disabled: false  }))
  }
  const showSpinner = () => {
    setInterfaceState((prev) => ({ ...prev,  isSpinnerActive: true  }))
  }
  const hideSpinner = () => {
    setInterfaceState((prev) => ({ ...prev,  isSpinnerActive: false  }))
  }

  const alertSnackbar = (message="hello, user", type="info", duration=9999999) => {
    setSnackbarMessage(message)
    setSnackbarSeverity(type)
    setSnackbarDuration(duration)
    showSnackbar()
  }

  const togglePasswordVisibility = async() => {

    if(credentials.passwordType === 'password') (
      setCredentials((prev) => ({...prev, passwordType: 'text' }))
      
      )
    if(credentials.passwordType === 'text') {
      setCredentials((prev) => ({...prev, passwordType: 'password' }))
      
    }

    await new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 5000); // 10000 milliseconds = 10 seconds
    });

    setCredentials((prev) => ({...prev, passwordType: 'password' }))


    
    //if it is text do nothing. This prevents us from having to disable button
  }



  const apiRequestDelayerRef = useRef(5000)
  //called on mount
  const attachCookieToHeader = async () => {

    try{
      const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL
      })


      await new Promise(resolve => setTimeout(resolve, apiRequestDelayerRef.current));

      const response = await axiosInstance.get(`/auth/attach-reset-password-token-cookie/${resetToken}`);
      //reset token is from the url params of router-dom

      // console.log(response.data.message)
      return response.data.message;
   }
   catch(error){
      throw error
   }
   finally{
    const reduceCustomDelayer = () => {
      const willDelayerHitZero = apiRequestDelayerRef.current - 2500
      if(willDelayerHitZero <= 0){
        apiRequestDelayerRef.current = 0
        // return ''
      }
      else{

        //below means else
        apiRequestDelayerRef.current = apiRequestDelayerRef.current - 2500
      }
    }
    reduceCustomDelayer()
   }
  };
  const { 
    data:attachedResetToken , 
    isLoading: isLoadingAttachingResetToken,
    isFetching: isFetchingAttachingResetToken, 
    refetch: reAttachCookieToHeader,
    error: errorAttachingCookieToHeader
  } = useQuery({
    queryKey: ['attachResetPasswordTokenToHeader'],
    retryDelay: 10000,
    // networkMode: 'always',
    queryFn: () =>
      attachCookieToHeader()
  })

  useEffect(() => {
    //if there is an error, notify the user.
    //if there is success
    const notifyUserOfStatus = () => {

      if(attachedResetToken && !isFetchingAttachingResetToken){
        console.log(attachedResetToken)
        setSnackbarMessage(`Link valid. You can now set up a new password`)
        setSnackbarSeverity("success")
        showSnackbar()
        enableButton()
        return ''
      }
      if(!attachedResetToken && !isFetchingAttachingResetToken && errorAttachingCookieToHeader){
        // console.log('FAILED ')
        // console.error(errorAttachingCookieToHeader)
        const error = errorAttachingCookieToHeader
        setSnackbarMessage(`${error.response?.data?.message ||  'An error occurred' }`)
        setSnackbarSeverity("error")
        showSnackbar()
        // disableButton()
        //disable everything no need to modify jack
        return ''
      }
      if(isFetchingAttachingResetToken && !attachedResetToken){
        //on mount
        setSnackbarMessage(`Please wait while we check your token validity`)
        setSnackbarSeverity("info")
        showSnackbar()
        // disableButton()
        //disable everything no need to modify jack

        return ''
      }
      //else do nothing
      return ''
    }
    notifyUserOfStatus()
    
  }, [attachedResetToken, isLoadingAttachingResetToken, isFetchingAttachingResetToken, errorAttachingCookieToHeader])


  const provideInputInfo = (name) => {
    // const {name, value} = e.target
    if( (!attachedResetToken && isFetchingAttachingResetToken) || isLoadingAttachingResetToken ){
      // return alertSnackbar('We have not completely verified your token, ...please wait', 'info', 1000)
      return ''
    }
    if(errorAttachingCookieToHeader){
      // return alertSnackbar('Your token expired or an error occurred. You must request a new token. Please navigate to the forgot password page', 'info', 1000)
      return ''
    }

    if(name === "email"){
      const requirements = 
      <>
        <div>Email must contain @  </div>
        <div> Email cannot contain spaces</div>
      </>

      alertSnackbar(requirements)
      
      return ''
    }
    if(name === "newPassword"){
      const requirements = 
      <>
      <div>Password must be at least 8 characters in length </div>
      <div> Password must contain at least one digit </div>
      <div>Password must contain at least one lowercase letter</div>
      <div>Password must contain at least one uppercase letter </div>
      <div>Password and confirm password must match </div>
      <div>Password must contain at least one special character </div>
      </>
      
      alertSnackbar(requirements)


      return ''
    }

    if(name === "newPasswordConfirm"){
      const requirements = 
      <>
      <div>Password and confirm password must match </div>
      <div>Confirm password must be at least 8 characters in length </div>
      <div> Confirm password must contain at least one digit </div>
      <div>Confirm password must contain at least one lowercase letter</div>
      <div>Confirm password must contain at least one uppercase letter </div>
      <div>Confirm password must contain at least one special character </div>
      </>
      
      alertSnackbar(requirements)


      return ''
    }

  }



  const provideInputFeedback = (name) => {
    //this function runs on every state change
    //we should rather use an effect for this in the case there are many other things that are changing

    //this should be in a use effect that changes when creedntials changes
    
    // console.log(e)
    // const {name, value} = e?.target
    // const {name,value} = e?.target
    // console.log(name, value)
    // return ''
    const value = credentials[name]
    //i.e credentials.name that is the value of the password, name etc
    
    const goodInput = 
      <span 
      style={{color: 'green', display:'flex', alignItems:'center', fontSize: '1.1rem' }}
      className='register-page-form-inner-wrapper-group-2-a-icon-2'><CheckCircle fontSize='1rem'/> </span>
    
    const badInput = <span 
    // style={{color: 'green'}}
    style={{cursor: 'pointer', color: 'tomato', display:'flex', alignItems:'center', fontSize: '1.1rem' }}
    onClick={()=> provideInputInfo(name) }
    className='register-page-form-inner-wrapper-group-2-a-icon-2'><InfoOutlined fontSize='1rem'/> </span>

    if(name === "newPassword"){
      const {isValid }= validatePassword_Util(value)
      console.log(value)
      console.log(value.length)
      if(isValid){
        return goodInput
      }
      
      return badInput
    }

    if(name === "newPasswordConfirm"){
      const {isValid }= validatePassword_Util(value)
      if(isValid){

        if(credentials.newPassword === credentials.newPasswordConfirm){

          return goodInput
          }
      }
      
      return badInput
    }



    // //default
    <span
    onClick={()=> provideInputInfo(name) }
    className='register-page-form-inner-wrapper-group-2-a-icon-2'>
    <InfoOutlined fontSize='1rem'/> </span>

    
  }


  const goBack = () => {
    navigate(-1)
  }


  const handleSetNewPassword = async (e) => {
    // console.log('hi')
    e.preventDefault()

    try{
      hideSnackbar()
      disableButton()
      
      if(true){
        if(!attachedResetToken ){
          return alertSnackbar('We have not completely verified your token, ...please wait  ')
        }
        if(!credentials.newPassword  ){
          return alertSnackbar('Please fill the new password field to proceed', 'error' )
        }
        if(!credentials.newPasswordConfirm){
         return alertSnackbar('Please fill the confirm password field to proceed' , 'error')

        }
        if( credentials.newPassword !== credentials.newPasswordConfirm ){
          setSnackbarMessage('Password field and confirm password fields do not match' )
          setSnackbarSeverity( "error")
          showSnackbar()
          return ''
        }

      }
      if(true){
        const {newPassword, } = credentials
        const {isValid: isPasswordValid} = validatePassword_Util(newPassword)

        if(!isPasswordValid){
          alertSnackbar('Password is not valid click the info button in the input box', 'error')
          return ''
        }


      }



      const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL
      })
      showSpinner()
      // const res = await axiosInstance.post(API_LINKS.login.url, credentials)
      const res = await axiosInstance.post('/auth/set-reset-password', credentials)

      setSnackbarMessage(  `${
        // res?.data?.message || 
        (  `Your password has been successfully reset. You will be redirected to the login page shortly` )
      } `)

      setSnackbarSeverity("success")
      setSnackbarDuration(1*60*60*24) //24 hrs
      showSnackbar()


      await new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 2500); // 10000 milliseconds = 10 seconds
      });

      navigate(CLIENT_LINKS.login.url)
      // return navigate(CLIENT_LINKS.home.url)
    }
    catch(error){

      console.error(error)
      setSnackbarMessage(`Failed to set password. Reason: ${error.response?.data?.message ||  'An error occurred' }`)
      setSnackbarSeverity("error")
      // toggleInterfaceVisibility('snackbar_visible')
      showSnackbar()
    }
    finally{
      enableButton()
      hideSpinner()
    }
  }
  // console.log(credentials)

  

  return (
    <div className='login-page'>
        <ReusableSnackbarComponent 
        severity={interfaceState.snackbar_severity}
        open={interfaceState.snackbar_visible}  
        inner_message={interfaceState.snackbar_message} 
        setOpen={() => toggleInterfaceVisibility('snackbar_visible')}
        autoHideDuration={ interfaceState.snackbar_autohide_duration || 2000}
        />
        {/* <img className='login-page-bg-image' src={login_bg} alt=''/> */}
        <div className='login-page-form-container'>
            <div className='login-page-form'>
                <div className='login-page-form-inner-wrapper'>
                    <div className='login-page-form-inner-wrapper-group-1'>
                        <h1 style={{
                              fontWeight: 300,
                              // fontSize: 
                              // color: 'red',
                              // fontSize: '0.5rem'
                            }}className='login-page-form-inner-wrapper-group-1-a' >Set up a new password</h1>
                        <div className='login-page-form-inner-wrapper-group-1-b' >
                            <strong onClick={()=> navigate(CLIENT_LINKS.login)} style={{
                              fontWeight: 100
                            }}> or log in</strong>
                        </div>
                    </div>
                    <div className='login-page-form-inner-wrapper-group-2'>
                        <div>
                        <label>New password</label>
                        <div className='login-page-form-inner-wrapper-group-2-a' >
                            <span className='login-page-form-inner-wrapper-group-2-a-icon-1'><Lock fontSize='1rem'/> </span>
                            <input 
                            disabled={interfaceState.button_disabled }
                            // disabled={}
                            onChange={(e) => handleChange(e)}
                            name="newPassword"
                            type={credentials.passwordType} 
                            placeholder='...enter a new password' />
                              {
                            credentials?.newPassword &&
                            <span 
                             onClick={togglePasswordVisibility}
                             style={{cursor: 'pointer',display:'flex', alignItems:'center', fontSize: '1.1rem', paddingRight:'0.3rem', color: 'gray' }}
                              className='register-page-form-inner-wrapper-group-2-a-icon-2'><VisibilityOutlined fontSize='1rem'/>
                            </span>
                            }
                            {provideInputFeedback("newPassword")}
                        </div>
                        </div>
                        <div>
                        <label>Confirm password</label>
                        <div className='login-page-form-inner-wrapper-group-2-a' >
                            <span className='login-page-form-inner-wrapper-group-2-a-icon-1'><Lock fontSize='1rem'/> </span>
                            <input 
                            disabled={interfaceState.button_disabled }
                            // disabled={}
                            onChange={(e) => handleChange(e)}
                            name="newPasswordConfirm"
                            type={credentials.passwordType} 
                            placeholder='...confirm your password' />
                            {
                            credentials?.newPasswordConfirm &&
                            <span 
                             onClick={togglePasswordVisibility}
                             style={{cursor: 'pointer',display:'flex', alignItems:'center', fontSize: '1.1rem', paddingRight:'0.3rem', color: 'gray' }}
                              className='register-page-form-inner-wrapper-group-2-a-icon-2'><VisibilityOutlined fontSize='1rem'/>
                            </span>
                            }
                            {provideInputFeedback("newPasswordConfirm")}
                        </div>
                        </div>

                        {/* <div 
                        className='login-page-form-inner-wrapper-group-2-c'>
                            <span style={{
                              fontWeight: 300
                            }}>Remaining time :</span>
                            <span style={{
                              fontWeight: 300
                            }}>{timer.countDown}</span>
                        </div> */}
                    </div>
                    {
                    // interfaceState.isSpinnerActive &&
                    <div style={{height: '0.5rem'}}>
                    <LinearProgress sx={{padding: 0, margin: 0, display: interfaceState.isSpinnerActive ? true: 'none'}}/>
                    </div>
                    }
                    <div className='login-page-form-inner-wrapper-group-3'>
                        <button 
                        disabled={interfaceState.button_disabled }
                        type='submit'
                        // onClick={(e)=>handleSubmit(e)}
                        onClick={(e) => handleSetNewPassword(e)}
                        className='login-page-form-inner-wrapper-group-3-a'>Set Password</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}



export default ResetPasswordSuccessPage




   