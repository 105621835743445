import { createContext, useContext, useEffect, useReducer, useState } from 'react'
// import authReducer from './AuthReducer'

const thesesReducer = (state, action) => {
    // console.log('fired switch')
  switch (action.type) {
      case "CREATE_THESIS_START":
        // console.log('fired login start')
          return {
              theses: [],
              isLoading: true,
              error: false,
          }
      case "CREATE_THESIS_SUCCESS":
        // console.log('fired login success')
        // console.log(state, action)
          return {
              theses: [state.theses, ...action.payload],
              isLoading: false,
              error:  false,
          }
      case "CREATE_THESIS_FAILURE":
        // console.log('fired login failure')
          return {
            //this place is fragile
            ...state,
                // thesis: null,
                // user: state.user === null ? '' : state.user ,
              isLoading: false,
              error: true,
          }
        case "UPDATE_THESIS_START":
        // console.log('fired login failure')
            return {
            //this place is fragile
            ...state,

                // theses: null,
                // user: state.user === null ? '' : state.user ,
                isLoading: false,
                error: true,
            }
        case "UPDATE_THESIS_SUCCESS":
        // console.log('fired login failure')
            return {
            //this place is fragile
            ...state,
                // theses: null,
                // user: state.user === null ? '' : state.user ,
                theses: [...state.theses, {...action.payload}],

                isLoading: false,
                error: true,
            }
        case "UPDATE_THESIS_FAILURE":
            // console.log('fired login failure')
                return {
                //this place is fragile
                ...state,
                    // theses: null,
                    // user: state.user === null ? '' : state.user ,
                    isLoading: false,
                    error: true,
        }
        case "DELETE_THESIS":
            // console.log('fired login failure')
                return {
                //this place is fragile
                ...state,
                    // theses: null,
                    // user: state.user === null ? '' : state.user ,
                    isLoading: false,
                    error: true,
        }
      // case "T"




      default:
          return state;
      
  }
}

const INITIAL_STATE = {
    theses: JSON.parse(localStorage.getItem("theses")) || [],
    isLoading: false,
    error: false,
    isDataFetched : false,
    contentAppendMethod: '' // 'text-editor', 'ocr', 'upload-document',
}
export const ThesesContext = createContext(INITIAL_STATE)


export const ThesesProvider = ({children}) => {
  const [state, dispatch] = useReducer(thesesReducer, INITIAL_STATE)

  useEffect(() => {
    localStorage.setItem("theses", JSON.stringify(state.theses))
}, [state])

  return (
    <ThesesContext.Provider
    value={{
        theses: state.theses,
        isLoading: state.isLoading,
        error:state.error,
        isDataFetched: state.isDataFetched,
        dispatch,
    }}>
        {!state.isLoading && children}
    </ThesesContext.Provider>
  )
}

export const useTheses = () => {
  return useContext(ThesesContext)
}

export default ThesesContext