import { BookmarkAdd, DoubleArrow, Email, KeyboardArrowLeft, KeyboardArrowRight, Menu, MoreHoriz, Person3, Sell, Share, Visibility } from '@mui/icons-material'
import { Link, useLocation, useNavigate } from 'react-router-dom'
// import { useEffect, useState } from 'react'
import axios from 'axios'
// import { UseShowTopbar } from '../../../hooks/UseTopbarVisibility'
// import { UseHideSidebar, UseShowSidebar } from '../../../hooks/UseSidebarVisibility'
// import { CLIENT_LINKS } from '../../../utils/LINKS'
// import { useAuth } from '../../../contexts/AuthContext'
import { handleCopyTextToClipboard_Util, textLengthShortened } from '../../../utils/Utils'
// import { useQuery, useQueryClient } from '@tanstack/react-query'
import { ReusableDraggableDialog } from '../ReusableDraggableDialog/ReusableDragableDialog'
import { useState } from 'react'
// import { useOtherContext } from '../../../contexts/OtherContext'


import {
    EmailShareButton,
    FacebookShareButton,
    HatenaShareButton,
    InstapaperShareButton,
    LineShareButton,
    LinkedinShareButton,
    LivejournalShareButton,
    MailruShareButton,
    OKShareButton,
    PinterestShareButton,
    PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton,
    WhatsappShareButton,
    WorkplaceShareButton
  } from "react-share";
  import {
    EmailIcon,
    FacebookIcon,
    FacebookMessengerIcon,
    HatenaIcon,
    InstapaperIcon,
    LineIcon,
    LinkedinIcon,
    LivejournalIcon,
    MailruIcon,
    OKIcon,
    PinterestIcon,
    PocketIcon,
    RedditIcon,
    TelegramIcon,
    TumblrIcon,
    TwitterIcon,
    ViberIcon,
    VKIcon,
    WeiboIcon,
    WhatsappIcon,
    WorkplaceIcon
  } from "react-share";
import { CLIENT_LINKS } from '../../../utils/LINKS'

//NOT A MODAL ITSELF BUT SHOULD BE PASSED INTO A MODAL
//IT IS ONLY A UI. iT DOESNT MANAGE ITS OWN STATE
const ReusableShareModalItem = ({shareUrl, passedAlertSnackbarFunc}) => {

    const handleCopyLink = async () => {

        try{

            const isLinkCopied = await handleCopyTextToClipboard_Util(shareUrl)
            if(isLinkCopied){
                
                if(passedAlertSnackbarFunc){
                    return passedAlertSnackbarFunc("Link copied")
                }
                
                alert("Link copied")
            }
        }
        catch(error){
            console.error('error while trying to copy thesis')
            console.error(error)
        }
    }

    // console.log(shareUrl)
    return (
    <div
        style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1.5rem'
        //   background: '#0000',
        //   height: '100vh',
        //   width: '100%',
        }}
      >
        {/* <h1>I hope you like it</h1> */}
        <div
        style={{
            display:"flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center"
        }}>
            <span>
                Share to:
            </span>
            <span 
            style={{
                fontSize: '0.9rem',
                color: 'blue',
                cursor: 'pointer'
            }}
            onClick={()=>handleCopyLink()}>
                copy link
            </span>
        </div>
        <div>
            {shareUrl || ''}
        </div>
        <div
        style={{
            display: 'flex',
            flexDirection: "row",
            gap: '1rem',
            alignItems: 'center'
        }}>
            <FacebookShareButton
            url={shareUrl}
            quote={'Title or jo bhi aapko likhna ho'}
            hashtag={'#portfolio...'}
            >
            <FacebookIcon size={40} round={true} />
            </FacebookShareButton>

            <WhatsappShareButton
            url={shareUrl}
            quote={'Title or jo bhi aapko likhna ho'}
            hashtag={'#portfolio...'}
            >
            <WhatsappIcon size={40} round={true} />
            </WhatsappShareButton>

            <EmailShareButton
            url={shareUrl}
            quote={'Title or jo bhi aapko likhna ho'}
            hashtag={'#portfolio...'}
            >
            <EmailIcon size={40} round={true} />
            </EmailShareButton>
            <TelegramShareButton
            url={shareUrl}
            quote={'Title or jo bhi aapko likhna ho'}
            hashtag={'#portfolio...'}
            >
            <TelegramIcon size={40} round={true} />
            </TelegramShareButton>
            <LinkedinShareButton
            url={shareUrl}
            quote={'Title or jo bhi aapko likhna ho'}
            hashtag={'#portfolio...'}
            >
            <LinkedinIcon size={40} round={true} />
            </LinkedinShareButton>
            <TwitterShareButton
            url={shareUrl}
            quote={'Title or jo bhi aapko likhna ho'}
            hashtag={'#portfolio...'}
            >
            <TwitterIcon size={40} round={true} />
            </TwitterShareButton>
        </div>
      </div>
    )
}

export default ReusableShareModalItem