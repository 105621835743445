import { useEffect, useLayoutEffect } from "react"
import { useOtherContext } from "../contexts/OtherContext"

export const UseHideSidebar = () => {
  const {sidebar_enabled, dispatch} = useOtherContext()

  useLayoutEffect(()=> {
    dispatch({
      type: "HIDE_SIDEBAR"
    })    
  }, [])
}

export const UseShowSidebar = () => {
  const {sidebar_enabled, dispatch} = useOtherContext()

  useLayoutEffect(()=> {
    dispatch({
      type: "SHOW_SIDEBAR"
    })   
  }, [])
}

