// import React from 'react'
import './reusableLoaders.scss'


//ONLY LOADER 3 WORKS WELL


export const ReusableLoader1 = () => {
  return (
    // <div>ReusableReusableLoaders</div>
    <span class="reusable-loader1"></span>
  )
}


export const ReusableLoader2 = () => {
return (
    // <div>ReusableReusableLoaders</div>
    <span class="reusable-loader2"></span>
)
}



export const ReusableLoader3 = ( {passed_style}) => {
return (
    // <div>ReusableReusableLoaders</div>
    <span style={{...passed_style}} class="reusable-loader3"></span>
)
}


export const ReusableLoader4 = () => {
return (
    // <div>ReusableReusableLoaders</div>
    <span class="reusable-loader4"></span>
)
}

export const ReusableLoader5 = () => {
return (
    // <div>ReusableReusableLoaders</div>
    <span class="reusable-loader5"></span>
)
}

export const ReusableLoader6 = () => {
return (
    // <div>ReusableReusableLoaders</div>
    <span class="reusable-loader6"></span>
)
}
  
export const ReusableLoader7 = () => {
    return (
      // <div>ReusableReusableLoaders</div>
          <span class="reusable-loader7"></span>
    )
  }


  export const ReusableLoaderInCenter = ({passed_loader}) => {
    return (
        <div
        style={{
            height: "100vh",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // backgroundColor: "red"
        }}>
            {
            passed_loader && 
            passed_loader
            }
            {
            !passed_loader &&
            <div>
                Please Pass a ReusableLoader Prop
            </div>
            }
        </div>
    )
}