import { useReducer, useState } from 'react'
import './advancedSearchPage.scss'
import { Add, AddCircle, AddCircleOutline, AdminPanelSettings, CalendarMonth, Delete, DeleteOutline, DriveFileRenameOutline, HighlightOff, Keyboard, Search, Title } from '@mui/icons-material'
import { Link, useNavigate } from 'react-router-dom'
import { UseHideSidebar } from '../../../hooks/UseSidebarVisibility'
import { Box, Button, Modal, Typography } from '@mui/material'
import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import { CLIENT_LINKS } from '../../../utils/LINKS'


const INITIAL_STATE = {
  title: {
    val: "",
    exact_match: false
  },
  author: {
    val: "",
    exact_match: false
  },
  year: {
    // value: new Date().getFullYear(),
    min_year: new Date().getFullYear(),
    max_year: new Date().getFullYear(),
    // exact_match: false
    //careful
  },
  supervisor: {
    val: "",
    exact_match: false
  },
  keyword: {
    val: "",
    exact_match: false
  },
  and_combination: false 
}

const AdvancedSearchPage = () => {
    // const queryParams = new URLSearchParams(location.search);
  // const paramValue = queryParams.get('title');
  // console.log(paramValue)

  UseHideSidebar()
  const navigate = useNavigate()

  //below state obj will be passed to req.body
  const [advancedSearchOptions, setAdvancedSearchOptions] = useState(INITIAL_STATE)


  const handleChange = (e, variant) => {
    const { name, value, type, checked } = e.target;
    //e comes from event
    //variant determines if we want to adjust the value or the exact match field
    
    if(variant === 'year'){
      // const selectedOption = event.target.options[event.target.selectedIndex];
      // const optionName = selectedOption.getAttribute('data-name');

      setAdvancedSearchOptions((prev) => (
        {
           ...prev, 
           year:{
          ...prev.year,
          [name]: value
          } 
        }
      ));
    }

    if(variant ===  'val'){
      // console.log()
      setAdvancedSearchOptions((prev) => (
        {
           ...prev, 
          [name]: {
            ...prev[name],
            val: value
          }
        }
      ));
    }
    if(variant === 'exact_match'){
      
      console.log(variant, e.target.value)
      setAdvancedSearchOptions((prev) => (
        {
           ...prev, 
          [name]: {
            ...prev[name],
            exact_match: value
          }
        }
      ));

    }
    if(variant === 'and_combination'){
      setAdvancedSearchOptions((prev) => (
        {
           ...prev, 
          and_combination: value
        }
      ));

    }

    // setAdvancedSearchOptions((prev) => ({ ...prev, [name]: value }));
  };


  // const [state, dispatch] = useReducer(advancedSearchReducer, {})

  const [static_criterias, setStaticCriterias] = useState([
    {
      text: "title",
      // icon: <div className='adv-criterion-delete-icon'><span><Title/></span></div>, //attach function to this
      display: <div className='adv-criterion-indicator'> <Title fontSize='1rem'/> Title</div>,
      exact_match: 
        <div className='adv-criterion-match-container'> 
          <select
          name='title'
          className='adv-criterion-match-select' onChange={(e) => handleChange(e, 'exact_match')}> 
            <option value={false}>contains</option>
            <option value={true}>is exactly</option>
          </select> 
        </div>,
      input :
      <div className='adv-criterion-input-container '> 
        <input
        defaultValue={advancedSearchOptions.title.val}
        // value={advancedSearchOptions.title.val}
        // defaultValue={'abc'}
        name="title"
        placeholder='...type title'
        type="text" className='adv-criterion-input' onChange={(e) => handleChange(e, 'val')} />
      </div>,
      clearPersonalState: () => {
        // clearStateChange()
      } 
        
  
    },
    {
      text: "author",
      // deleteIcon: <div className='adv-criterion-delete-icon'><span><HighlightOff /></span></div>, //attach function to this
      display: <div className='adv-criterion-indicator'> <DriveFileRenameOutline fontSize='1rem' />  Author</div>,
      exact_match: 
        <div className='adv-criterion-match-container'> 
          <select 
          name='author'
          className='adv-criterion-match-select' onChange={(e) => handleChange(e, 'exact_match')}> 
            <option value={false}>contains</option>
            <option value={true}>is exactly</option>
          </select> 
        </div>,
      input :
      <div className='adv-criterion-input-container'> 
        <input 
        defaultValue={advancedSearchOptions.author.val}
        name="author"
        placeholder='...type author'
        type="text" className='adv-criterion-input' onChange={(e) => handleChange(e, 'val')} />
      </div>,
  
    },
    // {
      //add this part later, but modify it, the state, and the backend route
      // text: "supervisor",
      // // deleteIcon: <div className='adv-criterion-delete-icon'><span><HighlightOff /></span></div>, //attach function to this
      // display: <div className='adv-criterion-indicator'> <AdminPanelSettings fontSize='1rem' /> Supervisor</div>,
      // exact_match: 
      //   <div className='adv-criterion-match-container'> 
      //   <select 
      //   name='supervisor'
      //   className='adv-criterion-match-select' onChange={(e) => handleChange(e, 'exact_match')}> 
      //       <option value={false}>contains</option>
      //       <option value={true}>is exactly</option>
      //   </select> 
      //   </div>,
      // input :
      // <div className='adv-criterion-input-container'> 
      //   <input 
      //   defaultValue={advancedSearchOptions.supervisor.val}
      //   placeholder='...type supervisor'
      //   name="supervisor"
      //   type="text" className='adv-criterion-input' onChange={(e) => handleChange(e, 'val')} />
      // </div>,
  
  
    // },
    {
      text: "keyword",
      // deleteIcon: <div className='adv-criterion-delete-icon'><span><HighlightOff /></span></div>, //attach function to this
      display: <div className='adv-criterion-indicator'> <Keyboard fontSize='1rem'/> Keyword</div>,
      exact_match: 
      <div className='adv-criterion-match-container'> 
      <select 
      name='keyword'
      className='adv-criterion-match-select'
      onChange={(e) => handleChange(e, 'exact_match')}> 
            <option value={false}>contains</option>
            <option value={true}>is exactly</option>
      </select> 
      </div>,
      input :
      <div className='adv-criterion-input-container'> 
        <input 
        defaultValue={advancedSearchOptions.keyword.val}
        placeholder='...type keyword'
        name="keyword"
        type="text" className='adv-criterion-input' onChange={(e) => handleChange(e, 'val')} />
      </div>,
  
    },
    {
      text: "year",
      // deleteIcon: <div className='adv-criterion-delete-icon'><span><HighlightOff /></span></div>, //attach function to this
      display: <div className='adv-criterion-indicator'> <CalendarMonth fontSize='1rem'/> Year</div>,
      exact_match: 
      <div className='adv-criterion-match-container'> 
        
      </div>,
      input :
      <div className='adv-criterion-input-container adv-input-year-container'> 
        <input 
        defaultValue={advancedSearchOptions.year.min_year}
        type="number" className='adv-input-year' 
        min={1} 
        max={new Date().getFullYear()}
        name='min_year' data-name='min' onChange={(e) => handleChange(e, 'year')}/>
        <span>to</span>
        <input 
        type="number"  className='adv-input-year'  
        defaultValue={advancedSearchOptions.year.max_year}
        min={1}
        max={new Date().getFullYear()}
        name='max_year' data-name="min" onChange={(e) => handleChange(e, 'year')}/>
      </div>,
    },
  ])

  const [searchCriteria, setSearchCriteria] = useState([])
  //change to above and delte below
  // const [searchCriteria, setSearchCriteria] = useState(static_criteriasX)

  const addSearchCriteria = (criteria) => {
    // console.log(criteria)
    // return ''
    setSearchCriteria((prev) => ([...prev, criteria]))

    //remove from adding list
    //activate below later
    setStaticCriterias((prev) => prev.filter((current_criteria) => current_criteria !== criteria))
  }


  const clearCriterionState = (criterionName) => {
    // Reset the state of the removed criterion to its initial values
    setAdvancedSearchOptions((prev) => ({
      ...prev,
      [criterionName]: INITIAL_STATE[criterionName],
    }));
  };

  const deleteSearchCriteria = (criteria) => {
    clearCriterionState(criteria.text);
    //clear the state 

    setSearchCriteria((prev) => prev.filter((current_criteria) => current_criteria !== criteria))

    //return to adding list
    //activate below later
    setStaticCriterias((prev) => ([...prev, criteria]))
  }

  // console.table(advancedSearchOptions)
  // console.log(searchCriteria)


  const removeUnwantedCriteria = () => {
    let deletions_required = 0
    const options = advancedSearchOptions //state object
    const selectedCriteria = searchCriteria //array

    for(let criterion of searchCriteria){
      //do the specific ones for year, min and max year must be specified if the year item is selected as a criteria item


      const specific_state_obj_item = advancedSearchOptions[`${criterion?.text}`]
      if(!specific_state_obj_item){
        //in case we cant see the picked criterion text of mirror/analogous state name,
        continue
      }
      //BELOW if we see the criterion in the selectedCriteria array it
      if(criterion.text === 'year'){
        if(specific_state_obj_item?.min_year && specific_state_obj_item?.max_year){
          //if we have values for both min and max
          continue
        } 
        //if we dont have values for any of the min or max
        deleteSearchCriteria(criterion)
        deletions_required++
        continue
      }
      const present_value = specific_state_obj_item?.val
      if(!present_value){
        //then it should not be there
        deleteSearchCriteria(criterion)
        deletions_required++
        continue
      }
      // continue
    }
    return deletions_required


    // if(options.)
  }

  const handleSubmit = () => {
    if(searchCriteria.length < 1){
      return alert('Please add at least, one criteria')
    }
    const deletions_required = removeUnwantedCriteria()
    if(deletions_required > 0){
      return alert(
        `
      Sorry, we had to remove ${deletions_required} input(s) you did not fill 
      If you do not want to fill them, press the button search button again
        `)
    }

    // Create an object to store the query parameters
    const paramsForQuery = {};

    // Iterate through advancedSearchOptions and add non-empty values to paramsForQuery
    for (const [key, value] of Object.entries(advancedSearchOptions)) {
      if (value.val && value.val !== "") {
        paramsForQuery[key] = value.val;
        paramsForQuery[`${key}_exact_match`] = value.exact_match;
      }

      // Special handling for the 'year' field
      if (key === 'year' && value.min_year && value.max_year) {
        for(let criterion of searchCriteria){
          if(criterion.text === 'year'){
            //to know if year was selected
            paramsForQuery.min_year = value.min_year;
            paramsForQuery.max_year = value.max_year;
          }
        }
      }
    }

    paramsForQuery.and_combination = advancedSearchOptions.and_combination;



      // Filter out parameters with empty or falsy values
  const filteredParams = {};
  for (const [key, value] of Object.entries(paramsForQuery)) {
    if (value !== undefined && value !== null) {
      filteredParams[key] = value;
    }
  }

  //use filtered params if you do not want to include uninputed values
  
  // Convert paramsForQuery object to a query string
    const queryParams = new URLSearchParams(paramsForQuery).toString()
    navigate(`${CLIENT_LINKS.advanced_search_results.url}?${queryParams}`)
  }

  return(
    <div className='advanced-search-page'>
      <div className='advanced-search-page-wrapper'>
        <div className='advanced-search-page-wrapper-inner'>
          <div>Add(click on) the criteria you wish to search through</div>
          {/* <div> */}
            <div className='available-criteria-items'>
              {
                static_criterias.map((criteria, index) => (
                  <div key={index} className='available-criteria-item'
                  onClick={() => addSearchCriteria(criteria)}>
                    {criteria.text}
                    <Add fontSize='1rem'/>
                  </div>
                ))
              }
            </div>

          {/* </div> */}
          {/* <div> */}
          <div className='advanced-search-criteria'>
            {
            searchCriteria.map((criterion, index) => (
              <div key={index} className='advanced-search-criterion'>
                {Object.keys(criterion).map((criter, index2) => (
                  criter === "text" ? '' :
                  <>
                    {criterion[criter]}
                    
                  </>
                ))}
                <div className='adv-criterion-delete-icon'
                onClick={()=>deleteSearchCriteria(criterion)}
                ><span><DeleteOutline/></span></div>
              </div>
            ))
            }
            
          </div>
          {/* </div> */}
          {/* <div className='adv-page-last-section'> */}
            {
            searchCriteria.length > 0 &&
            <div className='adv-criterion-matchall-select-div-outer'>
              <div className='adv-criterion-matchall-select-div'>
                  <span>Match:</span>           
                  <select 
                  className='adv-criterion-match-select' 
                  name='and_combination'
                  onChange={(e) => handleChange(e, 'and_combination')}> 
                    {/* Match */}
                    <option value={false}>any(recommended)</option>
                    <option value={true}>all</option>
                  </select> 
              </div>
            </div>
            }
            <div className='adv-go-button-div'>
              <button 
              onClick={()=> handleSubmit()}
              className='adv-go-button'>Search(advanced)</button>
            </div>

          {/* </div> */}
        </div>
      </div>
    </div>
  )
}



export default AdvancedSearchPage