// import './resetPasswordSuccessPage.scss'


import { useNavConsiderPrevRoute } from '../../../hooks/useNavConsiderPrevRoute'
import { useNavToPrev } from '../../../hooks/useNavToPrev'

import { CheckCircle, CheckCircleOutline, ErrorOutline, HourglassBottom, Lock, Mail, MailOutline, SpaRounded} from '@mui/icons-material';
import { Link, useNavigate, useNavigation, useParams } from 'react-router-dom';
import { useContext, useEffect, useRef, useState } from 'react';
import {useAuth} from '../../../contexts/AuthContext';
import axios from 'axios'
import { UseHideSidebar } from '../../../hooks/UseSidebarVisibility';
import { UseHideTopbar } from '../../../hooks/UseTopbarVisibility';
import { API_LINKS, CLIENT_LINKS } from '../../../utils/LINKS';
import ReusableSnackbarComponent from '../../../components/reusables/ReusableSnackbarComponent/ReusableSnackbarComponent';
import { useQuery } from '@tanstack/react-query';
import { LinearProgress } from '@mui/material';



const VerifyAccountSuccessPage = () => {
  //if your cookies tally
  //display 2 boxes for new and new password confirm
  //redirect the user on setting new passwords
  //if any error with the cookies, set error and redirect to login page
  //so they can click the forgot pwd button
  UseHideSidebar()
  UseHideTopbar()
  const navigate = useNavigate()

  const {verificationToken} = useParams()

  const [interfaceState, setInterfaceState] = useState({
    snackbar_visible: false,
    snackbar_message: "",
    snackbar_severity: "info",
    snackbar_autohide_duration: 1*60*60*24,
    button_disabled : false,
    isSpinnerActive: false
    // confirmation_prompt: false //just use a different state so you can use useeffect of this state, instead of typing each property
  })

  const showSnackbar = (val = true) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_visible: true  }))
  }
  const hideSnackbar = (val = true) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_visible: false  }))
  }
  const toggleInterfaceVisibility = (name) => {
    setInterfaceState((prev) => ({ ...prev,  [name]: !prev[name]  }))
  }
  const setSnackbarMessage = (message) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_message: message  }))
  }
  const setSnackbarSeverity = (severity) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_severity: severity  }))
  }
  const setSnackbarDuration = ( duration) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_autohide_duration: duration  }))
  }
  const handleInterfaceState = (name, value_passed) => {
    setInterfaceState((prev) => ({ ...prev,  [name]: value_passed  }))
  }

  const disableButton = () => {
    setInterfaceState((prev) => ({ ...prev,  button_disabled: true  }))
  }
  const enableButton = (name, value_passed) => {
    setInterfaceState((prev) => ({ ...prev,  button_disabled: false  }))
  }
  const showSpinner = () => {
    setInterfaceState((prev) => ({ ...prev,  isSpinnerActive: true  }))
  }
  const hideSpinner = () => {
    setInterfaceState((prev) => ({ ...prev,  isSpinnerActive: false  }))
  }

  const goBack = () => {
    navigate(-1)
  }

  const apiRequestDelayerRef = useRef(5000)
  //called on mount
  const confirmVerificationToken = async () => {
    try{

      const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL
      })

      await new Promise(resolve => setTimeout(resolve, apiRequestDelayerRef.current));

      const response = await axiosInstance.get(`/auth/register_confirmVerificationToken/${verificationToken}`);
      //reset token is from the url params of router-dom

      // console.log(response.data.message)
      return response.data.message;
      }
      catch(error){
        throw error
      }
      finally{

        const reduceCustomDelayer = () => {
          const willDelayerHitZero = apiRequestDelayerRef.current - 2500
          if(willDelayerHitZero <= 0){
            apiRequestDelayerRef.current = 0
            // return ''
          }
          else{

            //below means else
            apiRequestDelayerRef.current = apiRequestDelayerRef.current - 2500
          }
        }
        reduceCustomDelayer()
      }
  };
  const { 
    data: verificationResult , 
    isLoading: isLoadingVerificationResult,
    isFetching: isFetchingVerificationResult, 
    refetch: reFetchVerificationResult,
    error: verificationResultError
  } = useQuery({
    queryKey: ['confirmEmailVerification'],
    retryDelay: 2000,
    // networkMode: 'always',
    queryFn: () =>
      confirmVerificationToken()
  })

  useEffect(() => {
    //if there is an error, notify the user.
    //if there is success
    const notifyUserOfVerificationStatus = async () => {
      try{

        if(verificationResult && !isFetchingVerificationResult ){
          console.log(verificationResult)
          setSnackbarMessage(`Account successfully verified, you will be redirected to the login page shortly`)
          setSnackbarSeverity("success")
          showSnackbar()
          enableButton()


          await new Promise((resolve) => {
            setTimeout(() => {
              resolve();
            }, 3000); // 10000 milliseconds = 10 seconds
          });
          navigate(CLIENT_LINKS.login.url)
          return ''
        }
        if(!verificationResult && !isFetchingVerificationResult && verificationResultError){
          // console.log('FAILED ')
          // console.error(errorAttachingCookieToHeader)
          const error = verificationResultError
          setSnackbarMessage(`${error.response?.data?.message ||  'An error occurred' }`)
          setSnackbarSeverity("error")
          showSnackbar()
          disableButton()
          return ''
        }
        if(isFetchingVerificationResult && !verificationResult ){
          //on mount
          setSnackbarMessage(`Please wait. We are processing your verification status`)
          setSnackbarSeverity("info")
          showSnackbar()
          disableButton()
          return
        }
        //else do nothing
        return ''
      }
      catch(error){

      }

    }
    notifyUserOfVerificationStatus()
    
  }, [verificationResult, isLoadingVerificationResult, isFetchingVerificationResult, verificationResultError])
  

  return (
    <div  style={{
      width: '100%',
      height: '100dvh',
      // backgroundColor: 'cyan',
      display: 'flex',
      justifyContent: 'center'
    }}>
      <div>
        <ReusableSnackbarComponent
        severity={interfaceState.snackbar_severity}
        open={interfaceState.snackbar_visible}  
        inner_message={interfaceState.snackbar_message} 
        setOpen={() => toggleInterfaceVisibility('snackbar_visible')}
        autoHideDuration={ interfaceState.snackbar_autohide_duration || 2000}/>
      </div>
      <div className='verification-mail-sent-page-inner' >
          <div className='verification-mail-sent-page-box'>
            
            {
            verificationResult && !isFetchingVerificationResult &&
            <>
            <div className='verification-mail-sent-page-box-top'> 
              <span className='verification-mail-sent-page-box-top-icon'><CheckCircle fontSize='1rem' /> </span>
            </div>
            <div 
            style={{fontWeight: 300, fontSize:'1rem'}}
            className='verification-mail-sent-page-box-info'> 
              Hurray!...Your email has been verified successfully. 
            </div>
            <Link
            to={CLIENT_LINKS.login}
            className='verification-mail-sent-page-box-editForm'>  
              Login
            </Link>
            </>
            }

            {
            isFetchingVerificationResult && !verificationResult &&
            <>

            <div className='verification-mail-sent-page-box-top'> 
              <span className='verification-mail-sent-page-box-top-icon'><HourglassBottom fontSize='1rem' /> </span>
            </div>
            <div 
            style={{fontWeight: 300, fontSize:'1rem'}}
            className='verification-mail-sent-page-box-info'> 
              We are processing your verification status...please wait
            </div>
            {
                    // interfaceState.isSpinnerActive &&
            <div style={{height: '0.5rem'}}>
            <LinearProgress/>
            </div>
            }
            {
            verificationResultError &&
            <Link
            to={CLIENT_LINKS.register.url}
            className='verification-mail-sent-page-box-editForm'>  
              Register
            </Link>
            }
            </>
             }

            {
            !verificationResult && !isFetchingVerificationResult && verificationResultError &&
            <>
            <div className='verification-mail-sent-page-box-top'> 
              <span className='verification-mail-sent-page-box-top-icon'><ErrorOutline fontSize='1rem' /> </span>
            </div>
            <div 
            style={{fontWeight: 300, fontSize:'1rem'}}
            className='verification-mail-sent-page-box-info'> 
              Sorry...email confirmation failed
            </div>
            {
            verificationResultError &&
            <Link
            to={CLIENT_LINKS.register.url}
            className='verification-mail-sent-page-box-editForm'>  
              Register
            </Link>
            }
            </>
             }

          </div>
          {/* timer will also be reflected here */}
        
      </div>
    </div>
  )
}



export default VerifyAccountSuccessPage




   