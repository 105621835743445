import './ocrModulePage.scss'
import { useEffect, useLayoutEffect, useState } from 'react'
import { createWorker } from "tesseract.js";
import Tesseract from 'tesseract.js';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { v4 as uuidv4 } from 'uuid';

 
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { Add, Cancel, CancelOutlined, CloseOutlined, Image, KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight } from '@mui/icons-material';

import { useRef } from 'react';
import { UseHideSidebar } from '../../hooks/UseSidebarVisibility';
import { deleteItemAndGetNeighborIndex } from '../../utils/Utils';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { CLIENT_LINKS } from '../../utils/LINKS';
import ReusableSnackbarComponent from '../../components/reusables/ReusableSnackbarComponent/ReusableSnackbarComponent';

const  modules  = {
toolbar: [
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script:  "sub" }, { script:  "super" }],
    ["blockquote", "code-block"],
    [{ list:  "ordered" }, { list:  "bullet" }],
    [{ indent:  "-1" }, { indent:  "+1" }, { align: [] }],
    ["link", "image", "video"],
    ["clean"],
],
};

const OcrModulePage = () => {
    UseHideSidebar()
    // const {state: router_state} = useLocation()
    // const {prevLocation, currentLocation} = useHistoryTracker()
    const navigate = useNavigate()
    // console.log(prevLocation, currentLocation)

      //handles the interface
  const [interfaceState, setInterfaceState] = useState({
    snackbar_visible: false,
    snackbar_message: "",
    snackbar_severity: "info",
    snackbar_autohide_duration: 2000,
    button_disabled : false,
    confirmationDialogVisible: false
    // confirmation_prompt: false //just use a different state so you can use useeffect of this state, instead of typing each property
  })

  const showSnackbar = (val = true) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_visible: true  }))
  }
  const hideSnackbar = (val = true) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_visible: false  }))
  }
  const toggleInterfaceVisibility = (name) => {
    setInterfaceState((prev) => ({ ...prev,  [name]: !prev[name]  }))
  }
  const setSnackbarMessage = (message) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_message: message  }))
  }
  const setSnackbarSeverity = (severity) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_severity: severity  }))
  }
  const setSnackbarDuration = ( duration) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_autohide_duration: duration  }))
  }
  const handleInterfaceState = (name, value_passed) => {
    setInterfaceState((prev) => ({ ...prev,  [name]: value_passed  }))
  }

  const disableButton = () => {
    setInterfaceState((prev) => ({ ...prev,  button_disabled: true  }))
  }
  const enableButton = (name, value_passed) => {
    setInterfaceState((prev) => ({ ...prev,  button_disabled: false  }))
  }
  const alertSnackbar = (message="hello, user", type="info", duration=9999999) => {
    setSnackbarMessage(message)
    setSnackbarSeverity(type)
    setSnackbarDuration(duration)
    showSnackbar()
  }
    
    const [ocrExtractionProgress, setOcrExtractionProgress] = useState({
        completed: false,
        in_progress: false,
        failed: false
    })
    const handleOcrExtractionProgress = (key, value) => {
        setOcrExtractionProgress(prev => ({...prev, [key]: value }))
    }

    const [processedImageCount, setProcessedImageCount] = useState(0)
    const [images, setImages] = useState([]);
    const imagesRef = useRef([]);
    
    
    const [textConversionSuccessful, setTextConversionSuccessful] = useState(false)
    const [imagePath, setImagePath] = useState("");
    const [currentImageIndex ,  setCurrentImageIndex] = useState(0)


    const [stat, setStat] = useState([])
    const [currentImageIndexCopy, setCurrentImageIndexCopy] = useState(0)

    const handleSlideShow = (direction) => {
        if(images?.length === 0){
            return console.log('no image uploaded')
        }

        if(direction === "left"){
            if(currentImageIndex === 0){
                return ''
            }
            setCurrentImageIndexCopy((prev) => prev - 1)
            return setCurrentImageIndex((prev) => prev - 1)
            
        }

        if(direction === "right"){
            if(currentImageIndex === images.length-1){
                return ''
            }
            setCurrentImageIndexCopy((prev) => prev + 1)
            return setCurrentImageIndex((prev) => prev + 1)

        }
    }

    const handleChange = (event) => {
        // console.log('hi')
        const files = event.target.files;
        // imagesRef.current  = files

        // setImages(() => {
        //     // console.log()
        //     return files
        // });

        setImages([...files]);
        setStat([...files]);
        //this retains images as a js array
    }



    const processImages = async () => {
        setProcessedImageCount(0)
        handleOcrExtractionProgress('in_progress', true)
        for (let i = 0; i < images.length; i++) {
            const file = images[i];
            const result = await processImage(file);
            // setResults((prevResults) => [...prevResults, result]);
        }
        handleOcrExtractionProgress('in_progress', false)
        
        handleOcrExtractionProgress('completed', true)

        alertSnackbar('Extraction completed', 'info', 2000)
        

    }
    // console.log(images)

    const processImage = async (image) => {

        try{
            const current_index_image_to_extract = images.indexOf(image)
            setCurrentImageIndex(current_index_image_to_extract)
        
            setTextConversionSuccessful(false)
            // setTextConversionSuccessful(false)
            const worker = await Tesseract.createWorker({
                // logger: m => console.log(m),
            });
            // await worker.load();
            await worker.loadLanguage('eng');
            await worker.initialize('eng');
            //  const { data: { text } } = await worker.recognize('https://tesseract.projectnaptha.com/img/eng_bw.png');
    
    
            const { data: { text } } = await worker.recognize(image);
            setQuillContent((prev ) =>( prev +"\n" + "\n"+  text))
            setTextConversionSuccessful(true)
    
            // setCurrentImageIndex(images.indexOf(image))
            // console.log(images.indexOf(image))
            // console.log(typeof(images))
            // console.log(Array.isArray(images))
            setProcessedImageCount((prev) => prev + 1)
    
            await worker.terminate();
            //  setOcr('hi');
        }
        catch(error){
            console.error("Tesseract error:", error);
            return alertSnackbar(
                <>
                <div>Unable to begin extraction.</div>
                <div>Try the following:</div>
                <div>1. Check your internet connection</div>
                <div>2. Reload this webpage</div>
                <div>...or try some other image format if this issue persists</div>
                </>, 
                'error'
             )
        }


    };

    const [quillContent, setQuillContent] = useState('')
    
    useEffect(() => {
    //makes the toolbar of the quill component to become fixed on scrolling below

    const handleScroll = () => {
        const stickyDiv = document.getElementsByClassName('ql-toolbar ql-snow');
        const rect = stickyDiv[0].getBoundingClientRect();
        const stickyDivTop = rect.top;
        // console.log(stickyDivTop)
    //   console.log(rect)


        const formPart1Div = document.getElementsByClassName('ql-editor');
        const rect2 = formPart1Div[0].getBoundingClientRect()
        const formPart1DivTop = rect2.top
    //   console.log(rect2)
        //stickyDiv has a fixed height

        if (stickyDivTop <= 400  ) {
        // console.log('DIVTOP HIT 0000')
    stickyDiv[0].classList.add('quill-toolbar-fixed');
    // && formPart1DivTop > -120
        } 
        if (stickyDivTop <=820 && formPart1DivTop > 105) {
        //adjust this height by fixing the form1 section height// 
        // console.log('DIVTOP GREATER THAN > ZERO')
    stickyDiv[0].classList.remove('quill-toolbar-fixed');

        }
        else{
        // console.log('nayi')
        }


    };

    window.addEventListener('scroll', handleScroll);
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
    }, );

    const handleUseQuillContent = () => {
        if(!quillContent){
            return alertSnackbar('No text was extracted: Please click on the convert to text button', 'error')
        }

        // navigate(`${router_state.coming_from}`, {state: {passed_router_quill_content: quillContent}})
        // navigate('/author/create_thesis', {state: {passed_router_quill_content: quillContent}})
        // navAndUsePrevLocation({state: {passed_router_quill_content: quillContent}})
    }
    
    const fileInputRef = useRef()
    
    const clearUploadedFiles = () => {
        setImages([]); 
        setProcessedImageCount(0)
        setTextConversionSuccessful('')
        setCurrentImageIndex(0)
        //reset all

        fileInputRef.current.value = '';
        // Manually trigger the 'change' event
        fileInputRef.current.dispatchEvent(new Event('change', { bubbles: true }));
    }

    const openImageInNewTab = async (image) => {
        try{
            if (image) {
            const imageUrl = URL.createObjectURL(image);
            window.open(imageUrl, "_blank"); // This opens the image in a new tab.
            }
        }
        catch(err){
            if(!image){
                return alertSnackbar('please upload an image', 'error')
            }
            alertSnackbar('an error occurred, please upload an image', 'error')
        }
    }



    // useEffect(() => {
    //   setStat(images)
    //   console.log('RAN STAT EFFECT')
    
    //   return () => {
    //   };
    // }, [images])

    // console.log(stat)


    useEffect(() => {
    //   setStat(stat)
    if(currentImageIndex < 1){
        setCurrentImageIndex(0)
    }
    
    //   return () => {
    //   };
    }, [currentImageIndex])

    const [uniqueKey, setUniqueKey] = useState(uuidv4())
    useEffect(() => {
        //return the pointer back to default
        //i.e current index to zero 0
        //use a unique key to ensure a fresh input is rerendered
        //inputs use stale data or need to be bubbled
        if(images.length < 1){
            setCurrentImageIndex(0)
            fileInputRef.current.value = ''
            setUniqueKey(uuidv4())
        }
        //   return () => {
        //   };
    }, [images])


    const deleteImageFromUploadedImages = async (image_passed) => {
        try{

            const new_image_list = images.filter(image_item => image_item !== image_passed)

            // console.log('WE PASSED IMAGE: ', image_passed)

            const neighbour_index_of_deleted_image = deleteItemAndGetNeighborIndex(images, image_passed)
            
            if(neighbour_index_of_deleted_image < 0){
                return alertSnackbar('no item found', 'error')
                
            }
            // console.log(new_image_list)
            // console.log('NEIGHBOUR EXISTS AT: ',neighbour_index_of_deleted_image)

            setImages(new_image_list)

            setCurrentImageIndex(neighbour_index_of_deleted_image)
            

            return ''
            setCurrentImageIndex(neighbour_index_of_deleted_image)
            setImages(new_image_list)
        }
        catch(err){
            console.log(err)
            console.log('an error occurred while deleting this image')
        }
    }

    const [isModalUseContentOpen, setIsModalUseContentOpen] = useState(false);

    const toggleIsModalUseContentOpen = () => {
        setIsModalUseContentOpen(!isModalUseContentOpen)
    }

    const navigateToCreateThesisPage = () => {
        navigate(CLIENT_LINKS.create_thesis.url, {state: {passed_quill_ocr_content: quillContent}})
        // navigate(`${router_state.coming_from}`, {state: {passed_router_quill_content: quillContent}})

    }

    const handleCopyText = async (textToCopy) => {
        try {
            console.log(textToCopy)
            if(!textToCopy){
                return alertSnackbar(
                <>
                <div> No text to copy!:</div>
                <div>Please upload image(s) or </div>
                <div>Click the start extraction button if you have uploaded images </div>
                </>,
                'error'
                )
            }
            if(textToCopy === "<p><br></p>"){
                return alertSnackbar(
                <>
                <div>No text to copy!: </div>
                <div>Please upload image(s) or </div>
                <div>Click the start extraction button if you have uploaded images </div>
                </>,
                'error'
                )
            }

          await navigator.clipboard.writeText(textToCopy);
          toggleIsModalUseContentOpen()
        //   alert('Text copied to clipboard!');
        } 
        catch (error) {
          console.error('Failed to copy text:', error);
          return alertSnackbar('Failed to copy text', 'error');
        }
    };

    return (
     <div className="image-ocr-extract-page">
        <ReusableSnackbarComponent 
        severity={interfaceState.snackbar_severity}
        open={interfaceState.snackbar_visible}  
        inner_message={interfaceState.snackbar_message} 
        setOpen={() => toggleInterfaceVisibility('snackbar_visible')}
        autoHideDuration={ interfaceState.snackbar_autohide_duration || 2000}/>
        <div className="image-ocr-extract-page-wrapper">
            <div className="image-ocr-extract-page-ocr-image-wrapper">

                <div 
                    onClick={() => handleSlideShow("left")}
                    className="image-ocr-extract-page-ocr-image-wrapper-left-icon">
                        {
                        currentImageIndex > 0 &&
                        <KeyboardDoubleArrowLeft fontSize='1rem' className='ocr-left-icon'/>
                        }
                    </div> 
                <div className="image-ocr-extract-page-ocr-image-container">
                        {
                        false &&
                        <div>
                            <div>Image: {currentImageIndex +  1}</div>
                            <div>Hello There There are {images.length} images in here</div>
                        </div>
                        }
                        {
                        images.length > 0 &&
                        <img 
                        onClick={()=> openImageInNewTab(images[currentImageIndex])}
                        src={URL.createObjectURL(images[currentImageIndex])} 
                        // onClick={()=> openImageInNewTab(stat[currentImageIndexCopy])}
                        // src={URL.createObjectURL(stat[currentImageIndexCopy])} 
                        className="image-ocr-extract-page-ocr-image-container-image" alt="logo"/>
                        }
                        {
                        images.length <= 0 && 
                        <label for="ocr-files-upload-input">
                        <div
                        style={{
                            display:'flex',
                            alignItems: 'center',
                            height: 'max-content',
                            cursor: 'pointer'
                            // justifyContent: 'center',
                            // backgroundColor: 'pink'
                        }}>
                            <Image 
                            className='image-to-extract-icon'
                            fontSize='3rem'/>
                            <span>

                            Add the images
                            </span>
                        </div>
                        </label>
                        }


                </div>
                <div 
                    onClick={()=> handleSlideShow('right')}
                    className="image-ocr-extract-page-ocr-image-wrapper-right-icon">
                        {
                            (currentImageIndex < images.length -1) &&
                            <KeyboardDoubleArrowRight 
                            fontSize='1rem'
                            className='ocr-right-icon'/>
                        }
                </div>
                {
                images.length > 0 &&
                <div 
                className='delete-image-icon-container'

                onClick={() => deleteImageFromUploadedImages(images[currentImageIndex])}

                // onClick={() => deleteImageFromUploadedImages(stat[currentImageIndexCopy])}
                >
                    <CloseOutlined 
                    fontSize='1rem'
                    className='delete-image-icon'
                    />
                </div>
                }

            </div>
            <div className="image-ocr-extract-page-ocrText">
                <div className='quill-content-section'>
                {/* <div className='quill-content-section-label'>
                    <span>
                        Content
                    </span>
                </div> */}
                <ReactQuill 
                theme='snow' 
                modules={modules}   
                onChange={setQuillContent}  
                // defaultValue={theses[0].content || ''}
                placeholder="The extracted text will be displayed here (you can edit the results)"  
                value={quillContent}
                />
                </div>
            </div>
            <div className="image-ocr-extract-page-bottom-buttons">
                <div className='ocr-files-upload-input-container'>
                    {
                    images.length < 1 &&
                    <label for="ocr-files-upload-input">
                        <div
                        for="ocr-files-upload-input"
                        className="image-ocr-extract-page-submit-button">
                            Upload images
                        </div>
                    </label>
                    }



                    <input 
                    key={uniqueKey}
                    ref={fileInputRef}
                    className="image-ocr-extract-page-input"
                    id = "ocr-files-upload-input"
                    type="file" 
                    multiple
                     accept=".jpg, .jpeg, .png, .tif, .tiff, .bmp, .gif"
                    onChange={handleChange} />


                    {
                    images.length > 0 &&
                    <button
                    style={{
                        marginLeft: '2rem',
                        backgroundColor: 'black',
                        color: 'white',
                        // padding: '1rem 0rem'
                        paddingLeft: '4rem',
                        paddingRight: '4rem'
                    }} 
                    className="image-ocr-extract-page-submit-button start-extr" alt="logo"
                    onClick={() => processImages()}> start extraction
                    </button>
                    }

                    {
                        images.length > 0 &&
                        <div
                        onClick={() => clearUploadedFiles()}
                        for="ocr-files-upload-input"
                        className="image-ocr-extract-page-submit-button clear-all-files">
                            Clear images
                        </div>
                    }
                </div>



                <div className='extraction-progress-div'>
                    {
                    ocrExtractionProgress.in_progress &&
                    <>
                    <span className="vc-2x-2" >Running</span>
                    <div className="spin"></div>
                    </> 
                    }
                    {
                    textConversionSuccessful &&
                    <div style={{color: "green", marginLeft: '1rem'}} co>Text Extraction Successful!</div>
                    }
                    <div>
                    {
                    processedImageCount + " of " + images.length + ' images extracted'
                    }
                    </div>
                </div>
                {
                // quillContent &&
                false &&
                <button 
                    onClick ={() => handleCopyText(quillContent)}
                    className='fextract-ocr-copy-text'>
                    Copy Text
                </button>
                }

            </div>
            <div>
                <button 
                onClick ={() => handleCopyText(quillContent)}
                // onClick={() => handleUseQuillContent() }
                className='extract-ocr-copy-text'>
                    Use content
                </button>
            </div>
            <Dialog
            open={isModalUseContentOpen}
            onClose={toggleIsModalUseContentOpen}
            >
                <DialogTitle id="alert-dialog-title">
                    {"Copied text to clipbboard successfully!"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Do you want to create a new research record?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={toggleIsModalUseContentOpen}>No</Button>
                    <Button onClick={()=>navigateToCreateThesisPage()} autoFocus>
                        Yes, create a new research 
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
     </div>
    );
   }

export default OcrModulePage;

// export default UploadDocument



