

import React, { useEffect, useRef, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { CLIENT_LINKS } from '../../utils/LINKS';
import { useAuth } from '../../contexts/AuthContext';
import { useNavConsiderPrevRoute } from '../useNavConsiderPrevRoute';
import axios from 'axios';





const useMonitorUserAuthentication = () => {
  const navigate = useNavigate();
  const location = useLocation()
  // console.log(location.pathname)
  const {user, dispatch, authMonitorStates} = useAuth()
  const {navAndAddPrevLocation, navAndUsePrevLocation} = useNavConsiderPrevRoute()
  

  const {
    isAuthenticated,
    snackbarVisible: authSnackbarVisible, 
    snackbarMessage: authSnackbarMessage, 
    snackbarSeverity : authSnackbarSeverity, 
    snackbarDuration: authSnackbarDuration, 
  } = authMonitorStates

  const [authenticationState, setAuthenticationState] = useState({
    authCheckIntervalId: ''
  }); 
  // Set to the initial authentication status

  const clearAuthCheckIntervalId = () => {
    clearInterval(authenticationState.authCheckIntervalId)
    // setAuthenticationState(authenticationState)
  }

  const setAuthCheckIntervalId = (intervalId) => {
    setAuthenticationState(prev => ({...prev, authCheckIntervalId: intervalId}))
  }


  // const [authRetryCount, setAuthRetryCount] = useState(0)
  const authRetryCountRef = useRef(0);
  // let a = 0

  // console.log(authRetryCountRef)
  // Function to check authentication status
  const checkAuthentication = async () => {
    // console.log(location)
    try {
      const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
      });

      const res = await axiosInstance.get('/auth/isUserAuthenticated');
      
      dispatch({
        type: "VALIDATE_AUTH_MONITOR_SESSION"
      });
      // console.log('YOU ARE AUTHENTICATED');

      authRetryCountRef.current = 0; // Update the ref value

    } catch (error) {
      console.error(error);
      // console.log(authRetryCountRef.current);

      if (error?.response?.status?.toString()[0] === '4') {
        // console.log('AUTH RETRY COUNT IS: ', authRetryCountRef.current);
        const pagesThatDontRequireAuth = [
          CLIENT_LINKS.login.url,
          CLIENT_LINKS.register.url,
          CLIENT_LINKS.logout.url,
          CLIENT_LINKS.verify_account_page.url,
          CLIENT_LINKS.verify_account_success_page.url,
          CLIENT_LINKS.forgot_password_page.url,
          CLIENT_LINKS.reset_password_success_page.url
        ]
  
        for(let pageThatDontRequireAuth of pagesThatDontRequireAuth){
          //if the location we are does not include any of the unprotected routes
          if(location.pathname.toString().includes( pageThatDontRequireAuth.toString() )){
            console.warn('Page doesnt require auth but please login')
            authRetryCountRef.current = 0; // Update the ref value
            return ''
          }
          //hence with this we dont ever need to clear our timer
        }
        if(location.pathname === '/'){
          console.warn('Page doesnt require auth, it is base url but please login')
          authRetryCountRef.current = 0; // Update the ref value
          return ''
        }

        if(!user?._id){

          //check the second time
          // if (authRetryCountRef.current >= 1) {
            dispatch({
              type: "INVALIDATE_AUTH_MONITOR_SESSION",
            });

            authRetryCountRef.current = 0;
            
            await new Promise((resolve) => {
              setTimeout(() => {
                resolve();
              }, 1500);
            });
            return navAndAddPrevLocation(CLIENT_LINKS.login.url);
          // }

          authRetryCountRef.current += 1;
          return ''

        }

        if (authRetryCountRef.current < 3) {
          console.log(`I want ${3 - authRetryCountRef.current} more auth retries`);

          authRetryCountRef.current += 1;
          return ''
        }
        //when it hits 5 retries we invalidate the session

        // console.log('MORE THAN 5');
        // console.log('Monitor authentication issue identified');

        dispatch({
          type: "INVALIDATE_AUTH_MONITOR_SESSION",
        });
        //this dispatch is what runs the function that produces the object

        // console.log('NAVIGATING NOW');

        //
        authRetryCountRef.current = 0;
        await new Promise((resolve) => {
          setTimeout(() => {
            resolve();
          }, 1500);
        });
        return navAndAddPrevLocation(CLIENT_LINKS.login.url);

      }



      // if(!isAuthenticated)
    }
  };



  useEffect(() => {
    // console.log('AuthRetryCount changed:', authRetryCountRef);
    // setAuthRetryCount(prev => prev+1)


    // Add any logic here that needs to be executed when authRetryCount changes

  }, [authRetryCountRef.current]);
  


  //checkAuthentication runs every 5s
  useEffect(() => {
    // Initial check on component mount
    let intervalId

    const periodicFunc = async () => {
      
      // console.log('TRY BLOCK: ', authRetryCount)
      // checkAuthentication();

      // Periodically check authentication status (adjust the interval as needed)
      intervalId = setInterval( async () => {
        await checkAuthentication();
        // if(!isAuthenticated || !user?._id){
        //   //if not authenticated or logged out user

        //   clearAuthCheckIntervalId(authenticationState.authCheckIntervalId)
        //   clearInterval(intervalId)

        //   // clearInterval(intervalId)
        //   return ''
        // }
        // checkAuthentication();
      }, 3000); // Check every 5 seconds

      setAuthCheckIntervalId(intervalId)

      // Clean up the interval on component unmount

    }
    periodicFunc()
    return () => {
      clearInterval(authenticationState.authCheckIntervalId)
      clearInterval(intervalId)
    };
  }, [isAuthenticated, user?._id, location.pathname]); // Empty dependency array ensures the effect runs only on mount and unmount

  // return {...authenticationState, externalModifyAuthenticationState}
};

export default useMonitorUserAuthentication;
