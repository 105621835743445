import { BookmarkAdd, DeleteOutlined, DoubleArrow, Email, KeyboardArrowLeft, KeyboardArrowRight, Menu, MoreHoriz, Person3, Sell, Share, Visibility, WarningAmber } from '@mui/icons-material'
import { Link, useLocation, useNavigate } from 'react-router-dom'
// import { useEffect, useState } from 'react'
import axios from 'axios'
// import { UseShowTopbar } from '../../../hooks/UseTopbarVisibility'
// import { UseHideSidebar, UseShowSidebar } from '../../../hooks/UseSidebarVisibility'
// import { CLIENT_LINKS } from '../../../utils/LINKS'
// import { useAuth } from '../../../contexts/AuthContext'
import { authorizedPrivilegeLevelsThatCanAdmin_Util, authorized_privilege_levels_that_update_thesis_Util, checkIfUserHasPrivilegeLevelFor_Util, handleCopyTextToClipboard_Util, textLengthShortened } from '../../../utils/Utils'
// import { useQuery, useQueryClient } from '@tanstack/react-query'
import './reusableThesisItem.scss'
import { useOtherContext } from '../../../contexts/OtherContext'
// import { useOtherContext } from '../../../contexts/OtherContext'

import { useRef, useState } from 'react'
import {ReusableDraggableDialog} from '../ReusableDraggableDialog/ReusableDragableDialog'
import { CLIENT_LINKS } from '../../../utils/LINKS'
import ReusableSnackbarComponent from '../ReusableSnackbarComponent/ReusableSnackbarComponent'
import ReusableShareModalItem from '../ReusableShareModalItem/ReusableShareModalItem'

const ReusableThesisSavedItem = ({passed_thesis, authUser, useNav}) => {
    const [passedThesis, setPassedThesis] = useState(passed_thesis)

    const navigate = useNavigate()
    const {dispatch: dispatchOtherContext} = useOtherContext()
    const [interfaceState, setInterfaceState] = useState({
        isSupervisorModalOpen: false,
        snackbar_visible: false,
        snackbar_message: "",
        snackbar_severity: "info",
        snackbar_autohide_duration: 1*60*60*24,
        button_disabled : false,
        isSpinnerActive: false

        // confirmation_prompt: false //just use a different state so you can use useeffect of this state, instead of typing each property
    })

    const showSnackbar = (val = true) => {
        setInterfaceState((prev) => ({ ...prev,  snackbar_visible: true  }))
    }
    const hideSnackbar = (val = true) => {
        setInterfaceState((prev) => ({ ...prev,  snackbar_visible: false  }))
    }
    const toggleInterfaceVisibility = (name) => {
        setInterfaceState((prev) => ({ ...prev,  [name]: !prev[name]  }))
    }
    const setSnackbarMessage = (message) => {
        setInterfaceState((prev) => ({ ...prev,  snackbar_message: message  }))
    }
    const setSnackbarSeverity = (severity) => {
        setInterfaceState((prev) => ({ ...prev,  snackbar_severity: severity  }))
    }
    const setSnackbarDuration = ( duration) => {
        setInterfaceState((prev) => ({ ...prev,  snackbar_autohide_duration: duration  }))
    }
    const handleInterfaceState = (name, value_passed) => {
        setInterfaceState((prev) => ({ ...prev,  [name]: value_passed  }))
    }

    const disableButton = () => {
        setInterfaceState((prev) => ({ ...prev,  button_disabled: true  }))
    }
    const enableButton = (name, value_passed) => {
        setInterfaceState((prev) => ({ ...prev,  button_disabled: false  }))
    }
    const showSpinner = (val = true) => {
        setInterfaceState((prev) => ({ ...prev,  isSpinnerActive: true  }))
    }
    const hideSpinner = (val = true) => {
        setInterfaceState((prev) => ({ ...prev,  isSpinnerActive: false  }))
    }

    const alertSnackbar = (message="hello, user", type="info", duration=2000) => {
        setSnackbarMessage(message)
        setSnackbarSeverity(type)
        setSnackbarDuration(duration)
        showSnackbar()
    }

    
  

    const handleViewThesis = () => {
      navigate(`/view_thesis/${passedThesis?._id}`)
    }
  
    const handleUpdateThesis = () => {
      navigate(`/update_thesis/${passedThesis?._id}`)
    }
  
    const comingSoon = () => {
        alertSnackbar('This feature will be added in future updates')
    }

    const handleRemoveFromLibrary = async (auth_user_id, thesis_id) => {
        // console.log(authUser)
        if(!auth_user_id){
            return alertSnackbar('You must login before you can remove this thesis', 'error')
        }

        if(!thesis_id){
            return alertSnackbar('No thesis was selected', 'error')
        }

        const body = {
            userId: auth_user_id,
            thesisId: thesis_id
            //must align with our backend req.body
        }
        console.log(body)

        const axiosInstance = axios.create({
            baseURL: process.env.REACT_APP_API_URL
        })

        try{
 
            const res = await axiosInstance.post("/theses/remove_thesis_from_saved_theses_list", body)
            console.log(res.data)

            dispatchOtherContext({
                type: 'FORCE_UPDATE',
            })

            alertSnackbar('You have removed the thesis from your library')
            //we can show a little popup asking if you want to see the library. The popup will use a timeout of 2 seconds to stay visible. At every success it will turn off any visibility and turn it on for 2 seconds. It must clear itself in a useeEffect or something
        }
        catch(error){
            console.error('error')

            alertSnackbar( `${error?.response?.data?.message || ' an error occurred'}` ,'error' )
            // return alert('An error occurred')
        }

    
    }

    const [isShareModalVisible, setIsShareModalVisible] = useState(false)

    const toggleShareModal = () => {
        console.log('HIT')
        setIsShareModalVisible(prev => !prev)
    }

    // const {pathname, origin} = useLocation()
    // console.log(pathname)
    const base_url = window.location.origin

    const isAdminOrAuthor = () => {
        let canUserDeleteThesis = false
        const user_privilege_levels = authUser?.privilege_levels
        // console.log(user_privilege_levels)
        
        const isAdmin = checkIfUserHasPrivilegeLevelFor_Util(authorized_privilege_levels_that_update_thesis_Util, user_privilege_levels )

        if(isAdmin){
          canUserDeleteThesis = true
        }
    
        if(authUser?.email === passedThesis?.email){
          canUserDeleteThesis = true
        }

        return canUserDeleteThesis
    }
  

    const isAdminFunc = () =>{
        const user_privilege_levels = authUser?.privilege_levels

        const isAdmin = checkIfUserHasPrivilegeLevelFor_Util(authorizedPrivilegeLevelsThatCanAdmin_Util, user_privilege_levels )

        if(isAdmin){
            return true
        }

        return false
    }
    const adminLockThesis = async ( {
        lockAuthorFullTextShareable = false, 
        lockAuthorThesisEditable = false, 
        lockThesisTotallyDisabled = false, 
        lockAll= false
    } = {} ) => {
        try{
            disableButton()
            const axiosInstance = axios.create({
                baseURL: process.env.REACT_APP_API_URL
            })
            const body = {}

            if(lockAuthorFullTextShareable){
                body.lockAuthorFullTextShareable = true
            }

            if(lockAuthorThesisEditable){
                body.lockAuthorThesisEditable = true
            }

            if(lockThesisTotallyDisabled){
                body.lockThesisTotallyDisabled = true
            }

            if(lockAll){
                body.lockAll = true
            }


          
            const response = await axiosInstance.post(`/theses/adminLockThesis/${passedThesis?._id || ''}`, body);
            //   console.log(response.data.message)
            setPassedThesis(response?.data?.message || passed_thesis)

            
            // return response.data.message;

            // dispatchOtherContext({
            //     type: 'FORCE_UPDATE',
            //   })

            // forceLocalComponentUpdate()

            // disableButton()
            //how do we pass this to the 3 items differently

        }
        catch(error){
            console.error(error)
            alertSnackbar( `${error?.response?.data?.message || 'Sorry an error occurred. We could not process your request'}`, 'error' )
        }
        finally{
            enableButton()
        }
    }


    const adminUnlockThesis = async ( {
        unlockAuthorFullTextShareable = false, 
        unlockAuthorThesisEditable = false, 
        unlockThesisTotallyDisabled = false, 
        unlockAll= false
    } = {} ) => {
        try{
            disableButton()
            const axiosInstance = axios.create({
                baseURL: process.env.REACT_APP_API_URL
            })
            const body = {}

            if(unlockAuthorFullTextShareable){
                body.unlockAuthorFullTextShareable = true
            }

            if(unlockAuthorThesisEditable){
                body.unlockAuthorThesisEditable = true
            }

            if(unlockThesisTotallyDisabled){
                body.unlockThesisTotallyDisabled = true
            }

            if(unlockAll){
                body.unlockAll = true
            }


          
            const response = await axiosInstance.post(`/theses/adminUnlockThesis/${passedThesis?._id || ''}`, body);
            //   console.log(response.data.message)

            setPassedThesis(response?.data?.message || passed_thesis)
            // return response.data.message;

            // disableButton()
            //how do we pass this to the 3 items differently
            // forceLocalComponentUpdate()

            // dispatchOtherContext({
            //     type: 'FORCE_UPDATE',
            //   })
            // console.log('forced update')

        }
        catch(error){
            console.error(error)
            alertSnackbar( `${error?.response?.data?.message || 'Sorry an error occurred. We could not process your request'}`, 'error' )
        }
        finally{
            enableButton()
        }
    }

    const confirmationRef = useRef(0)
    const revokeAllFullTextGrants = async ( {
        anyParamYouLike = true,
        anyParamYouHate = true
    } = {} ) => {
        try{
            if(confirmationRef.current < 1){
                setTimeout(() => {
                    confirmationRef.current = 0
                    console.log(confirmationRef.current)
                }, 5000);
                confirmationRef.current = confirmationRef.current + 1



                const createCustomFrontendError = (cfMessage, cfCode) => {
                    const error = new Error();
                    error.cfName = 'CustomError';
                    error.cfCode = cfCode || 500;
                    error.cfData = { cfMessage };
                    return error;
                  }
                throw createCustomFrontendError('This is not a trivial action. Are you sure?. Click again to confirm')
                
                // return alertSnackbar('Are you sure')
            }

            disableButton()
            const axiosInstance = axios.create({
                baseURL: process.env.REACT_APP_API_URL
            })
            const body = {}
            body.thesisId = passedThesis?._id || ''

            const response = await axiosInstance.post(`/full_text_request/denyAllFullTextRequestsForThesis`, body);

            alertSnackbar(response?.data?.message || 'All Full Texts have been denied', 'info', 10000)
            //   console.log(response.data.message)

            // setPassedThesis(response?.data?.message || passed_thesis)
            // return response.data.message;

            // disableButton()
            //how do we pass this to the 3 items differently
            // forceLocalComponentUpdate()

            // dispatchOtherContext({
            //     type: 'FORCE_UPDATE',
            //   })
            // console.log('forced update')

        }
        catch(error){
            // console.log(error)
            console.error(error)
            // console.dir(error)
            alertSnackbar( `${error?.response?.data?.message || error?.cfData?.cfMessage || 'Sorry an error occurred. We could not process your request'}`, 'error', 5000)
        }
        finally{
            enableButton()

            
        }
    }



    return (
        <div className='reusable-thesis-item'>
            <ReusableSnackbarComponent
            severity={interfaceState.snackbar_severity}
            open={interfaceState.snackbar_visible}  
            inner_message={interfaceState.snackbar_message} 
            setOpen={() => toggleInterfaceVisibility('snackbar_visible')}
            autoHideDuration={ interfaceState.snackbar_autohide_duration || 2000}
            />
            <div 
            className='random-thesis-suggestion-item'>
                <div  className='random-thesis-suggestion-item-title'> {textLengthShortened(passedThesis?.title)}
                </div>
                <div className='random-thesis-suggestion-item-file-available'>
                    <span className='rtsifa-box-available'> File available </span>
                    <span className='rtsifa-box-pdf'> Pdf </span>
                    <span className='rtsifa-year'> {passedThesis?.year}</span>
                </div>
                <div  
                className='random-thesis-suggestion-item-author'> 
                    <span className='random-thesis-suggestion-item-author-icon'>
                    <Person3  fontSize='1rem'/>
                    </span>
                    <span>
                    {passedThesis?.author || ''}
                    </span>
                    <span  className='random-thesis-suggestion-item-supervisors'>
                    (Supervised by {passedThesis?.supervisor?.supervisor_title + ' ' + passedThesis?.supervisor?.supervisor_name })
                    </span>
                </div>

                <div  className='random-thesis-suggestion-item-keywords'>
                    <span className='random-thesis-suggestion-item-keywords-icon'><Sell fontSize='1rem'/></span>
                    {
                    passedThesis?.keywords?.length > 0 &&
                    passedThesis?.keywords.map((keyword, index) => (
                    <>
                    <span key={index}>{keyword}</span>
                    {index+1 < passedThesis?.keywords.length && <strong>,{' '}</strong>}
                    </>
                    ))
                    }
                </div>
                <div className='random-thesis-suggestion-item-abstract'>  
                    {textLengthShortened(passedThesis?.abstract || '', 400)}
                </div>

                {/* <div  className='random-thesis-suggestion-item-publish-date'>{thesis.year}</div> */}
                <div className='random-thesis-suggestion-item-options'>
                    <span 
                    onClick={() => handleViewThesis()}
                    className='random-thesis-suggestion-item-option-item rts-view'>
                    <Visibility fontSize="1rem"/>
                    View
                    </span>

                    <span 
                    onClick={()=>toggleShareModal()}
                    className='random-thesis-suggestion-item-option-item rts-share'>
                    <Share fontSize="1rem"/>
                    Share
                    </span>
                    {
                    (authUser?.email !== passedThesis?.email) &&
                    <span 
                    onClick={comingSoon}
                    className='random-thesis-suggestion-item-option-item rts-contact'>
                    <Email fontSize="1rem"/>
                    Contact author
                    </span>
                    }
                    {
                    isAdminOrAuthor() &&
                    <span 
                    className='random-thesis-suggestion-item-option-item rts-contact'
                    onClick={()=> handleUpdateThesis()}>
                    <Email fontSize="1rem"/>
                        Update Thesis
                    </span>
                    }
                                     {
                    isAdminOrAuthor() &&
                    <span 
                    className='random-thesis-suggestion-item-option-item rts-contact'
                    onClick={()=> revokeAllFullTextGrants()}>
                        <WarningAmber fontSize="1rem"/>
                        Revoke All Full Text Access
                    </span>
                    }

                    <span 
                    style={{
                        backgroundColor: 'f5d7d7'
                    }}
                    onClick={()=>handleRemoveFromLibrary(authUser?._id, passedThesis?._id)}
                    className='random-thesis-suggestion-item-option-item rts-save'>
                        <DeleteOutlined fontSize='1rem'/>
                        Remove from Saved List
                    </span>
                    <span  
                    onClick={comingSoon}
                    className='random-thesis-suggestion-item-option-more'>
                        <MoreHoriz fontSize='1rem'/>
                    </span>
                    {
                    isAdminFunc() &&
                    <div style={{
                        display: 'flex',
                        gap: '0.5rem',
                        flexWrap: 'wrap'
                    }}>
                        {
                        passedThesis?.modifiableRights?.authorFullTextShareable &&
                        <span 
                        disabled={interfaceState.button_disabled}
                        onClick={() => adminLockThesis({lockAuthorFullTextShareable: true})}
                        style={{padding: '1rem',  backgroundColor: 'skyblue'}}
                        className='random-thesis-suggestion-item-option-item rts-contact'>Disable Author From Sharing Full Text
                        </span>
                        }
                        {
                        !(passedThesis?.modifiableRights?.authorFullTextShareable) &&
                        <span 
                        disabled={interfaceState.button_disabled}
                        onClick={() => adminUnlockThesis({unlockAuthorFullTextShareable: true})}
                        style={{padding: '1rem', backgroundColor: 'pink'}}
                        className='random-thesis-suggestion-item-option-item rts-contact'>Allow Author To Grant Full Text Permissions
                        </span>
                        }


                        {
                        passedThesis?.modifiableRights?.authorThesisEditable &&
                        <span 
                        disabled={interfaceState.button_disabled}
                        onClick={() => adminLockThesis({lockAuthorThesisEditable: true})}
                        style={{padding: '1rem',  backgroundColor: 'skyblue'}}
                        className='random-thesis-suggestion-item-option-item rts-contact'>Disable Author From Editing Thesis</span>
                        }
                        {
                        !(passedThesis?.modifiableRights?.authorThesisEditable) &&
                        <span 
                        disabled={interfaceState.button_disabled}
                        onClick={() => adminUnlockThesis({unlockAuthorThesisEditable: true})}
                        style={{padding: '1rem',  backgroundColor: 'pink'}}
                        className='random-thesis-suggestion-item-option-item rts-contact'>Allow Author to Edit Thesis</span>
                        }


                        {
                        (passedThesis?.modifiableRights?.thesisTotallyDisabled) &&
                        <span 
                        disabled={interfaceState.button_disabled}
                        onClick={() => adminUnlockThesis({unlockThesisTotallyDisabled: true})}
                        style={{padding: '1rem',  backgroundColor: 'pink'}}
                        className='random-thesis-suggestion-item-option-item rts-contact'>Enable Thesis</span>
                        }
                        {
                        !(passedThesis?.modifiableRights?.thesisTotallyDisabled) &&
                        <span 
                        disabled={interfaceState.button_disabled}
                        onClick={() => adminLockThesis({lockThesisTotallyDisabled: true})}
                        style={{padding: '1rem', backgroundColor: 'skyblue'}}
                        className='random-thesis-suggestion-item-option-item rts-contact'>Disable Thesis</span>
                        }

                        {
                        
                        <>
                        <span 
                        disabled={interfaceState.button_disabled}
                        onClick={() => adminLockThesis({lockAll: true})}
                        style={{padding: '1rem'}}
                        className='random-thesis-suggestion-item-option-item rts-contact'>Disable All</span>
                        <span 
                        disabled={interfaceState.button_disabled}
                        onClick={() => adminUnlockThesis({unlockAll: true})}
                        style={{padding: '1rem'}}
                        className='random-thesis-suggestion-item-option-item rts-contact'>Enable All</span>
                        </>
                        }


                    </div>
                    }
                    {/* <span className='random-thesis-suggestion-item-option-more'>
                        <MoreHoriz fontSize='1rem'/>
                    </span> */}
                    {/* contact the author, report item */}
                </div>
            </div>

            
            {
            <ReusableDraggableDialog
            isOpened={isShareModalVisible}
            setIsOpened={setIsShareModalVisible}
            main_body={
                <ReusableShareModalItem 
                    shareUrl={`${base_url}${CLIENT_LINKS.view_thesis.url}/${passedThesis?._id}`} 
                    passedAlertSnackbarFunc={alertSnackbar}
                />
            } 
            />
            }
        </div>
    )
  } 


export default ReusableThesisSavedItem