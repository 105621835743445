import { createContext, useContext, useEffect, useReducer, useRef, useState } from 'react'
import { useAuth } from '../../../contexts/AuthContext'
import './createThesisPage.scss'
import './createThesisAddDocumentComponent.scss'
import { v4 as uuidv4 } from 'uuid';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useTheses } from '../../../contexts/ThesesContext';
// import 'react-quill/dist/quill.bubble.css
import ReactModal from 'react-modal';
// import OcrModule from './OcrModulePage/OcrModule';
import HomePage from '../../home/HomePage';
import { useLocation, useNavigate } from 'react-router-dom';
import { Add, Cancel, Check, CheckCircle, CreateNewFolderOutlined, DeleteOutline, Description, FileUpload, FileUploadOutlined, Info, InfoOutlined, Remove, UploadFileOutlined } from '@mui/icons-material';
import axios from 'axios';
import { useNavConsiderPrevRoute } from '../../../hooks/useNavConsiderPrevRoute';
import { UseHideSidebar } from '../../../hooks/UseSidebarVisibility';
import { objectIsPopulated, scrollToBottomUtil, textLengthShortened, validateAlphanumericTitleBlockV1_Util, validateSingleName_Util, validateMatricIDV1_Util, validateAlphabeticTitleBlockV1_Util, validateEmail_Util, validateThesisYearV1_Util, validateFullNameV2_Util, checkIfUserHasPrivilegeLevelFor_Util, authorizedPrivilegeLevelsThatUpdateFullTextRequest_Util, authorizedPrivilegeLevelsThatCanAdmin_Util } from '../../../utils/Utils';
import { CLIENT_LINKS } from '../../../utils/LINKS';
import { useQuery } from '@tanstack/react-query';
import { Box, Chip, LinearProgress, MenuItem, OutlinedInput, Select } from '@mui/material';
import ReusableSnackbarComponent from '../../../components/reusables/ReusableSnackbarComponent/ReusableSnackbarComponent';
// import { UseNotifyIfUserIsNotAuth } from '../../../hooks/UseNotifyIfUserIsNotAuth';

const sample_thesis = {
  title: "Development of an Electronic Repository for Undergraduate Theses",
  author:"Moboluwarin Eyisojumi Jibola-Shittu",
  matric_id: "CSC/2016/063",
  department: "The Department of Computer Science and Engineering",
  faculty:"Faculty of Technology",
  institution: "Obafemi Awolowo University",
  institution_abbreviation: "OAU",
  year: new Date().getFullYear(),
  supervisor: "Dr T.O Omodunbi",
  categories: [],
  abstract: "The study concluded something good",
  keywords: ["thesis", "repository", "undergraduate", "theses"],
  // content: [],
  content: {
    type: 'ocr',
    content: ''
  },

  // content_updates: [], //up to 3 versions of content
  // content_update_needed: false, // when you approve, set content to the latest content_updates.slice(-1)[0] and set this to true and clear the content_updates 
}

//context starts

const INITIAL_THESIS_STATE = {
  current_thesis: {
    title: "",
    author:"",
    matric_id: "",
    department: sample_thesis.department,
    faculty: sample_thesis.faculty,
    institution:sample_thesis.institution,
    institution_abbreviation: sample_thesis.institution_abbreviation,
    year: new Date().getFullYear(),
    supervisor: "",
    abstract: "",
    keywords: ["abc"],
    categories: [],
    // document: "",
    email: "",
    user_ref: "",
    current_thesis_content: {
      method: 'upload_document_method', //ocr_method, text_editor_method, upload_document_method
      content: '',
      url: '',
    },
  },
  thesis_text_editor_current_value: JSON.parse(sessionStorage.getItem("thesis_text_editor_current_value")) || '',
}

const thesisContextReducer = (state, action) => {
  // console.log('fired switch')
  // console.log(state);
switch (action.type) {
  case "UPDATE_CURRENT_THESIS":
      // console.log('fired login start')
      return {
          ...state,
          current_thesis: {
            ...state.current_thesis,
            ...action.payload
          }
      }
  case "SET_CURRENT_THESIS_CONTENT":
    return {
        ...state,
        current_thesis: {
          ...state.current_thesis,
          current_thesis_content: {
            ...state.current_thesis.current_thesis_content,
            content: action.payload
          }
        }
        // sidebar_enabled: !state.sidebar_enabled
    }
  case "SET_THESIS_EDITOR_CURRENT_VALUE":
    return {
        ...state,
        thesis_text_editor_current_value: action.payload
        // sidebar_enabled: !state.sidebar_enabled
    }
  case "SET_CONTENT_APPEND_METHOD":
    // console.log('fired shit', action.payload)
    return {
        ...state,
        current_thesis:{
          ...state.current_thesis,
          current_thesis_content: {
            ...state.current_thesis_content,
            method: action.payload
          }
        }
        // sidebar_enabled: !state.sidebar_enabled
    }


  default:
        return state;
    
}
}


export const CreateThesisContext = createContext(INITIAL_THESIS_STATE)

export const CreateThesisProvider = ({children}) => {
  const [state, dispatch] = useReducer(thesisContextReducer, INITIAL_THESIS_STATE)

  useEffect(() => {
    sessionStorage.setItem("thesis_text_editor_current_value", JSON.stringify(state.thesis_text_editor_current_value))
  

    // console.log('hi there')
      // (state.current_thesis.current_thesis_content.method ==='text_editor_method' && state.current_thesis.current_thesis_content.content) || ''
      // state.thesis_text_editor_current_value
    // ))
}, [state])

  return (
    <CreateThesisContext.Provider
    value={{
        current_thesis: state.current_thesis,
        thesis_text_editor_current_value: state.thesis_text_editor_current_value,
        dispatch
    }}>
        {children}
    </CreateThesisContext.Provider>
  )
}

export const useCreateThesisContext = () => {
  return useContext(CreateThesisContext)
}

//context ends

const CreateThesisAddDocumentComponent= () => {
  UseHideSidebar()
  const navigate = useNavigate()

  const {dispatch:dispatchCreateThesis} = useCreateThesisContext()
  const fileInputRef = useRef(null);
  const INITIAL_FILE_STATE = {
    fileItself: {},
    name: 'no document provided',
    size: 0
  }
  const [uploadedDocument, setUploadedDocument] = useState({
    fileItself: {},
    name: 'no document provided',
    size: 0
  })
  // console.log(uploadedDocument)
  // console.log(uploadedDocument.fileItself)

  const handleFileChange = (event) => {
    const formatFileSize = (bytes) => {
      if (bytes < 1024) {
        return bytes + ' B';
      } else if (bytes < 1024 * 1024) {
        return (bytes / 1024).toFixed(2) + ' KB';
      } else if (bytes < 1024 * 1024 * 1024) {
        return (bytes / (1024 * 1024)).toFixed(2) + ' MB';
      } else {
        return (bytes / (1024 * 1024 * 1024)).toFixed(2) + ' GB';
      }
    };
    setUploadedDocument((prev) => ({
      fileItself: event.target?.files[0],
      name: event.target.files[0]?.name,
      size: formatFileSize(event.target.files[0]?.size)
    }))

    dispatchCreateThesis({
      type: 'SET_CURRENT_THESIS_CONTENT',
      payload: event.target?.files[0] || ''
    })
  }

  const removeLoadedFile = () => {
    setUploadedDocument(INITIAL_FILE_STATE)
    // Reset the input by setting its value to an empty string
    fileInputRef.current.value = '';
    // Manually trigger the 'change' event
    fileInputRef.current.dispatchEvent(new Event('change', { bubbles: true }));
  }

  const wanna_use_file = uploadedDocument.fileItself?.name ? 'Do you want to use this file?' : 'Do you want to add a file?'

  const openUploadedDocumentPreview = (uploaded_document) => {
    if (uploaded_document) {
      // console.log(URL.createObjectURL(uploaded_document))
      // window.open(URL.createObjectURL(uploaded_document), '_blank');

      const dataToPass = URL.createObjectURL(uploaded_document)

      const modifiedUrl = `${CLIENT_LINKS.document_preview.url}?createThesisPageLocalPdfFromBlob=` + encodeURIComponent(dataToPass || '');

      // const modifiedUrl = `${CLIENT_LINKS.document_preview.url}?createThesisPageLocalPdfFromBlob=` + encodeURIComponent('');

      window.open(modifiedUrl, '_blank');

      // navigate(CLIENT_LINKS.document_preview)

      // wind(`${CLIENT_LINKS.document_preview.url}${current_thesis.current_thesis_content.content}`, '_blank');
    }
    else{
      return alert('Please select or upload a document first')
    }
  };

  return(
    <div className='create-thesis-add-document-component'>

      <div className='create-thesis-add-document-component-wrapper'>
        <div className='add-research-middle'>
          <div className='add-research-middle-inner'>
            <div className='add-research-middle-inner-prompt'>
              {wanna_use_file}
            </div>
            {
            uploadedDocument.fileItself?.name &&
            <div className='add-research-uploaded-document'>
              <div className='add-research-uploaded-document-left'>
                <span className='add-research-uploaded-document-left-icon-container'><Description className='add-research-uploaded-document-left-icon-container-icon' fontSize='1rem'/></span>
              </div>
              <div className='add-research-uploaded-document-right'>
                <div className='add-research-uploaded-document-rt-name'> {textLengthShortened(uploadedDocument?.name, 40, undefined )}</div>
                <div className='add-research-uploaded-document-rb'>
                  <div className='add-research-uploaded-document-rbl-size'>{uploadedDocument?.size}
                  <span style={{paddingLeft: '0.5rem'}}>∙</span>
                  </div>
                  <div 
                  style={{cursor: 'pointer'}}
                  onClick={()=>openUploadedDocumentPreview(uploadedDocument.fileItself)}
                  className='add-research-uploaded-document-rbl-preview'>Preview
                  <span style={{paddingLeft: '0.5rem'}}>∙</span>
                  </div>
                  <div 
                  style={{cursor: 'pointer'}}
                  onClick={()=>removeLoadedFile()}
                  className='add-research-uploaded-document-rbr-remove'>Remove</div>
                </div>
              </div>
            </div>
            }
            <input 
            ref={fileInputRef}
            type='file' 
            id='add-research-input-file' 
            style={{display: 'none'}} 
            onChange={(e) => handleFileChange(e)}
            accept=".pdf"
            />
            
            {
            !uploadedDocument.fileItself?.name &&
            <label htmlFor='add-research-input-file' className='add-research-middle-inner-select-file'>
              <span className='add-research-middle-inner-select-file-icon-container'>
                <FileUploadOutlined fontSize='1rem'/> 
              </span>
              <span>
                Select and upload file 
              </span>
            </label>
            }

            {
            !true &&
            <div className='add-research-middle-inner-skip-section'>

              <div className='add-research-middle-inner-skip-section-right'>
                {
                uploadedDocument.fileItself?.name &&
                <div className='add-research-middle-inner-skip-section-right-go'>Upload file</div>
                }
              </div>
            </div>
            }

          </div>
        </div>
      </div>
    </div>
  )
}

const CreateThesisTextEditorComponent = ({passed_initial_content}) => {
  // const [quillContent, setQuillContent] = useState('')

  const {dispatch: dispatchCreateThesis, current_thesis, thesis_text_editor_current_value} = useCreateThesisContext()
  // console.log(current_thesis)

  const handleAddThesisContent = (val) => {
    // console.log(val)
    dispatchCreateThesis({
      type: 'SET_CURRENT_THESIS_CONTENT',
      payload: val
    })
    dispatchCreateThesis({
      type: "SET_THESIS_EDITOR_CURRENT_VALUE",
      payload: val
    })

  }

  useEffect(() => {
    //makes the toolbar of the quill component to become fixed on scrolling below

    const handleScroll = () => {
      const stickyDiv = document.getElementsByClassName('ql-toolbar ql-snow');
      const rect = stickyDiv[0].getBoundingClientRect();
      const stickyDivTop = rect.top;
      // console.log(stickyDivTop)
      // console.log(rect)


      const formPart1Div = document.getElementsByClassName('form-part-1');
      const rect2 = formPart1Div[0].getBoundingClientRect()
      const formPart1DivTop = rect2.top
      // console.log(rect2)
      //stickyDiv has a fixed height

      if (stickyDivTop <= 0  ) {
        // console.log('DIVTOP HIT 0000')
    stickyDiv[0].classList.add('quill-toolbar-fixed');
    // && formPart1DivTop > -120
      } 
      if (stickyDivTop <=0 && formPart1DivTop > -600) {
        //adjust this height by fixing the form1 section height// 
        // console.log('DIVTOP GREATER THAN > ZERO')
    stickyDiv[0].classList.remove('quill-toolbar-fixed');

      }
      else{
        // console.log('nayi')
      }


    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, );

  const initial_content = passed_initial_content || thesis_text_editor_current_value || ''

  // console.log(initial_content)
  // console.log(passed_initial_content)
  // console.log(typeof(passed_initial_content))

  return (
    <div 
          style={{
            // display: contentAppend.text_editor_method ? 'block' : 'none'
          }}
          className='form-part-2'>
            <div className='quill-content-section'>
              <label className='quill-content-section-label'>Content</label>
              <ReactQuill 
              theme='snow' 
              modules={modules}   
              onChange={handleAddThesisContent}  
              // onChange={(e) => handleChangeNewThesis(e)}
              // name="content"
              // defaultValue={initial_content}
              // defaultValue={JSON.stringify(initial_content)}
              defaultValue={`${initial_content}`}
              // defaultValue={passed_initial_content}
              // defaultValue={"abcdef"}
              // defaultValue={"<div>abcdef</div>"}
              // value={<p>rrrrrr</p>}
              placeholder="Type or paste your thesis in this box..."  />
            </div>
          </div>
  )
}

const user = {
  priviledge_level: 'author' || 'admin' || 'viewer' ,
  first_name: "Moboluwarin",
  last_name: "Jibola-Shittu",
  full_name: "Moboluwarin Jibola-Shittu",
  username: "Moboluwarin1",
  email: "jibolashittubolu@gmail.com",
  institution: "",
  institution_abbreviation: "",
  saved_theses: [/*list of theses*/],
  verified: false,
  disabled: false,
  matric_id: ""
}

const thesis_static = {
  _id: "abc123zzz",
  title: "Development of an Electronic Repository for Undergraduate Theses",
  author:"Moboluwarin Eyisojumi Jibola-Shittu",
  matric_id: "CSC/2016/063",
  department: "The Department of Computer Science and Engineering",
  faculty:"Faculty of Technology",
  institution: "Obafemi Awolowo University",
  institution_abbreviation: "OAU",
  year: new Date().getFullYear(),
  supervisor: "Dr T.O Omodunbi",
  abstract: "The study concluded something good",
  keywords: ["thesis", "repository", "undergraduate", "theses"],
  categories: [],
  // content: [],
  content: "",

  // content_updates: [], //up to 3 versions of content
  // content_update_needed: false, // when you approve, set content to the latest content_updates.slice(-1)[0] and set this to true and clear the content_updates 
}

const  modules  = {
  toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script:  "sub" }, { script:  "super" }],
      ["blockquote", "code-block"],
      [{ list:  "ordered" }, { list:  "bullet" }],
      [{ indent:  "-1" }, { indent:  "+1" }, { align: [] }],
      ["link", "image", "video"],
      ["clean"],
  ],
};


















const CreateThesisButton = () => {
  const navigate = useNavigate()
  // const {dispatch: dispatchTheses}  = useTheses()
  const {dispatch: dispatchCreateThesis, current_thesis} = useCreateThesisContext()

  const [interfaceState, setInterfaceState] = useState({
    snackbar_visible: false,
    snackbar_message: "",
    snackbar_severity: "info",
    snackbar_autohide_duration: 5000,
    button_disabled : false,
    draggableDialogVisible: false,
    manageFullTextDialogVisible: false,
    isSpinnerActive: false,
    // confirmation_prompt: false
    // confirmation_prompt: false //just use a different state so you can use useeffect of this state, instead of typing each property
  })

  const toggleInterfaceVisibility = (name) => {
    setInterfaceState((prev) => ({ ...prev,  [name]: !prev[name]  }))
  }
  const showSnackbar = () => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_visible: true  }))
  }
  const hideSnackbar = () => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_visible: false  }))
  }
  const setSnackbarMessage = (message) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_message: message  }))
  }
  const setSnackbarSeverity = (severity) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_severity: severity  }))
  }
  const setSnackbarDuration = ( duration) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_autohide_duration: duration  }))
  }
  const disableButton = () => {
    setInterfaceState((prev) => ({ ...prev,  button_disabled: true  }))
  }
  const enableButton = (name, value_passed) => {
    setInterfaceState((prev) => ({ ...prev,  button_disabled: false  }))
  }
  const alertSnackbar = (message="hello, user", type="info", duration=9999999) => {
    setSnackbarMessage(message)
    setSnackbarSeverity(type)
    setSnackbarDuration(duration)
    showSnackbar()
  }
  const showSpinner = (val = true) => {
    setInterfaceState((prev) => ({ ...prev,  isSpinnerActive: true  }))
  }
  const hideSpinner = (val = true) => {
    setInterfaceState((prev) => ({ ...prev,  isSpinnerActive: false  }))
  }

  const handleSubmitFinal = async (e) => {
    // console.log(current_thesis.current_thesis_content)

    e.preventDefault()
    disableButton()

    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_URL
    })
    
    const newThesisData = {
      ...current_thesis
    }

    const hold_file_in_case_of_problems = current_thesis?.current_thesis_content?.content

    try{
      disableButton()
              
      if(true){
        //just for collapsing

        if(!current_thesis.title){
          //no file or text
          return alertSnackbar('Please supply a title', 'error')
        }
        if(!current_thesis.author){
          //no file or text
          return alertSnackbar('Please supply an author. You must log in to do this', 'error')
        }
        if(!current_thesis.email){
          //no file or text
          return alertSnackbar('Please supply an email. You must log in to this', 'error')
        }
        if(!current_thesis.year){
          //no file or text
          return alertSnackbar('Please supply a year', 'error')
        }
        if(!current_thesis.supervisor){
          //no file or text
          return alertSnackbar('Please select a supervisor', 'error')
        }
        if(!current_thesis.abstract){
          //no file or text
          return alertSnackbar('Please add an abstract. An abstract is a summary of your work usually < 250 words', 'error')
        }
        if(current_thesis.keywords?.length < 1 ){
          //no file or text
          return alertSnackbar('Please add at least one keyword. Use the add keyword button to add a keyword', 'error')

          // return alertSnackbar('Please add at least one keyword', 'error')
        }
        if(!(current_thesis.keywords?.length < 8 ) ){
          //no file or text
          return alertSnackbar('Please use at most 7 keywords', 'error')
        }

        if(current_thesis.categories.length < 1){
          //no file or text
          return alertSnackbar('Please select at least one category', 'error')
        }
        if(!current_thesis?.current_thesis_content?.content){
          //no file or text
          return alertSnackbar('Please upload a file or use the text editor box', 'error')
        }



      }
      if(true){
        const {title, author, abstract, email, matric_id, department, faculty, institution, institution_abbreviation, year, supervisor, categories, keywords} = current_thesis

        if(true){
          //compulsories
          const {isValid: isValidTitle }= validateAlphanumericTitleBlockV1_Util(title)
          if(!isValidTitle){
            return alertSnackbar('The title you supplied is not valid', 'error')
          }

          const {isValid: isValidAuthor }= validateFullNameV2_Util(author)
          if(!isValidAuthor){
            return alertSnackbar('The author you supplied is not valid', 'error')
          }

          const {isValid: isValidEmail }= validateEmail_Util(email)
          if(!isValidEmail){
            return alertSnackbar('The email you supplied is not valid', 'error')
          }

          const yearValidityCheck2Passed = parseInt(year) > 1960 //the max check is in the regex for current year
          const {isValid }= validateThesisYearV1_Util(year)
    
          if(!isValid || !yearValidityCheck2Passed){
            return alertSnackbar('The year you supplied is not valid', 'error')
          }


        }





        //optionals
        if(!matric_id){
          const {isValid }= validateMatricIDV1_Util(matric_id)
          if(!isValid){
            return alertSnackbar('The matric ID you supplied is not valid', 'error')
          }

        }
        if(department){
          const {isValid }= validateAlphabeticTitleBlockV1_Util(department)
          if(!isValid){
            return alertSnackbar('The department you supplied is not valid', 'error')
          }

        }
        if(faculty){
          const {isValid }= validateAlphabeticTitleBlockV1_Util(faculty)
          if(!isValid){
            return alertSnackbar('The department you supplied is not valid', 'error')
          }

        }
        if(institution){
          const {isValid }= validateAlphabeticTitleBlockV1_Util(institution)
          if(!isValid){
            return alertSnackbar('The institution you supplied is not valid', 'error')

          }

        }
        if(institution_abbreviation){
          const {isValid }= validateSingleName_Util(institution_abbreviation)
          if(!isValid){
            return alertSnackbar('The institution abbreviation you supplied is not valid', 'error')

          }

        }

      }


        showSpinner()
        // upload file if exists //this should be the last thing after all checks are completed
        if (current_thesis.current_thesis_content.method==="upload_document_method"){
          
          const file = current_thesis.current_thesis_content.content
          //file is an upload file from an input type of type file

          if(!file?.name){
            return alertSnackbar('Please reupload the document')
            // return console.log('Please reupload the document')
          }
          if (!(file instanceof File) || !file?.name) {
            return alertSnackbar('Please reupload the document');
          }
          const data = new FormData();
          // const filename = Date.now() + file.name;
          // const filename = uuidv4() ;
          const filename =  uuidv4() + file.name //file.name format must be preserved

          data.append("name", filename);
          //the above corresponds to the req.body.name
          data.append("file", file);
          //the above corresponds to upload.single("file") in the router post method of the backend
          newThesisData.current_thesis_content.url = filename

          //overwrite the current_thesis.current_thesis_content.content field
          newThesisData.current_thesis_content.content = filename
          
          console.log(newThesisData)
          
          // return ''
          try{
            await axiosInstance.post('/upload/upload_thesis', data, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            })
          }
          catch(error){
            console.error('error')
            return alertSnackbar('Error occurred while uploading file');
  
          }
           

        }

        //if text editor, upload text
        if (current_thesis.current_thesis_content.method==="text_editor_method"){
          if(current_thesis.current_thesis_content.content === '<p><br></p>'){
            return alertSnackbar("Please upload a file or use the text editor box")

          }
          newThesisData.current_thesis_content.url = ''

        }

        console.log(newThesisData)

        newThesisData.user_ref = user?._id || '' //add the ref. non standard

        // const res = await axios.post("/login", {email, password})
        const res = await axiosInstance.post('/theses/create_thesis', newThesisData)

        navigate(`${CLIENT_LINKS.view_thesis.url}/${res.data.message._id}`)
    }
    catch(error){
        console.error(error) //this red is disturbing me. 
        setSnackbarMessage( `${error.response?.data?.message ||  'An error occurred' }`)
        setSnackbarSeverity('error')
        showSnackbar()
        

        //because we have a useEffect that updates/changes things, on newThesis change
        //it is imperative to hold the file and reset the file if an issue occurs along the way
        // newThesisData.current_thesis_content.content = hold_file_in_case_of_problems
        if(newThesisData?.current_thesis_content?.content){
          newThesisData.current_thesis_content.content = hold_file_in_case_of_problems
        }
        dispatchCreateThesis({
          type: "UPDATE_CURRENT_THESIS",
          payload: newThesisData
        })



    }
    finally{
      enableButton()
      hideSpinner()
      // SetIsButtonDisabled(false)
      // console.log(current_thesis.current_thesis_content)
    }
  }


  return (
    <div 
      className='form-part-4-button-create-thesis'>
        <ReusableSnackbarComponent
        severity={interfaceState.snackbar_severity}
        open={interfaceState.snackbar_visible}  
        inner_message={interfaceState.snackbar_message} 
        setOpen={() => toggleInterfaceVisibility('snackbar_visible')}
        autoHideDuration={ interfaceState.snackbar_autohide_duration || 2000}/>
        <div style={{height: '0.5rem'}}>
          <LinearProgress sx={{padding: 0, margin: 0, display: interfaceState.isSpinnerActive ? true: 'none'}}/>
        </div>
        <button 
        disabled={interfaceState.button_disabled}
        // onClick={()=>handleCreateThesis()}
        onClick={(e)=>handleSubmitFinal(e)}
        className='button-create-thesis'>Submit Thesis</button>
    </div>
  )
}



















const CreateThesisPage = () => {
  UseHideSidebar()

  const navigate = useNavigate()
  
  const {user} = useAuth()

  const user_privilege_levels = user?.privilege_levels
  const isAdmin = checkIfUserHasPrivilegeLevelFor_Util(authorizedPrivilegeLevelsThatCanAdmin_Util, user_privilege_levels )
    //handles the interface
    const [interfaceState, setInterfaceState] = useState({
      snackbar_visible: false,
      snackbar_message: "",
      snackbar_severity: "info",
      snackbar_autohide_duration: 2000,
      button_disabled : false,
      confirmationDialogVisible: false
      // confirmation_prompt: false //just use a different state so you can use useeffect of this state, instead of typing each property
    })
  
    const showSnackbar = (val = true) => {
      setInterfaceState((prev) => ({ ...prev,  snackbar_visible: true  }))
    }
    const hideSnackbar = (val = true) => {
      setInterfaceState((prev) => ({ ...prev,  snackbar_visible: false  }))
    }
    const toggleInterfaceVisibility = (name) => {
      setInterfaceState((prev) => ({ ...prev,  [name]: !prev[name]  }))
    }
    const setSnackbarMessage = (message) => {
      setInterfaceState((prev) => ({ ...prev,  snackbar_message: message  }))
    }
    const setSnackbarSeverity = (severity) => {
      setInterfaceState((prev) => ({ ...prev,  snackbar_severity: severity  }))
    }
    const setSnackbarDuration = ( duration) => {
      setInterfaceState((prev) => ({ ...prev,  snackbar_autohide_duration: duration  }))
    }
    const handleInterfaceState = (name, value_passed) => {
      setInterfaceState((prev) => ({ ...prev,  [name]: value_passed  }))
    }
  
    const disableButton = () => {
      setInterfaceState((prev) => ({ ...prev,  button_disabled: true  }))
    }
    const enableButton = (name, value_passed) => {
      setInterfaceState((prev) => ({ ...prev,  button_disabled: false  }))
    }
    const alertSnackbar = (message="hello, user", type="info", duration=9999999)=> {
      setSnackbarMessage(message)
      setSnackbarSeverity(type)
      setSnackbarDuration(duration)
      showSnackbar()
    }
  
  

  // UseNotifyIfUserIsNotAuth()
  
  const {dispatch: dispatchTheses}  = useTheses()
  const {dispatch: dispatchCreateThesis, current_thesis} = useCreateThesisContext()
  // console.log(current_thesis.current_thesis_content)
  // console.log(current_thesis)

  const [newThesis, setNewThesis] = useState({
    user_ref: isAdmin ? '' : (user?._id || ''),
    title: "",
    author: isAdmin ? '' : (((user?.first_name && (user?.first_name + ' ')) || '') + ( (user?.last_name ) || '') || '') ,
    matric_id: isAdmin ? '' : (user?.matric_id || ''),
    department: user?.department || 'The Department of Computer Science and Engineering',
    faculty: user?.faculty || 'Faculty of Technology',
    institution: user?.institution || 'Obafemi Awolowo University',
    institution_abbreviation: user?.institution_abbreviation || 'OAU',
    year: new Date().getFullYear(),
    supervisor: "",
    abstract: "",
    keywords: [],
    email: isAdmin ? '' : (user?.email || ''),
    categories: [],
    pdfMainContentStartsAt: 1
  })

  // console.log(newThesis)
  // console.log(current_thesis)

  // const {theses, dispatch: dispatchTheses} = useTheses()

  const handleSubmit = (e) => {
    e.preventDefault()
  }

  // console.log(quillContent)

  // console.log(theses)
  const handleChangeNewThesis = (e) => {

    setNewThesis(prev => (
      {
        ...prev,
        [e.target.name]: e.target.value
      }
    ))
  }

  // console.log(newThesis)


  const [keywording, setKeywording] = useState({
    keyword: '',
    keywords: []
  })

  const handleChangeKeyword = (e) => {
    // setKeyword(e.target.value)
    setKeywording(prev => ({...prev, keyword: e.target.value}))
    // console.log(keyword)
  }

  const handleAddKeywordContainingCommas = (commaDelimitedKeywords) => {
    const commaDelimitedKeywordsToAdd = [...new Set(commaDelimitedKeywords.split(',').map(word => word.trim()) ) ]
    // const commaDelimitedKeywordsToAdd = [...new Set(commaDelimitedKeywords.split(',')) ]
    let savedKeywordsInput = [...commaDelimitedKeywordsToAdd]

    commaDelimitedKeywordsToAddLoop:
    for (let commaDelimitedKeyword of commaDelimitedKeywordsToAdd){
      if(!(current_thesis.keywords?.length < 7 )){
        return alertSnackbar('Sorry. You are not allowed to add more than 7 keywords. You have 7 keywords already attached')
      }
      if(commaDelimitedKeyword.trim()?.length > 25){
        console.log('no from here')
        alertSnackbar(`Keyword cannot exceed 40 characters. The keyword you supplied: ${commaDelimitedKeyword.trim()} has ${commaDelimitedKeyword.trim()?.length} character(s)`)
        // break
        continue commaDelimitedKeywordsToAddLoop

      }

      if(!keywording.keyword){
        // savedKeywordsInput = savedKeywordsInput.filter(keyword => keyword !== commaDelimitedKeyword)
        alertSnackbar('Please add a keyword')
        continue commaDelimitedKeywordsToAddLoop

      }
      // console.log(keywording.keyword)

      const isWordAlreadyIncluded = () => {
        for (let keyword of keywording.keywords){
          if( keyword.toLowerCase().trim() === commaDelimitedKeyword.toLowerCase().trim() ){
            return true //true is bad
          }
        }
        return false
      }

      const isInputEmpty = () => {
        if( commaDelimitedKeyword.toLowerCase().trim().length < 1 ){
          //i
          return true //true is bad
        }
        return false
      }


      if(isWordAlreadyIncluded()  ){
        // console.log('nay1')

        savedKeywordsInput = savedKeywordsInput.filter(keyword => keyword !== commaDelimitedKeyword)
        setKeywording(prev => ({...prev, keyword: savedKeywordsInput.join(',') }))
        continue commaDelimitedKeywordsToAddLoop
      }

      if(isInputEmpty()){
        // console.log('nay2')
        // setKeywording(prev => ({...prev, keyword: ''}))
        savedKeywordsInput = savedKeywordsInput.filter(keyword => keyword !== commaDelimitedKeyword)
        setKeywording(prev => ({...prev, keyword: savedKeywordsInput.join(',') }))
        continue commaDelimitedKeywordsToAddLoop

      }

      setKeywording((prev) => 
        (
          {...prev, keywords: [...prev.keywords, commaDelimitedKeyword.toLowerCase().trim() ]}
        )
      )

      savedKeywordsInput = savedKeywordsInput.filter(keyword => keyword !== commaDelimitedKeyword)
      setKeywording(prev => ({...prev, keyword: savedKeywordsInput.join(',') }))

    }

    if(savedKeywordsInput.length <= 0){

      return  setKeywording(prev => ({...prev, keyword: '' }))

    }
    // console.log(savedKeywordsInput.join(','))

    //else if something is still there
    // setKeywording(prev => ({...prev, keyword: 'abc' }))
    // setKeywording(prev => ({...prev, keyword: savedKeywordsInput.toString() }))
    setKeywording(prev => ({...prev, keyword: savedKeywordsInput.join(',') }))
    // console.log(savedKeywordsInput.join(','))

    
  }

  // console.log(current_thesis.keywords.length)
  const handleAddKeyword = (e) => {
// console.log('FIRE')

    if(!(current_thesis.keywords?.length < 7 )){
      return alertSnackbar('Sorry. You are not allowed to add more than 7 keywords')
    }

    if(keywording?.keyword?.trim()?.split(',').length > 1){
      return handleAddKeywordContainingCommas(keywording.keyword)
    }

    if(keywording?.keyword?.trim()?.length > 25){
      console.log('from here')
      return alertSnackbar(`Keyword cannot exceed 40 characters. The keyword you supplied: ${keywording?.keyword} has ${keywording?.keyword?.trim()?.length} character(s)`)
    }


    if(!keywording.keyword){
      return alertSnackbar('Please add a keyword')
    }
    // console.log(keywording.keyword)

    const isWordAlreadyIncluded = () => {
      for (let keyword of keywording.keywords){
        if( keyword.toLowerCase().trim() === keywording.keyword.toLowerCase().trim() ){
          return true //true is bad
        }
      }
      return false
    }

    const isInputEmpty = () => {
      if( keywording.keyword.toLowerCase().trim().length < 1 ){
        //i
        return true //true is bad
      }
      return false
    }


    if(isWordAlreadyIncluded()  ){
      // console.log('nay1')
      return setKeywording(prev => ({...prev, keyword: ''}))
    }

    if(isInputEmpty()){
      // console.log('nay2')
      return setKeywording(prev => ({...prev, keyword: ''}))
    }

    setKeywording((prev) => 
      (
        {...prev, keywords: [...prev.keywords, keywording.keyword.toLowerCase().trim() ]}
      )
    )
    setKeywording(prev => ({...prev, keyword: ''}))

  }



  const handleEnterKeywordPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleAddKeyword();
    }
  }

  const handleDeleteKeyword = (keyword_to_delete) => {
    setKeywording(prev => ({...prev, keywords:keywording.keywords.filter(keyword => keyword !== keyword_to_delete) }))
  }


  // //update add keyword
  // useEffect(() => {
  //   setNewThesis(prev => ({
  //     ...prev,
  //     content: quillContent
  //   }))
  // }, [quillContent]);

  //update quill content in state
  useEffect(() => {
    setNewThesis(prev => ({
      ...prev,
      keywords: keywording.keywords
    }))
    //set the input to empty,ie clear it
    // setKeywording(prev => ({...prev, keyword: ''}))
  }, [keywording.keywords]);


  //update context whenever internal state changes
  useEffect(() => {
    dispatchCreateThesis({
      type: "UPDATE_CURRENT_THESIS",
      payload: newThesis
    })
  }, [newThesis]);


  // console.log(newThesis.keywords)

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL
  })



  const [contentAppend, setContentAppend] = useState({
    ocr_method: false,
    text_editor_method: false,
    upload_document_method: true
  })

  const handleContentAppendMethod = (name) => {
    // console.log(name)
    dispatchCreateThesis({
      type: 'SET_CONTENT_APPEND_METHOD',
      payload: name
    })

    setContentAppend(prev => ({
      ocr_method: false,
      text_editor_method: false,
      upload_document_method: false,
      [name]: true
    }))
    window.scrollTo({
      top: window.scrollY + 500,  // Scroll down by 100 pixels
      behavior: 'smooth',         // Optional: Add smooth scrolling animation
    });
  }
  // console.log(contentAppend)


  
  const dull_color = '#a2a2a2'
  const dull_bg_color = 'white'
  // const bright_color = 'white'
  const bright_color= 'black'
  const bright_bg_color = 'black'
  const dull_border_width= "1px"
  const bright_border_width= "2px"


  const fetchSupervisorList = async () => {
    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_URL
    })

    const response = await axiosInstance.get('/supervisor/find_all_supervisor');
    // console.log(response.data.message)
    return response.data.message;
  };
  const { 
    data:fetched_supervisor_list,
    isFetching: isFetchingSupervisorList,
    isLoading: isLoadingSupervisorList,
    error: errorFetchingSuperviosrList
    
  } = useQuery({
    queryKey: ['supervisorList'],
    queryFn: () =>
      fetchSupervisorList()
  }
  )

  useEffect(() => {

    const alertError = () => {

      if(errorFetchingSuperviosrList && !fetched_supervisor_list && !isFetchingSupervisorList && !isLoadingSupervisorList ){
        const error = errorFetchingSuperviosrList
        // setSnackbarDuration(60000)
        alertSnackbar(`${error?.response?.data?.message || 'Unable to fetch supervisor list. An errror occurred'}`, 'error')
      }
      if(!user?._id){
        alertSnackbar(`Please login. You are not authenticated`, 'error')
      }
    }
    alertError()
  }, [errorFetchingSuperviosrList, fetched_supervisor_list, isFetchingSupervisorList, isLoadingSupervisorList])

  // console.log(fetched_supervisor_list)
  // console.table(newThesis)
  // console.log(current_thesis.current_thesis_content)

  const defVals = {
    name: (user?.first_name + user?.last_name) || '',
    email: user?.email || '',
    matric: user?.matric_id || '',
    department: user?.department || '',
    faculty: user?.faculty || '',
    institution: user?.institution || '',
    institution_abbreviation: user?.institution_abbreviation || '',
    year: new Date().getFullYear(),

  }

  const [clipbboardTextFromOcr, setClipbboardTextFromOcr] = useState('')

  useEffect(() => {
    //read the value from text editor
    const readClipboard = async () => {
      try {
        const clipboardData = await navigator.clipboard.readText();
        // setClipboardText(clipboardData);
        if(clipboardData){
          // console.log(clipboardData)
          setClipbboardTextFromOcr(clipboardData)
          handleContentAppendMethod('text_editor_method')
          scrollToBottomUtil()

        }
      } 
      catch (error) {
        console.error('Failed to read clipboard: ', error);
      }
    };

    readClipboard();
  }, []);


  const location = useLocation()
  // console.log(location)
  // const initial_content = 'hi'

  const [textFromOcrRouter, setTextFromOcrRouter] = useState('')
  useEffect(() => {
    //read the value from location
    const readTextFromOcrRouter = async () => {
      try {
        console.log('EFFECT OF ROUTRER')

        if(location?.state.passed_quill_ocr_content){
          console.log('EFFECT OF ROUTRER 22222')

          const text = location.state.passed_quill_ocr_content
          setTextFromOcrRouter(text)
          handleContentAppendMethod('text_editor_method')
          scrollToBottomUtil()

        }
      } 
      catch (error) {
        console.error('Failed to read clipboard: ', error);
      }
    };

    readTextFromOcrRouter()
  }, []);


  const initial_text_editor_content = textFromOcrRouter || clipbboardTextFromOcr

  // console.log(initial_text_editor_content)

  const fetchAllCategories = async () => {
    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_URL
    })

    const response = await axiosInstance.get('/category/find_all_category');
    // console.log(response.data.message)
    return response.data.message;
  };
  const { data:fetched_category_list } = useQuery({
    queryKey: ['categoryList'],
    queryFn: () =>
      fetchAllCategories() 
  })

  // console.log(current_thesis.current_thesis_content)
  // const[]
  const handleCategories = async(category) => {
    try{
      //check if category in array, if in array, remove it
      const is_category_present = newThesis?.categories.includes(category)

      if(is_category_present){
        console.log(category, ': IS PRESENT')
        //remove the category from the array
        const modified_category = newThesis?.categories.filter(category_item => category !== category_item)
        // console.log(modified_category.length)

        setNewThesis(prev => ({...prev, categories: modified_category }))
        return ''
      }

      //if category not in array, add it
      setNewThesis(prev => ({...prev, categories:[...prev.categories, category] }))
    }
    catch(error){
      console.log(error)
      console.log('an error occurred when handling category')
    }

  }
  // console.log(newThesis.categories)
  // console.log(newThesis.categories.length)



  const provideInputInfo = (name) => {
    // const {name, value} = e.target

    if(name === "email"){
      const requirements = 
      <>
        <div>Email must contain @  </div>
        <div> Email cannot contain spaces</div>
      </>

      alertSnackbar(requirements)
      
      return ''
    }
    if(name === "passwordOldInput"){
      const requirements = 
      <>
      <div>Password must be at least 8 characters in length </div>
      <div> Password must contain at least one digit </div>
      <div>Password must contain at least one lowercase letter</div>
      <div>Password must contain at least one uppercase letter </div>
      <div>Password and confirm password must match </div>
      </>
      
      alertSnackbar(requirements)


      return ''
    }

    if(name === "passwordNewInput"){
      const requirements = 
      <>
      <div>Password and confirm password must match </div>
      <div>Confirm password must be at least 8 characters in length </div>
      <div> Confirm password must contain at least one digit </div>
      <div>Confirm password must contain at least one lowercase letter</div>
      <div>Confirm password must contain at least one uppercase letter </div>
      </>
      
      alertSnackbar(requirements)


      return ''
    }

    if(name === "passwordNewInputConfirm"){
      const requirements = 
      <>
      <div>Password and confirm password must match </div>
      <div>Confirm password must be at least 8 characters in length </div>
      <div> Confirm password must contain at least one digit </div>
      <div>Confirm password must contain at least one lowercase letter</div>
      <div>Confirm password must contain at least one uppercase letter </div>
      </>
      
      alertSnackbar(requirements)


      return ''
    }

    if(name === "keywords"){
      const requirements = 
      <>
      <div>You must select at least one keyword</div>
      <div>A single keyword must not exceed 25 characters </div>
      <div>You can add as many as 7 different keywords</div>
      <div>You can either choose to enter your keywords and separate them with comma notation</div>
      <div>Or you can enter each keyword separately, ensure you click the add keyword button to attach your keyword(s) in any case  </div>
      </>
      
      alertSnackbar(requirements)
      // alertSnackbar("Sorry")


      return ''
    }

    if(name === "abstract"){
      const requirements = 
      <>
      <div>You must provide an abstract</div>
      <div>An abstract is a short summary of your work </div>
      <div>Must contain at least 7 words</div>
      </>
      
      alertSnackbar(requirements)
      // alertSnackbar("Sorry")


      return ''
    }



    
  }

  const provideInputFeedback = (name) => {
    const goodInput =
    <span 
      style={{cursor: 'pointer', color: 'green', display:'flex', alignItems:'center', justifyContent: 'center', fontSize: '0.8rem', width: '5%' }}
      className='register-page-form-inner-wrapper-group-2-a-icon-2'><CheckCircle fontSize='1rem'/> 
    </span>

    const grayInput = 
    <span 
    style={{cursor: 'pointer', color: '#cac8c8', display:'flex', alignItems:'center', fontSize: '0.8rem', width: '5%' }}
    onClick={()=> provideInputInfo(name) }
    className='register-page-form-inner-wrapper-group-2-a-icon-2'><InfoOutlined fontSize='1rem'/> </span>

    const badInput = 
    <span 
    style={{cursor: 'pointer', color: 'tomato', display:'flex', alignItems:'center', justifyContent: 'center', fontSize: '0.8rem', width: '5%' }}
    onClick={()=> provideInputInfo(name) }
    className='register-page-form-inner-wrapper-group-2-a-icon-2'><InfoOutlined fontSize='1rem'/> </span>

    const value = current_thesis[name]

    
    if(name === "title"){
      const {isValid }= validateAlphanumericTitleBlockV1_Util(value)
      if(isValid){
          return goodInput
      }
      return  badInput
    }

    if(name === "author"){
      //no need to check it is uneditable
      const {isValid }= validateFullNameV2_Util(value)
      if(isValid){
          return goodInput
      }
      return  badInput
    }

    if(name === "email"){
      // no need to check. It is not editable and fetched from ls
      const {isValid }= validateEmail_Util(value)
      if(isValid){
          return goodInput
      }
      return  badInput
    }

    if(name === "matric_id"){
      const isPopulated = current_thesis?.matric_id
      if(isPopulated){
        const {isValid }= validateMatricIDV1_Util(value)
        if(isValid){
          return goodInput
        }
        return  badInput
      }
      return grayInput
    }

    if(name === "department"){
      const isPopulated = current_thesis?.department

      if(isPopulated){

        const {isValid }= validateAlphabeticTitleBlockV1_Util(value)
        if(isValid){
          return goodInput
        }
        return  badInput
      }
      return grayInput
    }

    if(name === "faculty"){
      const isPopulated = current_thesis?.faculty
      if(isPopulated){

        const {isValid }= validateAlphabeticTitleBlockV1_Util(value)
        if(isValid){
          return goodInput
        }
        return  badInput
      }
      return grayInput
    }

    if(name === "institution"){
      const isPopulated = current_thesis?.institution
      if(isPopulated){
        const {isValid }= validateAlphabeticTitleBlockV1_Util(value)
        if(isValid){
          return goodInput
        }
        return  badInput
      }
      return grayInput
    }
    if(name === "institution_abbreviation"){
      const isPopulated = current_thesis?.institution_abbreviation
      if(isPopulated){
        const {isValid }= validateSingleName_Util(value)
        if(isValid){
          return goodInput
        }
        return  badInput
      }
      return grayInput
    }


    //the below have not been checked
    //these checks also need to be done in the button itself before axios.method
    if(name === "year"){
      const yearValidityCheck2Passed = parseInt(value) > 1950
      const {isValid }= validateThesisYearV1_Util(value)

      if(isValid && yearValidityCheck2Passed){
          return goodInput
      }
      return  badInput
    }

    if(name === "supervisor"){
      // const {isValid }= validateAlph(value)
      const isValid = current_thesis?.supervisor
      if(isValid){
          return goodInput
      }
      return  badInput
    }
    if(name === "categories"){
      const isValid = current_thesis?.categories?.length > 0
      //no destructuring here
      if(isValid){
          return goodInput
      }
      return  badInput
    }
    if(name === "abstract"){
      // const {isValid }= validateSingleName_Util(value)
      const abstractLength = current_thesis?.abstract?.toString().trim().split(' ').length
      const isValid = abstractLength && (abstractLength > 9 && abstractLength < 1000)  
      //length must be at least 25 words
      if(isValid){
          return goodInput
      }
      return  badInput
    }
    if(name === "keywords"){
      const isValid = current_thesis?.keywords?.length > 0
      if(isValid){
          return goodInput
        }
      // return goodInput
      return  badInput
    }


    
    //default
    return grayInput

  }


  

  return (
    <>
    <div className='create-thesis-page'>
      <ReusableSnackbarComponent 
      severity={interfaceState.snackbar_severity}
      open={interfaceState.snackbar_visible}  
      inner_message={interfaceState.snackbar_message} 
      setOpen={() => toggleInterfaceVisibility('snackbar_visible')}
      autoHideDuration={ interfaceState.snackbar_autohide_duration || 2000}/>
      <div className='add-research-top'>
          <div className='add-research-top-inner'>
            <span className='add-research-top-inner-icon-container' ><CreateNewFolderOutlined fontSize='1rem'/></span>
            <span 
            className='add-research-top-inner-text'>Add research to your profile</span>
          </div>
      </div>
      <div className='create-thesis-page-wrapper'>
        <div className='create-thesis-page-form-sections'>
          <div className='form-part-1'>
            
            {/* the className form-part-1 is crucial */}
            <div className='create-thesis-page-form-item'>
              <div style={{display: 'flex'}}>
                <label>Thesis Title</label>
                {provideInputFeedback("title")}
              </div>
              <input 
              onChange={(e) => handleChangeNewThesis(e)}
              className='create-thesis-page-input' type='text' name="title"/>
            </div>
            <div  className='create-thesis-page-form-item'>
              <div style={{display: 'flex'}}>
                <label>Author</label>
                {provideInputFeedback("author")}
              </div>
              <input 
              style={{cursor: isAdmin ? 'auto' : 'not-allowed'}}
              disabled={
                isAdmin ? false :true
              }
              onChange={(e) => handleChangeNewThesis(e)}
              className='create-thesis-page-input' type='text' name="author" 
              defaultValue={
                isAdmin ? '' :
                (current_thesis?.author || "") } />
            </div>
            <div  className='create-thesis-page-form-item'>
              <div style={{display: 'flex'}}>
                <label>Email</label>
                {provideInputFeedback("email")}
              </div>
              <input 
              style={{cursor: isAdmin ? 'auto' : 'not-allowed'}}
              disabled={
                isAdmin ? false :true
              }
              onChange={(e) => handleChangeNewThesis(e)}
              className='create-thesis-page-input' 
              type='email' name="email" 
              defaultValue={
                isAdmin ? '' :
                (current_thesis?.email || "")
              } />
            </div>
            <div  className='create-thesis-page-form-item'>
              <div style={{display: 'flex'}}>
                <label>Matric</label>
                {provideInputFeedback("matric_id")}
              </div>
              <input 
              onChange={(e) => handleChangeNewThesis(e)}
              className='create-thesis-page-input' type='text' name="matric_id" defaultValue={current_thesis?.matric_number || ''}/>
            </div>
            <div  className='create-thesis-page-form-item'>
              <div style={{display: 'flex'}}>
                <label>Department</label>
                {provideInputFeedback("department")}
              </div>
              <input 
              onChange={(e) => handleChangeNewThesis(e)}
              className='create-thesis-page-input' type='text' name="department" defaultValue={current_thesis?.department || ''}/>
            </div>
            <div  className='create-thesis-page-form-item'>
              <div style={{display: 'flex'}}>
                <label>Faculty</label>
                {provideInputFeedback("faculty")}
              </div>
              <input 
              onChange={(e) => handleChangeNewThesis(e)}
              className='create-thesis-page-input' type='text' name="faculty" defaultValue={current_thesis?.faculty || ''}/>
            </div>
            <div  className='create-thesis-page-form-item'>
              <div style={{display: 'flex'}}>
                <label>Institution</label>
                {provideInputFeedback("institution")}

              </div>
              <input 
              onChange={(e) => handleChangeNewThesis(e)}
              className='create-thesis-page-input' type='text' name="institution" defaultValue={current_thesis?.institution || ''}/>
            </div>
            <div  className='create-thesis-page-form-item'>
              <div style={{display: 'flex'}}>
                <label>Institution Abbreviation</label>
                {provideInputFeedback("institution_abbreviation")}


              </div>
              <input 
              onChange={(e) => handleChangeNewThesis(e)}
              className='create-thesis-page-input' type='text' name="institution_abbreviation" defaultValue={current_thesis?.institution_abbreviation || ''}/>
            </div>
            <div  className='create-thesis-page-form-item'>
              <div style={{display: 'flex'}}>
                <label>Year</label>
                {provideInputFeedback("year")}

              </div>
              <input 
              onChange={(e) => handleChangeNewThesis(e)}
              className='create-thesis-page-input'
              type='number' 
              name="year" defaultValue={new Date().getFullYear()}
              max={new Date().getFullYear()}
              min={1}
              />
            </div>
            <div className='create-thesis-page-form-item'>
              <div style={{display: 'flex'}}>
                  <label htmlFor="supervisor">Supervisor:</label>
                   {provideInputFeedback("supervisor")}


              </div>
              <select
                  id="supervisor"
                  name="supervisor"
                  // value={selectedSupervisor}
                  onChange={(e) => handleChangeNewThesis(e)}
                  required>
                  <option value="">Select a Supervisor</option>
                  {fetched_supervisor_list?.map((supervisor, index) => (
                      <option key={supervisor?._id || index} value={supervisor._id}>
                          {supervisor.supervisor_title} {supervisor.supervisor_name}
                      </option>
                  ))}
              </select>
            </div>
            <div className='create-thesis-page-form-item'>
              <div style={{display: 'flex'}}>
                  <label htmlFor="category">Categories(multiple allowed):</label>
                  {provideInputFeedback("categories")}

              </div>
                <div 
                className='create-thesis-page-category'
                >
                  <div 
                  className='create-thesis-page-category-inner'
                  >
                    {
                    fetched_category_list?.length > 0 &&
                    fetched_category_list.map((category_item, index) => 
                    <div 
                    key={category_item?._id || index}
                    onClick={() => handleCategories(category_item._id)}
                    // className='create-thesis-page-category-inner-item' 
                    className={
                      newThesis?.categories.includes(category_item._id) ?
                      'create-thesis-page-category-inner-item active': 
                      'create-thesis-page-category-inner-item' 
                    }
                    >
                      <span className='ctpcii-span'>
                        {category_item.category}
                      </span>
                      <span className='ctpcii-span'>
                        {
                        !newThesis?.categories.includes(category_item._id) &&
                        <Add fontSize='1rem' />
                        }
                        {
                        newThesis?.categories.includes(category_item._id) &&
                        <Check fontSize='1rem' />
                        }
                      </span>
                      {/* {category._id} */}
                    </div>
                    )
                    }
                    </div>
                </div>
                
            </div>
            <div  className='create-thesis-page-form-item'>
              <div style={{display: 'flex'}}>
                <label>Abstract/Summary</label>
              {provideInputFeedback("abstract")}

              </div>
              <textarea
              onChange={(e) => handleChangeNewThesis(e)}
              name="abstract"
              placeholder='Type or paste your abstract in this box...minimum of 10 words'
               className='create-thesis-page-form-item-textarea'
              // cols={}
              rows={5}
              />
            </div>
          </div>
          <div className='form-part-1-choose-option'>
              <div className='form-part-1-choose-option-head'>
                Fill in the content of your thesis using any of the following options
              </div>
              <div 
              className='form-part-1-choose-option-body'>
                {/* ocr */}
                <div className='form-part-1-choose-option-item'>
                  <div 
                  //  onClick={() => handleContentAppendMethod('ocr_method')}
                    onClick={() => {
                    handleContentAppendMethod('text_editor_method');
                    navigate(CLIENT_LINKS.ocr_module.url)
                  }
                  }
                  className='form-part-1-choose-option-item-inner'
                  style={{
                    borderColor: contentAppend.ocr_method ? bright_color : dull_color ,
                    color: contentAppend.ocr_method ? bright_color : dull_color ,
                    borderWidth: contentAppend.ocr_method ? bright_border_width : dull_border_width
                    // backgroundColor: contentAppend.ocr_method ? bright_bg_color: dull_bg_color
                  }}>
                    or Extract text from images (recommended for scanned hardcopies)
                    {
                      contentAppend.ocr_method &&
                      <Check />
                    }
                  </div>
                  {
                  !contentAppend.ocr_method &&
                  <button onClick={() => {
                    handleContentAppendMethod('ocr_method');
                    navigate(CLIENT_LINKS.ocr_module.url)
                  } } >
                      use
                  </button>
                  }
                </div>
                {/* text editor */}
                <div className='form-part-1-choose-option-item'>
                  <div 
                  onClick={() => handleContentAppendMethod('text_editor_method')}
                  className='form-part-1-choose-option-item-inner'
                  style={{
                    borderColor: contentAppend.text_editor_method ? bright_color : dull_color ,
                    color: contentAppend.text_editor_method ? bright_color : dull_color 
                  }}>

                    Open text editor

                    {
                      contentAppend.text_editor_method &&
                      <Check />
                    }
                  </div>
                  {
                  !contentAppend.text_editor_method &&
                  <button onClick={() => handleContentAppendMethod('text_editor_method')} >
                      use
                  </button>
                  }
                </div>
                {/* upload doc */}
                <div className='form-part-1-choose-option-item'>
                  <div 
                  onClick={() => handleContentAppendMethod('upload_document_method')}
                  className='form-part-1-choose-option-item-inner'
                  style={{
                    borderColor: contentAppend.upload_document_method ? bright_color : dull_color ,
                    color: contentAppend.upload_document_method ? bright_color : dull_color 
                  }}>                      
                    Upload pdf file
                    {
                      contentAppend.upload_document_method &&
                      <Check />
                    }
                  </div>
                  {
                  !contentAppend.upload_document_method &&
                  <button onClick={() => handleContentAppendMethod('upload_document_method')} >
                      use
                  </button>
                  }
                </div>
              </div>
          </div>
          {
          contentAppend.upload_document_method && 
          <CreateThesisAddDocumentComponent />
          }
          {
          contentAppend.upload_document_method && 
          <div  className='create-thesis-page-form-item'>
            <label>Main Writeup begins on what page?  (i.e Chapter 1 appears on what page of the uploaded file?)</label>
            <input 
            onChange={(e) => handleChangeNewThesis(e)}
            className='create-thesis-page-input' 
            type='number' 
            min={1}
            name="pdfMainContentStartsAt" 
            />
          </div>
          }
          {
          contentAppend.text_editor_method &&
          <CreateThesisTextEditorComponent passed_initial_content={initial_text_editor_content}/>
          //dynamic display * differs from &&
          }


          {/* keywords section */}
          <div className='form-part-3 keyword-section'>
            <div style={{display: 'flex'}}>
              <label className='keyword-section-label'>Keywords (max 7)</label>
              {provideInputFeedback("keywords")}

            </div>
            <div className='keyword-section-input'>
              <input 
              onKeyDown={handleEnterKeywordPress}
              onChange={(e) => handleChangeKeyword(e)}
              value={keywording.keyword}
              className='keyword-section-input-box' type='text' name="keywords" 
              placeholder="type keyword e.g database"/>
              <button 
              onClick={() => handleAddKeyword()}
              className='keyword-section-button'>Add keyword</button>
            </div>
            <div className='current-keywords'>
              {
              // keywording.keywords.length > 0 &&
              // <span className='current-keywords-text'>Current Keywords:</span>
              }
              <span className='keyword-items'>
                {newThesis.keywords.map((keyword, index) => (
                  <div key={index} 
                  className='keyword-items-wrapper'
                  onClick={() => handleDeleteKeyword(keyword)}>
                    <span className='keyword-item' >{keyword}</span>
                    <span className='keyword-item-delete'>
                      <DeleteOutline 
                      fontSize='1rem'
                     />
                    </span>
                  </div>
                ))}
              </span>
            </div>

          </div>

          {/* create thesis button*/}
          <CreateThesisButton />


        </div>
      </div>
    </div>
    </>
  )
}


const CreateThesisPagePlusContext = () => {

  return (
    // <CreateThesisProvider>
    //not persisted
      <CreateThesisPage />
    // </CreateThesisProvider>
  )
}



export default CreateThesisPagePlusContext
// export default CreateThesisAddDocumentComponent