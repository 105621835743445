import { createContext, useContext, useEffect, useReducer, useRef, useState } from 'react'
import { useAuth } from '../../../contexts/AuthContext'
import './updateThesisPage.scss'
// import './createThesisAddDocumentComponent.scss'
// import './'
import { v4 as uuidv4 } from 'uuid';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useTheses } from '../../../contexts/ThesesContext';
// import 'react-quill/dist/quill.bubble.css 
import ReactModal from 'react-modal';
// import OcrModule from './OcrModulePage/OcrModule';
import HomePage from '../../home/HomePage';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Add, Cancel, Check, CheckCircle, CreateNewFolderOutlined, Description, FileUpload, FileUploadOutlined, InfoOutlined, Refresh, Remove, UploadFileOutlined } from '@mui/icons-material';
import axios from 'axios';
import { useNavConsiderPrevRoute } from '../../../hooks/useNavConsiderPrevRoute';
import { UseHideSidebar } from '../../../hooks/UseSidebarVisibility';
import { authorizedPrivilegeLevelsThatCanAdmin_Util, checkIfUserHasPrivilegeLevelFor_Util, document_append_methods, document_append_methods_util, objectIsPopulated, textLengthShortened } from '../../../utils/Utils';

import {  scrollToBottomUtil,  validateAlphanumericTitleBlockV1_Util, validateSingleName_Util, validateMatricIDV1_Util, validateAlphabeticTitleBlockV1_Util, validateEmail_Util, validateThesisYearV1_Util, validateFullNameV2_Util } from '../../../utils/Utils';

import { CLIENT_LINKS } from '../../../utils/LINKS';
import { useQuery } from '@tanstack/react-query';
import ReusableSnackbarComponent from '../../../components/reusables/ReusableSnackbarComponent/ReusableSnackbarComponent';
import { LinearProgress } from '@mui/material';
import { upload } from '@testing-library/user-event/dist/upload';

const sample_thesis = {
  title: "",
  author:"",
  matric_id: "",
  category: [],
  department: "The Department of Computer Science and Engineering",
  faculty:"Faculty of Technology",
  institution: "Obafemi Awolowo University",
  institution_abbreviation: "OAU",
  year: new Date().getFullYear(),
  supervisor: "Dr T.O Omodunbi",
  abstract: "The study concluded something good",
  keywords: ["thesis", "repository", "undergraduate", "theses"],
  // content: [],
  content: {
    type: 'ocr',
    content: ''
  },

  // content_updates: [], //up to 3 versions of content
  // content_update_needed: false, // when you approve, set content to the latest content_updates.slice(-1)[0] and set this to true and clear the content_updates 
}

//context starts

const INITIAL_THESIS_STATE = {
  email: "",
  title: "",
  author:"",
  matric_id: "",
  categories: [],
  department: sample_thesis.department,
  faculty: sample_thesis.faculty,
  institution:sample_thesis.institution,
  institution_abbreviation: sample_thesis.institution_abbreviation,
  year: new Date().getFullYear(),
  supervisor: "",
  abstract: "",
  keywords: ["abc"],
  current_thesis_content: {
    method: 'upload_document_method', //ocr_method, text_editor_method, upload_document_method

    // pdfContent: "",
    // newPdfContent: false,
    // serverPdfContent: "", //store this, we will use it as default on reset. nothing more
    // serverTextContent: "", //store this, we will use it as default on reset nothing more

    content: '', 
    url: '',
  },
  thesisTextEditorValue: JSON.parse(sessionStorage.getItem("thesisTextEditorValue")) || '',
  // newThesisTextEditorValue: false,
  uploadedDocument: {
      // fileItself: "",
      name: "",
      size: 0,
      type: "",
      lastModified: "",
      lastModifiedDate: "",
      webkitRelativePath: ""
  },
  // newUploadedDocument: false,
  defaultThesisFromServer: ""
}

const thesisContextReducer = (state, action) => {
  // console.log('fired switch')
  // console.log(state);
switch (action.type) {
  case "updateThesis":
      // console.log('fired login start')
      return {
          ...state,
          ...action.payload
      }
  case "updateCurrentThesisContent":
    return {
        ...state,
        current_thesis_content: {
          ...state.current_thesis_content,
          serverPdfContent: "",
          serverTextContent: ""
          // url: 
        }
    }
  case "updateCurrentThesisContentOtherValues":
    //not in full use yet
    return {
        ...state,
        current_thesis_content: {
          ...state.current_thesis_content,
          // content: action.payload,
          // url: 
        }
   }
  case "updateCurrentThesisContentMethod":
    return {
        ...state,
        current_thesis_content: {
          ...state.current_thesis_content,
          method: action.payload
        }
    }
  case "setThesisTextEditorValue":
    return {
        ...state,
        thesisTextEditorValue: action.payload
    }

  case "setUploadedDocument":
    // console.log('UPLOADIND DOCUMENT')
    return {
        ...state,
        uploadedDocument: action.payload
  }
  case "setDefaultThesisFromServer":
    return {
        ...state,
        defaultThesisFromServer: action.payload
  }
  default:
        return state;
    
}
}


export const UpdateThesisContext = createContext(INITIAL_THESIS_STATE)


export const UpdateThesisProvider = ({children}) => {
  const [state, dispatch] = useReducer(thesisContextReducer, INITIAL_THESIS_STATE)

  useEffect(() => {
    sessionStorage.setItem("thesisTextEditorValue", JSON.stringify(state.thesisTextEditorValue))

}, [state.thesisTextEditorValue])

  return (
    <UpdateThesisContext.Provider
    value={{
        thesis: state,
        thesisTextEditorValue: state.thesisTextEditorValue,
        uploadedDocument: state.uploadedDocument,
        defaultThesisFromServer: state.defaultThesisFromServer,
        dispatch
    }}>
        {children}
    </UpdateThesisContext.Provider>
  )
}




export const useUpdateThesisContext = () => {
  return useContext(UpdateThesisContext)
}

//context ends








const UpdateThesisAddDocumentComponent= () => {
  UseHideSidebar()
  // const {id} = useParams

  const {dispatch:dispatchUpdateThesis, thesis, uploadedDocument, defaultThesisFromServer} = useUpdateThesisContext()
  const fileInputRef = useRef(null);
  const INITIAL_FILE_STATE = {
    // fileItself: "",
    name: '',
    size: 0,
    type: "",
    lastModified: "",
    lastModifiedDate: "",
    webkitRelativePath: ""
}

  

  const [componentState, setComponentState] = useState({
    useServerDocument: true,
    useNewDocument: false
    // useServerDocument: false,
    // useNewDocument: true
  }) 
  const formatFileSize = (bytes) => {
    if (bytes < 1024) {
      return bytes + ' B';
    } else if (bytes < 1024 * 1024) {
      return (bytes / 1024).toFixed(2) + ' KB';
    } else if (bytes < 1024 * 1024 * 1024) {
      return (bytes / (1024 * 1024)).toFixed(2) + ' MB';
    } else {
      return (bytes / (1024 * 1024 * 1024)).toFixed(2) + ' GB';
    }
  };
  // console.log(componentState)

  //know if we have an existing server document or to use a new upload
 
  

  const removeLoadedFile = () => {
    dispatchUpdateThesis({
      type: "setUploadedDocument",
      payload: INITIAL_FILE_STATE
    })
    // Reset the input by setting its value to an empty string
    fileInputRef.current.value = '';
    // Manually trigger the 'change' event
    fileInputRef.current.dispatchEvent(new Event('change', { bubbles: true }));

  }

  const handleFileChange = (event) => {
    // console.log('file change')
    // removeLoadedFile()
    setComponentState(prev => ({
      useNewDocument: true,
      useServerDocument: false //this is the magic for this page
    }))
    
    dispatchUpdateThesis({
      type: "setUploadedDocument",
      payload: event.target?.files[0]
    })
    
    //   size: formatFileSize(event.target?.files[0]?.size || '')
  }

  // useEffect(() => {
  //   if(uploadedDocument.size > 0){

  //     dispatchCreateThesis({
  //       type: 'SET_thesis_CONTENT',
  //       // payload: event.target?.files[0]?.name //should be this, but to use this, we must modify our handleSubmitFinal
  //       payload: uploadedDocument?.fileItself
  //     })
  //   }
    
  // }, [uploadedDocument])
  

  const openUploadedDocumentPreview = (uploaded_document) => {
    if (uploaded_document) {
      // console.log(URL.createObjectURL(uploaded_document))
      // window.open(URL.createObjectURL(uploaded_document), '_blank');

      const dataToPass = URL.createObjectURL(uploaded_document)

      const modifiedUrl = `${CLIENT_LINKS.document_preview.url}?updateThesisPageLocalPdfFromBlob=` + encodeURIComponent(dataToPass || '');

      // const modifiedUrl = `${CLIENT_LINKS.document_preview.url}?createThesisPageLocalPdfFromBlob=` + encodeURIComponent('');

      window.open(modifiedUrl, '_blank');
    }
    else{
      return alert('Please select or upload a document first')
    }
  };

  const openPreviewOfServerDocument = () => {
    if (true) {

      const modifiedUrl = `${CLIENT_LINKS.document_preview.url}?updateThesisPageServerPdfLink=${defaultThesisFromServer?.current_thesis_content?.content}&thesisId=${thesis?._id || ''}`;

      window.open(modifiedUrl, '_blank');
    }
    else{
      return alert('Please select or upload a document first')
    }
  };

  const removeServerDocument = () => {
    console.log('REMOVED')

    // dispatchUpdateThesis({
    //   type: 'SET_THESIS_CONTENT',
    //   payload: ''
    // })
    //no, i dont want us to touch the original content

    //when we fetch from the server, if pdf, lets set the uploaded document to some values. or not
    //lets keep it solely for new uploads

    // dispatchUpdateThesis({
    //   type: "setUploadedDocument",
    //   payload: ""
    // })
    

    setComponentState(prev => ({
      useNewDocument: true,
      useServerDocument: false
    }))


  }


  const toggleServerDocumentOrNew = () => {

    // if(componentState.useNewDocument){

    //  return  setComponentState(prev => ({...prev, useNewDocument: false, useServerDocument: false}))
    // }

    const atLeastOneIsActive = componentState.useNewDocument || componentState.useServerDocument

    if(atLeastOneIsActive){
      return  setComponentState(prev => ({...prev, useNewDocument: !prev.useNewDocument, useServerDocument: !prev.useServerDocument}))
    }

    return  setComponentState(prev => ({...prev, useNewDocument: true, useServerDocument: !prev.useServerDocument}))

  }

  useEffect(() => {
    const checkThings  = () => {
      if(!componentState?.useServerDocument){
        return ''
      }
      //below means only if useServerDocument


    }

    checkThings()
  }, [componentState])
  

  //if we are using server document, show the defaulthesis

  // console.log(thesis)
  // console.log(componentState)
  return(
    <div className='create-thesis-add-document-component'>
      <div className='create-thesis-add-document-component-wrapper'>
        <div className='add-research-middle'>
          <div 
          onClick={toggleServerDocumentOrNew}
          style={{ fontSize:'0.85rem', position:'absolute', top:'0.5rem', right:'0.5rem', display:'flex', alignItems:'center', color:'gray'}}>
            <span 
            // onClick={()=>fetchPdfModified()}
            style={{lineHeight:'1rem', display:'flex', alignItems: 'center', justifyContent:'flex-end', width:'100%', fontSize:'0.85rem', cursor: 'pointer'}}>
              Reset
            </span>
            <span 
            // onClick={()=>fetchPdfModified()}
            style={{lineHeight:'1rem', display:'flex', alignItems: 'center', justifyContent:'flex-end', width:'100%', fontSize:'0.85rem', cursor: 'pointer'}}>
              <Refresh fontSize='1rem' />
            </span>
          </div>
          <div className='add-research-middle-inner'>
            
            <div className='add-research-middle-inner-prompt'>
              {componentState.useNewDocument && 'Do you want to use a new file'}
              {componentState.useServerDocument && 'Do you want to use a new file ?'}
            </div>

            {
            // For newly uploaded document
            uploadedDocument?.name &&
            <div className='add-research-uploaded-document'>
              <div className='add-research-uploaded-document-left'>
                <span className='add-research-uploaded-document-left-icon-container'><Description className='add-research-uploaded-document-left-icon-container-icon' fontSize='1rem'/></span>
              </div>
              <div className='add-research-uploaded-document-right'>
                <div className='add-research-uploaded-document-rt-name'> {textLengthShortened(uploadedDocument?.name, 40, undefined )}</div>
                <div className='add-research-uploaded-document-rb'>
                  <div className='add-research-uploaded-document-rbl-size'> 
                    {/* {uploadedDocument?.size} */}
                    { formatFileSize(uploadedDocument?.size || '')}
                    <span style={{paddingLeft: '0.5rem'}}>∙</span>
                  </div>
                  {/* <div>∙</div> */}
                  <div 
                  onClick={()=>openUploadedDocumentPreview(uploadedDocument)}
                  style={{cursor:'pointer'}}
                  className='add-research-uploaded-document-rbl-preview'>Preview
                  <span style={{paddingLeft: '0.5rem'}}>∙</span>
                  </div>
                  {/* <div>∙</div> */}
                  <div 
                  onClick={()=>removeLoadedFile()}
                  className='add-research-uploaded-document-rbr-remove'>
                    Remove
                  </div>
                </div>
              </div>
            </div>
            }
            {
            // For already existing server document
            // ( thesis.current_thesis_content.method === "upload_document_method" && thesis.current_thesis_content ) &&
            (componentState.useServerDocument && defaultThesisFromServer?.current_thesis_content?.url  )  &&
            //if no url, it is  most likely not a server document
            <div className='add-research-uploaded-document'>
              <div className='add-research-uploaded-document-left'>
                <span className='add-research-uploaded-document-left-icon-container'><Description className='add-research-uploaded-document-left-icon-container-icon' fontSize='1rem'/></span>
              </div>
              <div className='add-research-uploaded-document-right'>
                <div className='add-research-uploaded-document-rt-name'> 
                  {
                  textLengthShortened(defaultThesisFromServer?.current_thesis_content?.content, 50, undefined)
                  // thesis?.current_thesis_content.url
                  }
                </div>
                <div className='add-research-uploaded-document-rb'>
                  <div 
                  onClick={()=>openPreviewOfServerDocument()}
                  style={{cursor:'pointer'}}
                  className='add-research-uploaded-document-rbl-preview'>Preview</div>
                  <div>∙</div>
                  <div 
                  onClick={()=>removeServerDocument()}
                  className='add-research-uploaded-document-rbr-remove'>Remove</div>
                </div>
              </div>
            </div>
            }
            <input 
            ref={fileInputRef}
            type='file' 
            id='add-research-input-file' 
            style={{display: 'none'}} 
            onChange={(e) => handleFileChange(e)}
            accept=".pdf"
            />
            {
            //use new document but you havent uploaded anything
            (componentState?.useNewDocument && !uploadedDocument?.name) &&
            // (componentState.useNewDocument ) &&
            <label 
            htmlFor='add-research-input-file' 
            className='add-research-middle-inner-select-file'>
              <span className='add-research-middle-inner-select-file-icon-container'>
                <FileUploadOutlined fontSize='1rem'/> 
              </span>
              <span>
                Select and upload file 
              </span>
            </label>
            }
            {
            //use new document but you havent uploaded anything
            (componentState.useServerDocument && !uploadedDocument?.name) &&
            // (componentState.useNewDocument ) &&
            <label 
            htmlFor='add-research-input-file' 
            className='add-research-middle-inner-select-file'>
              <span className='add-research-middle-inner-select-file-icon-container'>
                <FileUploadOutlined fontSize='1rem'/> 
              </span>
              <span>
                Select and upload file 
              </span>
            </label>
            }

            {
            //irrelevant
            !true &&
            <div className='add-research-middle-inner-skip-section'>

              <div className='add-research-middle-inner-skip-section-right'>
                {
                uploadedDocument.fileItself?.name &&
                <div className='add-research-middle-inner-skip-section-right-go'>Upload file</div>
                }
              </div>
            </div>
            }

          </div>
        </div>
      </div>
    </div>
  )
}










const UpdateThesisTextEditorComponent = () => {
  // const [quillContent, setQuillContent] = useState('')

  const {dispatch: dispatchUpdateThesis, thesis, thesisTextEditorValue} = useUpdateThesisContext()
  // console.log(thesis)

  const handleAddThesisContent = (val) => {
    // console.log(val)
    dispatchUpdateThesis({
      type: "setThesisTextEditorValue",
      payload: val
    })
    // dispatchUpdateThesis({
    //   type: "SET_THESIS_EDITOR_CURRENT_VALUE",
    //   payload: val
    // })
  }


  

  useEffect(() => {
    //makes the toolbar of the quill component to become fixed on scrolling below

    const handleScroll = () => {
      const stickyDiv = document.getElementsByClassName('ql-toolbar ql-snow');
      const rect = stickyDiv[0].getBoundingClientRect();
      const stickyDivTop = rect.top;
      // console.log(stickyDivTop)
      // console.log(rect)


      const formPart1Div = document.getElementsByClassName('form-part-1');
      const rect2 = formPart1Div[0].getBoundingClientRect()
      const formPart1DivTop = rect2.top
      // console.log(rect2)
      //stickyDiv has a fixed height

      if (stickyDivTop <= 0  ) {
        // console.log('DIVTOP HIT 0000')
    stickyDiv[0].classList.add('quill-toolbar-fixed');
    // && formPart1DivTop > -120
      } 
      if (stickyDivTop <=0 && formPart1DivTop > -600) {
        //adjust this height by fixing the form1 section height// 
        // console.log('DIVTOP GREATER THAN > ZERO')
    stickyDiv[0].classList.remove('quill-toolbar-fixed');

      }
      else{
        // console.log('nayi')
      }


    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, );


  
  useEffect(() => {
    
  }, [])
  

  
  return (
    <div 
          style={{
            // display: contentAppend.text_editor_method ? 'block' : 'none'
          }}
          className='form-part-2'>
            <div className='quill-content-section'>
              <label className='quill-content-section-label'>Content</label>
              <ReactQuill 
              theme='snow' 
              modules={modules}   
              onChange={handleAddThesisContent}  
              // onChange={(e) => handleChangeNewThesis(e)}
              // name="content"
              // defaultValue={thesis_text_editor_current_value}
              defaultValue={thesis.current_thesis_content.method === document_append_methods_util.text_editor_method ? `${thesisTextEditorValue}` : ''}
              placeholder="Type or paste your thesis in this box..."  />
            </div>
          </div>
  )
}

const  modules  = {
  toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script:  "sub" }, { script:  "super" }],
      ["blockquote", "code-block"],
      [{ list:  "ordered" }, { list:  "bullet" }],
      [{ indent:  "-1" }, { indent:  "+1" }, { align: [] }],
      ["link", "image", "video"],
      ["clean"],
  ],
};








const UpdateThesisButton = () => {
  const navigate = useNavigate()
  const {dispatch: dispatchUpdateThesis, thesis, uploadedDocument, thesisTextEditorValue, defaultThesisFromServer} = useUpdateThesisContext()

  // console.log(thesis)
  // console.warn(uploadedDocument)
  
  const [interfaceState, setInterfaceState] = useState({
    snackbar_visible: false,
    snackbar_message: "",
    snackbar_severity: "info",
    snackbar_autohide_duration: 5000,
    button_disabled : false,
    draggableDialogVisible: false,
    manageFullTextDialogVisible: false,
    isSpinnerActive: false,
    // confirmation_prompt: false
    // confirmation_prompt: false //just use a different state so you can use usefect of this state, instead of typing each property
  })

  const toggleInterfaceVisibility = (name) => {
    setInterfaceState((prev) => ({ ...prev,  [name]: !prev[name]  }))
  }
  const showSnackbar = () => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_visible: true  }))
  }
  const hideSnackbar = () => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_visible: false  }))
  }
  const setSnackbarMessage = (message) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_message: message  }))
  }
  const setSnackbarSeverity = (severity) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_severity: severity  }))
  }
  const setSnackbarDuration = ( duration) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_autohide_duration: duration  }))
  }
  const disableButton = () => {
    setInterfaceState((prev) => ({ ...prev,  button_disabled: true  }))
  }
  const enableButton = (name, value_passed) => {
    setInterfaceState((prev) => ({ ...prev,  button_disabled: false  }))
  }
  const alertSnackbar = (message="hello, user", type="info", duration=9999999) => {
    setSnackbarMessage(message)
    setSnackbarSeverity(type)
    setSnackbarDuration(duration)
    showSnackbar()
  }
  const showSpinner = (val = true) => {
    setInterfaceState((prev) => ({ ...prev,  isSpinnerActive: true  }))
  }
  const hideSpinner = (val = true) => {
    setInterfaceState((prev) => ({ ...prev,  isSpinnerActive: false  }))
  }


  // console.log(thesis)
  const handleSubmitFinal = async (e) => {

    try{
      e.preventDefault()

      const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL
      })
      
      const newThesisData = {
        ...thesis,
        uploadedDocument
      }
      // console.log('hello')
      // console.log(thesis)
      // SetIsButtonDisabled(true)
      disableButton()

      


        if(true){
          //just for collapsing

          if(!thesis.current_thesis_content.content){
            //no file or text
            setSnackbarMessage('Please upload a file or use the text editor box')
            setSnackbarSeverity('error')
            showSnackbar()
            return ''
          }
          if(!thesis.title){
            //no file or text
            setSnackbarMessage('Please supply a title')
            setSnackbarSeverity('error')
            showSnackbar()
            return ''
          }
          if(!thesis.author){
            //no file or text
            setSnackbarMessage('Please supply an author')
            setSnackbarSeverity('error')
            showSnackbar()
            return ''
          }
          if(!thesis.supervisor){
            //no file or text
            setSnackbarMessage('Please select a supervisor')
            setSnackbarSeverity('error')
            showSnackbar()
            return ''
          }
          if(!thesis.abstract){
            //no file or text
            setSnackbarMessage('Please supply an abstract. An abstract is a short body of text that summarizes your thesis')
            setSnackbarSeverity('error')
            showSnackbar()
            return ''
          }
          if(thesis.categories.length < 1){
            //no file or text
            return alertSnackbar('Please select at least one category', 'error')
          }
          if(thesis.keywords?.length < 1 ){
            //no file or text
            // setSnackbarMessage('Please add at least 1 keyword')
            // setSnackbarSeverity('error')
            // showSnackbar()
            // return ''
            return alertSnackbar('Please add at least one keyword. Use the add keyword button to add a keyword', 'error')
            
          }
          if(!(thesis.keywords?.length < 8 ) ){
            //no file or text
            setSnackbarMessage('Please supply at most 7 keywords')
            setSnackbarSeverity('error')
            showSnackbar()
            return ''
          }
          if(!thesis.email){
            //no file or text
            setSnackbarMessage('Please supply a email ')
            setSnackbarSeverity('error')
            showSnackbar()
            return ''
          }
  
  
  
        }
        if(true){
          const {title, author, abstract, email, matric_id, department, faculty, institution, institution_abbreviation, year, supervisor, categories, keywords} = thesis
  
          if(true){
            //compulsories
            const {isValid: isValidTitle }= validateAlphanumericTitleBlockV1_Util(title)
            if(!isValidTitle){
              return alertSnackbar('The title you supplied is not valid', 'error')
            }
  
            const {isValid: isValidAuthor }= validateFullNameV2_Util(author)
            if(!isValidAuthor){
              return alertSnackbar('The author you supplied is not valid', 'error')
            }
  
            const {isValid: isValidEmail }= validateEmail_Util(email)
            if(!isValidEmail){
              return alertSnackbar('The email you supplied is not valid', 'error')
            }
  
            const yearValidityCheck2Passed = parseInt(year) > 1960 //the max check is in the regex for current year
            const {isValid }= validateThesisYearV1_Util(year)
      
            if(!isValid || !yearValidityCheck2Passed){
              return alertSnackbar('The year you supplied is not valid', 'error')
            }
  
  
          }
  
  
  
  
  
          //optionals
          if(!matric_id){
            const {isValid }= validateMatricIDV1_Util(matric_id)
            if(!isValid){
              return alertSnackbar('The matric ID you supplied is not valid', 'error')
            }
  
          }
          if(department){
            const {isValid }= validateAlphabeticTitleBlockV1_Util(department)
            if(!isValid){
              return alertSnackbar('The department you supplied is not valid', 'error')
            }
  
          }
          if(faculty){
            const {isValid }= validateAlphabeticTitleBlockV1_Util(faculty)
            if(!isValid){
              return alertSnackbar('The department you supplied is not valid', 'error')
            }
  
          }
          if(institution){
            const {isValid }= validateAlphabeticTitleBlockV1_Util(institution)
            if(!isValid){
              return alertSnackbar('The institution you supplied is not valid', 'error')
  
            }
  
          }
          if(institution_abbreviation){
            const {isValid }= validateSingleName_Util(institution_abbreviation)
            if(!isValid){
              return alertSnackbar('The institution abbreviation you supplied is not valid', 'error')
  
            }
  
          }
  
        }
  


        showSpinner()
        // upload file if exists
        //since it update, we must be sure it is a file upload
        //a file upload was present if we can read a file.name, else it must have been the former string


        const contentMethod = thesis?.current_thesis_content.method
        // if( (contentMethod === "upload_document_method") && !(upl) )
        // if (thesis?.current_thesis_content.method==="upload_document_method" && thesis?.current_thesis_content?.content?.name ){ //this is better and is reserved for file uploads for fresh uploads

        //this is how we would do it
        //we eill modify the content and url once we change contentAppendMethod
        //so if we choose a method,
        //on submitting we check
        //if there is a new value supplied for it in the outer state
        //if there is none, it means we want to use the old value
        //but the the old value method, match with the new value method,
        //if they dont then we dont allow jack,
        //we must ask the user to supply new values or change the content append method to the method of the old value
        //if they do, we can use the old values

        const trackers = {
          // shouldUseOldPdf: false,
          // shouldUseNewPdf: false,

          // shouldUseOldText: false,
          // shouldUseNewText: false,
          
          pdfSuccess: false,
          textSuccess: false
        }  


        if (thesis?.current_thesis_content.method === "upload_document_method"){ //this is better and is reserved for file uploads for fresh uploads

          //check if we have an uploaded document
          //if not check if we have an uploaded document in the default
          //if we have in the default, then continue

          
          //trackers.pdfSuccess is true on success
          //have we uploaded something
          if(uploadedDocument?.name && !trackers?.pdfSuccess){
          
            console.warn('Passed through upload route')
            
            const fileForUpload = uploadedDocument
            // console.warn(fileForUpload)
            
            if(!fileForUpload?.name){
              return alertSnackbar('Please reupload the document')
              // return console.log('Please reupload the document')
            }
            if (!(fileForUpload instanceof File) || !fileForUpload?.name) {
              return alertSnackbar('Please reupload the document');
            }

            const data = new FormData();
            // const filename = Date.now() + file.name;
            const filename =  uuidv4() + fileForUpload.name
            // console.log(uuidv4())
            
            data.append("name", filename);
            //the above corresponds to the req.body.name
            data.append("file", fileForUpload);
            //the above corresponds to upload.single("file") in the router post method of the backend
            newThesisData.current_thesis_content.url = filename
            newThesisData.current_thesis_content.content = filename

            // return alertSnackbar("Please upload a pdf or use the text editor")
            //for the newThesisData.url, we never manipulate the state itself, because throughtout the lifecycle of the app, we set is as '' i.e empty, it is only when we fetch it from the server that we have a value

            // return alertSnackbar("Please upload a pdf or use the text editor")
            // newThesisData.current_thesis_content.content = filename
            // return alertSnackbar("Please upload a pdf or use the text editor")
            
            // return ''
            try{
              await axiosInstance.post(
                '/upload/upload_thesis', 
                data, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              })

              console.log('successfully uploaded');
              trackers.pdfSuccess = true;
            }
            catch(error){
              console.error('error')
              alertSnackbar('Error occurred during file upload...please try again. If the issue persists, kindly refresh this page');
              //if any issue occurs
              newThesisData.current_thesis_content.url = defaultThesisFromServer?.current_thesis_content?.url
              newThesisData.current_thesis_content.content = defaultThesisFromServer?.current_thesis_content?.content

              throw error
    
            }
            finally{

            }
            

            // return alertSnackbar("Please upload a pdf or use the text editor")
          }


          //we havent uploaded something
          //but we are using upload method
          //but is our method same as our old server
          if( !uploadedDocument?.name && !trackers.pdfSuccess){

            if(thesis?.current_thesis_content.method !== defaultThesisFromServer?.current_thesis_content?.method){
              //if it is not the same method
              
              if(thesis.current_thesis_content.method === "upload_document_method"){

                return alertSnackbar(`You chose to upload a pdf but you have not uploaded any pdf. Please upload a pdf or use the text editor box `)
              }
              if(thesis.current_thesis_content.method === "text_editor_method"){

                return alertSnackbar(`You chose to use the text editor box but you have not supplied any text. Please supply text to the text editor box, or upload a pdf instead`)
              }
              return alertSnackbar(`You have not supplied content for the method you chose `)
            }

            //below means we are still using upload method, but we dont actually want to upload a new file
            //so use the old ones from the default
            newThesisData.current_thesis_content.url = defaultThesisFromServer?.current_thesis_content.url;
            newThesisData.current_thesis_content.content =  defaultThesisFromServer?.current_thesis_content.content;


          }


        }




        //if text editor, upload text
        if (thesis.current_thesis_content.method==="text_editor_method"  ){

          if(thesisTextEditorValue && !trackers?.textSuccess){
            // if(!thesisTextEditorValue){
            //   return alertSnackbar("Please upload a file or use the text editor box", 'error')
            // }
            if(thesisTextEditorValue === '<p><br></p>'){
              console.log('empty default box with p tags')
              return alertSnackbar("You chose to use the text editor box. Please enter some text into the editor box", 'error')

            }
            if(thesisTextEditorValue === ''){
              console.log('empty null box')
              return alertSnackbar("You chose to use the text editor box. Please enter some text into the editor box", 'error')

            }

            newThesisData.current_thesis_content.content =  thesisTextEditorValue;
            newThesisData.current_thesis_content.url = ''

            trackers.textSuccess = true
          }


          //we havent added any text
          //but we are using text method
          //but is our method same as our old server
          if( !thesisTextEditorValue && !trackers?.textSuccess){


            if(thesis?.current_thesis_content?.method !== defaultThesisFromServer?.current_thesis_content?.method){
              //if it is not the same method
              
              if(thesis.current_thesis_content.method === "upload_document_method"){
                return alertSnackbar(`You chose to upload a pdf but you have not uploaded any pdf. Please upload a pdf or choose use the text editor box `)
              }
              if(thesis.current_thesis_content.method === "text_editor_method"){
                return alertSnackbar(`You chose to use the text editor box but you have not supplied any text. Please supply text to the text editor box, or choose to upload a pdf `)
              }
              return alertSnackbar(`You have not supplied content for the method you chose `)
            } 
            


            //below means we are still using upload method, but we dont actually want to upload a new file
            //so use the old ones from the default
            // newThesisData.current_thesis_content.url = defaultThesisFromServer?.current_thesis_content.url;
            newThesisData.current_thesis_content.url = '';
            newThesisData.current_thesis_content.content =  defaultThesisFromServer?.current_thesis_content.content;
          }

          // newThesisData.current_thesis_content.url = ''
        }


        const oneMethodUsed = (thesis.current_thesis_content.method === "upload_document_method") || (thesis.current_thesis_content.method === "text_editor_method")

        if (!oneMethodUsed){
          console.log('ERROR HERE')
          return alertSnackbar("Please upload a pdf or use the text editor box")

        }


        const res = await axiosInstance.post(`/theses/update_thesis/${thesis._id}`, newThesisData)


        // alertSnackbar('FULL SUCCESS, NAVIGATING', 'success')
        navigate(`${CLIENT_LINKS.view_thesis.url}/${res.data.message._id}`)
        //ENBABLE THE NAVIGATE FUNCTION


    }
    catch(error){
        console.error(error)
        setSnackbarMessage( `${error.response?.data?.message ||   'An error occurred' }`)
        setSnackbarSeverity('error')
        showSnackbar()
        // return(alert('An error occurred'))
        // dispatchTheses({
        //     type: 'CREATE_THESIS_FAILURE', 
        // })
    }
    finally{
      enableButton()
      hideSpinner()

      // SetIsButtonDisabled(false)
    }
  }

  return (
    <div 
      className='form-part-4-button-create-thesis'>
        <ReusableSnackbarComponent
          severity={interfaceState.snackbar_severity}
          open={interfaceState.snackbar_visible}  
          inner_message={interfaceState.snackbar_message} 
          setOpen={() => toggleInterfaceVisibility('snackbar_visible')}
          autoHideDuration={ interfaceState.snackbar_autohide_duration || 2000}/>
        <div style={{height: '0.5rem'}}>
          <LinearProgress sx={{padding: 0, margin: 0, display: interfaceState.isSpinnerActive ? true: 'none'}}/>
        </div>
        <button 
        disabled={interfaceState.button_disabled}
        // onClick={()=>handleCreateThesis()}
        onClick={(e)=>handleSubmitFinal(e)}
        className='button-create-thesis'>Update Thesis</button>
    </div>
  )
}

















const UpdateThesisPage = () => {
  UseHideSidebar()

  const navigate = useNavigate()
  
  const {user} = useAuth()


  const user_privilege_levels = user?.privilege_levels
  const isAdmin = checkIfUserHasPrivilegeLevelFor_Util(authorizedPrivilegeLevelsThatCanAdmin_Util, user_privilege_levels )

  // const {dispatch: dispatchTheses}  = useTheses()
  const {dispatch: dispatchUpdateThesis, thesis, uploadedDocument, defaultThesisFromServer} = useUpdateThesisContext()

  console.log(thesis?.current_thesis_content)
  console.log(defaultThesisFromServer)
  // console.log(thesis.matric_id)
  // console.log(uploadedDocument)
  // console.warn(uploadedDocument)


  const [interfaceState, setInterfaceState] = useState({
    snackbar_visible: false,
    snackbar_message: "",
    snackbar_severity: "info",
    snackbar_autohide_duration: 1*60*60*24,
    button_disabled : false,
    isSpinnerActive: false,
    isPageSettingsOpened: false

    // confirmation_prompt: false //just use a different state so you can use usefect of this state, instead of typing each property
  })
  
  const showSnackbar = (val = true) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_visible: true  }))
  }
  const hideSnackbar = (val = true) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_visible: false  }))
  }
  const toggleInterfaceVisibility = (name) => {
    setInterfaceState((prev) => ({ ...prev,  [name]: !prev[name]  }))
  }
  const setSnackbarMessage = (message) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_message: message  }))
  }
  const setSnackbarSeverity = (severity) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_severity: severity  }))
  }
  const setSnackbarDuration = ( duration) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_autohide_duration: duration  }))
  }
  const handleInterfaceState = (name, value_passed) => {
    setInterfaceState((prev) => ({ ...prev,  [name]: value_passed  }))
  }

  const disableButton = () => {
    setInterfaceState((prev) => ({ ...prev,  button_disabled: true  }))
  }
  const enableButton = (name, value_passed) => {
    setInterfaceState((prev) => ({ ...prev,  button_disabled: false  }))
  }
  const showSpinner = () => {
    setInterfaceState((prev) => ({ ...prev,  isSpinnerActive: true  }))
  }
  const hideSpinner = () => {
    setInterfaceState((prev) => ({ ...prev,  isSpinnerActive: false  }))
  }

  const alertSnackbar = (message="hello, user", type="info", duration=9999999) => {
    setSnackbarMessage(message)
    setSnackbarSeverity(type)
    setSnackbarDuration(duration)
    showSnackbar()
  }

  const [newThesis, setNewThesis] = useState({
    title: "",
    author:"",
    matric_id: "",
    categories: [],
    department: sample_thesis.department,
    faculty: sample_thesis.faculty,
    institution:sample_thesis.institution,
    institution_abbreviation: sample_thesis.institution_abbreviation,
    year: new Date().getFullYear(),
    supervisor: "",
    abstract: "",
    keywords: [],
    email: "",
    pdfMainContentStartsAt: 1

  })
  // console.log(newThesis)


  // const {theses, dispatch: dispatchTheses} = useTheses()

  const handleSubmit = (e) => {
    e.preventDefault()
  }

  const handleChangeNewThesis = (e) => {
    setNewThesis(prev => (
      {
        ...prev,
        [e.target.name]: e.target.value
      }
    ))
  }

  const [keywording, setKeywording] = useState({
    keyword: '',
    keywords: [...thesis.keywords]
  })

  useEffect(() => {
    setNewThesis(prev => ({
      ...prev,
      keywords: keywording.keywords
    }))
    // setKeywording(prev => ({...prev, keyword: ''}))
  }, [keywording.keywords]);

  const handleChangeKeyword = (e) => {
    // setKeyword(e.target.value)
    setKeywording(prev => ({...prev, keyword: e.target.value}))
    // console.log(keyword)
  }

  const handleAddKeywordContainingCommas = (commaDelimitedKeywords) => {
    // const commaDelimitedKeywordsToAdd = commaDelimitedKeywords.split(',')
    // const commaDelimitedKeywordsToAdd = [...new Set(commaDelimitedKeywords.split(',')) ]
    const commaDelimitedKeywordsToAdd = [...new Set(commaDelimitedKeywords.split(',').map(word => word.trim()) ) ]

    let savedKeywordsInput = [...commaDelimitedKeywordsToAdd]

    commaDelimitedKeywordsToAddLoop:
    for (let commaDelimitedKeyword of commaDelimitedKeywordsToAdd){
      if(!(thesis.keywords?.length < 7 )){
        return alertSnackbar('Sorry. You are not allowed to add more than 7 keywords. You have 7 keywords already attached')
      }
      if(commaDelimitedKeyword.trim()?.length > 25){
        console.log('no from here')
        alertSnackbar(`Keyword cannot exceed 40 characters. The keyword you supplied: ${commaDelimitedKeyword.trim()} has ${commaDelimitedKeyword.trim()?.length} character(s)`)
        // break
        continue commaDelimitedKeywordsToAddLoop

      }

      if(!keywording.keyword){
        // savedKeywordsInput = savedKeywordsInput.filter(keyword => keyword !== commaDelimitedKeyword)
        alertSnackbar('Please add a keyword')
        continue commaDelimitedKeywordsToAddLoop

      }
      // console.log(keywording.keyword)

      const isWordAlreadyIncluded = () => {
        for (let keyword of keywording.keywords){
          if( keyword.toLowerCase().trim() === commaDelimitedKeyword.toLowerCase().trim() ){
            return true //true is bad
          }
        }
        return false
      }

      const isInputEmpty = () => {
        if( commaDelimitedKeyword.toLowerCase().trim().length < 1 ){
          //i
          return true //true is bad
        }
        return false
      }


      if(isWordAlreadyIncluded()  ){
        // console.log('nay1')

        savedKeywordsInput = savedKeywordsInput.filter(keyword => keyword !== commaDelimitedKeyword)
        setKeywording(prev => ({...prev, keyword: savedKeywordsInput.join(',') }))
        continue commaDelimitedKeywordsToAddLoop
      }

      if(isInputEmpty()){
        // console.log('nay2')
        // setKeywording(prev => ({...prev, keyword: ''}))
        savedKeywordsInput = savedKeywordsInput.filter(keyword => keyword !== commaDelimitedKeyword)
        setKeywording(prev => ({...prev, keyword: savedKeywordsInput.join(',') }))
        continue commaDelimitedKeywordsToAddLoop

      }

      setKeywording((prev) => 
        (
          {...prev, keywords: [...prev.keywords, commaDelimitedKeyword.toLowerCase().trim() ]}
        )
      )

      savedKeywordsInput = savedKeywordsInput.filter(keyword => keyword !== commaDelimitedKeyword)
      setKeywording(prev => ({...prev, keyword: savedKeywordsInput.join(',') }))

    }

    if(savedKeywordsInput.length <= 0){

      return  setKeywording(prev => ({...prev, keyword: '' }))

    }
    // console.log(savedKeywordsInput.join(','))

    //else if something is still there
    // setKeywording(prev => ({...prev, keyword: 'abc' }))
    // setKeywording(prev => ({...prev, keyword: savedKeywordsInput.toString() }))
    setKeywording(prev => ({...prev, keyword: savedKeywordsInput.join(',') }))
    // console.log(savedKeywordsInput.join(','))

    
  }

  // console.log(current_thesis.keywords.length)
  const handleAddKeyword = (e) => {
// console.log('FIRE')

    if(!(thesis.keywords?.length < 7 )){
      return alertSnackbar('Sorry. You are not allowed to add more than 7 keywords')
    }

    if(keywording?.keyword?.trim()?.split(',').length > 1){
      return handleAddKeywordContainingCommas(keywording.keyword)
    }

    if(keywording?.keyword?.trim()?.length > 25){
      console.log('from here')
      return alertSnackbar(`Keyword cannot exceed 40 characters. The keyword you supplied: ${keywording?.keyword} has ${keywording?.keyword?.trim()?.length} character(s)`)
    }


    if(!keywording.keyword){
      return alertSnackbar('Please add a keyword')
    }
    // console.log(keywording.keyword)

    const isWordAlreadyIncluded = () => {
      for (let keyword of keywording.keywords){
        if( keyword.toLowerCase().trim() === keywording.keyword.toLowerCase().trim() ){
          return true //true is bad
        }
      }
      return false
    }

    const isInputEmpty = () => {
      if( keywording.keyword.toLowerCase().trim().length < 1 ){
        //i
        return true //true is bad
      }
      return false
    }


    if(isWordAlreadyIncluded()  ){
      // console.log('nay1')
      return setKeywording(prev => ({...prev, keyword: ''}))
    }

    if(isInputEmpty()){
      // console.log('nay2')
      return setKeywording(prev => ({...prev, keyword: ''}))
    }

    setKeywording((prev) => 
      (
        {...prev, keywords: [...prev.keywords, keywording.keyword.toLowerCase().trim() ]}
      )
    )
    setKeywording(prev => ({...prev, keyword: ''}))

  }


  const handleEnterKeywordPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleAddKeyword();
    }
  }

  const handleDeleteKeyword = (keyword_to_delete) => {
    console.log(keywording.keywords)
    console.log(keyword_to_delete)

    setKeywording(
      prev => ({
        ...prev, 
        keywords:keywording.keywords.filter(keyword => keyword !== keyword_to_delete) 
      })
    )
  }




  //update quill content in state



  const [contentAppend, setContentAppend] = useState({
    ocr_method: false,
    text_editor_method: false,
    upload_document_method: true
  })

  console.log(contentAppend)

  const handleContentAppendMethod = (name) => {
    // console.log(name)
    dispatchUpdateThesis({
      type: "updateCurrentThesisContentMethod",
      payload: name
    })

    setContentAppend(prev => ({
      ocr_method: false,
      text_editor_method: false,
      upload_document_method: false,
      [name]: true
    }))
    window.scrollTo({
      top: window.scrollY + 500,  // Scroll down by 100 pixels
      behavior: 'smooth',         // Optional: Add smooth scrolling animation
    });
  }




  
  const dull_color = '#a2a2a2'
  const dull_bg_color = 'white'
  // const bright_color = 'white'
  const bright_color= 'black'
  const bright_bg_color = 'black'
  const dull_border_width= "1px"
  const bright_border_width= "2px"


  const {thesis_id} = useParams()

  const fetchThesisForUpdate = async () => {
    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_URL
    })

    const response = await axiosInstance.get(`/theses/thesis/${thesis_id}`, 
    {}
    );
    // console.log(response.data.message)
    return response.data.message;
  };
  const { 
    isLoading: isLoadingThesisForUpdate,
    isFetching: isFetchingThesisForUpdate, 
    error: errorFetchingThesisForUpdate, 
    data:fetched_thesis_for_update 
  } = useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['thesisForUpdate'],
    queryFn: () =>
      fetchThesisForUpdate()
  })


  // console.log(fetched_thesis_for_update)



  // console.log(fetched_thesis_for_update)
  useEffect(() => {
    const alertError = () => {
      if( errorFetchingThesisForUpdate && !isLoadingThesisForUpdate && !isFetchingThesisForUpdate && !fetched_thesis_for_update){
        const error = errorFetchingThesisForUpdate
        console.error(error)
        // setSnackbarDuration(60000)
        alertSnackbar(`${error?.response?.data?.message || 'Unable to fetch theses. An error occurred'}`, 'error', 9999999 )
      }
    }
    alertError()
  }, [isLoadingThesisForUpdate, isFetchingThesisForUpdate, errorFetchingThesisForUpdate, fetched_thesis_for_update]);

  // console.log(thesis._id)

  //update context whenever internal state changes
  useEffect(() => {
    dispatchUpdateThesis({
      type: "updateThesis",
      payload: newThesis
    })
  }, [newThesis]);

  //set current thesis whenever we fetch from server and data changes
  useEffect(() => {

    const setValuesToServerDefault = () => {


      if(fetched_thesis_for_update && !isLoadingThesisForUpdate && !isFetchingThesisForUpdate && !errorFetchingThesisForUpdate){
        console.log('WEEEEEEEEEEEEEEE  SEETTTTTTTTTTTTT IT AGAINNNNNNNNNNN')
        dispatchUpdateThesis({
          type: "setDefaultThesisFromServer",
          payload: fetched_thesis_for_update
        })
        setNewThesis(fetched_thesis_for_update)

        handleContentAppendMethod(fetched_thesis_for_update?.current_thesis_content?.method || 'upload_document_method' )
        // setKeywording(
        //   prev => ({
        //     ...prev, 
        //     keywords:fetched_thesis_for_update?.keywords
        //   })
        //   // fetched_thesis_for_update?.keywords
        // )
        setKeywording({
          keyword: '',
          // keywords: ['a', 'b']
          keywords: fetched_thesis_for_update?.keywords || []
        })

      }
    }
    setValuesToServerDefault()
    // console.log(fetched_thesis_for_update )
  }, [isLoadingThesisForUpdate, isFetchingThesisForUpdate, errorFetchingThesisForUpdate, fetched_thesis_for_update]);


  // console.log(fetched_thesis_for_update)
  // console.log('CURRENT THESIS IS: ', thesis)
  // console.log(newThesis?.keywords)
  // console.log(newThesis)


  const fetchAllCategories = async () => {
    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_URL
    })

    const response = await axiosInstance.get('/category/find_all_category');
    // console.log(response.data.message)
    return response.data.message;
  };
  const { data:fetched_category_list } = useQuery({
    queryKey: ['categoryList'],
    queryFn: () =>
      fetchAllCategories()
  })
  // console.log(fetched_category_list)


  const checkIfThesisCategoryIsPartOfFullCategoryList = (category_item) => {
    let isPresent = false
    for(let thesisCategoryItem of (newThesis?.categories || []) ){
      // for(let singleFetchedCategoryItem of ca)
      const matchItemFound = thesisCategoryItem?._id?.toString() === category_item?._id?.toString()
      if(matchItemFound){
        isPresent = true
        break
      }
    }
    
    return isPresent
  }

  // const[]
  const removeOrAddCategories = async(category) => {
    try{
      //check if category in array, if in array, remove it
      const is_category_present = checkIfThesisCategoryIsPartOfFullCategoryList(category)

      if(is_category_present){
        console.log(category, ': IS PRESENT')
        //remove the category from the array
        const modified_category = newThesis?.categories.filter(thesisCategoryItem => category?._id !== thesisCategoryItem?._id)
        // console.log(modified_category.length)
        

        setNewThesis(prev => ({...prev, categories: modified_category }))
        return ''
      }


      //if category not in array, add it
      setNewThesis(prev => ({...prev, categories:[...prev.categories, category] }))
    }
    catch(error){
      console.log(error)
      console.log('an error occurred when handling category')
    }

  }




  const fetchSupervisorList = async () => {
    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_URL
    })

    const response = await axiosInstance.get('/supervisor/find_all_supervisor');
    // console.log(response.data.message)
    return response.data.message;
  };
  const { data:fetched_supervisor_list } = useQuery({
    queryKey: ['supervisorList'],
    queryFn: () =>
      fetchSupervisorList()
  }
  )


  const initialSupervisorEnablexd = async (supervisor) => {
    try{
      if(fetched_thesis_for_update){
        const check_passed = (fetched_thesis_for_update.supervisor._id === supervisor._id) 
        if(check_passed){
          return true
        }
      }
      return false
    }
    catch(err){
      return false
    }
  }

  const initialSupervisorEnabled = (supervisor) => {
      if(fetched_thesis_for_update){
        const check_passed = (fetched_thesis_for_update?.supervisor?._id === supervisor?._id) 
        // console.log(check_passed)
        return check_passed
      }
      return false
  }


  const provideInputInfo = (name) => {
    // const {name, value} = e.target

    if(name === "email"){
      const requirements = 
      <>
        <div>Email must contain @  </div>
        <div> Email cannot contain spaces</div>
      </>

      alertSnackbar(requirements)
      
      return ''
    }
    if(name === "passwordOldInput"){
      const requirements = 
      <>
      <div>Password must be at least 8 characters in length </div>
      <div> Password must contain at least one digit </div>
      <div>Password must contain at least one lowercase letter</div>
      <div>Password must contain at least one uppercase letter </div>
      <div>Password and confirm password must match </div>
      </>
      
      alertSnackbar(requirements)


      return ''
    }

    if(name === "passwordNewInput"){
      const requirements = 
      <>
      <div>Password and confirm password must match </div>
      <div>Confirm password must be at least 8 characters in length </div>
      <div> Confirm password must contain at least one digit </div>
      <div>Confirm password must contain at least one lowercase letter</div>
      <div>Confirm password must contain at least one uppercase letter </div>
      </>
      
      alertSnackbar(requirements)


      return ''
    }

    if(name === "passwordNewInputConfirm"){
      const requirements = 
      <>
      <div>Password and confirm password must match </div>
      <div>Confirm password must be at least 8 characters in length </div>
      <div> Confirm password must contain at least one digit </div>
      <div>Confirm password must contain at least one lowercase letter</div>
      <div>Confirm password must contain at least one uppercase letter </div>
      </>
      
      alertSnackbar(requirements)


      return ''
    }

    
    if(name === "keywords"){
      const requirements = 
      <>
      <div>You must select at least one keyword</div>
      <div>A single keyword must not exceed 25 characters </div>
      <div>You can add as many as 7 different keywords</div>
      <div>You can either choose to enter your keywords and separate them with comma notation</div>
      <div>Or you can enter each keyword separately, ensure you click the add keyword button to attach your keyword(s) in any case  </div>
      </>
      
      alertSnackbar(requirements)


      return ''
    }

    if(name === "abstract"){
      const requirements = 
      <>
      <div>You must provide an abstract</div>
      <div>An abstract is a short summary of your work </div>
      <div>Must contain at least 7 words</div>
      </>
      
      alertSnackbar(requirements)
      // alertSnackbar("Sorry")


      return ''
    }



    
  }

  const provideInputFeedback = (name) => {
    const goodInput =
    <span 
      style={{cursor: 'pointer', color: 'green', display:'flex', alignItems:'center', justifyContent: 'center', fontSize: '0.8rem', width: '5%' }}
      className='register-page-form-inner-wrapper-group-2-a-icon-2'>
        <CheckCircle fontSize='1rem'/> 
    </span>

    const grayInput = 
    <span 
    style={{cursor: 'pointer', color: '#cac8c8', display:'flex', alignItems:'center', fontSize: '0.8rem', width: '5%' }}
    onClick={()=> provideInputInfo(name) }
    className='register-page-form-inner-wrapper-group-2-a-icon-2'><InfoOutlined fontSize='1rem'/> </span>

    const badInput = 
    <span 
    style={{cursor: 'pointer', color: 'tomato', display:'flex', alignItems:'center', justifyContent: 'center', fontSize: '0.8rem', width: '5%' }}
    onClick={()=> provideInputInfo(name) }
    className='register-page-form-inner-wrapper-group-2-a-icon-2'><InfoOutlined fontSize='1rem'/> </span>

    const value = thesis[name]

    
    if(name === "title"){
      const {isValid }= validateAlphanumericTitleBlockV1_Util(value)
      if(isValid){
          return goodInput
      }
      return  badInput
    }

    if(name === "author"){
      //no need to check it is uneditable
      const {isValid }= validateFullNameV2_Util(value)
      if(isValid){
          return goodInput
      }
      return  badInput
    }

    if(name === "email"){
      // no need to check. It is not editable and fetched from ls
      const {isValid }= validateEmail_Util(value)
      if(isValid){
          return goodInput
      }
      return  badInput
    }

    if(name === "matric_id"){
      const isPopulated = thesis?.matric_id
      if(isPopulated){
        const {isValid }= validateMatricIDV1_Util(value)
        if(isValid){
          return goodInput
        }
        return  badInput
      }
      return grayInput
    }

    if(name === "department"){
      const isPopulated = thesis?.department

      if(isPopulated){

        const {isValid }= validateAlphabeticTitleBlockV1_Util(value)
        if(isValid){
          return goodInput
        }
        return  badInput
      }
      return grayInput
    }

    if(name === "faculty"){
      const isPopulated = thesis?.faculty
      if(isPopulated){

        const {isValid }= validateAlphabeticTitleBlockV1_Util(value)
        if(isValid){
          return goodInput
        }
        return  badInput
      }
      return grayInput
    }

    if(name === "institution"){
      const isPopulated = thesis?.institution
      if(isPopulated){
        const {isValid }= validateAlphabeticTitleBlockV1_Util(value)
        if(isValid){
          return goodInput
        }
        return  badInput
      }
      return grayInput
    }
    if(name === "institution_abbreviation"){
      const isPopulated = thesis?.institution_abbreviation
      if(isPopulated){
        const {isValid }= validateSingleName_Util(value)
        if(isValid){
          return goodInput
        }
        return  badInput
      }
      return grayInput
    }


    //the below have not been checked
    //these checks also need to be done in the button itself before axios.method
    if(name === "year"){
      const yearValidityCheck2Passed = parseInt(value) > 1950
      const {isValid }= validateThesisYearV1_Util(value)

      if(isValid && yearValidityCheck2Passed){
          return goodInput
      }
      return  badInput
    }

    if(name === "supervisor"){
      // const {isValid }= validateAlph(value)
      const isValid = thesis?.supervisor
      if(isValid){
          return goodInput
      }
      return  badInput
    }
    if(name === "categories"){
      const isValid = thesis?.categories?.length > 0
      //no destructuring here
      if(isValid){
          return goodInput
      }
      return  badInput
    }
    if(name === "abstract"){
      // const {isValid }= validateSingleName_Util(value)
      const abstractLength = thesis?.abstract?.toString().trim().split(' ').length
      const isValid = abstractLength && (abstractLength > 9 && abstractLength < 1000)  
      //length must be at least 25 words
      if(isValid){
          return goodInput
      }
      return  badInput
    }
    if(name === "keywords"){
      const isValid = thesis?.keywords?.length > 0
      if(isValid){
          return goodInput
        }
      // return goodInput
      return  badInput
    }


    
    //default
    return grayInput

  }



  return (
    <>
    <div className='create-thesis-page'>
      <ReusableSnackbarComponent
        severity={interfaceState.snackbar_severity}
        open={interfaceState.snackbar_visible}  
        inner_message={interfaceState.snackbar_message} 
        setOpen={() => toggleInterfaceVisibility('snackbar_visible')}
        autoHideDuration={ interfaceState.snackbar_autohide_duration || 2000}
      />
      <div className='add-research-top'>
          <div className='add-research-top-inner'>
            <span className='add-research-top-inner-icon-container' ><CreateNewFolderOutlined fontSize='1rem'/></span>
            <span className='add-research-top-inner-text'>Update a research</span>
          </div>
      </div>
      <div className='create-thesis-page-wrapper'>
        <div className='create-thesis-page-form-sections'>
          <div className='form-part-1'>
            
            {/* the className form-part-1 is crucial */}
            <div className='create-thesis-page-form-item'>
              <label>Thesis Title</label>
                {provideInputFeedback("title")}
              <input 
              defaultValue={thesis?.title || ''}
              onChange={(e) => handleChangeNewThesis(e)}
              className='create-thesis-page-input' type='text' name="title"/>
            </div>
            <div  className='create-thesis-page-form-item'>
              <label>Author</label>
              <input 
              style={{cursor: isAdmin ? 'auto' : 'not-allowed'}}
              disabled={
                isAdmin ? false :true

              }
              defaultValue={   thesis?.author || ''}
              onChange={(e) => handleChangeNewThesis(e)}
              className='create-thesis-page-input' type='text' name="author" />
            </div>
            <div  className='create-thesis-page-form-item'>
              <label>Email</label>
              {provideInputFeedback("author")}

              <input 
              style={{cursor: isAdmin ? 'auto' : 'not-allowed'}}
              disabled={
                isAdmin ? false :true

              }
              defaultValue={thesis?.email || ''}
              onChange={(e) => handleChangeNewThesis(e)}
              className='create-thesis-page-input' type='email' name="email"  />
            </div>
            <div  className='create-thesis-page-form-item'>
              <label>Matric</label>
              {provideInputFeedback("matric_id")}

              <input 
              defaultValue={thesis?.matric_id || ''}
              onChange={(e) => handleChangeNewThesis(e)}
              className='create-thesis-page-input' type='text' name="matric_id" />
            </div>
            <div  className='create-thesis-page-form-item'>
              <label>Department</label>
              {provideInputFeedback("department")}

              <input 
              defaultValue={thesis?.department || ''}
              onChange={(e) => handleChangeNewThesis(e)}
              className='create-thesis-page-input' type='text' name="department"/>
            </div>
            <div  className='create-thesis-page-form-item'>
              <label>Faculty</label>
              {provideInputFeedback("faculty")}

              <input 
              defaultValue={thesis?.faculty || ''}
              onChange={(e) => handleChangeNewThesis(e)}
              className='create-thesis-page-input' type='text' name="faculty" />
            </div>
            <div  className='create-thesis-page-form-item'>
              <label>Institution</label>
              {provideInputFeedback("institution")}

              <input 
              defaultValue={thesis?.institution || ''} 
              onChange={(e) => handleChangeNewThesis(e)}
              className='create-thesis-page-input' type='text' name="institution"/>
            </div>
            <div  className='create-thesis-page-form-item'>
              <label>Institution Abbreviation</label>
              {provideInputFeedback("institution_abbreviation")}

              <input 
              defaultValue={thesis?.institution_abbreviation || ''}
              onChange={(e) => handleChangeNewThesis(e)}
              className='create-thesis-page-input' type='text' name="institution_abbreviation" />
            </div>
            <div  className='create-thesis-page-form-item'>
              <label>Year</label>
              {provideInputFeedback("year")}

              <input 
              defaultValue={thesis?.year || ''}
              onChange={(e) => handleChangeNewThesis(e)}
              className='create-thesis-page-input'
              type='number' 
              name="year" 
              max={new Date().getFullYear()}
              min={1}
              />
            </div>
            <div className='create-thesis-page-form-item'>
                <label htmlFor="supervisor">Supervisor:</label>
                {provideInputFeedback("supervisor")}

                <select
                    id="supervisor"
                    name="supervisor"
                    // value={selectedSupervisor}
                    onChange={(e) => handleChangeNewThesis(e)}
                    required>
                    <option value="">Select a Supervisor</option>
                    {fetched_supervisor_list?.map((supervisor, index) => (
                        <option 
                        // selected={index === 10 ? true : false}
                        selected={initialSupervisorEnabled(supervisor)}
                        key={supervisor._id || index} value={supervisor._id}>
                            {supervisor.supervisor_title} {supervisor.supervisor_name}
                        </option>
                    ))}
                </select>
            </div>
            <div className='create-thesis-page-form-item'>
                <label htmlFor="category">Category(multiple allowed):</label>
                {provideInputFeedback("category")}

                <div 
                className='create-thesis-page-category'
                >
                  <div 
                  className='create-thesis-page-category-inner'
                  >
                    {
                    fetched_category_list?.length > 0 &&
                    fetched_category_list.map((category_item, index) => 
                    <div 
                    key={category_item?._id || index}
                    // onClick={() => removeOrAddCategories(category_item._id)}
                    onClick={() => removeOrAddCategories(category_item)}
                    //since we have popultated our response we cant pass only id
                    // className='create-thesis-page-category-inner-item' 
                    className={
                     checkIfThesisCategoryIsPartOfFullCategoryList(category_item) ?
                      'create-thesis-page-category-inner-item active': 
                      'create-thesis-page-category-inner-item' 
                    }>
                      <span className='ctpcii-span'>
                        {category_item.category}
                      </span>
                      <span className='ctpcii-span'>
                        {
                        !checkIfThesisCategoryIsPartOfFullCategoryList(category_item) &&
                        <Add fontSize='1rem' />
                        }
                        {
                        checkIfThesisCategoryIsPartOfFullCategoryList(category_item) &&
                        <Check fontSize='1rem' />
                        }
                      </span>
                      {/* {category._id} */}
                    </div>
                    )
                    }
                    </div>
                </div>
                
            </div>
            <div  className='create-thesis-page-form-item'>
              <label>Abstract/Summary</label>
              {provideInputFeedback("abstract")}

              <textarea
              defaultValue={thesis?.abstract || ''}
              onChange={(e) => handleChangeNewThesis(e)}
              name="abstract"
              placeholder='Type or paste your abstract in this box...minimum of 10 words'
               className='create-thesis-page-form-item-textarea'
              // cols={}
              rows={5}
              />
            </div>
          </div>
          <div className='form-part-1-choose-option'>
                <div className='form-part-1-choose-option-head'>
                  Fill in the content of your thesis using any of the following options
                </div>
                <div 
                className='form-part-1-choose-option-body'>
                  {/* ocr */}
                  <div className='form-part-1-choose-option-item'>
                    <div 
                     onClick={() => {
                      handleContentAppendMethod('text_editor_method');
                      navigate(CLIENT_LINKS.ocr_module.url)
                    }}
                    className='form-part-1-choose-option-item-inner'
                    style={{
                      borderColor: contentAppend.ocr_method ? bright_color : dull_color ,
                      color: contentAppend.ocr_method ? bright_color : dull_color ,
                      borderWidth: contentAppend.ocr_method ? bright_border_width : dull_border_width
                      // backgroundColor: contentAppend.ocr_method ? bright_bg_color: dull_bg_color
                    }}>
                      Extract text from images (recommended for scanned hardcopies)
                      {
                        contentAppend.ocr_method &&
                        <Check />
                      }
                    </div>
                    {
                    !contentAppend.ocr_method &&
                    <button onClick={() => handleContentAppendMethod('ocr_method')} >
                        use
                    </button>
                    }
                  </div>
                  {/* text editor */}
                  <div className='form-part-1-choose-option-item'>
                    <div 
                    onClick={() => handleContentAppendMethod('text_editor_method')}
                    className='form-part-1-choose-option-item-inner'
                    style={{
                      borderColor: contentAppend.text_editor_method ? bright_color : dull_color ,
                      color: contentAppend.text_editor_method ? bright_color : dull_color 
                    }}>

                      Use the text editor

                      {
                        contentAppend.text_editor_method &&
                        <Check />
                      }
                    </div>
                    {
                    !contentAppend.text_editor_method &&
                    <button onClick={() => handleContentAppendMethod('text_editor_method')} >
                        use
                    </button>
                    }
                  </div>
                  {/* upload doc */}
                  <div className='form-part-1-choose-option-item'>
                    <div 
                    onClick={() => handleContentAppendMethod('upload_document_method')}
                    className='form-part-1-choose-option-item-inner'
                    style={{
                      borderColor: contentAppend.upload_document_method ? bright_color : dull_color ,
                      color: contentAppend.upload_document_method ? bright_color : dull_color 
                    }}>                      
                      Use a pdf file
                      {
                        contentAppend.upload_document_method &&
                        <Check />
                      }
                    </div>
                    {
                    !contentAppend.upload_document_method &&
                    <button onClick={() => handleContentAppendMethod('upload_document_method')} >
                        use
                    </button>
                    }
                  </div>
                </div>
            </div>
          {
          contentAppend.upload_document_method && 
          <UpdateThesisAddDocumentComponent />
          }
          {
          contentAppend.upload_document_method && 
          <div  className='create-thesis-page-form-item'>
            <label>Main Writeup begins on what page?  (i.e Chapter 1 appears on what page of the uploaded file?)</label>
            <input 
            onChange={(e) => handleChangeNewThesis(e)}
            className='create-thesis-page-input' 
            type='number' 
            min={1}
            name="pdfMainContentStartsAt" 
            />
          </div>
          }
          {
            contentAppend.text_editor_method &&
            <UpdateThesisTextEditorComponent />
          //dynamic display * differs from &&
          }


          {/* keywords section */}
          <div className='form-part-3 keyword-section'>
            <label className='keyword-section-label'>Keywords (max 7)</label>
            <div className='keyword-section-input'>
              <input 
              value={keywording.keyword}
              className='keyword-section-input-box' type='text' name="keywords" 
              onChange={(e) => handleChangeKeyword(e)}
              onKeyDown={handleEnterKeywordPress}

              placeholder="type keyword e.g database"/>
              <button 
              onClick={() => handleAddKeyword()}
              className='keyword-section-button'>Add keyword</button>
            </div>
            <div className='current-keywords'>
              {
              // keywording.keywords.length > 0 &&
              // <span className='current-keywords-text'>Current Keywords:</span>
              }
              <span className='keyword-items'>
                {newThesis?.keywords.map((keyword, index) => (
                  <div key={index} 
                  className='keyword-items-wrapper'
                  onClick={() => handleDeleteKeyword(keyword)}>
                    <span className='keyword-item' >{keyword}</span>
                    <span className='keyword-item-delete'>
                      <Remove 
                      fontSize='1rem'
                     />
                    </span>
                  </div>
                ))}
              </span>
            </div>

          </div>

          {/* create thesis button*/}
          <UpdateThesisButton />


        </div>
      </div>
    </div>
    </>
  )
}


const CreateThesisPagePlusContext = () => {

  return (
    // <CreateThesisProvider>
      <UpdateThesisPage />
    // </CreateThesisProvider>
  )
}



export default CreateThesisPagePlusContext
// export default CreateThesisAddDocumentComponent




















