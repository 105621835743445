import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import './reusablePaginationComponent.scss'

const ReusablePaginationComponent = ({ currentPage, totalPages, onChange }) => {

    // console.log(currentPage, totalPages)
  
    const siblingsToShow = 2;
    const siblingsRange = siblingsToShow * 2;
  
    const renderPageNumbers = () => {
      const pages = [];
  
      for (let page = 1; page <= totalPages; page++) {
        const isCurrent = page === currentPage;
        const isSibling = Math.abs(page - currentPage) <= siblingsToShow;
  
        if (isCurrent || isSibling) {
          if(page === totalPages){
            continue
            //dont push the last item
          }
  
          pages.push(
            <button
              style={{
                // display: 'none'
                // display: currentPage === totalPages ? 'none': 'block'
              }}
              key={page}
              className={`page-number${isCurrent ? ' current' : ''}`}
              onClick={() => onChange(page)}
            >
              {page}
            </button>
          );
        } else if (pages.length > 0 && pages[pages.length - 1] !== '...') {
          pages.push('...');
        }
      }
  
      return pages;
    };
  
    const handleChange = (direction) => {
      if(direction === 'plus'){
        if(currentPage+1 > totalPages){
          return alert('maximum page reached')
        }
        return onChange(currentPage + 1)
      }
  
      if(direction === 'minus'){
        return ''
      }
    }
  
    return (
      <div className="pagination-bottom-bar-component">
        <div className='pagination-bottom-bar-component-inner'>
          <span
            className="pagination-bottom-bar-component-inner-button-left"
            onClick={() => onChange(currentPage - 1)}
            disabled={currentPage === 1}
            style={{
              display: currentPage === 1 ? 'none' : 'block'
            }}
          >
            <KeyboardArrowLeft fontSize='1rem'/>
          </span>
  
          <div className='pagination-component-pages'>
            {renderPageNumbers()}
          </div>
  
          <div className='pagination-component-pages'>
            <button 
              // key={page}
              className={`page-number${(currentPage === totalPages) ? ' current' : ''}`}
              onClick={() => onChange(totalPages)}>
              {(totalPages > 0) && totalPages}
            </button>
          </div>
  
          <span
            className="pagination-bottom-bar-component-inner-button-right"
            onClick={() => onChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            style={{
              display: currentPage === totalPages ? 'none' : 'block'
            }}
          >
            <KeyboardArrowRight fontSize='1rem'/>
            {/* Hello */}
          </span>
        </div>
      </div>
    );
  }

export default ReusablePaginationComponent