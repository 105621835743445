import { BookmarkAdd, Cached, DoubleArrow, Email, KeyboardArrowLeft, KeyboardArrowRight, Menu, MoreHoriz, Person3, Sell, Share, Shuffle, Visibility } from '@mui/icons-material'
import { UseHideSidebar, UseShowSidebar } from '../../hooks/UseSidebarVisibility'
import './homePage.scss'
import { useTheses } from '../../contexts/ThesesContext'
import { textLengthShortened } from '../../utils/Utils'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import useFetch from '../../hooks/useFetch'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { UseHideTopbar, UseShowTopbar } from '../../hooks/UseTopbarVisibility'
import ReusableSearchBox from '../../components/reusables/ReusableSearchBox/ReusableSearchBox'
import { useAuth } from '../../contexts/AuthContext'
import { CLIENT_LINKS } from '../../utils/LINKS'
import ReusablePaginationComponent from '../../components/reusables/ReusablePaginationComponent/ReusablePaginationComponent'
import { useQuery } from '@tanstack/react-query'
import ReusableThesisItem from '../../components/reusables/ReusableThesisItem/ReusableThesisItem'
import { ReusableLoader3, ReusableLoader5, ReusableLoader7 } from '../../components/reusables/ReusableLoaders/ReusableLoaders'
import ReusableSnackbarComponent from '../../components/reusables/ReusableSnackbarComponent/ReusableSnackbarComponent'
// import { LinearProgress } from '@mui/material'
import { LinearProgress } from '@mui/material';







const HomePage = () => {
  // UseHideTopbar()
  // UseShowSidebar()
  UseShowTopbar()
  UseHideSidebar()
  const navigate = useNavigate()
  const [interfaceState, setInterfaceState] = useState({
    snackbar_visible: false,
    snackbar_message: "",
    snackbar_severity: "info",
    snackbar_autohide_duration: 2000,
    button_disabled : false,
    confirmationDialogVisible: false
    // confirmation_prompt: false //just use a different state so you can use useeffect of this state, instead of typing each property
  })

  const showSnackbar = (val = true) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_visible: true  }))
  }
  const hideSnackbar = (val = true) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_visible: false  }))
  }
  const toggleInterfaceVisibility = (name) => {
    setInterfaceState((prev) => ({ ...prev,  [name]: !prev[name]  }))
  }
  const setSnackbarMessage = (message) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_message: message  }))
  }
  const setSnackbarSeverity = (severity) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_severity: severity  }))
  }
  const setSnackbarDuration = ( duration) => {
    setInterfaceState((prev) => ({ ...prev,  snackbar_autohide_duration: duration  }))
  }
  const handleInterfaceState = (name, value_passed) => {
    setInterfaceState((prev) => ({ ...prev,  [name]: value_passed  }))
  }

  const disableButton = () => {
    setInterfaceState((prev) => ({ ...prev,  button_disabled: true  }))
  }
  const enableButton = (name, value_passed) => {
    setInterfaceState((prev) => ({ ...prev,  button_disabled: false  }))
  }
  const alertSnackbar = (message="hello, user", type="info", duration=9999999) => {
    setSnackbarMessage(message)
    setSnackbarSeverity(type)
    setSnackbarDuration(duration)
    showSnackbar()
  }


  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL
  })
  
  const [titleSearch, setTitleSearch] = useState('')
  const handleSearch = () => {

    if(!titleSearch){
      return alertSnackbar('please fill the search input box', 'info')
    }

    navigate(`${CLIENT_LINKS.advanced_search_results.url}?&title=${titleSearch}`)
  }


  // const handleSubmit = () => {
  //   if(!searchParameterTitle){
  //     return alertSnackbar('please fill in a search parameter', 'info')
  //   }
  //   // navigate(
  //   //   `${CLIENT_LINKS.search_results.url}?title=${searchParameterTitle}`, 
  //   //   {state: {router_SearchParameterTitle: searchParameterTitle }}
  //   // )
  //             dispatch({
  //     type: 'MODIFY_SEARCH_STATE_SESSION',
  //     newObj: 'current_page',
  //     payload: 1
  //   })
  //   //adv page is not updating because the currentPage is not changing
  //   //we use this double to force adv page to rerender

  //   dispatch({
  //     type: 'FORCE_UPDATE',
  //   })

  //   navigate(`${CLIENT_LINKS.advanced_search_results.url}?title=${searchParameterTitle}`)
  // }
  
  const handleEnterPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSearch();
    }
  };


  const fetchAllThesisPaginated = async () => {
    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_URL
    })

    const response = await axiosInstance.get(`/theses/view_random_theses`, 

    );
    // console.log(response.data.message)
    return response.data.message;
  };
  const { 
    isFetching: isFetchingRandomTheses, 
    isLoading: isLoadingRandomTheses, 
    error: errorFetchingRandomTheses,
    data:fetched_random_theses, 
    refetch:refetchRandomTheses
  } = useQuery({
    refetchOnWindowFocus:false,
    queryKey: ['view_all_thesis_paginated'],
    queryFn: () =>
      fetchAllThesisPaginated()
  })

  useEffect(() => {
    const alertError = () => {

      if(errorFetchingRandomTheses && !fetched_random_theses && !isFetchingRandomTheses && !isLoadingRandomTheses ){
        const error = errorFetchingRandomTheses
        // setSnackbarDuration(60000)
        alertSnackbar(`${error?.response?.data?.message || 'Unable to fetch suggested theses. An error occurred'}`, 'error')
      }
      if(!user?._id){
        alertSnackbar(`Please login. You are not authenticated`, 'error')
      }
    }
    alertError()
  }, [
    errorFetchingRandomTheses, 
    fetched_random_theses,
    isFetchingRandomTheses ,
    isLoadingRandomTheses 
  ])

  // console.log(fetched_random_theses)

  const {user }= useAuth()



  

  return (
    <div className='homepage'>
      <ReusableSnackbarComponent
      severity={interfaceState.snackbar_severity}
      open={interfaceState.snackbar_visible}  
      inner_message={interfaceState.snackbar_message} 
      setOpen={() => toggleInterfaceVisibility('snackbar_visible')}
      autoHideDuration={ interfaceState.snackbar_autohide_duration || 2000}/>
      <div className='homepage-wrapper'>
        <div className='homepage-wrapper-section1'>
          <h1 className='homepage-wrapper-section1-h1'>Theses</h1>
          <div className='homepage-wrapper-section1-search'>
            <div className='homepage-wrapper-section1-input-container'>
              <input 
              onKeyDown={handleEnterPress}

              onChange={(e) => setTitleSearch(e.target.value)}
              className='homepage-wrapper-section1-input'
              type='text'
              placeholder='search for thesis' />
              <div className='use-advanced-search'>
                <Link to="/advanced_search" className='router-dom-LINK'>
                  use advanced search?
                </Link>
              </div>
            </div>
            <div className='homepage-wrapper-section1-button-container'>
              <button 
              onClick={() => handleSearch(titleSearch)}
              className='homepage-wrapper-section1-button'>Search</button>
            </div>
          </div>
        </div>
        <Link 
        to={CLIENT_LINKS.view_all_theses.url}
        className='homepage-wrapper-section2 router-dom-LINK'>
            <span 
            className='homepage-wrapper-section2-icon'>
              <Menu fontSize='1rem' /></span> Or browse through the whole database? <DoubleArrow fontSize="1rem" />
        </Link>
        {
        fetched_random_theses?.length > 0 &&
        <div className='homepage-wrapper-random-suggestions'>
          <div className='homepage-wrapper-random-suggestions-wrapper'>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              gap: '2rem'
            }}>
              <h1 
              style={{
                fontWeight: '300',
                fontSize: '1rem'
              }}
              className='homepage-wrapper-random-suggestions-wrapper-h1'>Suggested:</h1>
              {
              !isFetchingRandomTheses &&
              <span 
              onClick={() => refetchRandomTheses()}
              style={{
                display: 'flex',
                alignItems: 'center',
                lineHeight: '1',
                cursor: 'pointer'
               }}>
                <Cached fontSize='1rem' />
              </span>
              }
            </div>

            <div style={{height: '0.5rem', marginBottom:'0.25rem'}}>
                  <LinearProgress
                  sx={{padding: 0, margin: 0, display: isFetchingRandomTheses ? true: 'none'}}/>
            </div>
            <div className='homepage-wrapper-random-suggestions-wrapper-items'>
            {
            fetched_random_theses.map((thesis, index) => (
              <>
              {/* <span key={index}>{thesis?.title || 'abc'}</span> */}
              <ReusableThesisItem key={thesis?._id || index} authUser={user} passed_thesis={thesis} />
              </>
            ))
            }
            </div>
          </div>
        </div>
        }
        <div 
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'flex-end',
          padding: '1rem 0rem'
        }}>
          <Link to={CLIENT_LINKS.view_all_theses.url}>...view all</Link>
        </div>
      </div>
    </div>
  )
}



export default HomePage